import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SocketService } from 'app/shared/services/socket/socket.service';
import { cloneDeep } from 'lodash';
import {
    BehaviorSubject,
    Observable,
    catchError,
    forkJoin,
    lastValueFrom,
    map,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';
import { Chat, Contact, Profile } from './chat.types';
import { User } from 'app/core/user/user.types';

@Injectable({ providedIn: 'root' })
export class ChatService {
    private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject(null);
    private _contact: BehaviorSubject<Contact> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
    private _members: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
    private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);

    private userInfo = this._authService.userInfo;;
    private contactInfo = this._authService.contactInfo;

    /**
     * Constructor
     */
    constructor(
        private _commonService: CommonService,
        private _socketService: SocketService,
        private _authService: AuthService
    ) {
        this._socketService.connect();
        this._socketService.receiveMessage((data) => {
            this.getChatById(data?.chatId).subscribe();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat> {
        return this._chat.asObservable();
    }

    /**
     * Getter for chats
     */
    get chats$(): Observable<Chat[]> {
        return this._chats.asObservable();
    }

    /**
     * Getter for contact
     */
    get contact$(): Observable<Contact> {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<Contact[]> {
        return this._contacts.asObservable();
    }

    /**
     * Getter for members
     */
    get members$(): Observable<Contact[]> {
        return this._members.asObservable();
    }

    /**
     * Getter for profile
     */
    get profile$(): Observable<Profile> {
        return this._profile.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chats
     */
    getChats(filterKey = 'archived', filterValue = false): Observable<any> {
        return this._commonService
            .getDataByFields(MODELS_CONSTANTS.CHATS, {
                sender_contact_id: this.contactInfo?._id,
                type: 'chat',
            })
            .pipe(
                catchError((err) => {
                    return of([]);
                }),
                map(async (response: IResponse<Chat[]>) => {
                    if (response?.status === 200) {
                        let chats = response?.data || [];

                        const receiverChats$ = this.getReceiverChats();
                        const receiverChats = await lastValueFrom(
                            receiverChats$
                        );
                        const groups = await this.getGroups();
                        chats = [...chats, ...receiverChats, ...groups];

                        chats?.sort((a, b) => {
                            if (b.updatedAt > a.updatedAt) {
                                return 1;
                            } else if (a.updatedAt > b.updatedAt) {
                                return -1;
                            }
                            return 0;
                        });
                        for (let chat of chats) {
                            if (
                                chat?.type === 'chat' &&
                                chat?.receiver_contact_id
                            ) {
                                const contact$ =
                                    this._commonService.getDataById(
                                        MODELS_CONSTANTS.CONTACTS,
                                        chat?.receiver_contact_id
                                    );
                                const contact = await lastValueFrom(contact$);
                                chat['contact'] = contact?.data || null;
                            }

                            const settings$ =
                                this._commonService.getDataByFields(
                                    MODELS_CONSTANTS.CHAT_SETTINGS,
                                    {
                                        contact_id: this.contactInfo?._id,
                                        chat_id: chat?._id,
                                    }
                                );
                            const settings = await lastValueFrom(settings$);

                            chat['settings'] = settings?.data[0] || {
                                contact_id: this.contactInfo?._id,
                                chat_id: chat?._id,
                            };

                            const messages$ =
                                this._commonService.getDataByField(
                                    MODELS_CONSTANTS.CHAT_MESSAGES,
                                    'chat_id',
                                    chat?._id
                                );
                            const messages = await lastValueFrom(messages$);

                            chat['messages'] = messages?.data || [];
                            chat.messages.sort((a, b) => {
                                if (b.createdAt > a.createdAt) {
                                    return 1;
                                } else if (a.createdAt > b.createdAt) {
                                    return -1;
                                }
                                return 0;
                            });

                            chat?.messages?.forEach((e) => {
                                e['isMine'] =
                                    e?.sender_contact_id ===
                                    this.contactInfo?._id;
                            });

                            chat.lastMessageAt = chat?.messages?.[0]?.createdAt;
                            chat.lastMessage = chat?.messages?.[0]?.value;
                        }

                        chats = chats?.filter(
                            (e) => !!e?.settings?.[filterKey] === filterValue
                        );

                        this._chats.next(chats);
                        return chats;
                    } else {
                        this._chats.next(response?.data || []);
                        return response?.data || [];
                    }
                })
            );
    }

    /**
     * Get reciever chats
     */
    getReceiverChats(): Observable<any> {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.CHATS,
                'receiver_contact_id',
                this.contactInfo?._id
            )
            .pipe(
                map((response) => {
                    const chats = response?.data?.map((e) => {
                        e['receiver_contact_id'] = cloneDeep(
                            e.sender_contact_id
                        );
                        e['sender_contact_id'] = this.contactInfo?._id;
                        return e;
                    });

                    return chats;
                })
            );
    }

    /**
     * Get groups
     */
    async getGroups(): Promise<Chat[]> {
        const response$ = this._commonService
            .getDataByFields(MODELS_CONSTANTS.CHATS, {
                tenant_id: this.userInfo?.tenant_id,
                type: 'group',
            })
            .pipe(
                map(async (response: IResponse<Chat[]>) => {
                    if (response?.status === 200) {
                        let groups = response?.data?.filter(
                            (e) =>
                                e?.sender_contact_id ===
                                    this.contactInfo?._id ||
                                e?.members?.includes(this.contactInfo?._id)
                        );
                        groups = groups.map((e) => {
                            e.contact = {
                                name: e?.name,
                                avatar: e?.avatar,
                            };
                            delete e?.name;
                            delete e?.avatar;
                            return e;
                        });

                        return groups;
                    } else {
                        return [];
                    }
                })
            );
        const response = await lastValueFrom(response$);
        return response;
    }

    /**
     * Get contacts
     */
    getContacts(): Observable<Contact[]> {
        return forkJoin({
            contacts: this._commonService.getDataByField(
                MODELS_CONSTANTS.CONTACTS,
                'tenant_id',
                this.userInfo?.tenant_id
            ),
            users: this._commonService.getDataByField(
                MODELS_CONSTANTS.USERS,
                'tenant_id',
                this.userInfo?.tenant_id
            )
        }).pipe(
            map(({ contacts, users }: { contacts: IResponse<Contact[]>, users: IResponse<User[]> }) => {
                const userIds: string[] = users?.data?.map(user => user._id) || [];
        
                const filteredContacts: Contact[] = contacts?.data?.filter(contact => 
                    contact.enableLogin && contact.user_id && userIds.includes(contact.user_id)
                ) || [];
        
                this._members.next(filteredContacts);
    
                return filteredContacts;
            }),
            switchMap((filteredContacts: Contact[]) => {
                return this.chats$.pipe(
                    map(chats => {
                        const chatsContactIds = chats?.map(chat => chat.receiver_contact_id) || [];
        
                        const chatContacts = filteredContacts.filter(contact =>
                            !chatsContactIds.includes(contact._id) &&
                            contact.user_id !== this.userInfo?._id
                        );
        
                        this._contacts.next(chatContacts || []);
                        return chatContacts || [];
                    })
                );
            })
        );
    }
    

    /**
     * Get profile
     */
    getProfile(): Observable<any> {
        this.contactInfo = this._authService.contactInfo;
        this._profile.next(this.contactInfo);
        return of(this.contactInfo);
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any> {
        return this.chats$.pipe(
            take(1),
            switchMap((chats) => {
                return this._commonService
                    .getDataById(MODELS_CONSTANTS.CHATS, id)
                    .pipe(
                        switchMap(async (response: IResponse<Chat>) => {
                            let chat = response?.data;

                            if (chat?.type === 'group') {
                                chat.contact = {
                                    name: chat?.name,
                                    avatar: chat?.avatar,
                                };
                                delete chat?.name;

                                chat['memberDetails'] = {};

                                for (let member of chat?.members) {
                                    const response$ =
                                        this._commonService.getDataById(
                                            MODELS_CONSTANTS.CONTACTS,
                                            member
                                        );
                                    const memberDetails = await lastValueFrom(
                                        response$
                                    );
                                    chat['memberDetails'][member] =
                                        memberDetails?.data;
                                }
                            }

                            if (
                                chat?.receiver_contact_id &&
                                chat?.receiver_contact_id ===
                                    this.contactInfo?._id
                            ) {
                                chat.receiver_contact_id = cloneDeep(
                                    chat?.sender_contact_id
                                );
                                chat.sender_contact_id = this.contactInfo?._id;
                            }

                            if (chat?.receiver_contact_id) {
                                const contact$ =
                                    this._commonService.getDataById(
                                        MODELS_CONSTANTS.CONTACTS,
                                        chat?.receiver_contact_id
                                    );
                                const contact = await lastValueFrom(contact$);
                                chat['contact'] = contact?.data || null;
                            }

                            const settings$ =
                                this._commonService.getDataByFields(
                                    MODELS_CONSTANTS.CHAT_SETTINGS,
                                    {
                                        contact_id: this.contactInfo?._id,
                                        chat_id: chat?._id,
                                    }
                                );
                            const settings = await lastValueFrom(settings$);

                            chat['settings'] = settings?.data[0] || {
                                contact_id: this.contactInfo?._id,
                                chat_id: chat?._id,
                            };

                            const messages$ =
                                this._commonService.getDataByField(
                                    MODELS_CONSTANTS.CHAT_MESSAGES,
                                    'chat_id',
                                    chat?._id
                                );
                            const messages = await lastValueFrom(messages$);

                            chat['messages'] = messages?.data || [];
                            chat.messages.sort((a, b) => {
                                if (b.createdAt > a.createdAt) {
                                    return 1;
                                } else if (a.createdAt > b.createdAt) {
                                    return -1;
                                }
                                return 0;
                            });

                            chat?.messages?.forEach((e) => {
                                e['isMine'] =
                                    e?.sender_contact_id ===
                                    this.contactInfo?._id;
                            });

                            chat.lastMessageAt = chat?.messages?.[0]?.createdAt;
                            chat.lastMessage = chat?.messages?.[0]?.value;

                            // Update the chat
                            this._chat.next(chat);

                            // Update chats
                            if (chats?.length) {
                                const index = chats.findIndex(
                                    (e) => e._id === chat?._id
                                );
                                if (index !== -1) {
                                    chats[index] = chat;
                                    this._chats.next(chats);
                                }
                            }
                            // Return the chat
                            return chat;
                        }),
                        switchMap((chat) => {
                            if (!chat) {
                                return throwError(
                                    'Could not found chat with id of ' +
                                        id +
                                        '!'
                                );
                            }

                            return of(chat);
                        })
                    );
            })
        );
    }

    /**
     * Save Chat
     *
     * @param chat
     */
    saveChat(chat): Observable<any> {
        return this.chats$.pipe(
            take(1),
            switchMap((chats) => {
                return this._commonService
                    .saveRecord(MODELS_CONSTANTS.CHATS, chat)
                    .pipe(
                        tap((response: IResponse<any>) => {
                            if (response?.status === 200) {
                                if (chat?._id) {
                                    this.getChatById(chat?._id).subscribe(
                                        (chat) => {
                                            const index = chats?.findIndex(
                                                (e) => e?._id === chat?._id
                                            );
                                            if (index !== -1) {
                                                chats[index] = chat;
                                            } else {
                                                chats.push(chat);
                                            }
                                            this._chats.next(chats);
                                        }
                                    );
                                } else {
                                    this.getChatById(
                                        response?.data?._id
                                    ).subscribe((chat) => {
                                        chats.push(chat);
                                        this._chats.next(chats);
                                    });
                                }
                            }
                        })
                    );
            })
        );
    }

    /**
     * save chat settings
     */
    saveChatSettings(settings): Observable<IResponse<any>> {
        return this.chats$.pipe(
            take(1),
            switchMap((chats) => {
                return this._commonService
                    .saveRecord(MODELS_CONSTANTS.CHAT_SETTINGS, settings)
                    .pipe(
                        tap((response) => {
                            if (response?.status === 200) {
                                const index = chats?.findIndex(
                                    (e) => e?._id === settings?.chat_id
                                );
                                if (index !== -1) {
                                    chats[index].settings = response?.data;
                                    this._chat.next(chats[index]);
                                    this._chats.next(chats);
                                }
                            }
                        })
                    );
            })
        );
    }

    /**
     * Save receiver chat
     */
    createChat(chat): Observable<any> {
        return this._commonService.saveRecord(MODELS_CONSTANTS.CHATS, chat);
    }

    /**
     * Reset the selected chat
     */
    resetChat(): void {
        this._chat.next(null);
    }

    /**
     * Delete a chat
     */
    deleteChat(id: string, settingsId?: string): Observable<any> {
        return this.chats$.pipe(
            take(1),
            switchMap((chats) => {
                return this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.CHATS, id)
                    .pipe(
                        tap((response: IResponse<any>) => {
                            if (response?.status === 200) {
                                const index = chats.findIndex(
                                    (e) => e._id === id
                                );

                                if (index !== -1) {
                                    chats.splice(index, 1);
                                }
                                this._chats.next(chats);

                                if (settingsId) {
                                    this._commonService
                                        .deleteRecordsById(
                                            MODELS_CONSTANTS.CHAT_SETTINGS,
                                            settingsId
                                        )
                                        .subscribe();
                                }
                            }
                        })
                    );
            })
        );
    }

    /**
     * Send a message
     *
     * @param body
     */
    sendMessage(body): Observable<any> {
        return this.chats$.pipe(
            take(1),
            switchMap((chats) => {
                return this.chat$.pipe(
                    take(1),
                    switchMap((chat) => {
                        return this._commonService
                            .saveRecord(MODELS_CONSTANTS.CHAT_MESSAGES, body)
                            .pipe(
                                tap((response: IResponse<any>) => {
                                    if (response?.status === 200) {
                                        const message = response?.data;
                                        message['isMine'] =
                                            message?.sender_contact_id ===
                                            this.contactInfo?._id;
                                        chat?.messages?.unshift(message);
                                        chat.lastMessageAt = message?.createdAt;
                                        chat.lastMessage = message?.value;
                                        this._chat.next(chat);

                                        const chatIndex = chats.findIndex(
                                            (e) => e?._id === chat?._id
                                        );
                                        if (chatIndex !== -1) {
                                            chats[chatIndex] = chat;
                                            this._chats.next(chats);
                                        }

                                        let contactIds;
                                        if (chat?.type === 'group') {
                                            contactIds = chat?.members?.filter(
                                                (e) =>
                                                    e !== this.contactInfo?._id
                                            );
                                        } else {
                                            contactIds =
                                                message?.receiver_contact_id;
                                        }

                                        this._socketService.sendMessage(
                                            contactIds,
                                            {
                                                message: `Received a new chat message from ${this.userInfo?.name}`,
                                                chatId: chat?._id,
                                            }
                                        );
                                    }
                                })
                            );
                    })
                );
            })
        );
    }

    /**
     * Delete selected messages
     */
    async deleteSelectedMessages(ids): Promise<boolean> {
        for (let [index, id] of ids.entries()) {
            let success = true;
            const response$ = this._commonService.deleteRecordsById(
                MODELS_CONSTANTS?.CHAT_MESSAGES,
                id
            );
            const result = await lastValueFrom(response$);
            if (result?.status !== 200) {
                success = false;
            }

            if (index === ids?.length - 1) {
                return success;
            }
        }
    }

    /**
     * Delete all the messages from a chat
     */
    deleteAllMessagesFromChat(chat_id: string): Observable<IResponse<any>> {
        return this._commonService.deleteRecordsByfield(
            MODELS_CONSTANTS.CHAT_MESSAGES,
            'chat_id',
            chat_id
        );
    }

    /**
     *
     * @param contact
     */
    saveContact(contact): Observable<IResponse<any>> {
        return this._commonService
            .saveRecord(MODELS_CONSTANTS.CONTACTS, contact)
            .pipe(
                tap((response: IResponse<any>) => {
                    if (response?.status === 200 && response?.data) {
                        localStorage.setItem(
                            'contact_info',
                            JSON.stringify(response?.data)
                        );
                        this.getProfile();
                    }
                })
            );
    }
}
