import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { BehaviorSubject, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Label, Note } from './notes.types';

@Injectable({providedIn: 'root'})
export class NotesService
{
    // Private
    private _labels: BehaviorSubject<Label[] | null> = new BehaviorSubject(null);
    private _note: BehaviorSubject<Note | null> = new BehaviorSubject(null);
    private _notes: BehaviorSubject<Note[] | null> = new BehaviorSubject(null);

    private user = this._authService.userInfo;

    /**
     * Constructor
     */
    constructor(private _commonService: CommonService, private _authService: AuthService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for labels
     */
    get labels$(): Observable<Label[]>
    {
        return this._labels.asObservable();
    }

    /**
     * Getter for notes
     */
    get notes$(): Observable<Note[]>
    {
        return this._notes.asObservable();
    }

    /**
     * Getter for note
     */
    get note$(): Observable<Note>
    {
        return this._note.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get labels
     */
    getLabels(data?: any): Observable<Label[]>
    {
        const params = {};
        if(data){
            params['reference_type_id'] = data?.reference_type_id || null;
            params['reference_type'] = data?.reference_type;

            if(data?.reference_type !== 'module' && data?.reference_id) {
                params['reference_id'] = data?.reference_id;
            }
        } else {
            params['user_id'] = this.user?._id;
            params['reference_type'] = 'menu';
        }
        return this._commonService
            .getDataByFields(
                MODELS_CONSTANTS.LABELS,
                params
            )
            .pipe(
                map((response: IResponse<Label[]>) => {
                    const labels =
                        response?.data && response?.data?.length
                            ? response?.data?.filter((e) => e?.type === 'note')
                            : [];
                    this._labels.next(labels);
                    return labels;
                })
            );
    }

    /**
     * Add label
     *
     * @param title
     */
    addLabel(json: any, data?: any): Observable<any>
    {
        const body = {
            user_id: this.user?._id,
            type: 'note',
            title: json.title,
            color: json.color,
            reference_type: data ? data?.reference_type || 'form' : 'menu'
        };
        if (data) {
            body['reference_type_id'] = data?.reference_type_id;
            body['reference_id'] = data?.reference_id;
        }
        return this._commonService
            .saveRecord(MODELS_CONSTANTS.LABELS, body)
            .pipe(
                tap((response: IResponse<any>) => {
                    if (response.status === 200) {
                        this.getLabels(data).subscribe();
                    }
                })
            );
    }

    /**
     * Update label
     *
     * @param label
     */
    updateLabel(label: Label, data?: any): Observable<Label[]>
    {
        return this._commonService.saveRecord(MODELS_CONSTANTS.LABELS, label).pipe(
            tap((response) => {
                if(response?.status === 200){
                    this.getLabels(data).subscribe();
                }
            })
        )
    }

    /**
     * Delete a label
     *
     * @param id
     */
    deleteLabel(id: string, data?: any): Observable<Label[]>
    {
        return this._commonService.deleteRecordsById(MODELS_CONSTANTS.LABELS, id).pipe(
            tap((response) => {
                if(response?.status === 200){
                    this.getLabels(data).subscribe();
                }
            })
        )
    }

    /**
     * Get notes
     */
    getNotes(data?:any): Observable<Note[]>
    {
        const params = {};
        if(data) {
            params['reference_type_id'] = data?.reference_type_id || null;
            params['reference_type'] = data?.reference_type;

            if(data?.reference_type !== 'module' && data?.reference_id) {
                params['reference_id'] = data?.reference_id;
            }
        } else {
            params['user_id'] = this.user?._id;
            params['reference_type'] = 'menu';
        }
        return this._commonService.getDataByFields(MODELS_CONSTANTS.NOTES, params).pipe(
            map((response: IResponse<Note[]>) => {
                this._notes.next(response?.data || []);
                return response?.data || [];
            })
        );
        // return this._httpClient.get<Note[]>('api/apps/notes/all').pipe(
        //     tap((response: Note[]) =>
        //     {
        //         this._notes.next(response);
        //     }),
        // );
    }

    /**
     * Get note by id
     */
    getNoteById(id: string): Observable<Note>
    {
        return this._notes.pipe(
            take(1),
            map((notes) =>
            {
                // Find within the folders and files
                const note = notes.find(value => value._id === id) || null;

                // Update the note
                this._note.next(note);

                // Return the note
                return note;
            }),
            switchMap((note) =>
            {
                if ( !note )
                {
                    return throwError('Could not found the note with id of ' + id + '!');
                }

                return of(note);
            }),
        );
    }
}
