import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);

    // Check the authentication status
    return inject(AuthService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is authenticated...
            if ( authenticated )
            {
                if(route?.queryParams?.params && state?.url?.includes('/sign-in')){
                    localStorage.clear();
                    return of(router.parseUrl(state?.url));
                } else {
                    return of(router.parseUrl('/home'));
                }
                
            }

            // Allow the access
            return of(true);
        }),
    );
};
