import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { io } from 'socket.io-client';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    socket: any;

    constructor() {}

    connect() {
        try {
            if (!this.socket) {
                const contactInfo = JSON.parse(localStorage.getItem('contact_info') || '{}');
                if (JSON.stringify(contactInfo) !== '{}') {
                    this.socket = io(environment.socketApiRoot, {
                        path: '/api/socket',
                    });

                    this.socket.on('connect', () => {
                        if (contactInfo?._id) {
                            this.socket.emit(
                                'socket_connect',
                                contactInfo?._id
                            );
                        }
                    });
                } else {
                    throw new Error(
                        'Socket connection failed, Contact info not found!!!'
                    );
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    sendMessage(contactIds: string | string[], message: any) {
        if (this.socket) {
            if (!Array.isArray(contactIds)) {
                contactIds = [contactIds];
            }
            // Emit a 'sendMessage' event with the message and contact ID to the server
            this.socket.emit('sendMessage', { contactIds, message });
        } else {
            console.error('Socket is not connected!!!');
        }
    }

    receiveMessage(callback: (data: any) => void) {
        if (this.socket) {
            // Listen for 'receiveMessage' event from the server
            this.socket.on('receiveMessage', callback);
        } else {
            console.error('Socket is not connected!!!');
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}
