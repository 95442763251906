<div class="flex h-full flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">
  <div #scrollContainer class="flex-auto h-full dark:bg-transparent overflow-y-auto overflow-x-hidden"
    (scroll)="onScroll()">

    <div class="relative px-4 overflow-hidden" [ngClass]="titleClass ? 'pt-0 py-0 sm:pt-0' : 'pt-8 py-4 sm:pt-10'">
      <div class="z-10 relative flex items-left">
        <div class="mt-1 text-4xl sm:text-5xl font-extrabold text-gray-700 tracking-tight leading-tight text-left">
          {{ contentPropertyName }}
        </div>
        <div class="ml-auto h-fit pt-2 hide-on-mobile">
          <mat-button-toggle-group name="view" aria-label="View" color="primary" appearance="legacy"
            [(ngModel)]="searchView">
            <mat-button-toggle value="Vertical">Vertical</mat-button-toggle>
            <mat-button-toggle value="Horizontal">Horizontal</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    @if (searchView === 'Vertical') {
      <div class="flex py-4">
        <div class="col-4 desktop-only">
          <div class="py-2" *ngFor="let data of categoryList; let i = index; trackBy: trackByFn">
            <div class="content-card cursor-pointer border border-primary flex flex-0 w-full rounded py-2 px-4 
              hover:bg-primary-800 bg-primary-700 text-white font-medium text-lg transition ease-in-out hover:scale-105 duration-300"
              (click)="getParticularData(data._id, i)" [ngClass]="{'clicked': isCardClicked(i)}">
              
              <div class="flex items-center justify-center w-full">
                <mat-icon class="text-white">stars</mat-icon>
                <div class="w-full font-medium leading-none text-center">
                  {{ data.content_title }}
                </div>
                <div class="flex items-center ml-auto">
                  <mat-icon *ngIf="data?.description !== '' " class="text-white ml-auto"
                  svgIcon="heroicons_mini:arrow-small-down"></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-8 desktop-only">
          <div *ngFor="let annoncement of particularCategoryList; let i = index; trackBy: trackByFn">
            <div *ngIf="categoryFlag" class="row px-4 p-2 pb-0">
              <mat-card class="flex flex-col px-8 py-6 pb-4 w-full fade-in">
                <div class="flex flex-row gap-6">
                  <div class="flex flex-col gap-5">
                    <div class="mr-auto text-3xl flex gap-2 font-bold">
                      <mat-icon class="text-black mt-2">stars</mat-icon>
                      {{ annoncement.content_title }}
                    </div>
                    <div class="text-xl font-semibold text-gray-700 ">{{annoncement.subject}}</div>
                  </div>
                  <!-- <div class="ml-auto">
                    <div *ngIf="isValidDate(annoncement?.start_date)"
                      class="items-center px-2 gap-2 text-center">
                      <mat-label class="text-md font-medium text-gray-700 "> Start Date </mat-label>
                      <div class="text-sm font-bold text-gray-700 text-nowrap ">{{annoncement.start_date | date:'dd-MM-yyyy'}}
                      </div>
                    </div>
                    <div *ngIf="isValidDate(annoncement?.end_date)"
                      class="items-center px-2 gap-2 text-center">
                      <mat-label class="text-md font-medium text-gray-700 "> End Date </mat-label>
                      <div class="text-sm font-bold text-gray-700 text-nowrap ">{{annoncement.end_date | date:'dd-MM-yyyy'}}
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="mt-3 ml-2">
                  {{annoncement.message}}
                </div>
              </mat-card>
            </div>
          </div>

          <div *ngIf="subCategoryFlag" id="mat-accordion" class="pb-8 mt-4 px-1 overflow-hidden items-center">
            <mat-accordion class="mat-accordion w-full mt-8">
              <mat-expansion-panel [expanded]="true"
                *ngFor="let item of particularCategoryList; let i=index; trackBy: trackByFn" class="w-full" #panel>
                <mat-expansion-panel-header *ngIf="item.sub_category">
                  <mat-panel-title>
                    {{item.sub_category}}
                  </mat-panel-title>
                  <span class="custom-expansion-indicator"
                    [ngStyle]="{transform: panel.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" aria-hidden="true" focusable="false">
                      <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"></path>
                    </svg>
                  </span>
                </mat-expansion-panel-header>
                <div class="mat-expansion-panel-body">
                  <div class="p-6 capitalize">
                    <div class="text-lg font-medium tracking-tight leading-6">
                      <div [innerHtml]="item.description">{{item.description}}</div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <div class="category-container">
        <div class="col-12 mobile-only">
          <div class="p-2" *ngFor="let data of categoryList; let i = index; trackBy: trackByFn">
            <div class="page-body help-category-box cursor-pointer border border-primary flex flex-col w-full rounded py-2 px-4 bg-primary-50 text-white font-medium text-lg transition ease-in-out hover:scale-105 duration-300"
              (click)="getParticularData(data._id, i, $event)" [ngClass]="{'clicked': isCardClicked(i), 'small-height': isCardClicked(i)}">
              <div class="flex items-center justify-center w-full">
                <mat-icon class="text-color">stars</mat-icon>
                <div class="text-2xl font-bold text-color"  style="margin-left: 30px;">{{ data.content_title }}</div>
                <div *ngIf="!isCardClicked(i)" class="flex items-center justify-center py-4 pl-8">
                    <mat-icon *ngIf="data?.sub_category" class="text-color" svgIcon="heroicons_mini:arrow-small-down"></mat-icon>
                  </div>
              </div>
              <div *ngIf="!isCardClicked(i)" class="text-xl font-semibold text-color text-center text-nowrap">{{ data.subject }}</div>
              <!-- <div *ngIf="!isCardClicked(i)" class="flex justify-between text-color">
                <div *ngIf="isValidDate(data?.start_date)" class="flex flex-col items-center p-2 text-center">
                  <mat-label class="text-md font-medium">Start Date</mat-label>
                  <div class="text-sm font-bold text-nowrap">{{ data.start_date | date:'dd-MM-yyyy' }}</div>
                </div>
                <div *ngIf="isValidDate(data?.end_date)" class="flex flex-col items-center p-2 text-center">
                  <mat-label class="text-md font-medium">End Date</mat-label>
                  <div class="text-sm font-bold text-nowrap">{{ data.end_date | date:'dd-MM-yyyy' }}</div>
                </div>
              </div> -->
              <!-- <div *ngIf="!isCardClicked(i)" class="flex items-center justify-center py-4 px-8 ">
                <mat-icon *ngIf="data?.sub_category" class="text-color" svgIcon="heroicons_mini:arrow-small-down"></mat-icon>
              </div> -->
            </div>
      
            <div *ngIf="isCardClicked(i)" class="details-section">
              <mat-card class="flex flex-col px-8 py-6 pb-4 w-full fade-in">
                <div class="flex flex-row gap-6">
                  <div class="flex flex-col gap-5">
                    <div *ngIf="!isCardClicked(i)" class="mr-auto text-3xl flex gap-2 font-bold">
                      <mat-icon class="text-black mt-2">stars</mat-icon>
                      {{ particularCategoryList[0]?.content_title }}
                    </div>
                    <div class="text-xl font-semibold text-gray-700">{{ particularCategoryList[0]?.subject }}</div>
                  </div>
                  <!-- <div class="ml-auto">
                    <div *ngIf="isValidDate(particularCategoryList[0]?.start_date)" class="items-center px-2 gap-2 text-center">
                      <mat-label class="text-md font-medium text-gray-700"> Start Date </mat-label>
                      <div class="text-sm font-bold text-gray-700 text-nowrap">{{ particularCategoryList[0]?.start_date | date:'dd-MM-yyyy' }}</div>
                    </div>
                    <div *ngIf="isValidDate(particularCategoryList[0]?.end_date)" class="items-center px-2 gap-2 text-center">
                      <mat-label class="text-md font-medium text-gray-700"> End Date </mat-label>
                      <div class="text-sm font-bold text-gray-700 text-nowrap">{{ particularCategoryList[0]?.end_date | date:'dd-MM-yyyy' }}</div>
                    </div>
                  </div> -->
                </div>
                <div class="mt-3 ml-2">
                  {{ particularCategoryList[0]?.message }}
                </div>
      
                <div *ngIf="subCategoryFlag" id="mat-accordion" class="pb-8 mt-4 px-1 overflow-hidden items-center">
                  <mat-accordion class="mat-accordion w-full mt-8">
                    <mat-expansion-panel [expanded]="true" *ngFor="let item of particularCategoryList; let j = index; trackBy: trackByFn" class="w-full" #panel>
                      <mat-expansion-panel-header *ngIf="item.sub_category">
                        <mat-panel-title>
                          {{ item.sub_category }}
                        </mat-panel-title>
                        <span class="custom-expansion-indicator" [ngStyle]="{transform: panel.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
                            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"></path>
                          </svg>
                        </span>
                      </mat-expansion-panel-header>
                      <div class="mat-expansion-panel-body">
                        <div class="p-6 capitalize">
                          <div class="text-lg font-medium tracking-tight leading-6">
                            <div [innerHtml]="item.description">{{ item.description }}</div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
    } @else if (searchView === 'Horizontal') {
      <div class="sticky-header shadow-md py-4">
        <div class="flex flex-wrap gap-4 px-2 m-auto">
          <div *ngFor="let data of categoryList; let i = index; trackBy: trackByFn">
            <div
              class="content-card cursor-pointer border border-primary flex flex-0 w-64 rounded py-2 px-4 hover:bg-primary-800 bg-primary-700 text-white font-medium text-lg transition ease-in-out hover:scale-105 duration-300"
              (click)="getParticularData(data._id, i)" [ngClass]="{'clicked': isCardClicked(i)}">
              <div class="flex items-center justify-center w-full">
                <mat-icon class="text-white">stars</mat-icon>
                <div class="w-full font-medium leading-none text-center">
                  {{ data.content_title }}
                </div>
                <div class="flex items-center ml-auto">
                  <mat-icon *ngIf="data?.description !== '' " class="text-white ml-auto"
                  svgIcon="heroicons_mini:arrow-small-down"></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let annoncement of particularCategoryList; let i = index; trackBy: trackByFn">
        <div *ngIf="categoryFlag" class="row p-8 pb-0">
          <mat-card class="flex flex-col px-8 py-6 pb-4 w-full fade-in">
            <div class="flex flex-row gap-6">
              <div class="flex flex-col gap-5">
                <div class="mr-auto text-3xl flex gap-2 font-bold">
                  <mat-icon class="text-black mt-2">stars</mat-icon>
                  {{ annoncement.content_title }}
                </div>
                <div class="text-xl font-semibold text-gray-700 ">{{annoncement.subject}}</div>
              </div>
              <!-- <div class="ml-auto">
                <div *ngIf="isValidDate(annoncement?.start_date)"
                  class="flex flex-row flex-auto items-center px-2 gap-2 text-center">
                  <mat-label class="text-md font-medium text-gray-700 "> Start Date : </mat-label>
                  <div class="text-sm font-bold text-gray-700 text-nowrap ">{{annoncement.start_date | date:'dd-MM-yyyy'}}
                  </div>
                </div>
                <div *ngIf="isValidDate(annoncement?.end_date)"
                  class="flex flex-row flex-auto items-center px-2 gap-2 text-center">
                  <mat-label class="text-md font-medium text-gray-700 "> End Date : </mat-label>
                  <div class="text-sm font-bold text-gray-700 text-nowrap ">{{annoncement.end_date | date:'dd-MM-yyyy'}}
                  </div>
                </div>
              </div> -->
            </div>
            <div class="mt-3 ml-2">
              {{annoncement.message}}
            </div>
          </mat-card>
        </div>
      </div>

      <div *ngIf="subCategoryFlag" id="mat-accordion" class="pb-8 m-4 px-4 overflow-hidden items-center">
        <mat-accordion class="mat-accordion w-full mt-8">
          <mat-expansion-panel [expanded]="true"
            *ngFor="let item of particularCategoryList; let i=index; trackBy: trackByFn" class="w-full" #panel>
            <mat-expansion-panel-header *ngIf="item.sub_category">
              <mat-panel-title>
                {{item.sub_category}}
              </mat-panel-title>
              <span class="custom-expansion-indicator"
                [ngStyle]="{transform: panel.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" aria-hidden="true" focusable="false">
                  <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"></path>
                </svg>
              </span>
            </mat-expansion-panel-header>
            <div class="mat-expansion-panel-body">
              <div class="p-6 capitalize">
                <div class="text-lg font-medium tracking-tight leading-6">
                  <div [innerHtml]="item.description">{{item.description}}</div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    }
    <button *ngIf="showScrollButton" (click)="scrollToTop()" [matTooltip]="'Back to top'"
      [class.show]="showScrollButton" class="scroll-button">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</div>