import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { ScrumboardService } from 'app/modules/features/scrumboard/scrumboard.service';
import { AUTH_TYPES } from 'app/shared/constants/auth-types.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { SHARED_CONSTANTS } from 'app/shared/constants/shared.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { generateUniqueId, getDataByFieldsString } from 'app/shared/utilities/utilities';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import { Observable, interval, lastValueFrom, map } from 'rxjs';
import { SnackbarService } from '../snackbar/snackbar.service';
import { API_REGISTRY_CONSTANTS } from 'app/shared/constants/api-registry.constants';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private base_url: string = `${environment.apiRoot}/common`;
    private auth_url: string = `${environment.apiRoot}/auth`;
    private common_base_url: string = `${environment.apiRoot}/vrm`;
    private common_report_url: string = `${environment.apiRoot}/report`;
    private ai_base_url: string = `${environment.apiRoot}/ai`;
    private encryptionKey = 'U2FsdGVkX1/WviU+nl1QgDrLKW3XyWHzq51MhevP3v4='
    private _navigationService: NavigationService;
    private scrumboardService: ScrumboardService;

    constructor(
        private _httpClient: HttpClient,
        private authService: AuthService,
        private _router: Router,
        private location: Location,
        private injector: Injector,
        private _snackbar: SnackbarService
    ) {}

    private get navigationService(): NavigationService {
        if (!this._navigationService) {
            this._navigationService = this.injector.get(NavigationService);
        }
        return this._navigationService;
    }

    private get _scrumboardService() {
        if (!this.scrumboardService) {
          this.scrumboardService = this.injector.get(ScrumboardService);
        }
        return this.scrumboardService;
    }


    statusLists: { [key: number]: string } = {
        0: 'Inactive',
        1: 'Active'
    };

    documentStatusLists: { [key: number]: string } = {
        0: 'Saved',
        1: 'Submitted',
        2: 'Approved',
        3: 'Rejected',
        4: 'Pushedback'
    };

    invoiceStatusLists: { [key: number]: string } = {
        0: 'NA',
        1: 'Created',
        2: 'Posted'
    };

    getStatusList(appr_yn: string){
        if(appr_yn == 'y'){
            return this.documentStatusLists;
        }else if(appr_yn == 'i'){
            return this.invoiceStatusLists;
        }else{
            return this.statusLists;
        }
    }
    getStatusDescription(appr_yn: string, status: number){
        const list = this.getStatusList(appr_yn);
        return list?.[status];
    }

    private _getHeaders(): HttpHeaders {
        const token = this.authService.access_token;
        //const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQ2hhbmRydSIsInBob25lIjoiOTUwMDkxMjM5OCIsIl9pZCI6IjY2MTUwZWYxYmU5YWYwZDU3MmI4YTZmZSIsInVzZXJfaWQiOiJVU0VSXzAwMDEiLCJlbWFpbF9pZCI6ImNoYW5kcnUuZ0BxdWFudHppLmluIiwicm9sZV9pZCI6IlJPTEVfMDAwMSIsInRlbmFudF9pZCI6IlRFTl8wMDAxIiwiaWF0IjoxNzEyNzUyNTY1fQ.tmeGpQSq4Z6X740k16Uo-wq_F7qEmea1hhbJN6a-3rY';
        if (!token) {
            if (!this.location.path().includes('set-password')) {
                this._router.navigate(['/sign-out']);
                throw new Error('Token not found');
            }
        }
        return new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
    }

    getAllData(module_code: string,isVRM: boolean = false): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url;
        return this._httpClient.get(`${url}`, {
            headers: this._getHeaders(),
            params: { module_code },
        });
    }

    getDataByFields(module_code: string, params: any, isVRM: boolean = false): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url;
        return this._httpClient.get(`${url}/filter`, {
            headers: this._getHeaders(),
            params: { ...params, module_code: module_code },
        });
    }

    getDataById(module_code: string, _id: string, isVRM: boolean = false): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url;
        return this._httpClient.get(`${url}/id`, {
            headers: this._getHeaders(),
            params: { module_code, _id },
        });
    }

    generateReport(type: string, template_id: string, data: any): Observable<any> {
        let url = this.common_report_url;
        if(type=='1'){
            url = url + '/jstl';
        }else{
            url = url + '/docx';
        }
        data.template_id = template_id;
        const requestBody = { data };
        return this._httpClient.post(`${url}`, requestBody, {
            headers: this._getHeaders(),
        });
    }

    getDataByField(
        module_code: string,
        field_name: string,
        value: any,
        isVRM: boolean = false
    ): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url;
        const params = { module_code };
        params[field_name] = value;
        return this._httpClient.get(`${url}/filter`, {
            headers: this._getHeaders(),
            params,
        });
    }

    getDataByMultipleField(module_code: string, data: any): Observable<any> {
        data['module_code'] = module_code;
        const params = data;
        return this._httpClient.get(`${this.base_url}/filter`, {
            headers: this._getHeaders(),
            params,
        });
    }

    getAppconfigData(): Observable<any> {
        let url =  environment.apiRoot + '/app_config_images';
        return this._httpClient.get(`${url}`);
    }

    saveRecord(module_code: string, data: any,isVRM: boolean = false): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url;
        const requestBody = { module_code, data };
        let method: string;
        if(!isVRM){
            method = data?._id ? 'put' : 'post';
        }else if(this.common_base_url){
            method = data?.id ? 'put' : 'post';
        }
        return this._httpClient[method](`${url}`, requestBody, {
            headers: this._getHeaders(),
        });
    }

    save_many_record(module_code: string, data: any,isVRM: boolean = false): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url;
        const requestBody = { module_code, data };
        return this._httpClient.post(`${url}/many`, requestBody, {
            headers: this._getHeaders(),
        });
    }

    updateRecordsByFields(
        module_code: string,
        filter_by: object,
        data: any
    ): Observable<any> {
        const requestBody = {
            module_code,
            filter_by,
            data,
        };
        return this._httpClient.put(`${this.base_url}/fields`, requestBody, {
            headers: this._getHeaders(),
        });
    }

    update_many_records(
        module_code: string,
        data: any
    ): Observable<any> {
        const requestBody = { module_code, data };
        return this._httpClient.put(`${this.base_url}/many`, requestBody, {
            headers: this._getHeaders(),
        });
    }

    deleteRecordsById(module_code: string, _id: string,isVRM: boolean = false): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url; 
        return this._httpClient.delete(`${url}`, {
            headers: this._getHeaders(),
            params: { module_code, _id },
        });
    }

    deleteRecordsByfield(
        module_code: string,
        filter_by: string,
        value: any,
        isVRM: boolean = false
    ): Observable<any> {
        let url = isVRM ? this.common_base_url : this.base_url; 
        const params = { module_code };
        params[`filter_by[${filter_by}]`] = value;
        return this._httpClient.delete(`${url}/fields`, {
            headers: this._getHeaders(),
            params,
        });
    }

    getMenuByRoles(): Observable<any> {
        const params = {};
        return this._httpClient.get(`${environment.apiRoot}/navigation`, {
            headers: this._getHeaders(),
            params,
        });
    }

    uploadFile(requestBody: {
        file: any;
        app_config: { store_to: any; file_size?: any };
    }): Observable<any> {
        const formData = new FormData();
        formData.append('files', requestBody.file);
        formData.append('app_config', JSON.stringify(requestBody.app_config));
        return this._httpClient.post(`${environment.apiRoot}/file`, formData, {
            headers: this._getHeaders(),
        });
    }

    uploadImage(files, dir?: string): Observable<any> {
        const formData = new FormData();
        formData.append('files', files[0]);
        formData.append('name', files[0]?.name);
        formData.append('dir', dir);
        return this._httpClient.post(`${environment.apiRoot}/file/form`, formData, {
            headers: this._getHeaders(),
        });
    }

    callAPI(url: string, method: string, module_code: string, data: any, params: any): Observable<any> {
        if(method === 'post' || method === 'put'){
            const requestBody = { module_code, data };
            return this._httpClient[method](`${url}`, requestBody, {
                headers: this._getHeaders(),
            });
        }else{
            return this._httpClient[method](`${url}`, {
                headers: this._getHeaders(),
                params : { module_code, ...params}
            });
        }
    }

    async executeApiMethod(apiRegistry: any, data?: any): Promise<any> {
        let retry = 1;
        let authApiConfigData;
        data.user_id = this.authService.userInfo._id;
        data.tenant_id = this.authService.userInfo.tenant_id;
        /*let menu = await firstValueFrom(this.navigationService.currentMenu$);
        console.log('menu',menu);
        data.menu = menu;*/

        const getConfigData = async (apiRegistry, headers?: any) => {
            const responseData: IResponse<any> = await this.getDataByFields(
                MODELS_CONSTANTS.AUTH_API_CONFIG,
                {
                    category: apiRegistry?.api_category,
                    sub_category: apiRegistry?.api_sub_catg,
                }
            ).toPromise();
            authApiConfigData = responseData?.data?.[0];
            data['auth_config'] = authApiConfigData;

            if (headers) {
                let { headers: newHeaders } = await this.constructData(
                    apiRegistry,
                    data
                );
                headers = { ...newHeaders };
                return headers;
            }
        };

        try {
            const response = await this.getDataByFields(
                MODELS_CONSTANTS.VALUE_SET_DETAILS,
                {
                    vs_code: 'API_REGISTRY_BASE_URL',
                    vsd_code: apiRegistry.api_url,
                }
            ).toPromise();
            let { vsd_property_1: base_url } = response.data[0];
            //base_url = base_url.replace('http://143.244.142.190/auth/v1', 'http://192.168.29.130:8006/v1')
            const url = base_url + apiRegistry.api_request_path;

            // Add token to data
            data.token = this.authService.access_token;

            if(apiRegistry?.is_third_party){
                await getConfigData(apiRegistry);
            }

            // Construct request parameters
            let { headers, queryParams, requestBody } = await this.constructData(
                apiRegistry,
                data
            );

            let api_cate_db_params = JSON.parse(await localStorage.getItem('api_cate_db_params'));
            if (api_cate_db_params[apiRegistry.api_category]) {
                queryParams = {
                    ...queryParams,
                    ...api_cate_db_params[apiRegistry.api_category],
                };
                requestBody = {
                    ...requestBody,
                    ...api_cate_db_params[apiRegistry.api_category],
                };
            }

            const callApi = async (newHeaders?: any) => {
                // Choose HTTP method based on api_request_method
                if(newHeaders) {
                    headers = { ...headers, ...newHeaders };
                }
                try {
                    switch (apiRegistry.api_request_method) {
                        case 'GET':
                            return await this._httpClient
                                .get<any[]>(url, {
                                    headers,
                                    params: queryParams,
                                })
                                .toPromise();
                        case 'POST':
                            return await this._httpClient
                                .post<any>(url, requestBody, { headers })
                                .toPromise();
                        case 'PUT':
                            return await this._httpClient
                                .put<any>(url, requestBody, { headers })
                                .toPromise();
                        case 'DELETE':
                            return await this._httpClient
                                .delete<any>(url, {
                                    headers,
                                    params: queryParams,
                                })
                                .toPromise();
                        default:
                            throw new Error(
                                `Unsupported HTTP method: ${apiRegistry.api_request_method}`
                            );
                    }
                } catch (error) {
                    if(apiRegistry?.is_third_party && error?.status === 401 && retry > 0) {
                        retry--;
                        const response = await this.constructAuthHeader(apiRegistry, authApiConfigData, headers, true, callApi, getConfigData);
                        if(apiRegistry?.res_data_field) {
                            let data = getDataByFieldsString(apiRegistry?.res_data_field, response);
                            return { status: 200, data };
                        } else {
                            return { status: 200, data: response };
                        }
                    } else {
                        console.error('Error making API request:', error);
                        throw error; // rethrow error after logging it
                    }
                }
            };

            if(apiRegistry?.is_third_party) {
                this.executeChronoPlanner(apiRegistry?.api_resp_action);
                headers['X-third-party'] = 'true';
                const response = await this.constructAuthHeader(apiRegistry, authApiConfigData, headers, false, callApi, getConfigData);
                if(apiRegistry?.res_data_field) {
                    let data = getDataByFieldsString(apiRegistry?.res_data_field, response);
                    if(!data){
                        data = [];
                    }
                    
                    return { status: 200, data };
                } else {
                    return { status: 200, data: response };
                }
            } else {
                this.executeChronoPlanner(apiRegistry?.api_resp_action);
                return await callApi();
            }

            
        } catch (error) {
            console.error(error)
            throw error; // Rethrow the error to handle it in the caller function
        }
    }

    async constructData(apiRegistry: any, json_data: any) {
        let headers = {};
        let requestBody = {};
        let queryParams = {};
        // const regex = /{{\s*(\w+)\s*}}/g;
        const regex = /{{\s*([^{}]+?)\s*}}/g;

        if (apiRegistry.api_request_headers) {
            const header_params = Object.keys(apiRegistry.api_request_headers);
            header_params.forEach((param) => {
                let value = apiRegistry.api_request_headers[param];
                if(/\{\{.*?\}\}/.test(value)) {
                    value = value.replace(regex, (match, key) => {
                        if (value?.includes('.')) {
                            let headerValue = getDataByFieldsString(key, json_data);
                            return headerValue;
                        } else {
                            return json_data[key.toLowerCase()];
                        }
                    });
                }
                headers[param] = value;
            });
        }

        if (apiRegistry.api_request_query_params) {
            const query_params = Object.keys(
                apiRegistry.api_request_query_params
            );
            query_params.forEach((param) => {
                let value = apiRegistry.api_request_query_params[param];
                value = value.replace(regex, (match, key) => {
                    if(key?.toUpperCase() === key){
                        return json_data[key.toLowerCase()];
                    } else {
                        return json_data[key];
                    }
                });
                queryParams[param] = value;
            });
        }

        if (apiRegistry.api_request_body) {
            let request_body = apiRegistry.api_request_body;
            request_body.replace(/\\n/g, '').replace(/\\"/g, '"');
            request_body = JSON.parse(request_body);
            let request_body_keys = Object.keys(request_body);
            request_body_keys.forEach((param) => {
                let value = request_body[param];
                if (typeof value === 'object') {
                    let value_keys = Object.keys(value);
                    value_keys.forEach((value_key) => {
                        let value_value = value[value_key];
                        if(/\{\{.*?\}\}/.test(value_value)) {
                            value_value = value_value.replace(regex, (match, key) => {
                                    return json_data[key.toLowerCase()];
                                }
                            );
                        }
                        if (value_key === 'status') value_value = Number(value_value);
                        value[value_key] = value_value;
                    });
                } else {
                    if(/\{\{.*?\}\}/.test(value)){
                        value = value.replace(regex, (match, key) => {
                            if (value?.includes('.')) {
                                let value = getDataByFieldsString(key, json_data);
                                return value;
                            } else {
                                if(typeof json_data[key.toLowerCase()] === 'object'){
                                    return JSON.stringify(json_data[key.toLowerCase()]);
                                } else {
                                    return json_data[key.toLowerCase()];
                                }
                            }
                        });
                    }
                }
                try {
                    value = JSON.parse(value);
                } catch (error) {
                    value = value;
                }
                requestBody[param] = value;
            });
        }

        return { headers, requestBody, queryParams };
    }

    async constructAuthHeader(apiRegistry, authApiConfigData, headers, isGenerateToken: boolean, callback, getConfigData) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const isTokenActive = async (config): Promise<boolean> => {
                    if (config?.auth_type === AUTH_TYPES.OAUTH_2_0) {
                        if (!config?.expiry_time) {
                            return false;
                        }

                        const currentTime = new Date().getTime();
                        return currentTime < new Date(config?.expiry_time).getTime();
                    } else {
                        return true;
                    }
                };

                if (authApiConfigData) {
                    const callbackFunc = async (token: string, data?: any) => {
                        let expiry_time: number | string = null;
                        let refresh_token: string = null;
                        if(!data) {
                            data = null
                        } else {
                            const dataObj: any = {};
                            authApiConfigData?.configurations?.forEach((e) => {
                                let value;
                                try {
                                    const obj = JSON.parse(e?.value);
                                    value = obj;
                                } catch (error) {
                                    value = e?.value;
                                }
                                dataObj[e?.key] = value;
                            });

                            if(data?.expires_in) {
                                expiry_time = new Date().getTime() + data?.expires_in * 1000;
                                expiry_time = new Date(expiry_time).toISOString();
                            } else if (dataObj?.['res_expiry_time_field']) {
                                expiry_time = getDataByFieldsString(dataObj?.['res_expiry_time_field'], data);
                            }

                            if(dataObj?.['res_refresh_token_field']){
                                refresh_token = getDataByFieldsString(dataObj?.['res_refresh_token_field'], data);
                            }
                        }
                        if (token) {

                            const requestBody = {
                                _id: authApiConfigData?._id,
                                res_data: data,
                                token: token,
                                expiry_time: expiry_time || null,
                                refresh_token: refresh_token || null,
                                last_invoked: new Date().toISOString(),
                            };

                            await this.saveRecord(MODELS_CONSTANTS.AUTH_API_CONFIG, requestBody).toPromise();
                            if(data) {
                                headers = await getConfigData(apiRegistry, headers)
                            }
                        }
                        resolve(await callback(headers))
                    }

                    if (authApiConfigData?.token && !isGenerateToken) {
                        const result: boolean = await isTokenActive(authApiConfigData);
                        if(result) {
                            await this.saveRecord(MODELS_CONSTANTS.AUTH_API_CONFIG, {
                                _id: authApiConfigData?._id,
                                last_invoked: new Date().toISOString()
                            }).toPromise();
                            resolve(await callback());
                        } else {
                            await this.generateAuthToken(
                                authApiConfigData,
                                callbackFunc
                            );
                        }
                    } else {
                        await this.generateAuthToken(
                            authApiConfigData,
                            callbackFunc
                        );
                    }
                } else {
                    reject(
                        new Error(
                            "Please create Auth Configs for this API's category and sub category in 'Auth API Configs' menu"
                        )
                    );
                }
            } catch (error) {
                reject(error);
            }
        })
    }

    async executeChronoPlanner(chronoPlannerId){
        if(chronoPlannerId && chronoPlannerId?.toLowerCase() !== 'na' && /^[0-9a-fA-F]{24}$/.test(chronoPlannerId)){
            const chronoPlanner = await this.getDataById(
                MODELS_CONSTANTS.CHRONO_PLANNER,
                chronoPlannerId
            )
                .toPromise()
                .then((res) => res?.data)
                .catch((err) => console.error(err));
    
            if(chronoPlanner) {
                for(let [index, action] of chronoPlanner.action.entries()) {
                    if(/^[0-9a-fA-F]{24}$/.test(action)){
                        const apiRegistry = await this.getDataById(
                            MODELS_CONSTANTS.API_REGISTRIES,
                            action
                        )
                            .toPromise()
                            .then((res) => res.data)
                            .catch((err) => console.error(err));
                        let data = {};
    
                        if(chronoPlanner?.params[index]){
                            for (let param of chronoPlanner?.params[index]?.action_params) {
                                data[param.key] = param.value;
                            }
                        }

                        await this.executeApiMethod(apiRegistry, data);
                        await this.saveRecord(MODELS_CONSTANTS.CHRONO_PLANNER, { _id: chronoPlannerId, last_run_time: Date.now() }).subscribe();
                    }
                }
            }
        }
    }

    getMenuPermission(menu_id: string): Observable<any> {
        const role_id = this.authService.userInfo?.role_id
        return this.getDataByFields(MODELS_CONSTANTS.MENU_ROLES, { menu_id, role_id }).pipe(
            map((response: IResponse<any>) => {
                return response?.data || [];
            })
        )
    }

    encryptData(value: any) {
        console.log('value', value);
        return CryptoJS.AES.encrypt(JSON.stringify(value), this.encryptionKey).toString();
    }

    decryptData(encryptedValue: any) {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedValue, this.encryptionKey);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return encryptedValue;
        } catch (e) {
            console.log(e);
        }
    }

    create_task(payload : any) {
        return this._httpClient.post(`${this.base_url}/create_task`, payload, {
            headers: this._getHeaders(),
        });
    }

    update_task_status(payload : any){
        return this._httpClient.post(`${this.base_url}/update_task_status`, payload, {
            headers: this._getHeaders(),
        });
    }

    get_user_group(): Observable<any> {
        return this._httpClient.get(`${this.base_url}/user_group`, {
            headers: this._getHeaders(),
        });
    }

    get_line_manager(user_id: string, level: number): Observable<any> {
        return this._httpClient.get(`${this.base_url}/line_manager`, {
            headers: this._getHeaders(),
            params: { user_id, level }
        });
    }

    async generateAuthToken(config, callback) {
        let token = '';
        const dataObj: any = {};
        config?.configurations?.forEach((e) => {
            let value;
            try {
                const obj = JSON.parse(e?.value);
                value = obj;
            } catch (error) {
                value = e?.value;
            }
            dataObj[e?.key] = value;
        });

        if (config?.auth_type === AUTH_TYPES.BASIC_AUTH) {
            if ('username' in dataObj && 'password' in dataObj) {
                const { username, password } = dataObj;
                const base64Credential = btoa(username + ':' + password);
                token = `Basic ${base64Credential}`;
                await callback(token);
            } else {
                throw new Error(
                    "'username' and 'password' are required for 'Basic Auth' APIs"
                );
            }
        } else if (config?.auth_type === AUTH_TYPES.BEARER_AUTH) {
            const validations = [
                'auth_url',
                'res_token_field'
            ];
            const required = [];
            for (let key of validations) {
                if (!dataObj?.[key]) {
                    required.push(key);
                }
            }
            if (required?.length) {
                throw new Error(
                    `'${required?.join(', ')}' ${
                        required?.length > 1 ? 'are' : 'is'
                    } required in 'Bearer Auth' APIs`
                );
            }

            const auth_url: string = dataObj?.auth_url;
            const responseTokenField: string = dataObj?.res_token_field;
            
            delete dataObj?.auth_url;
            delete dataObj?.res_token_field;
            delete dataObj?.res_refresh_token_field;
            delete dataObj?.res_expiry_time_field;

            const reqestBody = dataObj;
            const authUrl = config?.base_url + auth_url;
            const headers = { 'X-third-party': 'true' };

            const response = await this._httpClient
                .post(authUrl, reqestBody, { headers })
                .toPromise()
            let value = getDataByFieldsString(responseTokenField, response);
            if(!value) {
                token = null;
            } else {
                token = `${value}`;
            }
            await callback(token, response || null);
        } else if (config?.auth_type === AUTH_TYPES.OAUTH_2_0) {
            const validations = [
                'auth_url',
                'client_id',
                'scope',
                'token_url'
            ];
            const required = [];
            for (let key of validations) {
                if (!dataObj?.[key]) {
                    required.push(key);
                }
            }
            if (required?.length) {
                throw new Error(
                    `'${required?.join(', ')}' ${
                        required?.length > 1 ? 'are' : 'is'
                    } required in 'OAuth 2.0 API'`
                );
            }
            const { auth_url, token_url } = dataObj;

            const callAuthApi = async (
                authUrl?: string, refresh_token?: string, state?: string
            ): Promise<any> => {
                try {
                    if(authUrl || refresh_token) {
                        const grant_type = refresh_token ? 'refresh_token' : 'authorization_code'
                        let url = `${token_url}?grant_type=${grant_type}`;
                        for (let key in dataObj) {
                            if (key !== 'auth_url' && key !== 'token_url' && key !== 'token_info_url') {
                                url += `&${key}=${dataObj[key]}`;
                            }
                        }
                        if(authUrl && !refresh_token) {
                            let code: string = authUrl?.split('&code=')?.[1];
                            code = code?.split('&')?.[0];
                            let receivedState: string = authUrl?.split('&state=')?.[1];
                            receivedState = receivedState?.split('&')?.[0];

                            console.log({receivedState, state})

                            url += '&code=' + code;
                        }
                        const headers = { 'X-third-party': 'true' }
                        const result$ = this._httpClient.post(url, {}, { headers });
                        const result: any = await lastValueFrom(result$);
                        const token = `${result?.token_type} ${result?.access_token}`;
                        return { ...result, token };
                    } else {
                        return null
                    }
                } catch (error) {
                    console.error(error);
                    return null;
                }
            };

            if(config?.res_data?.refresh_token) {
                const { token: access_token, ...rest } = await callAuthApi(null, config?.res_data?.refresh_token);
                await callback(access_token, rest);
            } else {
                const array = new Uint8Array(16);
                window.crypto.getRandomValues(array);
                const state = Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');

                dataObj['redirect_uri'] = window.location.href;
                let url = `${auth_url}?response_type=code`;
                for (let key in dataObj) {
                    if (key !== 'auth_url' && key !== 'token_url' && key !== 'token_info_url') {
                        url += `&${key}=${dataObj[key]}`;
                    }
                }
                url += `&state=${state}`;

                console.log({url})

                const newWindow = window.open(url, 'popup', 'width=600,height=600');
                if (newWindow) {
                    const authIntervalSubscription = interval(1000).subscribe(
                        async () => {
                            try {
                                if (newWindow?.origin === window.origin) {
                                    const authUrl = newWindow.location.href;
                                    authIntervalSubscription?.unsubscribe();
                                    newWindow?.close();
                                    const { token: access_token, ...rest } = await callAuthApi(authUrl, null, state);;

                                    await callback(access_token, rest);
                                }
                            } catch (error) {}
                        }
                    );
                }
            }
        } else {
            await callback(token);
        }
    }

    mailUser(user_id, template_id, data){
        let payload = {"user_id": user_id, "content_management_id": template_id, data, is_email: true}; 
        return this._httpClient.post(`${this.auth_url}/notify`, payload, {
            headers: this._getHeaders(),
        });
    }

    vendor(payload: any){
        return this._httpClient.post(`${this.auth_url}/vendor`, payload, {
            headers: this._getHeaders(),
        });
    }

    isPasswordChanged(email_id: string): Observable<any> {
        return this._httpClient.post(`${this.auth_url}/is_password_changed`, {email_id});
    }

    async getListDataByListFields(data: Array<any>, fields: Array<any>) {
        try {
            const components: string[] = SHARED_CONSTANTS.LIST_FIELD_MODULE_COMPONENT_TYPES;
            const allDataComponents: string[] = SHARED_CONSTANTS.LIST_FIELD_MODULE_ALL_DATA_COMPONENT_TYPES;
            const allData = {};
            const list: any = {};
            for(let field of fields) {
                if(components?.includes(field?.fieldType) && !list?.[field?.fieldType]) {
                    list[field?.fieldType] = {};
                    let ids = data?.map((e) => e[field?.field_name]);
                    ids = [...new Set(ids)];
                    for(let id of ids) {
                        if(id) {
                            if(!field?.fieldTypeDetails?.vsd_property_1 || !field?.fieldTypeDetails?.vsd_property_2){  
                                list[field?.fieldType][id] = id;
                                return;
                            }
                            
                            if(allDataComponents?.includes(field?.fieldType)) {
                                if(allData[field?.fieldType]) {
                                    const details = allData?.[field?.fieldType]?.find((e) => e._id === id);
                                    list[field?.fieldType][id] = details?.[field?.fieldTypeDetails?.vsd_property_2];
                                } else {
                                    const response: any = await lastValueFrom(
                                        this.getAllData(
                                            field?.fieldTypeDetails
                                                ?.vsd_property_1
                                        )
                                    )
                                        .then((res) => res?.data)
                                        .catch((err) => console.error(err));

                                    allData[field?.fieldType] = response;
                                    const details = response?.find((e) => e?._id === id);
                                    list[field?.fieldType][id] = details?.[field?.fieldTypeDetails?.vsd_property_2];
                                }
                            } else {
                                const details = await lastValueFrom(
                                    this.getDataById(
                                        field?.fieldTypeDetails?.vsd_property_1,
                                        id
                                    )
                                )
                                    .then((res) => res?.data)
                                    .catch((err) => console.error(err));

                                list[field?.fieldType][id] = details?.[field?.fieldTypeDetails?.vsd_property_2];
                            }
                        }
                    }
                }
            }
            return list;
        } catch (error) {
            console.error(error);
            return {};
        }
    }

    async sendMessageToAI(instructions) {
        const response = await this._httpClient
            .post(this.ai_base_url, { instructions }, { headers: this._getHeaders() })
            .toPromise()
            .then((res: any) => res.data)
            .catch((err) => console.error(err));

        return response;
    }

    executeFormsAI(body): Observable<any> {
        return this._httpClient.post(`${this.ai_base_url}/form`, body, { headers: this._getHeaders() });
    }

    getJsonFromExcel(body: { file_url: string | string[], module_code: string }) {
        return this._httpClient.post(`${this.ai_base_url}/file_url`, body, { headers: this._getHeaders() })
    }

    async getWorkflowDynamicTabs(matDrawer, board_id, close_dialog, task = null, tab = 0, isTaskDetailsTab = true) {
        try {
            let dynamicTabs = {};
    
            const getBoardStageTabs = async (board_id) => {
                const listActions = [];
                const board = await this.getDataById(
                    MODELS_CONSTANTS.SCRUMBOARDS,
                    board_id
                )
                    .toPromise()
                    .then((res) => res.data)
                    .catch((err) => console.error(err));
                
                const board_stages = await this.getDataByField(
                    MODELS_CONSTANTS.BOARD_STAGES,
                    'board_id',
                    board_id
                )
                    .toPromise()
                    .then((res) => res.data)
                    .catch((err) => console.error(err));
    
                board?.actions?.forEach((action) => {
                    if (action.action_type && action.action_type != "api") {
                        if (!action?._id) {
                            action = { ...action, _id: generateUniqueId() };
                        }
                        listActions.push(action);
                    }
                });
                board_stages?.forEach((stage) => {
                    stage.actions?.forEach((action) => {
                        if (action.action_type && action.action_type != "api") {
                            if (!action?._id) {
                                action = { ...action, _id: generateUniqueId() };
                            }
                            listActions.push(action);
                        }
                    });
                });
        
                // if(listActions?.length) {
                //     this.secondTabName = listActions[0]?.name || listActions[0]?.tooltip;
                // }
        
                return {listActions, board, board_stages};
            }
    
            const board = await this._scrumboardService.getBoard(board_id).toPromise();
    
            const board_approval_access = await this.getDataByField(
                MODELS_CONSTANTS.BOARD_TASK_APPROVAL,
                'board_id',
                board_id
            )
                .toPromise()
                .then((res) => res.data)
                .catch((err) => console.error(err));
    
            const user_group = await this.get_user_group()
                .toPromise()
                .then((res) => res.data)
                .catch((err) => console.error(err));
                
            const user_info = this.authService.userInfo;
            
            let listActions = [];
            if(isTaskDetailsTab){
                listActions = [
                    {
                        _id: generateUniqueId(),
                        tooltip: 'Task Details',
                        icon: 'heroicons_outline:clipboard',
                        action_type: 'scrumboard-card-details',
                    },
                ];
            }
            
            const { listActions: boardStageListActions, board_stages} = await getBoardStageTabs(board_id);
            listActions = [ ...listActions, ...boardStageListActions ];
            
            let show_approval = false;
            let show_next = false;
            let user_appoval: any;
            let group_appoval: any;
            let line_manager_approval: any;
    
            if (task && task._id) {
                let stage = board_stages.find(s => s._id === task.stage_id);
                if (stage) {
                    if(stage.appr_yn){
                        let task_approval = board_approval_access?.filter(a => a.task_id === task._id && a.stage_id === task.stage_id);
                        for(let approval of task_approval) {
                            if(approval?.status === 1 && approval?.user_id) {
                                if(approval?.user_type == 'U') {
                                    if(approval?.user_id === user_info?._id) {
                                        user_appoval = approval;
                                        break;
                                    }
                                } else if (approval?.user_type == 'G') {
                                    const group = user_group?.find( u => u._id == approval.user_id);
                                    if(group) {
                                        group_appoval = approval;
                                        break;
                                    }
                                } else if (approval?.user_type == 'L') {
                                    if(approval?.level) {
                                        const line_manager = await this.get_line_manager(approval?.user_id, approval?.level)
                                            .toPromise()
                                            .then((res) => res.data)
                                            .catch((err) => console.error(err));
                                        
                                        console.log({line_manager})
                                        if(line_manager?._id === user_info?._id) {
                                            line_manager_approval = approval;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        if ((user_appoval || group_appoval || line_manager_approval) && !user_appoval?.appr_status) {
                            show_approval = true;
                        }
                    } else {
                        show_approval = true;
                        show_next = true;
                    }
                }
            };
    
            dynamicTabs = {
                close_dialog,
                listActions,
                row_data: task,
                is_from_board: true,
                show_approval,
                show_next,
                matDrawer: matDrawer,
                default_tab: listActions.length > 1 ? tab : 0,
                default_tab_id: listActions.length > 1 ? listActions[tab]?._id : 0,
                user_type : user_appoval || group_appoval || line_manager_approval, 
                isWorkflowTabs: true
            };
    
            return dynamicTabs
        } catch (error) {
            console.error(error)
        }
    }

    async workflowActions(data, task_id) {
        let taskInfo = await this.getDataById(
            MODELS_CONSTANTS.BOARD_TASK,
            task_id
        )
            .toPromise()
            .then((res) => res?.data)
            .catch((err) => console.error(err));

        const { board_id } = taskInfo;

        const board_stages = await this._scrumboardService
            .get_board_stages(board_id)
            .toPromise();
        const board_tasks = await this._scrumboardService
            .get_board_tasks(board_id)
            .toPromise();
        const contactDetails: any = await this.getAllData(
            MODELS_CONSTANTS.CONTACTS
        )
            .toPromise()
            .then((res) => res?.data || []);

        if (
            data?.data?.status === 'When Approved' ||
            data?.data?.status === 'When Rejected' ||
            data?.data?.status === 'On Pushback'
        ) {
            const currentStageIndex = board_stages?.findIndex(
                (e) => e?._id === taskInfo?.stage_id
            );

            let isLastStage: boolean = false;
            if (
                currentStageIndex !== -1 &&
                currentStageIndex == board_stages?.length - 1
            ) {
                isLastStage = true;
            }

            taskInfo['status'] =
                data?.data?.status === 'When Approved' && isLastStage
                    ? 2
                    : data?.data?.status === 'When Rejected'
                    ? 3
                    : data?.data?.status === 'On Pushback'
                    ? 4
                    : 1;

            taskInfo = await this.saveRecord(MODELS_CONSTANTS.BOARD_TASK, {
                _id: taskInfo?._id,
                status: taskInfo?.status,
            })
                .toPromise()
                .then((res) => res?.data || null)
                .catch((err) => console.error(err));

            const boardTaskForms = await this.getDataByField(
                MODELS_CONSTANTS.BOARD_TASK_FORM,
                'task_id',
                taskInfo?._id
            )
                .toPromise()
                .then((response: IResponse<any>) => response?.data)
                .catch((error) => console.error(error));

            for (let form of boardTaskForms) {
                const formData = await this.getDataById(
                    MODELS_CONSTANTS.FORM_SETP,
                    form?.ref_type_id
                )
                    .toPromise()
                    .then((res) => res?.data)
                    .catch((err) => console.error(err));

                const moduleData = await this.getDataByField(
                    MODELS_CONSTANTS.MODULES,
                    'module_id',
                    formData?.module_id
                )
                    .toPromise()
                    .then((res) => res?.data[0])
                    .catch((err) => console.error(err));

                const res: any = await this.saveRecord(
                    moduleData?.module_code,
                    {
                        _id: form?.ref_id,
                        status: taskInfo?.status,
                    }
                ).toPromise();

                form['status'] = taskInfo?.status;
                await this.saveRecord(
                    MODELS_CONSTANTS.BOARD_TASK_FORM,
                    form
                ).toPromise();

                if (res?.status == 200) {
                    const body = {
                        form_id: form?.ref_type_id,
                        ref_id: form?.ref_id,
                        action: 'update',
                        remarks: 'Form Status Updated',
                        where: '',
                        date_time: new Date(),
                        done_by:
                            res?.data?.updated_by ||
                            this.authService?.userInfo?._id,
                    };
                    this.saveRecord(
                        MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER,
                        body
                    ).subscribe();
                }
            }
        }

        const fromStageActions = await this.getDataByField(
            MODELS_CONSTANTS.STAGE_ACTIONS,
            'stage_id',
            data?.data?.stageData?.from_stage?._id
        )
            .toPromise()
            ?.then((res) =>
                res?.data?.filter(
                    (e) =>
                        e?.stage_trigger === data?.data?.status ||
                        e?.stage_trigger === 'On Exit'
                )
            );

        const toStageActions = await this.getDataByField(
            MODELS_CONSTANTS.STAGE_ACTIONS,
            'stage_id',
            data?.data?.stageData?.to_stage?._id
        )
            .toPromise()
            ?.then((res) =>
                res?.data?.filter((e) => e?.stage_trigger === 'On Entry')
            );

        const actionsFromStage = await this.getDataById(
            MODELS_CONSTANTS.BOARD_STAGES,
            data?.data?.stageData?.from_stage?._id
        )
            .toPromise()
            ?.then((res) => {
                let actions = res?.data?.actions;
                return actions
                    ?.filter(
                        (e) =>
                            e?.stage_trigger === data?.data?.status ||
                            e?.stage_trigger === 'On Exit'
                    )
                    ?.map((e) => {
                        e['stage_id'] = res?.data?._id;
                        return e;
                    });
            });

        const actionsToStage = await this.getDataById(
            MODELS_CONSTANTS.BOARD_STAGES,
            data?.data?.stageData?.to_stage?._id
        )
            .toPromise()
            ?.then((res) => {
                let actions = res?.data?.actions;
                return actions
                    ?.filter((e) => e?.stage_trigger === 'On Entry')
                    ?.map((e) => {
                        e['stage_id'] = res?.data?._id;
                        return e;
                    });
            });

        const allActions = [
            ...fromStageActions,
            ...toStageActions,
            ...actionsFromStage,
            ...actionsToStage,
        ];

        const executeApi = async (apiRegistry, apiData, isMail = false) => {
            try {
                await this.executeApiMethod(apiRegistry, apiData);
                if (isMail) {
                    this._snackbar.success('Mail sent successfully');
                }
            } catch (error) {
                console.error(error);
            }
        };

        for (const action of allActions) {
            if (action?.action_type === 'api') {
                const apiRegistry = await this.getDataById(
                    MODELS_CONSTANTS.API_REGISTRIES,
                    action?.api
                )
                    .toPromise()
                    .then((res) => res?.data)
                    .catch((err) => console.error(err));
                const stageInfo = board_stages?.find(
                    (e) => e?._id == action?.stage_id
                );

                const apiData = {};
                if (
                    apiRegistry?._id ===
                    API_REGISTRY_CONSTANTS.SEND_NOTIFY_MAIL_TO_USER
                ) {
                    const content_management_id = action?.params?.find(
                        (e) => e?.key?.trim() === 'template_id'
                    )?.value;
                    if (!content_management_id) {
                        console.error(
                            'Failed to send mail. Template Id not provided.'
                        );
                        return;
                    }

                    if (!taskInfo?.asignTo) {
                        console.error(
                            'Failed to send mail to user. Task is not assigned to any user.'
                        );
                        return;
                    }
                    const user_id = contactDetails?.find(
                        (e) => e?._id === taskInfo?.asignTo
                    )?.user_id;
                    const user_info = await this.getDataById(
                        MODELS_CONSTANTS.USERS,
                        user_id
                    ).toPromise();

                    apiData['content_management_id'] = content_management_id;
                    apiData['user_id'] = user_id;
                    apiData['template_data'] = {
                        userInfo: user_info?.data,
                        taskInfo,
                        stageInfo,
                    };
                    await executeApi(apiRegistry, apiData, true);
                } else if (
                    apiRegistry?._id ===
                    API_REGISTRY_CONSTANTS.SEND_NOTIFY_MAIL_TO_ASSIGNEE
                ) {
                    const content_management_id = action?.params?.find(
                        (e) => e?.key?.trim() === 'template_id'
                    )?.value;
                    if (!content_management_id) {
                        console.error(
                            'Failed to send mail. Template Id not provided.'
                        );
                        return;
                    }

                    const user_ids = stageInfo?.members?.map(
                        (e) => e?.member_id
                    );
                    if (user_ids?.length) {
                        for (const [index, user_id] of user_ids?.entries()) {
                            if (user_id) {
                                const user_info = await this.getDataById(
                                    MODELS_CONSTANTS.USERS,
                                    user_id
                                ).toPromise();
                                apiData['content_management_id'] =
                                    content_management_id;
                                apiData['user_id'] = user_id;
                                apiData['template_data'] = {
                                    userInfo: user_info?.data,
                                    taskInfo,
                                    stageInfo,
                                };
                                let isMail: boolean = false;
                                if (index === user_ids?.length - 1) {
                                    isMail = true;
                                }
                                await executeApi(apiRegistry, apiData, isMail);
                            }
                        }
                    }
                } else {
                    await executeApi(apiRegistry, apiData);
                }
            }
        }
    }

    create_grn(requestBody): Observable<any> {
        return this._httpClient.post(`${this.base_url}/create_grn`, requestBody, { headers: this._getHeaders() });
    }

    
}
