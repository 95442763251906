<div class="overflow-hidden" style="display: table;width: 100%;">
    <div class="px-4 py-2" style=" border-width: 0px 0px thin 0px;">
        <div class="flex flex-row mb-2">
            <div>
                <!-- <h2 matDialogTitle>{{head}}</h2> -->
                <!-- <h2 matDialogTitle>{{data.taskId}}</h2> -->
            </div>
            <div class="ml-auto flex">
                <button mat-flat-button color="primary" class="api-primary-btn mr-6"
                    (click)="save(); $event.preventDefault()">
                    Save
                </button>
                <mat-icon [mat-dialog-close] [matTooltip]="'Close'" class="close-btn mt-2" mat-dialog-close>close</mat-icon>
            </div>
        </div>
    </div>

    <div class="row overflow-y-auto" style="max-height: 39rem !important;">
        <div class="col-lg-6 row-scrolls" style="border-width: 0px thin 0px 0px;">
            <div class="mt-2 mb-1 px-8">
                <b>Title :</b><span class="ml-2">{{taskDetails.task_title}}</span>
            </div>
            <div class="mt-2 mb-1 px-8">
                <b>Description :</b><span class="ml-2">{{taskDetails.task_description}}</span>
            </div>
            <div class="mt-2 mb-1 px-8">
                <b>Project :</b><span class="ml-2">{{taskDetails.project_name}}</span>
            </div>
            <div class="mt-2 mb-2 line"></div>
            <!-- <div class="px-8" style="width: 80%;">
                <ul class="grid grid-cols-2">
                    <li *ngFor="let data of checkListvalue" class="p-2 mt-1 mb-1 list-row">
                        <input [(ngModel)]="data.addItems" [disabled]="data.checkedFlag" class="form-control">
                        <mat-icon *ngIf="!data.checkedFlag"
                            style="float: right; cursor: pointer; margin-top: -29px;font-size: 16px;"
                            (click)="completeTask(data)">done</mat-icon>
                        <mat-icon *ngIf="data.checkedFlag"
                            style="float: right; cursor: pointer; margin-top: -29px;font-size: 16px;">close</mat-icon>
                    </li>
                </ul>
            </div> -->

            <!-- <form [formGroup]="checklistForm" class="form-border"> -->
            <!-- <div class="px-8">
                    <div>
                        <span><b>Checklist :</b> </span>
                    </div>

                    <div>
                        <mat-form-field class="subtask-field flex-auto">
                            <input matInput [placeholder]="'Add item'" (click)="checklistField()"
                                formControlName="checklist">
                        </mat-form-field>
                    </div>
                    <div class="row" *ngIf="buttonFlag">
                        <div class="col-lg-12">
                            <div class="grid">
                                <div class="flex">
                                    <button mat-flat-button (click)="addChecklist(data.taskId)"
                                        class="pl-2 pr-2 save-btns rounded">
                                        Add
                                    </button>
                                    <button mat-flat-button class="ml-auto cls-Btn-shadow pl-2 pr-2 rounded"
                                        (click)="closeBtnRow()">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->


            <div class="px-8">
                <div>
                    <span><b>Sub tasks :</b></span>
                </div>

                <div class="mt-4">
                    <mat-form-field class="w-full" (click)="subTaskField()">
                        <input matInput [placeholder]="'Create a sub task'"
                            [(ngModel)]="subTask">
                    </mat-form-field>
                </div>
                <div class="row" *ngIf="createBtn">
                    <div class="col-lg-12">
                        <div class="grid grid-cols-1">
                            <div class="flex">
                                <button mat-flat-button color="primary" class="api-primary-btn"
                                (click)="addSubTask()">
                                    Create
                                </button>
                                <button  mat-button class="ml-auto api-primary-btn cancel-btn" (click)="closeSubTaskField()">
                                    Cancel
                                </button>
                                <!-- <button mat-flat-button (click)="saveSubTask(data.taskId)"
                                        class="pl-2 pr-2 save-btns rounded">
                                        Create
                                    </button>
                                    <button (click)="closeSubTaskField()" mat-flat-button
                                        class="ml-auto rounded cls-Btn-shadow pl-2 pr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        Cancel
                                    </button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 mb-3 line"></div>
            <div class="px-8" *ngIf="subTaskDetails?.length">
                <ul>
                    <li *ngFor="let data of subTaskDetails; let i=index" class="p-2 mt-1 mb-1 border list-row flex flex-col sm:flex-row">
                        <span class="taskView-wrap">{{data?.task_title}}</span>
                        <div class="flex flex-row ml-auto mt-2">
                        <span style="height: 29px;" [ngClass]="{
                                'Inprogress': data.task_status== 'Inprogress',
                                'Todo': data.task_status === 'Todo',
                                'Done': data.task_status === 'Done',
                                'Hold': data.task_status === 'Hold'
                            }" [matMenuTriggerFor]="statuslist"
                            class="cursor-pointer">{{data.task_status}}</span>
                        <mat-menu #statuslist="matMenu">
                            <!-- <form [formGroup]="taskForm" class="form-border"> -->
                                <mat-form-field style="padding: 10px;padding-bottom: 0px;">
                                    <mat-select [placeholder]="'-Status-'"
                                        (selectionChange)="updateSubTask($event.value, data)">
                                        <mat-option *ngFor="let item of statusList;"
                                        [value]="item.vsd_code">{{item.vsd_value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            <!-- </form> -->
                        </mat-menu>

                        <div style="height: 29px;" class="bg-card delete-btn hide-print ml-2" [matTooltip]="'Delete'"
                            (click)="deleteSubTask(data._id)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                class="w-5 h-5">
                                <path fill-rule="evenodd"
                                    d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- </form> -->
        </div>


        <div class="col-lg-6 px-8 row-scrolls">
            <form>

                <div class="mt-2 mb-1">
                    <div class="">
                        <div class="flex tasklist-item">
                            <div *ngIf="taskDetails.assing_to" class="col-md-4 tasklist-res1 row mt-2 mb-3">
                                <b>Assigned To:</b>
                                <a class="pl-2 viewName" [matMenuTriggerFor]="assing_to">
                                    <span>{{taskDetails.assing_to_name}}</span>
                                </a>
                                <mat-menu #assing_to="matMenu">
                                    <mat-form-field style="padding: 10px;padding-bottom: 0px;">
                                        <mat-select
                                            (selectionChange)="changeValue($event.value,'assing_to',taskDetails)"
                                            [placeholder]="'-Assigned To-'">
                                            <mat-option *ngFor="let item of users;"
                                                [value]="item._id">{{item.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-menu>

                                <div class="pl-1" *ngIf="taskDetails.task_status">
                                    <span [ngClass]="{
                                    'Inprogress': taskDetails.task_status === 'Inprogress',
                                    'Todo': taskDetails.task_status === 'Todo',
                                    'Done': taskDetails.task_status === 'Done',
                                    'Hold': taskDetails.task_status === 'Hold'
                                }" [matMenuTriggerFor]="statuslist"
                                        class="ml-auto cursor-pointer">{{taskDetails.task_status}}</span>
                                    <mat-menu #statuslist="matMenu">
                                        <mat-form-field style="padding: 10px;padding-bottom: 0px;">
                                            <mat-select
                                                (selectionChange)="changeValue($event.value,'task_status',taskDetails)"
                                                [placeholder]="'-Status-'">
                                                <mat-option *ngFor="let item of statusList;"
                                                    [value]="item.vsd_code">{{item.vsd_value}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </mat-menu>
                                </div>
                            </div>

                            <div *ngIf="taskDetails.collaborators"
                                class="col-md-4 tasklist-res1 mt-2 mb-3 tasklist-item">
                                <b>Collaborators:</b>
                                <a [matMenuTriggerFor]="collaborators"
                                    class="ml-2 viewName">{{taskDetails.collaborator_name}}</a>
                                <mat-menu #collaborators="matMenu">
                                    <mat-form-field style="padding: 10px;padding-bottom: 0px;">
                                        <mat-select
                                            (selectionChange)="changeValue($event.value,'collaborators',taskDetails)"
                                            [placeholder]="'-Collaborators-'" multiple>
                                            <mat-option *ngFor="let item of users;"
                                                [value]="item._id">{{item.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-menu>
                            </div>

                            <div *ngIf="taskDetails.milestone_id"
                                class="col-md-4 tasklist-res1 mt-2 mb-3   tasklist-item">
                                <b>Milestone:</b>
                                <a [matMenuTriggerFor]="milestone"
                                    class="ml-2 viewName">{{taskDetails.milestone_title}}</a>
                                <mat-menu #milestone="matMenu">
                                    <mat-form-field style="padding: 10px;padding-bottom: 0px;">
                                        <mat-select
                                            (selectionChange)="changeValue($event.value,'milestone_id',taskDetails)"
                                            [placeholder]="'-Milestone-'">
                                            <mat-option *ngFor="let item of milestone;"
                                                [value]="item._id">{{item.title}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="flex tasklist-item">

                    <div *ngIf="taskDetails.labelName" class="mt-2 mb-1 pl-0"><b>Label:</b>
                        <a [matMenuTriggerFor]="label" class="ml-2 viewName">{{taskDetails.labelName}}</a>
                        <mat-menu #label="matMenu">
                            <mat-form-field style="padding: 10px;padding-bottom: 0px;">
                                <mat-select (selectionChange)="changeValue($event.value,'label_id',taskDetails)"
                                    [placeholder]="'-Label-'">
                                    <mat-option *ngFor="let item of labelList;"
                                        [value]="item._id">{{item.labelName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-menu>
                    </div>

                    <div *ngIf="taskDetails.start_date" class="mt-2 mb-1 pl-0"><b>Start date:</b>
                        <a [matMenuTriggerFor]="start_date" class="ml-2 viewName">{{taskDetails.start_date |
                            date:'dd-MM-yyyy'}}</a>
                        <mat-menu #start_date="matMenu" class="demo-inline-calendar-card">
                            <mat-card>
                                <mat-calendar [(selected)]="taskDetails.start_date"
                                    (selectedChange)="changeValue(taskDetails.start_date,'start_date',taskDetails)" (click)="$event.stopPropagation()"></mat-calendar>
                            </mat-card>
                        </mat-menu>
                    </div>
                    <div *ngIf="taskDetails.end_date" class="mt-2 mb-1 pl-0"><b>Deadline:</b>
                        <a [matMenuTriggerFor]="end_date" class="ml-2 viewName">{{taskDetails.end_date |
                            date:'dd-MM-yyyy'}}</a>
                        <mat-menu #end_date="matMenu" class="demo-inline-calendar-card">
                            <mat-card>
                                <mat-calendar [(selected)]="taskDetails.end_date"
                                    (selectedChange)="changeValue(taskDetails.end_date,'end_date',taskDetails)" (click)="$event.stopPropagation()"></mat-calendar>
                            </mat-card>
                        </mat-menu>
                    </div>
                </div>
            </form>

            <div class="mt-2 mb-2 line"></div>
            <div class="grid grid-cols-1 lg:grid-cols-1 flex mt-2 mb-1">

                <div class="items-center text-secondary">
                    <div class="flex mb-3">
                        <mat-label style="font-weight: 600;">Date & Hours</mat-label>
                        <a class="flex viewName" style="text-wrap: nowrap;" (click)="addMore()">
                            <mat-icon class="ml-2 mr-1">add_circle_outline</mat-icon>
                            Add More
                        </a>
                    </div>
                    <div class="" *ngFor="let item of taskDate;let i = index">
                        <div class="flex" style="width: 100%;">
                            <mat-form-field class="col-6 col-lg-4 px-0 fuse-mat-dense fuse-mat-no-subscript" style="width: 40%;">
                                <input matInput [matDatepicker]="startDate" [(ngModel)]="item.date"
                                    [placeholder]="'Date'" (click)="startDate.open()">
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="col-3 col-xl-2 ml-1 px-0 sm:px-4 md:px-2 fuse-mat-dense fuse-mat-no-subscript" style="width: 20%;">
                                <input matInput placeholder="" [(ngModel)]="item.time" type="text" maxlength="5"
                                    id="flight_number_{{i}}" name="number_{{i}}">
                            </mat-form-field>

                            <mat-label class="hours-label"
                                [ngStyle]="i == 0 ? {'margin-right': '0px'} : {'margin-right': '0px'}">Hrs</mat-label>
                            &nbsp;&nbsp;
                            <div *ngIf="i != 0" (click)="removeHours(i)" class="bg-card delete-btn hours-deleterow"
                                [matTooltip]="'Delete'">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="w-5 h-5">
                                    <path fill-rule="evenodd"
                                        d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex" *ngIf="workingHours">
                <mat-label class="pl-3">Total Hours : <span class="ml-2">{{ workingHours }}
                        hrs</span></mat-label>
            </div>

            <div class="bg-card bg-transparent right-panel col-md-9 p-0">
                <div class="bg-card card-body border-gray-300  border-b mt-2 p-0">

                    <!-- <form [formGroup]="commentForm"> -->

                    <div class="form-group">
                        <div style="display: flex;">
                            <div class="col-md-11">
                                <mat-form-field class="w-full">
                                    <textarea matInput [(ngModel)]="comment" [placeholder]="'Write a comment...'"
                                        [spellcheck]="false"></textarea>
                                </mat-form-field>
                                <!-- <mat-error
                                        *ngIf="(commentForm.get('message').hasError('required') && commentSubmitted )|| (commentForm.get('message').touched && commentForm.get('message').hasError('required'))">*comment
                                        required</mat-error> -->

                                <!-- <div class="flex" *ngIf="commentImageUrl">
                                        <ng-container *ngIf="isImageFile(filename)">
                                            <div class="flex">
                                                <img [src]="commentImageUrl" class="w-24 ml-2" alt="...">
                                                <mat-icon class="imageclose" [matTooltip]="'Unselect'"
                                                    [svgIcon]="'heroicons_outline:x-circle'"
                                                    style="color: gray;margin-top: 1px;cursor: pointer;"
                                                    (click)="removeCommentFile()"></mat-icon>
                                            </div>
                                            <span *ngIf="filename">{{ filename }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="!isImageFile(filename)">
                                            <div class="flex">
                                                <svg class="mr-4 w-12 cursor-pointer" version="1.1" id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 400 400"
                                                    xml:space="preserve">
                                                    <g id="XMLID_807_">
                                                        <g id="XMLID_808_">
                                                            <polygon id="XMLID_75_" style="fill:#ACABB1;"
                                                                points="90,320 90,0 290,0 350,60 350,320 		" />
                                                            <polygon id="XMLID_809_" style="fill:#818085;"
                                                                points="290,0 350,60 290,60 		" />
                                                        </g>
                                                        <g id="XMLID_810_">
                                                            <polygon id="XMLID_72_" style="fill:#EEEEEF;"
                                                                points="70,360 70,40 270,40 330,100 330,360 		" />
                                                            <polygon id="XMLID_811_" style="fill:#DEDDE0;"
                                                                points="270,40 330,100 270,100 		" />
                                                        </g>
                                                        <polygon id="XMLID_68_" style="fill:#78B9EB;"
                                                            points="50,400 50,80 250,80 310,140 310,400 	" />
                                                        <polygon id="XMLID_812_" style="fill:#5A8BB0;"
                                                            points="310,253.75 50,400 310,400 	" />
                                                        <rect id="XMLID_813_" x="110" y="200" style="fill:#DEDDE0;"
                                                            width="140" height="20" />
                                                        <rect id="XMLID_814_" x="110" y="240" style="fill:#DEDDE0;"
                                                            width="140" height="20" />
                                                        <rect id="XMLID_815_" x="110" y="160" style="fill:#FFFFFF;"
                                                            width="70" height="20" />
                                                        <rect id="XMLID_816_" x="110" y="200" style="fill:#FFFFFF;"
                                                            width="70" height="20" />
                                                        <rect id="XMLID_817_" x="110" y="240" style="fill:#FFFFFF;"
                                                            width="70" height="20" />
                                                        <rect id="XMLID_818_" x="110" y="280" style="fill:#DEDDE0;"
                                                            width="140" height="20" />
                                                        <rect id="XMLID_819_" x="110" y="280" style="fill:#FFFFFF;"
                                                            width="70" height="20" />
                                                        <rect id="XMLID_820_" x="110" y="320" style="fill:#DEDDE0;"
                                                            width="140" height="20" />
                                                        <rect id="XMLID_821_" x="110" y="320" style="fill:#FFFFFF;"
                                                            width="70" height="20" />
                                                        <polygon id="XMLID_822_" style="fill:#1E2E3B;"
                                                            points="250,80 310,140 250,140 	" />
                                                    </g>
                                                </svg>
                                                <span class="mr-4" *ngIf="filename">{{ filename
                                                    }}</span>
                                                <mat-icon class="fileclose ml-6" [matTooltip]="'Unselect'"
                                                    [svgIcon]="'heroicons_outline:x-circle'"
                                                    style="color: gray;margin-top: 1px;cursor: pointer;"
                                                    (click)="removeCommentFile()"></mat-icon>
                                            </div>
                                        </ng-container>

                                    </div>
                                    <div class="">
                                        <input type="file" [(ngModel)]="filename" #file1 id="upload_file"
                                            (change)="csvInputChange($event,'commentUrl')">
                                    </div> -->
                                <div>
                                    <div
                                        class="relative flex flex-row sm:flex-row flex-0 sm:items-center sm:justify-between">

                                        <!-- <div class="flex shrink-0 items-center mt-6 sm:mt-2 sm:ml-4">
                                                <button class="comment-btns_2 save-btns rounded"
                                                    (click)="file1.click()">&nbsp;&nbsp;
                                                    <mat-icon class="icon-size-5" style="color: white;"
                                                        [svgIcon]="'heroicons_solid:camera'"></mat-icon>
                                                    <span class="ml-2 mr-1">Upload file</span>
                                                </button>
                                            </div> -->

                                        <div class="">
                                            <!-- <button class="comment-btns_2 save-btns rounded"
                                                   >&nbsp;
                                                    <mat-icon class="icon-size-5 rotate-90" style="color: white;"
                                                        [svgIcon]="'heroicons_solid:paper-airplane'"></mat-icon>
                                                    <span class="ml-2 mr-1">Post Comment</span>
                                                </button> -->
                                            <button mat-flat-button color="primary" class="api-primary-btn"
                                                (click)="addComment(); $event.preventDefault()">
                                                Post Comment
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>


                <div class="card-body post-dropzone">
                    <div class="" *ngIf="taskDetails?.comment?.length">
                        <div class="posted-comments" *ngFor="let item of commentList; let i=index;">
                            <div class="bg-card card-style">
                                <div class="row">
                                    <div class="user-img">
                                        <ng-container>
                                            <img width="50" height="50" class="w-8 h-8 rounded-full mr-4"
                                                style="margin-top: -3px;"
                                                src="https://img.icons8.com/ios-filled/50/000000/user-male-circle.png"
                                                alt="user-male-circle" />
                                        </ng-container>
                                    </div>
                                    <div class="flex">
                                        <div class=" mb5 capital txt-dark txt-strong cursor-pointer">
                                            {{item.user_name}}
                                            <!-- <small>
                                                <span class="text-off capital">{{item.createdAt |
                                                    date:'dd-MM-yyyy HH:mm:ss'}}</span>
                                            </small> -->
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-12 col-md-12 col-sm-6 col-xs-6 py-2">
                                    <div class="taskView-wrap" style="margin-left: 20px;">
                                        <p class="capital">{{item.message}}</p>
                                    </div>
                                    <!-- <div class="" style="padding: 2px;" *ngIf="item.uploadFiles">
                                        <div *ngIf="item.uploadFiles">
                                            <img [src]='item.uploadFiles' class="d-block w-100 responsive-image"
                                                alt="...">
                                        </div><br>
                                    </div> -->

                                    <div class="row">
                                        <button class="row ml-5" (click)="deleteComment(i)">&nbsp;
                                            <mat-icon class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                            <span class="ml-2 mr-1">Delete</span>
                                        </button>
                                    </div><br>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>