<div *ngIf="footerPermissions()" class="w-full flex flex-wrap">
  <div class="w-full flex align-items-center shadow py-1.5 px-6 justify-content-between">
    <div *ngIf="footerData()?.doc_no" class="px-2 text-sm" matTooltip="Document No.">
      <!-- <b>Document No.</b> <br> -->
      {{footerData()?.doc_no}}
    </div>
    <div *ngIf="footerPermissions()?.show_created_by && footerData()?.created_by" class="px-2 text-sm" matTooltip="Created By">
      <!-- <b>Created By</b> <br> -->
      {{footerData()?.created_by}}
    </div>
    <div *ngIf="footerPermissions()?.show_updated_by && footerData()?.updated_by" class="px-2 text-sm" matTooltip="Updated By">
      <!-- <b>Updated By</b> <br> -->
      {{footerData()?.updated_by}}
    </div>
    <div *ngIf="footerPermissions()?.show_status" class="px-2 text-sm">
      <!-- <b class="mr-1">Status</b> -->
      <span class="text-sm" [ngClass]="'status ' + formStatus()" matTooltip="Status">
        {{formStatus()}}
      </span>
    </div>
    <div *ngIf="footerPermissions()?.show_activity_tracker" class="px-2">
      <button mat-icon-button matTooltip="Activity Tracker" (click)="openComponent('activity-tracker')" class="footer-btn">
        <mat-icon class="footer-icon">directions_run</mat-icon>
      </button>
    </div>
    <div *ngIf="footerPermissions()?.show_help_support" class="px-2">
      <button mat-icon-button matTooltip="Help & Support" (click)="openComponent('content_management')" class="footer-btn">
        <mat-icon [svgIcon]="'heroicons_solid:information-circle'" class="footer-icon"></mat-icon>
      </button>
    </div>
    <div *ngIf="isApproval()" class="px-2">
      <button mat-icon-button matTooltip="Task Details" (click)="openComponent('scrumboard-card-details')" class="footer-btn">
        <mat-icon [svgIcon]="'heroicons_solid:clipboard-document-list'" class="footer-icon"></mat-icon>
      </button>
    </div>
  </div>
</div>