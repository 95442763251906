import { TextFieldModule } from '@angular/cdk/text-field';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, Input, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatMenuModule } from '@angular/material/menu';
import moment from 'moment';
import { TaskViewComponent } from '../task-view/task-view.component';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
    selector: 'project-timesheet',
    templateUrl: './time-sheet.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatTableModule, MatMenuModule, MatFormFieldModule, MatIconModule, MatInputModule, CommonModule,
        TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatDatepickerModule, MatSortModule],
})
export class ProjectTimesheetComponent implements OnInit {
    @Input() row_data: any;
    displayedColumns: string[] = [
        'task_title',
        'project_name',
        'assing_to_name',
        // 'task_status',
        'start_time',
        'end_time',
        'total_hours'
    ];
    dataSource: any;
    projects: any;
    users: any;
    projectId: any;

    sortDirection: boolean = false;
    sortKey: string = '';
    @ViewChild(MatSort) sort: MatSort;

    /**
     * Constructor
     */
    constructor(
        private _commonService: CommonService,
        private fb: FormBuilder,
        private _snackbar: SnackbarService,
        private dialog: MatDialog,
        private _fuseConfirmationService: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private snackbar: SnackbarService,
        private route: ActivatedRoute
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.row_data && this.row_data._id) {
            this.projectId = this.row_data._id
        } else {
            this.route.params.subscribe((params: Params) => {
                this.projectId = params['id'];
            });
        }
        this.getAllData();
    }

    sortData(column: string) {
        if (this.sortKey === column) {
            this.sortDirection = !this.sortDirection;
        } else {
            this.sortKey = column;
            this.sortDirection = false;
        }

        if (this.sort && column && this.dataSource.length > 1) {
            this.dataSource.sort((a, b) => {
                const valA = a[column];
                const valB = b[column];

                if (valA < valB) {
                    return this.sortDirection ? 1 : -1;
                } else if (valA > valB) {
                    return this.sortDirection ? -1 : 1;
                } else {
                    return 0;
                }
            });
        }

        this.dataSource = this.dataSource.slice();

        this._changeDetectorRef.detectChanges();
    }

    async getAllData() {
        const userResult = await this._commonService.getAllData(MODELS_CONSTANTS.USERS).toPromise();
        if (userResult?.status === 200) {
            this.users = userResult?.data || [];
        }
        const projectResult = await this._commonService.getAllData(MODELS_CONSTANTS.PROJECT).toPromise();
        if (projectResult?.status === 200) {
            this.projects = projectResult?.data || [];
        }
        this.getAllTasks();
    }

    getAllTasks() {
        let data: any = {
            project_id: this.projectId,
            task_type: 'Task'
        }
        this._commonService.getDataByMultipleField(MODELS_CONSTANTS.PROJECT_TASKS, data).subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.dataSource = response?.data;
                    this.dataSource.forEach(element => {
                        let project = this.projects.find((item) => item._id == element.project_id)
                        let user = this.users.find((item) => item._id == element.assing_to)
                        if (project) {
                            element.project_name = project.projectTitle;
                        }
                        if (user) {
                            element.assing_to_name = user.name;
                        }
                    });
                    this.hours();
                    console.log(response?.data);
                    this._changeDetectorRef.detectChanges();
                }
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    hours() {
        this.dataSource.forEach(element => {
            if (element?.task_date?.length) {
                element.start_time = element.task_date[0].date;
                if (element.task_date.length > 1) {
                    let Length = element.task_date.length - 1;
                    element.end_time = element.task_date[Length].date;
                }
                let sum = 0;
                element.task_date.forEach(element => {
                    sum = sum + Number(element.time)
                });
                element.total_hours = sum + ' ' + 'Hrs';
            } else {
                element.total_hours = '-';
            }
        })
    }

}
