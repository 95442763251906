<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden p-2">
  <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="matDrawer.close()">
    <mat-drawer class="dark:bg-gray-900" [style.width]="drawerWidth" [mode]="'over'" [opened]="false" [position]="'end'"
      [disableClose]="true" [autoFocus]="false" #matDrawer>
      <div class="dynamic-tabs-container" *ngIf="dynamicTabsData">
        <app-dynamic-tabs [data]="dynamicTabsData"></app-dynamic-tabs>
      </div>
    </mat-drawer>

    <mat-drawer-content class="flex flex-col">
      <div class="w-full flex flex-row add-api-modal-header sticky top-0 z-49 
        shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent">

        <button *ngIf="workflowView === 'Horizontal'" mat-mini-fab
          [color]="currentIndexHorizontal !== 0 ? 'primary' : ''" class="ml-2 p-2 rounded h-fit cursor-pointer"
          (click)="toggleActive()" [matTooltip]="currentIconAndTooltip.tooltip">
          <mat-icon>{{ currentIconAndTooltip.icon }}</mat-icon>
        </button>

        <!-- For Vertical View -->
        <button *ngIf="workflowView === 'Vertical'" mat-mini-fab [color]="currentIndexVertical !== 0 ? 'primary' : ''"
          class="ml-2 p-2 rounded h-fit cursor-pointer" (click)="toggleActive()"
          [matTooltip]="currentIconAndTooltip.tooltip">
          <mat-icon>{{ currentIconAndTooltip.icon }}</mat-icon>
        </button>

        <h3 class="mr-auto ml-6">{{stageTitle}}</h3>
        <mat-button-toggle-group class="ml-auto h-fit" name="view" aria-label="View" color="primary" appearance="legacy"
          [(ngModel)]="workflowView">
          <mat-button-toggle value="Vertical">Vertical</mat-button-toggle>
          <mat-button-toggle value="Horizontal">Horizontal</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="px-4  flex justify-end cencel-dialog-icon">
          <button mat-mini-fab [matTooltip]="'Board Properties'" class="shadow-md ml-2 manageButton"
            color="bg-card ml-2 border" (click)="modifyBoardProperty()">
            <mat-icon class="p-2">layers</mat-icon>
          </button>
          <button mat-mini-fab [matTooltip]="'Board Access'" class="shadow-md ml-2 manageButton"
            color="bg-card ml-2 border" (click)="modifyBoardAccess()">
            <mat-icon class="p-2">vpn_key</mat-icon>
          </button>
          <button mat-mini-fab [matTooltip]="'Board Actions'" class="shadow-md ml-2 manageButton"
            color="bg-card ml-2 border" (click)="modifyBoardActions()">
            <mat-icon class="p-2">widgets</mat-icon>
          </button>
        </div>
      </div>

      @if (workflowView === 'Vertical') {
      <div class="card-container m-auto py-4">
        <!-- Static Start Card -->
        <div class="flex-0 w-72 p-2 border mr-1 bg-green-500 w-fit rounded">
          <div class="flex items-center justify-between cursor-pointer">
            <div class="flex items-center w-full py-0 px-3 rounded-md cursor-text 
                border border-transparent focus-within:bg-white focus-within:shadow-sm 
                focus-within:border-primary dark:focus-within:bg-gray-900">
              <div class="text-lg font-medium leading-5 m-auto">Start</div>
            </div>
          </div>
        </div>
        <div class="line-container my-2 ml-2">
          <div class="absolute left-4 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600">
            <mat-icon class="down-arrow1 text-gray-300">keyboard_arrow_down</mat-icon>
          </div>
          <div class="flex shrink-0 items-center justify-center w-5 h-5 mr-4 rounded-full 
              bg-gray-400 m-auto ml-2" style="z-index: 1;">
            <mat-icon (click)="add_update_stage_task(null)" class="icon-size-5 text-white cursor-pointer addStageIcon"
              matTooltip="Add Stage" matTooltipPosition="right">add</mat-icon>
          </div>
        </div>

        <!-- Dynamic Cards -->
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <ng-container *ngFor="let stage of board_stages; let i = index">
            <div cdkDrag class="">
              <div class="flex-0 w-72 px-1 group" style="margin-bottom: 55px;">
                <!-- Entry Section -->
                <div
                  *ngIf="iconsAndFlagsVertical[currentIndexVertical].icon !== 'remove_red_eye' && stage?.onEntryList?.length">
                  <div [ngClass]="{ 'flip-in': selectedEntryIndex !== i }">
                    <img class="w-12 h-12 p-1 rounded-md bg-card border shadow-lg hover:shadow-lg 
                        hover:bg-primary-400 ease-in-out m-auto cursor-pointer" src="assets/images/icons/log-in.png"
                      matTooltip="On Entry" (click)="toggle_onEntry(i)">
                    <span class="flex items-center justify-center h-0 onexit-numbers">
                      <span
                        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                        {{stage?.onEntryList?.length}}
                      </span>
                    </span>
                  </div>
                  <div *ngIf="selectedEntryIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel'"
                    [ngClass]="{ 'flip-in': selectedEntryIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel' }"
                    class="border rounded shadow-lg justify-center p-2 onEntrycard-position">
                    <div class="absolute text-center font-semibold text-white pb- px-2 rounded bg-primary text-sm"
                      style="margin-top: -1.4rem; left: 1rem;">On Entry
                    </div>
                    <div class="flex">
                      <ng-container *ngFor="let action of stage.onEntryList; let j = index">
                        <div class="flex justify-center space-x-4">
                          <div
                            class="conditionIcon w-40 text-ellipsis overflow-hidden m-auto mx-2 border rounded-lg border-primary bg-gray-300 text-center text-nowrap cursor-pointer hover:bg-gray-400">
                            <div class="max-w-sm mx-auto p-1 bg-white border border-gray-300 rounded-lg drop-shadow-xl">
                              <div class="text-center flex tile">
                                <div class="text-center flex w-30" (click)="modifyStageActions(stage, action)">
                                  <mat-icon class="m-1" style="padding: 1px;" color="primary"
                                    [svgIcon]="action?.icon ? action?.icon : ''"
                                    [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                                  <div class="text-sm font-semibold truncate-text self-center"
                                    [matTooltip]="action?.api_registry_name">
                                    {{action?.api_registry_name}}
                                  </div>
                                </div>
                                <div class="ml-auto flex justify-end hover-icons">
                                  <mat-icon class="m-1" style="padding: 1px;" color="warn"
                                    (click)="deleteAction(action)" [svgIcon]="'heroicons_outline:trash'"
                                    [matTooltip]="Delete"></mat-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="entry line-container" style="margin-left: 7.8rem;">
                    <div class="absolute left-4 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600">
                      <mat-icon class="absolute down-arrow3 text-gray-300">keyboard_arrow_down</mat-icon>
                    </div>
                  </div>
                </div>

                <div>
                  <!-- On Entry Add Actions -->
                  <div class="entry items-center justify-center text-center">
                    <mat-icon (click)="modifyStageActions(stage, null)"
                      class="border rounded-full p-1 absolute icon-size-5 cursor-pointer entry-add-icon"
                      matTooltip="Add On Entry" matTooltipPosition="right">build</mat-icon>
                  </div>

                  <div class="flex" [ngClass]="{ '-ml-2 ': showWhenPushDiv }">
                    <!-- Pushback Section -->
                    <div
                      *ngIf="iconsAndFlagsVertical[currentIndexVertical].icon !== 'remove_red_eye' && stage?.onPushbackList?.length">
                      <div class="pushicondiv">
                        <img [ngClass]="{ 'flip-in ': selectedPushIndex !== i }" (click)="toggle_onPushback(i)" class="w-12 h-12 p-1 rounded-md bg-card border shadow-lg hover:shadow-lg 
                            hover:bg-primary-400 ease-in-out cursor-pointer" src="assets/images/icons/push.png"
                          matTooltip="When Pushback">
                        <span class="absolute top-0 right-0 left-0 ml-4 flex items-center justify-center h-0">
                          <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-6 
                                rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium m-auto">
                            {{stage?.onPushbackList?.length}}
                          </span>
                        </span>
                      </div>

                      <div class="relative l-shape"
                        [ngClass]="selectedPushIndex === i  || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel' ? 'pushbackcard' : 'pushbackicon'">
                        <mat-icon
                          class="absolute push-down-arrow text-gray-300 rotate-180">keyboard_arrow_down</mat-icon>
                        <div class=""
                          [ngClass]="selectedPushIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel' ? 'push-card ' : 'push-icon'">
                          <div
                            *ngIf="selectedPushIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel'"
                            [ngClass]="{ 'flip-in': selectedPushIndex === i }"
                            class="border rounded shadow-lg    flex justify-center p-2 whenpushcard-position">
                            <div
                              class="absolute text-center font-semibold text-white pb- px-2 rounded bg-primary text-sm"
                              style="margin-top: -1.4rem;left: 1rem;">When Pushback
                            </div>

                            <ng-container *ngFor="let action of stage.onPushbackList; let j = index">
                              <div class="flex justify-center space-x-4">
                                <div
                                  class="conditionIcon w-40 text-ellipsis overflow-hidden m-auto mx-2 border rounded-lg border-primary bg-gray-300 text-center text-nowrap cursor-pointer hover:bg-gray-400">
                                  <div
                                    class="max-w-sm mx-auto p-1 bg-white border border-gray-300 rounded-lg drop-shadow-xl">
                                    <div class="text-center flex tile">
                                      <div class="text-center flex w-30" (click)="modifyStageActions(stage, action)">
                                        <mat-icon class="m-1" style="padding: 1px;" color="primary"
                                          [svgIcon]="action?.icon ? action?.icon : ''"
                                          [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                                        <div class="text-sm font-semibold truncate-text self-center"
                                          [matTooltip]="action?.api_registry_name">
                                          {{action?.api_registry_name}}
                                        </div>
                                      </div>
                                      <div class="ml-auto flex justify-end hover-icons">
                                        <mat-icon class="m-1" style="padding: 1px;" color="warn"
                                          (click)="deleteAction(action)" [svgIcon]="'heroicons_outline:trash'"
                                          [matTooltip]="Delete"></mat-icon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Reject Section -->
                    <div
                      *ngIf="iconsAndFlagsVertical[currentIndexVertical].icon !== 'remove_red_eye' && stage?.onRejectList?.length">
                      <div class="flex absolute" style="left: 0.3rem !important">
                        <div class="" [ngClass]="selectedRejectIndex === i ? 'reject-card' : 'reject-icon'"
                          style="position: relative; top: 1rem;">
                          <div [ngClass]="{ 'flip-in': selectedRejectIndex !== i }">
                            <img class="w-12 h-12 p-1 rounded-md bg-card border shadow-lg hover:shadow-lg
                                hover:bg-primary-400 ease-in-out cursor-pointer" src="assets/images/icons/reject.png"
                              matTooltip="When Reject" (click)="toggle_onReject(i)">
                            <span class="absolute top-0 right-0 left-0 ml-10 flex items-center justify-center h-0">
                              <span
                                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                                {{stage?.onRejectList?.length}}
                              </span>
                            </span>
                          </div>

                          <div
                            *ngIf="selectedRejectIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel'"
                            [ngClass]="{ 'flip-in': selectedRejectIndex === i }"
                            class="border rounded shadow-lg    flex justify-center p-2 whenRejectcard-position">
                            <div
                              class="absolute text-center font-semibold text-white pb- px-2 rounded bg-primary text-sm"
                              style="margin-top: -1.4rem;left: 1rem;">When Reject
                            </div>

                            <ng-container *ngFor="let action of stage.onRejectList; let j = index">
                              <div class="flex justify-center space-x-4">
                                <div
                                  class="conditionIcon w-40 text-ellipsis overflow-hidden m-auto mx-2 border rounded-lg border-primary bg-gray-300 text-center text-nowrap cursor-pointer hover:bg-gray-400">
                                  <div
                                    class="max-w-sm mx-auto p-1 bg-white border border-gray-300 rounded-lg drop-shadow-xl">
                                    <div class="text-center flex tile">
                                      <div class="text-center flex w-30" (click)="modifyStageActions(stage, action)">
                                        <mat-icon class="m-1" style="padding: 1px;" color="primary"
                                          [svgIcon]="action?.icon ? action?.icon : ''"
                                          [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                                        <div class="text-sm font-semibold truncate-text self-center"
                                          [matTooltip]="action?.api_registry_name">
                                          {{action?.api_registry_name}}
                                        </div>
                                      </div>
                                      <div class="ml-auto flex justify-end hover-icons">
                                        <mat-icon class="m-1" style="padding: 1px;" color="warn"
                                          (click)="deleteAction(action)" [svgIcon]="'heroicons_outline:trash'"
                                          [matTooltip]="Delete"></mat-icon>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="relative lr-shape" style="margin-top: -1.5rem;">
                          <div class="absolute" style="top: -0.7rem;left: -0.7rem;">
                            <mat-icon class="absolute text-gray-300 rotate-90"
                              style="margin-right: -1.5rem;">keyboard_arrow_down</mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- On Pushback Add Actions -->
                    <div class="entry">
                      <div class="items-center justify-between">
                        <mat-icon (click)="modifyStageActions(stage, null)"
                          class="border rounded-full p-1 absolute icon-size-5 cursor-pointer push-add-icon"
                          matTooltip="Add When Pushback">build</mat-icon>
                      </div>
                    </div>

                    <!-- On Reject Add Actions -->
                    <div class="entry items-center justify-between">
                      <mat-icon (click)="modifyStageActions(stage, null)"
                        class="border rounded-full p-1 absolute icon-size-5 cursor-pointer reject-add-icon"
                        matTooltip="Add When Reject">build</mat-icon>
                    </div>

                    <!-- Main Stage Section -->
                    <div class="flex items-center justify-between border w-full cursor-pointer"
                      (dblclick)="add_update_stage_task(stage)">
                      <div [ngClass]="bgColors[i]" class="flex items-center justify-center p-4 rounded 
                          text-primary-700 dark:bg-primary dark:text-on-primary">
                        <mat-icon class="primary">{{ icons[i] }}</mat-icon>
                      </div>
                      <div
                        class="flex items-center w-full py-0 px-3 rounded-md border border-transparent 
                          focus-within:bg-white focus-within:shadow-sm focus-within:border-primary dark:focus-within:bg-gray-900">
                        <div class="text-lg font-medium leading-5 m-auto">{{ stage.title }}</div>
                      </div>

                      <div class="w-full flex flex-row">
                        <div></div>
                        <div class="flex flex-row ml-auto">
                          <mat-icon class="m-1" color="primary" [matTooltip]="'Property'"
                            svgIcon="heroicons_outline:building-library"
                            (click)="modifyStageProperty(stage)"></mat-icon>
                          <mat-icon class="m-1 act-icon" [matTooltip]="'Access'" svgIcon="heroicons_outline:users"
                            (click)="modifyStagePermissions(stage)"></mat-icon>
                          <mat-icon class="m-1 action-icons" color="warn" [matTooltip]="'Delete'"
                            svgIcon="heroicons_outline:trash" (click)="deleteItem(stage)"></mat-icon>
                        </div>
                      </div>
                    </div>

                    <!-- On Approve Add Actions -->
                    <div class="entry">
                      <mat-icon (click)="modifyStageActions(stage, null)"
                        class="border rounded-full p-1 absolute icon-size-5 cursor-pointer approve-add-icon"
                        matTooltip="Add When Approve">build</mat-icon>
                    </div>

                    <!-- Approve Section -->
                    <div
                      *ngIf="iconsAndFlagsVertical[currentIndexVertical].icon !== 'remove_red_eye' && stage?.onApproveList?.length">
                      <div class="flex absolute" style="right: -7.2rem !important">
                        <div class="" [ngClass]="selectedApproveIndex === i ? 'approve-card' : 'approve-icon'"
                          style="position: relative; top: 1rem;">
                          <div [ngClass]="{ 'flip-in': selectedApproveIndex !== i }">
                            <img
                              class="w-12 h-12 p-1 rounded-md bg-card border shadow-lg hover:shadow-lg hover:bg-primary-400 ease-in-out cursor-pointer"
                              src="assets/images/icons/ready.png" matTooltip="When Approved"
                              (click)="toggle_onApprove(i)">
                            <span class="absolute top-0 right-2 left-0 ml-10 flex items-center justify-center h-0">
                              <span
                                class="flex items-center justify-center shrink-0 min-w-4 h-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                                {{stage?.onApproveList?.length}}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div class="approve lr-shape2" style="margin-top: -2.5rem;">
                          <div class="absolute" style="top: -0.7rem; right: -0.7rem;">
                            <mat-icon class="absolute text-gray-300 rotate-270"
                              style="margin-left: -1.5rem;">keyboard_arrow_down</mat-icon>
                          </div>
                        </div>
                        <div class="ml-3">
                          <div
                            [ngClass]="selectedApproveIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel' ? 'approve-right17 flip-in absolute' : 'approve-right10'">
                            <div
                              *ngIf="selectedApproveIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel'"
                              class="border rounded shadow-lg w-full flex justify-center p-2 whenApprovecard-position">
                              <div
                                class="absolute text-center font-semibold text-white pb- px-2 rounded bg-primary text-sm"
                                style="margin-top: -1.4rem;left: 1rem;">When Approved</div>
                              <ng-container *ngFor="let action of stage.onApproveList; let j = index">
                                <div class="flex justify-center space-x-4">
                                  <div
                                    class="conditionIcon w-40 text-ellipsis overflow-hidden m-auto mx-2 border rounded-lg border-primary bg-gray-300 text-center text-nowrap cursor-pointer hover:bg-gray-400">
                                    <div
                                      class="max-w-sm mx-auto p-1 bg-white border border-gray-300 rounded-lg drop-shadow-xl">
                                      <div class="text-center flex tile">
                                        <div class="text-center flex w-30" (click)="modifyStageActions(stage, action)">
                                          <mat-icon class="m-1" style="padding: 1px;" color="primary"
                                            [svgIcon]="action?.icon ? action?.icon : ''"
                                            [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                                          <div class="text-sm font-semibold truncate-text self-center"
                                            [matTooltip]="action?.api_registry_name">{{action?.api_registry_name}}</div>
                                        </div>
                                        <div class="ml-auto flex justify-end hover-icons">
                                          <mat-icon class="m-1" style="padding: 1px;" color="warn"
                                            (click)="deleteAction(action)" [svgIcon]="'heroicons_outline:trash'"
                                            [matTooltip]="Delete"></mat-icon>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- On Exit Add Actions -->
                  <div class="entry text-center">
                    <mat-icon (click)="modifyStageActions(stage, null)"
                      class="border rounded-full p-1 absolute icon-size-5 cursor-pointer exit-add-icon"
                      matTooltip="Add On Exit" matTooltipPosition="right">build</mat-icon>
                  </div>
                </div>

                <!-- Exit Section -->
                <div
                  *ngIf="iconsAndFlagsVertical[currentIndexVertical].icon !== 'remove_red_eye' && stage?.onExitList?.length">
                  <div [ngClass]="stage?.onApproveList?.length >0 ? 'exitline1' : 'exitline2'">
                    <div class="w-0.5 h-10 -ml-px bg-gray-300 dark:bg-gray-600 exitlinecustom"></div>
                    <div [ngClass]="{ 'flip-in': selectedExitIndex !== i }">
                      <img class="w-12 h-12 p-1 rounded-md bg-card border shadow-lg hover:shadow-lg
                          hover:bg-primary-400 ease-in-out cursor-pointer" src="assets/images/icons/log-out.png"
                        matTooltip="On Exit" (click)="toggle_onExit(i)">
                      <span class="flex items-center justify-center h-0 onexit-numbers">
                        <span
                          class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                          {{stage?.onExitList?.length}}
                        </span>
                      </span>
                    </div>
                    <div *ngIf="selectedExitIndex === i || iconsAndFlagsVertical[currentIndexVertical].icon == 'cancel'"
                      [ngClass]="{ 'flip-in': selectedExitIndex === i }"
                      class="border rounded shadow-lg onExitcard-position flex justify-center p-2">
                      <div class="absolute text-center font-semibold text-white pb- px-2 rounded bg-primary text-sm"
                        style="margin-top: -1.4rem; left: 1rem;">On Exit
                      </div>

                      <ng-container *ngFor="let action of stage.onExitList; let j = index">
                        <div class="flex justify-center space-x-4">
                          <div
                            class="conditionIcon w-40 text-ellipsis overflow-hidden m-auto mx-2 border rounded-lg border-primary bg-gray-300 text-center text-nowrap cursor-pointer hover:bg-gray-400">
                            <div class="max-w-sm mx-auto p-1 bg-white border border-gray-300 rounded-lg drop-shadow-xl">
                              <div class="text-center flex tile">
                                <div class="text-center flex w-30" (click)="modifyStageActions(stage, action)">
                                  <mat-icon class="m-1" style="padding: 1px;" color="primary"
                                    [svgIcon]="action?.icon ? action?.icon : ''"
                                    [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                                  <div class="text-sm font-semibold truncate-text self-center"
                                    [matTooltip]="action?.api_registry_name">
                                    {{action?.api_registry_name}}
                                  </div>
                                </div>
                                <div class="ml-auto flex justify-end hover-icons">
                                  <mat-icon class="m-1" style="padding: 1px;" color="warn"
                                    (click)="deleteAction(action)" [svgIcon]="'heroicons_outline:trash'"
                                    [matTooltip]="Delete"></mat-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="line-container " *ngIf="i === board_stages.length - 1">
                  <div class="absolute w-0.5 -ml-px bg-gray-300 dark:bg-gray-600"
                    style="margin-left: -0.35rem !important; height: 6.5rem;">
                    <mat-icon class="down-arrow text-gray-300">keyboard_arrow_down</mat-icon>
                  </div>
                  <div class="flex shrink-0 items-center justify-center w-5 h-5 mr-4 rounded-full 
                      bg-gray-400 mt-auto ml-2" style="z-index: 1;">
                    <mat-icon class="icon-size-5 text-white cursor-pointer addStageIcon" matTooltip="Add Stage"
                      (click)="add_update_stage_task(null)" matTooltipPosition="right">add</mat-icon>
                  </div>
                </div>

                <div class=" line-container " *ngIf="i < board_stages.length - 1">
                  <div class="exit absolute w-0.5 -ml-px bg-gray-300 dark:bg-gray-600"
                    style="height: 6.5rem !important;">
                    <mat-icon class="down-arrow2 text-gray-300">keyboard_arrow_down</mat-icon>
                  </div>
                  <div class="flex shrink-0 items-center justify-center w-5 h-5 mr-4 rounded-full 
                      bg-gray-400 mt-auto ml-4" style="z-index: 1;">
                    <mat-icon (click)="add_update_stage_task(null)"
                      class="icon-size-5 text-white cursor-pointer addStageIcon" matTooltip="Add Stage"
                      matTooltipPosition="right">add</mat-icon>
                  </div>
                </div>

              </div>
            </div>
          </ng-container>
        </div>

        <!-- Static End Card -->
        <div class="flex-0 w-72 p-2 border mr-1 mt-3 bg-green-500 w-fit rounded">
          <div class="flex items-center justify-between cursor-pointer">
            <div class="flex items-center w-full py-0 px-3 rounded-md cursor-text 
                border border-transparent focus-within:bg-white focus-within:shadow-sm 
                focus-within:border-primary dark:focus-within:bg-gray-900">
              <div class="text-lg font-medium leading-5 m-auto">End</div>
            </div>
          </div>
        </div>
      </div>
      } @else if (workflowView === 'Horizontal') {
      <div class="container mx-auto p-4 flex flex-row overflow-x-auto h-full">
        <div class="flex items-center bg-primary-500 text-white p-4 rounded-md w-30 h-fit  mt-4">
          <svg fill="white" class="w-6 m-auto mr-2" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M29.6,5.2C29.3,5,29,4.9,28.7,5.1c-4.3,1.4-8.7,0.3-12-2.8c-0.4-0.4-1-0.4-1.4,0c-3.3,3.1-7.7,4.2-12,2.8 C3,4.9,2.7,5,2.4,5.2C2.2,5.4,2,5.7,2,6c0,15.7,6.9,20.9,13.6,23.9C15.7,30,15.9,30,16,30s0.3,0,0.4-0.1C23.1,26.9,30,21.7,30,6 C30,5.7,29.8,5.4,29.6,5.2z M22.7,14.8l-3.2,2.3l1.2,3.8c0.1,0.4,0,0.9-0.4,1.1c-0.2,0.1-0.4,0.2-0.6,0.2s-0.4-0.1-0.6-0.2L16,19.7 L12.8,22c-0.4,0.3-0.8,0.3-1.2,0c-0.4-0.3-0.5-0.7-0.4-1.1l1.2-3.8l-3.2-2.3c-0.4-0.3-0.5-0.7-0.4-1.1s0.5-0.7,1-0.7h4L15,9.2 c0.1-0.4,0.5-0.7,1-0.7s0.8,0.3,1,0.7l1.2,3.8h4c0.4,0,0.8,0.3,1,0.7S23.1,14.6,22.7,14.8z">
              </path>
            </g>
          </svg>
          <span class="flex-grow">Start</span>
        </div>
        <div matTooltip="Add Stage" class="mx-2 h-fit my-3 cursor-pointer hover:border
             p-2 rounded-lg h-fit hover:shadow-lg" (click)="add_update_stage_task(null)">
          <svg class="w-10 m-auto" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60" xml:space="preserve" fill="#000000">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path style="fill:#546A79;"
                  d="M44,34.5c-0.552,0-1-0.447-1-1c0-7.72-6.28-14-14-14s-14,6.28-14,14c0,0.553-0.448,1-1,1 s-1-0.447-1-1c0-8.822,7.178-16,16-16s16,7.178,16,16C45,34.053,44.552,34.5,44,34.5z">
                </path>
                <path style="fill:#546A79;"
                  d="M14,34.5H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h13c0.552,0,1,0.447,1,1S14.552,34.5,14,34.5z"></path>
                <path style="fill:#546A79;"
                  d="M59,34.5H44c-0.552,0-1-0.447-1-1s0.448-1,1-1h15c0.553,0,1,0.447,1,1S59.553,34.5,59,34.5z"></path>
                <path style="fill:#546A79;"
                  d="M59,34.5c-0.256,0-0.512-0.098-0.707-0.293l-8-8c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l8,8c0.391,0.391,0.391,1.023,0,1.414C59.512,34.402,59.256,34.5,59,34.5z">
                </path>
                <path style="fill:#546A79;"
                  d="M51,42.5c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l8-8 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-8,8C51.512,42.402,51.256,42.5,51,42.5z">
                </path>
                <g>
                  <circle style="fill:#F3D55B;" cx="29" cy="33.5" r="8"></circle>
                  <path style="fill:#F3D55B;"
                    d="M29,42.5c-4.962,0-9-4.037-9-9s4.038-9,9-9s9,4.037,9,9S33.962,42.5,29,42.5z M29,26.5 c-3.86,0-7,3.141-7,7s3.14,7,7,7s7-3.141,7-7S32.86,26.5,29,26.5z">
                  </path>
                </g>
                <!-- Add the plus shape inside the circle -->
                <rect x="28" y="30" width="2" height="7" fill="#546A79" />
                <rect x="25.5" y="32.5" width="7" height="2" fill="#546A79" />
              </g>
            </g>
          </svg>
        </div>
        <div *ngFor="let stage of board_stages; let i = index" class="mb-4 flex flex-col">
          <div class="flex flex-row">
            <div class="flex flex-col gap-2">
              <div (click)="toggleSubMenu(i)" (dblclick)="add_update_stage_task(stage)"
                class="cursor-pointer flex gap-2 items-center divide-x divide-black  bg-primary-600 text-white p-2 m-auto rounded-md  min-w-40">
                <mat-icon class=" text-white mx-2 text-2xl">{{ icons[i] }}</mat-icon>
                <div class="flex flex-col p-2">
                  <div class=" m-auto w-fit text-center">
                    <span class="flex-grow font-bold text-nowrap">{{ stage.title }}</span>
                  </div>
                  <div class="w-full flex flex-row">
                    <div class="flex flex-row m-auto">
                      <mat-icon class="m-1 text-white" [matTooltip]="'Property'"
                        svgIcon="heroicons_outline:building-library" (click)="modifyStageProperty(stage)"></mat-icon>
                      <mat-icon class="m-1 act-icon text-white" [matTooltip]="'Access'"
                        svgIcon="heroicons_outline:users" (click)="modifyStagePermissions(stage)"></mat-icon>
                      <mat-icon class="m-1 action-icons text-white" color="warn" [matTooltip]="'Delete'"
                        svgIcon="heroicons_outline:trash" (click)="deleteItem(stage)"></mat-icon>
                    </div>
                  </div>
                </div>
              </div>


              <div matTooltip="Add Trigger"
                class="mx-2 cursor-pointer hover:border  p-2 rounded-lg hover:shadow-lg rotate-90  w-fit self-center">
                <svg class="w-10   m-auto" (click)="modifyStageActions(stage, null)" version="1.1" id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60"
                  xml:space="preserve" fill="#000000">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path style="fill:#546A79;"
                        d="M44,34.5c-0.552,0-1-0.447-1-1c0-7.72-6.28-14-14-14s-14,6.28-14,14c0,0.553-0.448,1-1,1 s-1-0.447-1-1c0-8.822,7.178-16,16-16s16,7.178,16,16C45,34.053,44.552,34.5,44,34.5z">
                      </path>
                      <path style="fill:#546A79;"
                        d="M14,34.5H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h13c0.552,0,1,0.447,1,1S14.552,34.5,14,34.5z">
                      </path>
                      <path style="fill:#546A79;"
                        d="M59,34.5H44c-0.552,0-1-0.447-1-1s0.448-1,1-1h15c0.553,0,1,0.447,1,1S59.553,34.5,59,34.5z">
                      </path>
                      <path style="fill:#546A79;"
                        d="M59,34.5c-0.256,0-0.512-0.098-0.707-0.293l-8-8c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l8,8c0.391,0.391,0.391,1.023,0,1.414C59.512,34.402,59.256,34.5,59,34.5z">
                      </path>
                      <path style="fill:#546A79;"
                        d="M51,42.5c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l8-8 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-8,8C51.512,42.402,51.256,42.5,51,42.5z">
                      </path>
                      <g>
                        <circle style="fill:#F3D55B;" cx="29" cy="33.5" r="8"></circle>
                        <path style="fill:#F3D55B;"
                          d="M29,42.5c-4.962,0-9-4.037-9-9s4.038-9,9-9s9,4.037,9,9S33.962,42.5,29,42.5z M29,26.5 c-3.86,0-7,3.141-7,7s3.14,7,7,7s7-3.141,7-7S32.86,26.5,29,26.5z">
                        </path>
                      </g>
                      <!-- Add the plus shape inside the circle -->
                      <rect x="28" y="30" width="2" height="7" fill="#546A79" />
                      <rect x="25.5" y="32.5" width="7" height="2" fill="#546A79" />
                    </g>
                  </g>
                </svg>
              </div>

              <div class="flex flex-col gap-1">
                <div
                  *ngIf="iconsAndFlagsHorizontal[currentIndexHorizontal].icon !== 'remove_red_eye' && stage?.onEntryList?.length">
                  <div class="flex w-48">
                    <div (click)="toggle_onEntry(i)"
                      class="flex items-center bg-primary-500  ease-in-out  duration-150   text-white p-0.5 rounded-lg mb-2 w-48 m-auto">
                      <div class="flex m-auto gap-2 font-semibold text-md leading-7 cursor-pointer">
                        <img class="w-8 h-8 p-1  ease-in-out m-auto " src="assets/images/icons/log-in.png"
                          matTooltip="On Entry">
                        <span> On Entry </span>
                      </div>
                    </div>
                    <span style="margin-top: -0.7rem; margin-left: -2rem; left: 1rem;"
                      class="flex items-center justify-center shrink-0 min-w-6 h-6 px-1   rounded-full bg-red-600 text-indigo-50 text-xs font-medium  relative   scale-90 ">
                      {{stage?.onEntryList?.length}}</span>
                  </div>

                  <div
                    *ngIf="selectedEntryIndex === i || iconsAndFlagsHorizontal[currentIndexHorizontal].icon == 'cancel'">
                    <div
                      class="flex flex-col items-center bg-primary-400 text-white p-2 rounded-b-lg mb-2 w-40 mx-6 -mt-2 divide-y divide-gray-400">
                      <ng-container *ngFor="let action of stage.onEntryList; let j = index">
                        <div class="text-center flex w-full" (click)="modifyStageActions(stage, action)">
                          <mat-icon class="m-1 text-primary-800" style="padding: 1px;"
                            [svgIcon]="action?.icon ? action?.icon : ''"
                            [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                          <div class="text-sm font-semibold truncate-text self-center cursor-pointer"
                            [matTooltip]="action?.api_registry_name">
                            {{action?.api_registry_name}}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="iconsAndFlagsHorizontal[currentIndexHorizontal].icon !== 'remove_red_eye' && stage?.onApproveList?.length">
                  <div class="flex w-48">
                    <div (click)="toggle_onApprove(i)"
                      class="flex items-center bg-primary-500  ease-in-out  duration-300   text-white p-0.5 rounded-lg mb-2 w-48 m-auto">
                      <div class="flex m-auto gap-2 font-semibold text-md leading-7 cursor-pointer">
                        <img class="w-8 h-8 p-1  ease-in-out m-auto " src="assets/images/icons/ready.png"
                          matTooltip="When Approved">
                        <span> When Approved </span>
                      </div>
                    </div>
                    <span style="margin-top: -0.7rem; margin-left: -2rem; left: 1rem;"
                      class="flex items-center justify-center shrink-0 min-w-6 h-6 px-1   rounded-full bg-red-600 text-indigo-50 text-xs font-medium  relative   scale-90 ">
                      {{stage?.onApproveList?.length}}</span>
                  </div>

                  <div
                    *ngIf="selectedApproveIndex === i || iconsAndFlagsHorizontal[currentIndexHorizontal].icon == 'cancel'">
                    <div
                      class="flex flex-col items-center bg-primary-400 text-white p-2 rounded-b-lg mb-2 w-40 mx-6 -mt-2 divide-y divide-gray-400">
                      <ng-container *ngFor="let action of stage.onApproveList; let j = index">
                        <div class="text-center flex w-full" (click)="modifyStageActions(stage, action)">
                          <mat-icon class="m-1 text-primary-800" style="padding: 1px;"
                            [svgIcon]="action?.icon ? action?.icon : ''"
                            [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                          <div class="text-sm font-semibold truncate-text self-center cursor-pointer"
                            [matTooltip]="action?.api_registry_name">
                            {{action?.api_registry_name}}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="iconsAndFlagsHorizontal[currentIndexHorizontal].icon !== 'remove_red_eye' && stage?.onPushbackList?.length">
                  <div class="flex w-48">
                    <div (click)="toggle_onPushback(i)"
                      class="flex items-center bg-primary-500  ease-in-out  duration-300 w-48  text-white p-0.5 rounded-lg mb-2 m-auto">
                      <div class="flex m-auto gap-2 font-semibold text-md leading-7 cursor-pointer">
                        <img class="w-8 h-8 p-1  ease-in-out m-auto" src="assets/images/icons/push.png"
                          matTooltip="When Pushback">
                        <span> When Pushback </span>
                      </div>
                    </div>
                    <span style="margin-top: -0.7rem; margin-left: -2rem; left: 1rem;"
                      class="flex items-center justify-center shrink-0 min-w-6 h-6 px-1   rounded-full bg-red-600 text-indigo-50 text-xs font-medium  relative   scale-90 ">
                      {{stage?.onPushbackList?.length}}</span>
                  </div>

                  <div
                    *ngIf="selectedPushIndex === i || iconsAndFlagsHorizontal[currentIndexHorizontal].icon == 'cancel'">
                    <div
                      class="flex flex-col items-center bg-primary-400 text-white p-2 rounded-b-lg mb-2 w-40 mx-6 -mt-2 divide-y divide-gray-400">
                      <ng-container *ngFor="let action of stage.onPushbackList; let j = index">
                        <div class="text-center flex w-full" (click)="modifyStageActions(stage, action)">
                          <mat-icon class="m-1 text-primary-800" style="padding: 1px;"
                            [svgIcon]="action?.icon ? action?.icon : ''"
                            [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                          <div class="text-sm font-semibold truncate-text self-center cursor-pointer"
                            [matTooltip]="action?.api_registry_name">
                            {{action?.api_registry_name}}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="iconsAndFlagsHorizontal[currentIndexHorizontal].icon !== 'remove_red_eye' && stage?.onRejectList?.length">
                  <div class="flex w-48">
                    <div (click)="toggle_onReject(i)"
                      class="flex items-center bg-primary-500  ease-in-out  duration-300   text-white p-0.5 rounded-lg mb-2 w-48 m-auto">
                      <div class="flex m-auto gap-2 font-semibold text-md leading-7 cursor-pointer">
                        <img class="w-8 h-8 p-1  ease-in-out m-auto" src="assets/images/icons/reject.png"
                          matTooltip="When Reject">
                        <span> When Reject </span>
                      </div>
                    </div>
                    <span style="margin-top: -0.7rem; margin-left: -2rem; left: 1rem;"
                      class="flex items-center justify-center shrink-0 min-w-6 h-6 px-1   rounded-full bg-red-600 text-indigo-50 text-xs font-medium  relative   scale-90 ">
                      {{stage?.onRejectList?.length}}</span>
                  </div>

                  <div
                    *ngIf="selectedRejectIndex === i || iconsAndFlagsHorizontal[currentIndexHorizontal].icon == 'cancel'">
                    <div
                      class="flex flex-col items-center bg-primary-400 text-white p-2 rounded-b-lg mb-2 w-40 mx-6 -mt-2 divide-y divide-gray-400">
                      <ng-container *ngFor="let action of stage.onRejectList; let j = index">
                        <div class="text-center flex w-full" (click)="modifyStageActions(stage, action)">
                          <mat-icon class="m-1 text-primary-800" style="padding: 1px;"
                            [svgIcon]="action?.icon ? action?.icon : ''"
                            [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                          <div class="text-sm font-semibold truncate-text self-center cursor-pointer"
                            [matTooltip]="action?.api_registry_name">
                            {{action?.api_registry_name}}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="iconsAndFlagsHorizontal[currentIndexHorizontal].icon !== 'remove_red_eye' && stage?.onExitList?.length">
                  <div class="flex w-48">
                    <div (click)="toggle_onExit(i)"
                      class="flex items-center bg-primary-500  ease-in-out  duration-300   text-white p-0.5 rounded-lg mb-2 w-48 m-auto">
                      <div class="flex m-auto gap-2 font-semibold text-md leading-7 cursor-pointer">
                        <img class="w-8 h-8 p-1  ease-in-out m-auto" src="assets/images/icons/log-out.png"
                          matTooltip="On Exit">
                        <span> On Exit </span>
                      </div>
                    </div>
                    <span style="margin-top: -0.7rem; margin-left: -2rem; left: 1rem;"
                      class="flex items-center justify-center shrink-0 min-w-6 h-6 px-1   rounded-full bg-red-600 text-indigo-50 text-xs font-medium  relative   scale-90 ">
                      {{stage?.onExitList?.length}}</span>
                  </div>

                  <div
                    *ngIf="selectedExitIndex === i || iconsAndFlagsHorizontal[currentIndexHorizontal].icon == 'cancel'">
                    <div
                      class="flex flex-col items-center bg-primary-400 text-white p-2 rounded-b-lg mb-2 w-40 mx-6 -mt-2 divide-y divide-gray-400">
                      <ng-container *ngFor="let action of stage.onExitList; let j = index">

                        <div class="text-center flex w-full" (click)="modifyStageActions(stage, action)">
                          <mat-icon class="m-1 text-primary-800" style="padding: 1px;"
                            [svgIcon]="action?.icon ? action?.icon : ''"
                            [matTooltip]="action?.icon ? action?.tooltip : ''"></mat-icon>
                          <div class="text-sm font-semibold truncate-text self-center cursor-pointer"
                            [matTooltip]="action?.api_registry_name">
                            {{action?.api_registry_name}}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div matTooltip="Add Stage"
              class="mx-2 h-fit my-3 cursor-pointer hover:border p-2 rounded-lg h-fit hover:shadow-lg">
              <svg class="w-10   m-auto" (click)="add_update_stage_task(null)" version="1.1" id="Capa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60"
                xml:space="preserve" fill="#000000">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path style="fill:#546A79;"
                      d="M44,34.5c-0.552,0-1-0.447-1-1c0-7.72-6.28-14-14-14s-14,6.28-14,14c0,0.553-0.448,1-1,1 s-1-0.447-1-1c0-8.822,7.178-16,16-16s16,7.178,16,16C45,34.053,44.552,34.5,44,34.5z">
                    </path>
                    <path style="fill:#546A79;"
                      d="M14,34.5H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h13c0.552,0,1,0.447,1,1S14.552,34.5,14,34.5z">
                    </path>
                    <path style="fill:#546A79;"
                      d="M59,34.5H44c-0.552,0-1-0.447-1-1s0.448-1,1-1h15c0.553,0,1,0.447,1,1S59.553,34.5,59,34.5z">
                    </path>
                    <path style="fill:#546A79;"
                      d="M59,34.5c-0.256,0-0.512-0.098-0.707-0.293l-8-8c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l8,8c0.391,0.391,0.391,1.023,0,1.414C59.512,34.402,59.256,34.5,59,34.5z">
                    </path>
                    <path style="fill:#546A79;"
                      d="M51,42.5c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l8-8 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-8,8C51.512,42.402,51.256,42.5,51,42.5z">
                    </path>
                    <g>
                      <circle style="fill:#F3D55B;" cx="29" cy="33.5" r="8"></circle>
                      <path style="fill:#F3D55B;"
                        d="M29,42.5c-4.962,0-9-4.037-9-9s4.038-9,9-9s9,4.037,9,9S33.962,42.5,29,42.5z M29,26.5 c-3.86,0-7,3.141-7,7s3.14,7,7,7s7-3.141,7-7S32.86,26.5,29,26.5z">
                      </path>
                    </g>
                    <!-- Add the plus shape inside the circle -->
                    <rect x="28" y="30" width="2" height="7" fill="#546A79" />
                    <rect x="25.5" y="32.5" width="7" height="2" fill="#546A79" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="flex items-center bg-primary-500 text-white p-4 rounded-md w-30 h-fit mt-4">
          <mat-icon class="w-6 m-auto mr-2 text-white">flag</mat-icon>
          <span class="flex-grow">End</span>
        </div>
      </div>

      }
    </mat-drawer-content>
  </mat-drawer-container>
</div>