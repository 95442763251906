import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { VALUE_SET_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { generateUniqueId } from 'app/shared/utilities/utilities';
import {
    BehaviorSubject,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError
} from 'rxjs';
import { Contact } from '../contacts/contacts.types';
import { Board, Card, Label, List } from './scrumboard.models';

@Injectable({ providedIn: 'root' })
export class ScrumboardService {
    // Private
    private _board: BehaviorSubject<Board | null>;
    private _boards: BehaviorSubject<Board[] | null>;
    private _board_states: BehaviorSubject<Contact[] | null>;
    private _board_tasks: BehaviorSubject<Contact[] | null>;
    private _card: BehaviorSubject<Card | null>;
    private _members: BehaviorSubject<Contact[] | null>;
    private searchViewSubject = new BehaviorSubject<'Tile' | 'Grid'>('Tile');
    private userInfo = this._authService.userInfo;
    private contactInfo = this._authService.contactInfo;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _commonService: CommonService,
        private _navigationService: NavigationService,
        private _authService: AuthService
    ) {
        // Set the private defaults
        this._board = new BehaviorSubject(null);
        this._boards = new BehaviorSubject(null);
        this._card = new BehaviorSubject(null);
        this._members = new BehaviorSubject(null);
        this._board_states = new BehaviorSubject(null);
        this._board_tasks = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for board
     */
    get board$(): Observable<Board> {
        return this._board.asObservable();
    }

    /**
     * Getter for boards
     */
    get boards$(): Observable<Board[]> {
        return this._boards.asObservable();
    }

    /**
     * Getter for card
     */
    get card$(): Observable<Card> {
        return this._card.asObservable();
    }

    /**
     * Getter for contacts
     */
    get members$(): Observable<Contact[]> {
        return this._members.asObservable();
    }

     /**
     * Getter for contacts
     */
    get board_stages$(): Observable<Contact[]> {
        return this._board_states.asObservable();
    }

    /**
     * Getter for contacts
     */
    get board_tasks$(): Observable<Contact[]> {
        return this._board_tasks.asObservable();
    }
    /**
     * Getter for menu permissions
     */
    get permissions$(): Observable<any> {
        return this._navigationService.currentMenu$;
    }
    get searchView$(): Observable<'Tile' | 'Grid'> {
        return this.searchViewSubject.asObservable();
      }
    
    

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get boards
     */
    getBoards(): Observable<Board[]> {
        return this._commonService
            .getDataByFields(MODELS_CONSTANTS.SCRUMBOARDS, {
                'members.member_id': this.contactInfo?._id,
            })
            .pipe(
                switchMap(async (response) => {
                    const res: any = await this._commonService
                        .getDataByField(
                            MODELS_CONSTANTS.SCRUMBOARDS,
                            'user_id',
                            this.userInfo?._id
                        )
                        .toPromise();
                    const boards = res?.data || [];
                    const boardByMembers = response?.data?.map(
                        (board) => new Board(board)
                    );
                    const allBoards = [...boards, ...boardByMembers];
                    const uniqueBoards = allBoards?.filter(
                        (item, index, self) =>
                            index === self?.findIndex((t) => t?._id === item?._id)
                    );
                    return uniqueBoards;
                }),
                tap((boards) => {
                    this._boards.next(boards);
                })
            );
    }

    setsearchView(mode: 'Tile' | 'Grid'): void {
        this.searchViewSubject.next(mode);
        localStorage.setItem('searchView', mode);
      }
      
    getMembers(): Observable<Contact[]> {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.CONTACTS,
                'tenant_id',
                this.userInfo.tenant_id
            )
            .pipe(
                map((response: IResponse<Contact[]>) => {
                    const members = response?.data?.filter(
                        (e) => e?.enableLogin && e?.user_id
                    );
                    this._members.next(members || []);
                    return members || [];
                })
            );
    }

    getBoardIcons(): Observable<any> {
        return this._commonService.getDataByField(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            'vs_code',
            VALUE_SET_CODE_CONSTANTS.WIDGET_ICON
        );
    }

    /**
     * Get board
     *
     * @param id
     */
    getBoard(id: string): Observable<Board> {
        return this._boards.pipe(
            take(1),
            switchMap((boards) => {
                // Find the board
                return this._commonService
                    .getDataById(MODELS_CONSTANTS.SCRUMBOARDS, id)
                    .pipe(
                        map((response: IResponse<any>) => {
                            let board = response?.data;
                            board?.lists?.sort(
                                (a, b) => a.position - b.position
                            );
                            board?.lists?.forEach((list) => {
                                list?.cards?.sort(
                                    (a, b) => a.position - b.position
                                );
                            });
                            this._board.next(board);
                            return board;
                        })
                    );
            }),
            switchMap((board) => {
                if (!board) {
                    return throwError(
                        'Could not found the board with id of ' + id + '!'
                    );
                }

                return of(board);
            })
        );
    }

    /**
     * Get board stages
     *
     * @param id
     */
    get_board_stages(id: string): Observable<Contact[]> {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.BOARD_STAGES,
                'board_id',
                id
            )
            .pipe(
                map((response) =>{
                    let stages = response?.data;
                    stages.sort((a, b) => a.position - b.position);
                    this._board_states.next(stages || []);
                    return stages || [];
                })
            );
    }

    /**
     * Get board stages
     *
     * @param id
     */
    get_board_tasks(id: string): Observable<Contact[]> {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.BOARD_TASK,
                'board_id',
                id
            )
            .pipe(
                map((response) =>{
                    let tasks = response?.data;
                    tasks.sort((a, b) => a.position - b.position);
                    this._board_tasks.next(tasks || []);
                    return tasks || [];
                })
            );
    }
    /**
     * Save a board
     */

    saveBoard(board: Board): Observable<any> {
        board.user_id = this.userInfo._id;
        board.lastActivity = new Date().toISOString();
        return this._commonService.saveRecord(
            MODELS_CONSTANTS.SCRUMBOARDS,
            board
        );
    }

    update_tasks(tasks : any): Observable<any> {
        return this._commonService.update_many_records(
            MODELS_CONSTANTS.BOARD_TASK,
            tasks
        );
    }

    update_stages(boards : any): Observable<any> {
        return this._commonService.update_many_records(
            MODELS_CONSTANTS.BOARD_STAGES,
            boards
        );
    }

    /**
     * Create board
     *
     * @param board
     */
    createBoard(board: Board): Observable<Board> {
        return this.boards$.pipe(
            take(1),
            switchMap((boards) =>
                this._httpClient
                    .put<Board>('api/apps/scrumboard/board', { board })
                    .pipe(
                        map((newBoard) => {
                            // Update the boards with the new board
                            this._boards.next([...boards, newBoard]);

                            // Return new board from observable
                            return newBoard;
                        })
                    )
            )
        );
    }

    /**
     * Update the board
     *
     * @param id
     * @param board
     */
    updateBoard(id: string, board: Board): Observable<Board> {
        return this.boards$.pipe(
            take(1),
            switchMap((boards) =>
                this._httpClient
                    .patch<Board>('api/apps/scrumboard/board', {
                        id,
                        board,
                    })
                    .pipe(
                        map((updatedBoard) => {
                            // Find the index of the updated board
                            const index = boards.findIndex(
                                (item) => item._id === id
                            );

                            // Update the board
                            boards[index] = updatedBoard;

                            // Update the boards
                            this._boards.next(boards);

                            // Return the updated board
                            return updatedBoard;
                        })
                    )
            )
        );
    }

    /**
     * Delete the board
     *
     * @param id
     */
    deleteBoard(id: string): Observable<any> {
        return this.boards$.pipe(
            take(1),
            switchMap((boards) =>
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.SCRUMBOARDS, id)
                    .pipe(
                        tap((response: IResponse<any>) => {
                            if (response?.status === 200) {
                                // Find the index of the deleted board
                                const index = boards.findIndex(
                                    (item) => item._id === id
                                );

                                // Delete the board
                                boards.splice(index, 1);

                                // Update the boards
                                this._boards.next(boards);

                                // Update the board
                                this._board.next(null);

                                // Update the card
                                this._card.next(null);
                            }
                        })
                    )
            )
        );
    }

    // /**
    //  * Create list
    //  *
    //  * @param list
    //  */
    // createList(board: Board): Observable<any>
    // {
    //     return this._commonService
    //         .saveRecord(MODELS_CONSTANTS.SCRUMBOARDS, board)
    //         .pipe(
    //             tap((response: IResponse<any>) => {
    //                 if (response?.status === 200) {
    //                     this.getBoard(board?._id).subscribe();
    //                 }
    //             })
    //         );
    // }

    /**
     * Update the list
     *
     * @param list
     */
    updateList(list: List): Observable<List> {
        return this._httpClient
            .patch<List>('api/apps/scrumboard/board/list', { list })
            .pipe(
                map((response) => new List(response)),
                tap((updatedList) => {
                    // Get the board value
                    const board = this._board.value;

                    // Find the index of the updated list
                    const index = board.lists.findIndex(
                        (item) => item.listId === list.listId
                    );

                    // Update the list
                    board.lists[index] = updatedList;

                    // Sort the board lists
                    board.lists.sort((a, b) => a.position - b.position);

                    // Update the board
                    this._board.next(board);
                })
            );
    }

    /**
     * Update the lists
     *
     * @param lists
     */
    updateLists(lists: List[]): Observable<List[]> {
        return this._httpClient
            .patch<List[]>('api/apps/scrumboard/board/lists', { lists })
            .pipe(
                map((response) => response.map((item) => new List(item))),
                tap((updatedLists) => {
                    // Get the board value
                    const board = this._board.value;

                    // Go through the updated lists
                    updatedLists.forEach((updatedList) => {
                        // Find the index of the updated list
                        const index = board.lists.findIndex(
                            (item) => item.listId === updatedList.listId
                        );

                        // Update the list
                        board.lists[index] = updatedList;
                    });

                    // Sort the board lists
                    board.lists.sort((a, b) => a.position - b.position);

                    // Update the board
                    this._board.next(board);
                })
            );
    }

    /**
     * Delete the list
     *
     * @param id
     */
    deleteList(id: string): Observable<boolean> {
        return this._httpClient
            .delete<boolean>('api/apps/scrumboard/board/list', {
                params: { id },
            })
            .pipe(
                tap((isDeleted) => {
                    // Get the board value
                    const board = this._board.value;

                    // Find the index of the deleted list
                    const index = board.lists.findIndex(
                        (item) => item.listId === id
                    );

                    // Delete the list
                    board.lists.splice(index, 1);

                    // Sort the board lists
                    board.lists.sort((a, b) => a.position - b.position);

                    // Update the board
                    this._board.next(board);
                })
            );
    }

    /**
     * Get card
     */
    getCard(id: string): Observable<Card> {
        return this._board.pipe(
            take(1),
            map((board) => {
                // Find the card
                const card = board.lists
                    .find((list) =>
                        list?.cards?.some((item) => item?.cardId === id)
                    )
                    ?.cards?.find((item) => item?.cardId === id);

                // Update the card
                this._card.next(card);

                // Return the card
                return card;
            }),
            switchMap((card) => {
                if (!card) {
                    return throwError(
                        'Could not found the card with id of ' + id + '!'
                    );
                }

                return of(card);
            })
        );
    }

    // /**
    //  * Create card
    //  *
    //  * @param card
    //  */
    // createCard(card: Card): Observable<Card>
    // {
    //     return this._httpClient.put<Card>('api/apps/scrumboard/board/card', {card}).pipe(
    //         map(response => new Card(response)),
    //         tap((newCard) =>
    //         {
    //             // Get the board value
    //             const board = this._board.value;

    //             // Find the list and push the new card in it
    //             board.lists.forEach((listItem, index, list) =>
    //             {
    //                 if ( listItem.listId === newCard.listId )
    //                 {
    //                     list[index].cards.push(newCard);
    //                 }
    //             });

    //             // Update the board
    //             this._board.next(board);

    //             // Return the new card
    //             return newCard;
    //         }),
    //     );
    // }

    // /**
    //  * Update the card
    //  *
    //  * @param id
    //  * @param card
    //  */
    // updateCard(id: string, card: Card): Observable<Card>
    // {
    //     return this.board$.pipe(
    //         take(1),
    //         switchMap(board => this._httpClient.patch<Card>('api/apps/scrumboard/board/card', {
    //             id,
    //             card,
    //         }).pipe(
    //             map((updatedCard) =>
    //             {
    //                 // Find the card and update it
    //                 board.lists.forEach((listItem) =>
    //                 {
    //                     listItem.cards.forEach((cardItem, index, array) =>
    //                     {
    //                         if ( cardItem.cardId === id )
    //                         {
    //                             array[index] = updatedCard;
    //                         }
    //                     });
    //                 });

    //                 // Update the board
    //                 this._board.next(board);

    //                 // Update the card
    //                 this._card.next(updatedCard);

    //                 // Return the updated card
    //                 return updatedCard;
    //             }),
    //         )),
    //     );
    // }

    /**
     * Update the cards
     *
     * @param cards
     */
    updateCards(cards: Card[]): Observable<Card[]> {
        return this._httpClient
            .patch<Card[]>('api/apps/scrumboard/board/cards', { cards })
            .pipe(
                map((response) => response.map((item) => new Card(item))),
                tap((updatedCards) => {
                    // Get the board value
                    const board = this._board.value;

                    // Go through the updated cards
                    updatedCards.forEach((updatedCard) => {
                        // Find the index of the updated card's list
                        const listIndex = board.lists.findIndex(
                            (list) => list.listId === updatedCard.listId
                        );

                        // Find the index of the updated card
                        const cardIndex = board.lists[
                            listIndex
                        ].cards.findIndex(
                            (item) => item.cardId === updatedCard.cardId
                        );

                        // Update the card
                        board.lists[listIndex].cards[cardIndex] = updatedCard;

                        // Sort the cards
                        board.lists[listIndex].cards.sort(
                            (a, b) => a.position - b.position
                        );
                    });

                    // Update the board
                    this._board.next(board);
                })
            );
    }

    /**
     * Delete the card
     *
     * @param id
     */
    deleteCard(id: string): Observable<boolean> {
        return this.board$.pipe(
            take(1),
            switchMap((board) =>
                this._httpClient
                    .delete('api/apps/scrumboard/board/card', {
                        params: { id },
                    })
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the card and delete it
                            board.lists.forEach((listItem) => {
                                listItem.cards.forEach(
                                    (cardItem, index, array) => {
                                        if (cardItem.cardId === id) {
                                            array.splice(index, 1);
                                        }
                                    }
                                );
                            });

                            // Update the board
                            this._board.next(board);

                            // Update the card
                            this._card.next(null);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    /**
     * Create label
     *
     * @param label
     */
    createLabel(label: Label): Observable<Label> {
        return this.board$.pipe(
            take(1),
            switchMap((board) =>
                this._httpClient
                    .post<Label>('api/apps/scrumboard/board/label', { label })
                    .pipe(
                        map((newLabel) => {
                            // Update the board labels with the new label
                            board.labels = [...board.labels, newLabel];

                            // Update the board
                            this._board.next(board);

                            // Return new label from observable
                            return newLabel;
                        })
                    )
            )
        );
    }

    /**
     * Update the label
     *
     * @param id
     * @param label
     */
    updateLabel(id: string, label: Label): Observable<Label> {
        return this.board$.pipe(
            take(1),
            switchMap((board) =>
                this._httpClient
                    .patch<Label>('api/apps/scrumboard/board/label', {
                        id,
                        label,
                    })
                    .pipe(
                        map((updatedLabel) => {
                            // Find the index of the updated label
                            const index = board.labels.findIndex(
                                (item) => item.labelId === id
                            );

                            // Update the label
                            board.labels[index] = updatedLabel;

                            // Update the board
                            this._board.next(board);

                            // Return the updated label
                            return updatedLabel;
                        })
                    )
            )
        );
    }

    /**
     * Delete the label
     *
     * @param id
     */
    deleteLabel(id: string): Observable<boolean> {
        return this.board$.pipe(
            take(1),
            switchMap((board) =>
                this._httpClient
                    .delete('api/apps/scrumboard/board/label', {
                        params: { id },
                    })
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted label
                            const index = board.labels.findIndex(
                                (item) => item.labelId === id
                            );

                            // Delete the label
                            board.labels.splice(index, 1);

                            // If the label is deleted...
                            if (isDeleted) {
                                // Remove the label from any card that uses it
                                board.lists.forEach((list) => {
                                    list.cards.forEach((card) => {
                                        const labelIndex =
                                            card.labels.findIndex(
                                                (label) => label.labelId === id
                                            );
                                        if (labelIndex > -1) {
                                            card.labels.splice(labelIndex, 1);
                                        }
                                    });
                                });
                            }

                            // Update the board
                            this._board.next(board);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    /**
     * Search within board cards
     *
     * @param query
     */
    search(query: string): Observable<Card[] | null> {
        // @TODO: Update the board cards based on the search results
        return this._httpClient.get<Card[] | null>(
            'api/apps/scrumboard/board/search',
            { params: { query } }
        );
    }

    /**
     * Generate Unique Id
     */
    generateUniqueId() {
        return generateUniqueId();
    }
}
