import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { Subject } from 'rxjs';


@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, MatTooltipModule],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: any;
    statuses: any[] = [
        { icon: 'fa-circle', color: '#28a745', tooltip: 'Online' },   // Online
        { icon: 'fa-circle', color: '#ffc107', tooltip: 'Away' },    // Away
        { icon: 'fa-circle', color: '#dc3545', tooltip: 'Busy' },  // Busy
        { icon: 'fa-circle', color: '#6c757d', tooltip: 'Invisible' }   // Invisible
      ];
      currentStatusIndex: number = 0;
      currentStatus: any = JSON.parse(localStorage.getItem('currentStatus')) || 
      { icon: 'fa-circle', color: '#28a745' };

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _commonService: CommonService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
  ngOnInit(): void {
    this.user = this._authService.userInfo;
    // Subscribe to user changes
    /* this._userService.user$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((user: User) =>
        {
            this.user = user;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }); */
  }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    // updateUserStatus(status: string): void {
    //     // Return if user is not available
    //     if (!this.user) {
    //         return;
    //     }

    //     /* this.user.settings.status = status;
    //     // Update the user
    //     this._commonService.saveRecord(MODELS_CONSTANTS.USER_SETTINGS, this.user.settings).subscribe(); */
    // }

    updateUserStatus(status: string) {
        switch (status) {
          case 'online':
            this.currentStatus = { icon: 'fa-circle', color: '#28a745' };
            break;
          case 'away':
            this.currentStatus = { icon: 'fa-circle', color: '#ffc107' };
            break;
          case 'busy':
            this.currentStatus = { icon: 'fa-circle', color: '#dc3545' };
            break;
          case 'not-visible':
            this.currentStatus = { icon: 'fa-circle', color: '#6c757d' };
            break;
          default:
            break;
        }
        localStorage.setItem('currentStatus', JSON.stringify(this.currentStatus));
      }

    /**
     * Sign out
     */
    signout(): void {
        this._router.navigate(['/sign-out']);
    }

    settings(): void {
        this._router.navigate(['/settings'])
        console.log("s")
    }
}
