import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DOCUMENT, DatePipe, NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { IResponse } from 'app/shared/interfaces/response-i';
import { SharedModule } from 'app/shared/shared.module';
import { cloneDeep } from 'lodash';
import { Subject, filter, fromEvent, takeUntil } from 'rxjs';
import { TasksDetailsComponent } from '../details/details.component';
import { TasksService } from '../tasks.service';
import { Tag, Task } from '../tasks.types';
import { FuseFindByKeyPipe } from '@fuse/pipes/find-by-key/find-by-key.pipe';

@Component({
    selector       : 'tasks-list',
    templateUrl    : './list.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [RouterOutlet, NgIf, CdkDropList, NgFor, CdkDrag, NgClass, CdkDragPreview, CdkDragHandle, RouterLink, TitleCasePipe, DatePipe, SharedModule, TasksDetailsComponent, FuseFindByKeyPipe],
})
export class TasksListComponent implements OnInit, OnChanges, OnDestroy
{
    @ViewChild('matDrawer', {static: true}) matDrawer: MatDrawer;

    @Input() data: any;

    drawerMode: 'side' | 'over';
    selectedTask: Task;
    tags: Tag[];
    tasks: Task[];
    clonedTasks: Task[];
    sections: any;
    tasksCount: any = {
        completed : 0,
        incomplete: 0,
        total     : 0,
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _tasksService: TasksService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        if(this.data){
            this._tasksService.getTasks(this.data).subscribe()
        }
        // Get the drawer
        this._tasksService.drawer$
           .pipe(takeUntil(this._unsubscribeAll))
           .subscribe((opened: boolean) =>
           {
               if(opened){
                   this.matDrawer?.open();
               } else {
                    this.matDrawer?.close();
               }
               // Mark for check
               this._changeDetectorRef.markForCheck();
           });

        // Get the tags
        this._tasksService.tags$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tags: Tag[]) =>
            {
                this.tags = tags;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Get the tasks
        this._tasksService.tasks$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tasks: Task[]) =>
            {
                tasks?.forEach((e) => {
                    if (!e?.type) {
                        e.type = 'task';
                    }
                });
                
                this.sections = tasks?.filter((val) => val.type === 'section');
                this.sections?.forEach((e:any) => {
                    let list = tasks?.filter((val:any) => val.section_id == e._id);
                    if (list) {
                        e.tasks = []
                        list.forEach(element => {
                            e.tasks.push(element)
                        });
                    }
                });
                this.tasks = tasks || [];
                if (!this?.clonedTasks) {
                    this.clonedTasks = cloneDeep(tasks || []);
                }

                // Update the counts
                this.tasksCount.total = this.tasks.filter(task => task.type === 'task').length;
                this.tasksCount.completed = this.tasks.filter(task => task.type === 'task' && task.completed).length;
                this.tasksCount.incomplete = this.tasksCount.total - this.tasksCount.completed;

                // Mark for check
                this._changeDetectorRef.markForCheck();

                // Update the count on the navigation
                setTimeout(() => {
                    // Get the component -> navigation data -> item
                    const mainNavigationComponent =
                        this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                            'mainNavigation'
                        );

                    // If the main navigation component exists...
                    if (mainNavigationComponent) {
                        const mainNavigation =
                            mainNavigationComponent?.navigation || [];
                        const menuItem = this._fuseNavigationService.getItem(
                            'M0202',
                            mainNavigation
                        );

                        if (menuItem) {
                            // Update the subtitle of the item
                            menuItem.subtitle =
                                this.tasksCount.incomplete + ' remaining tasks';
                        }

                        // Refresh the navigation
                        mainNavigationComponent.refresh();
                    }
                }, 500);
            });

        // Get the task
        this._tasksService.task$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((task: Task) =>
            {
                this.selectedTask = task;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to media query change
        this._fuseMediaWatcherService.onMediaQueryChange$('(min-width: 1440px)')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((state) =>
            {
                // Calculate the drawer mode
                this.drawerMode = state.matches ? 'side' : 'over';

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Listen for shortcuts
        fromEvent(this._document, 'keydown')
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter<KeyboardEvent>(event =>
                    (event.ctrlKey === true || event.metaKey) // Ctrl or Cmd
                    && (event.key === '/' || event.key === '.'), // '/' or '.' key
                ),
            )
            .subscribe((event: KeyboardEvent) =>
            {
                // If the '/' pressed
                if ( event.key === '/' )
                {
                    this.createTask('task');
                }

                // If the '.' pressed
                if ( event.key === '.' )
                {
                    this.createTask('section');
                }
            });
    }

    /**
     * On Changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if('data' in changes){
            this._tasksService.getTasks(this.data).subscribe();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On backdrop clicked
     */
    onBackdropClicked(): void
    {
        // Go back to the list
        this._router.navigate(['./'], {relativeTo: this._activatedRoute});

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Create task
     *
     * @param type
     */
    createTask(type: 'task' | 'section'): void
    {
        const task = {
            type,
            order: this.tasks?.length
             ? Math.max(...this.tasks.map(task => task.order)) + 1 
             : 1,
            // reference_type: this.data ? 'form' : 'menu'
            reference_type: 'menu'
        };
        // if(this.data){
        //     task['reference_type_id'] = this.data?.reference_type_id;

        //     if(this.data && this.data?.reference_type) {
        //         task['reference_type'] = this.data?.reference_type;
        //     }

        //     if(this.data && this.data?.reference_id) {
        //         task['reference_id'] = this.data?.reference_id;
        //     }
        // }
        // Create the task
        localStorage.setItem('task_data', JSON.stringify(task))
        if(this.data){
            this._tasksService.getTaskById('create').subscribe();
            this.matDrawer.open();
        } else {
            this._router.navigate(['./', 'create'], {
                relativeTo: this._activatedRoute,
            });
        }
        this._changeDetectorRef.detectChanges();
        // this._tasksService.saveTask(task).subscribe((response: IResponse<any>) => {
        //     // Go to the new task
        //     if(this.data) {
        //         this.matDrawer.open();
        //     } else {
        //         this._router.navigate(['./', `${response?.data?._id}`], {
        //             relativeTo: this._activatedRoute,
        //         });
        //     }
        //     // Mark for check
        //     this._changeDetectorRef.markForCheck();
        // });
    }

    /**
     * Toggle the completed status
     * of the given task
     *
     * @param task
     */
    toggleCompleted(task: Task): void
    {
        // Toggle the completed status
        task.completed = !task.completed;

        // Update the task on the server
        this._tasksService.updateTask(task._id, task).subscribe();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Task dropped
     *
     * @param event
     */
    async dropped(event: CdkDragDrop<Task[]>)
    {
        // Move the item in the array
        moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );

        // Save the new order
        await this._tasksService.updateTasksOrders(event.container.data, this.clonedTasks);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    modifyTask(taskId){
        if(this.data){
            this._tasksService?.getTaskById(taskId).subscribe((task) => {
                if (task) {
                    this.matDrawer?.open();
                }
            });
        } else {
            this._router.navigate(['./', `${taskId}`], {
                relativeTo: this._activatedRoute,
            });
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}
