import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { VALUE_SET_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { BehaviorSubject, catchError, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Contact, Country, Tenant } from './contacts.types';

@Injectable({providedIn: 'root'})
export class ContactsService
{
    // Private
    private _contact: BehaviorSubject<Contact | null> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
    private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);
    private _tenants: BehaviorSubject<Tenant[] | null> = new BehaviorSubject(null);
    // private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);
    private allContacts = [];

    /**
     * Constructor
     */
    constructor(private _commonService: CommonService, private _authService: AuthService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get contact$(): Observable<Contact>
    {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<Contact[]>
    {
        return this._contacts.asObservable();
    }

    /**
     * Getter for countries
     */
    get countries$(): Observable<Country[]>
    {
        return this._countries.asObservable();
    }

    /**
     * Getter for tenants
     */
    get tenants$(): Observable<Tenant[]>
    {
        return this._tenants.asObservable();
    }

    // /**
    //  * Getter for tags
    //  */
    // get tags$(): Observable<Tag[]>
    // {
    //     return this._tags.asObservable();
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */
    getContacts(tenant_id = null): Observable<Contact[]>
    {
        const user = this._authService.userInfo;
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.CONTACTS,
                'tenant_id',
                tenant_id || user.tenant_id
            )
            .pipe(
                map((response: IResponse<Contact[]>) => {
                    const contacts = response?.data;
                    const index = contacts.findIndex((e) => e?.user_id === user?._id);
                    if(index !== -1){
                        const [contact] = contacts.splice(index, 1);
                        contacts.unshift(contact);
                    }
                    this._contacts.next(response?.data || []);
                    this.allContacts = response?.data || [];
                    return response?.data;
                })
            );
        // return this._httpClient.get<Contact[]>('api/apps/contacts/all').pipe(
        //     tap((contacts) =>
        //     {
        //         this._contacts.next(contacts);
        //     }),
        // );
    }

    /**
     * Search contacts with given query
     *
     * @param query
     */
    searchContacts(query: string): Observable<Contact[]>
    {
        query = query?.toLowerCase()
        const filterKeys = ['name', 'company', 'title', 'address', 'emails', 'phoneNumbers'];
        const filteredContacts = this.allContacts.filter((e) => {
            let isMatched = false;
            for (let key of filterKeys) {
                if (Array.isArray(e?.[key])) {
                    if (key === 'emails') {
                        for (let email of e?.[key]) {
                            if (email?.email?.toLowerCase()?.includes(query)) {
                                isMatched = true;
                                break;
                            }
                        }
                    } else if (key === 'phoneNumbers') {
                        for (let phone of e?.[key]) {
                            if (phone?.phoneNumber?.includes(query)) {
                                isMatched = true;
                                break;
                            }
                        }
                    }
                } else {
                    if (e?.[key]?.toLowerCase()?.includes(query)) {
                        isMatched = true;
                        break;
                    }
                }
            }
            return isMatched;
        });
        this._contacts.next(filteredContacts);
        return of(filteredContacts)
        // return this._httpClient.get<Contact[]>('api/apps/contacts/search', {
        //     params: {query},
        // }).pipe(
        //     tap((contacts) =>
        //     {
        //         this._contacts.next(contacts);
        //     }),
        // );
    }

    /**
     * Get contact by id
     */
    getContactById(id: string): Observable<Contact>
    {
        return this._contacts.pipe(
            take(1),
            map((contacts) =>
            {
                let contact;
                if(id === 'create'){
                    contact = {};
                } else {
                    // Find the contact
                    contact = contacts?.find(item => item._id === id) || null;
                }

                // Update the contact
                this._contact.next(contact);

                // Return the contact
                return contact;
            }),
            switchMap((contact) =>
            {
                if ( !contact )
                {
                    return throwError('Could not found contact with id of ' + id + '!');
                }
                return of(contact);
            }),
        );
    }

    /**
     * Get Tenants
     */
    getTenants(): Observable<Tenant[]> {
        return this._commonService.getAllData(MODELS_CONSTANTS.TENANTS).pipe(
            map((response: IResponse<Tenant[]>) => {
                this._tenants.next(response?.data || []);
                return response?.data || []
            }),
            catchError((err) => {
                return of([]);
            })
        );
    }

    /**
     * Save Contact
     */
    saveContact(contact): Observable<any> {
        return this._commonService.saveRecord(MODELS_CONSTANTS.CONTACTS, contact);
    }

    // /**
    //  * Create contact
    //  */
    // createContact(): Observable<Contact>
    // {
    //     return this.contacts$.pipe(
    //         take(1),
    //         switchMap(contacts => this._httpClient.post<Contact>('api/apps/contacts/contact', {}).pipe(
    //             map((newContact) =>
    //             {
    //                 // Update the contacts with the new contact
    //                 this._contacts.next([newContact, ...contacts]);

    //                 // Return the new contact
    //                 return newContact;
    //             }),
    //         )),
    //     );
    // }

    // /**
    //  * Update contact
    //  *
    //  * @param id
    //  * @param contact
    //  */
    // updateContact(id: string, contact: Contact): Observable<Contact>
    // {
    //     return this.contacts$.pipe(
    //         take(1),
    //         switchMap(contacts => this._httpClient.patch<Contact>('api/apps/contacts/contact', {
    //             id,
    //             contact,
    //         }).pipe(
    //             map((updatedContact) =>
    //             {
    //                 // Find the index of the updated contact
    //                 const index = contacts.findIndex(item => item._id === id);

    //                 // Update the contact
    //                 contacts[index] = updatedContact;

    //                 // Update the contacts
    //                 this._contacts.next(contacts);

    //                 // Return the updated contact
    //                 return updatedContact;
    //             }),
    //             switchMap(updatedContact => this.contact$.pipe(
    //                 take(1),
    //                 filter(item => item && item._id === id),
    //                 tap(() =>
    //                 {
    //                     // Update the contact if it's selected
    //                     this._contact.next(updatedContact);

    //                     // Return the updated contact
    //                     return updatedContact;
    //                 }),
    //             )),
    //         )),
    //     );
    // }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteContact(id: string): Observable<boolean>
    {
        return this.contacts$.pipe(
            take(1),
            switchMap(contacts => this._commonService.deleteRecordsById(MODELS_CONSTANTS.CONTACTS, id).pipe(
                map((response: IResponse<any>) =>
                {
                    // Find the index of the deleted contact
                    const index = contacts.findIndex(item => item._id === id);

                    // Delete the contact
                    contacts.splice(index, 1);

                    // Update the contacts
                    this._contacts.next(contacts);

                    // Return the deleted status
                    return response.status === 200;
                }),
            )),
        );
    }

    /**
     * Get countries
     */
    getCountries(): Observable<Country[]>
    {
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.VALUE_SET_DETAILS,
                'vs_code',
                VALUE_SET_CODE_CONSTANTS.COUNTRIES
            )
            .pipe(
                map((response: IResponse<Country[]>) => {
                    this._countries.next(response?.data || []);
                    return response?.data;
                })
            );
        // return this._httpClient.get<Country[]>('api/apps/contacts/countries').pipe(
        //     tap((countries) =>
        //     {
        //         this._countries.next(countries);
        //     }),
        // );
    }

    // /**
    //  * Get tags
    //  */
    // getTags(): Observable<Tag[]>
    // {
    //     return of([])
    //     // return this._httpClient.get<Tag[]>('api/apps/contacts/tags').pipe(
    //     //     tap((tags) =>
    //     //     {
    //     //         this._tags.next(tags);
    //     //     }),
    //     // );
    // }

    // /**
    //  * Create tag
    //  *
    //  * @param tag
    //  */
    // createTag(tag: Tag): Observable<Tag>
    // {
    //     return this.tags$.pipe(
    //         take(1),
    //         switchMap(tags => this._httpClient.post<Tag>('api/apps/contacts/tag', {tag}).pipe(
    //             map((newTag) =>
    //             {
    //                 // Update the tags with the new tag
    //                 this._tags.next([...tags, newTag]);

    //                 // Return new tag from observable
    //                 return newTag;
    //             }),
    //         )),
    //     );
    // }

    // /**
    //  * Update the tag
    //  *
    //  * @param id
    //  * @param tag
    //  */
    // updateTag(id: string, tag: Tag): Observable<Tag>
    // {
    //     return this.tags$.pipe(
    //         take(1),
    //         switchMap(tags => this._httpClient.patch<Tag>('api/apps/contacts/tag', {
    //             id,
    //             tag,
    //         }).pipe(
    //             map((updatedTag) =>
    //             {
    //                 // Find the index of the updated tag
    //                 const index = tags.findIndex(item => item.id === id);

    //                 // Update the tag
    //                 tags[index] = updatedTag;

    //                 // Update the tags
    //                 this._tags.next(tags);

    //                 // Return the updated tag
    //                 return updatedTag;
    //             }),
    //         )),
    //     );
    // }

    // /**
    //  * Delete the tag
    //  *
    //  * @param id
    //  */
    // deleteTag(id: string): Observable<boolean>
    // {
    //     return this.tags$.pipe(
    //         take(1),
    //         switchMap(tags => this._httpClient.delete('api/apps/contacts/tag', {params: {id}}).pipe(
    //             map((isDeleted: boolean) =>
    //             {
    //                 // Find the index of the deleted tag
    //                 const index = tags.findIndex(item => item.id === id);

    //                 // Delete the tag
    //                 tags.splice(index, 1);

    //                 // Update the tags
    //                 this._tags.next(tags);

    //                 // Return the deleted status
    //                 return isDeleted;
    //             }),
    //             filter(isDeleted => isDeleted),
    //             switchMap(isDeleted => this.contacts$.pipe(
    //                 take(1),
    //                 map((contacts) =>
    //                 {
    //                     // Iterate through the contacts
    //                     contacts.forEach((contact) =>
    //                     {
    //                         const tagIndex = contact.tags.findIndex(tag => tag === id);

    //                         // If the contact has the tag, remove it
    //                         if ( tagIndex > -1 )
    //                         {
    //                             contact.tags.splice(tagIndex, 1);
    //                         }
    //                     });

    //                     // Return the deleted status
    //                     return isDeleted;
    //                 }),
    //             )),
    //         )),
    //     );
    // }

    /**
     * Update the avatar of the given contact
     *
     * @param id
     * @param avatar
     */
    uploadAvatar(id: string, avatar: File): Observable<Contact>
    {
        return this.contacts$.pipe(
            take(1),
            switchMap((contacts) =>
                this._commonService
                    .uploadFile({
                        file: avatar,
                        app_config: {
                            store_to: { local: {} },
                            file_size: null,
                        },
                    })
                    .pipe(
                        map((response: IResponse<any>) => {
                            // Find the index of the updated contact
                            const index = contacts.findIndex(
                                (item) => item._id === id
                            );

                            // Update the contact
                            contacts[index].avatar =
                                response?.data?.local?.[0]?.path;

                            // Update the contacts
                            this._contacts.next(contacts);

                            const updatedContact: any = contacts[index];

                            // Return the updated contact
                            return updatedContact;
                        }),
                        switchMap((response: any) =>
                            this.contact$.pipe(
                                take(1),
                                filter((item) => item && item._id === id),
                                tap((item) => {
                                    item.avatar = response?.avatar;
                                    // Update the contact if it's selected
                                    this._contact.next(item);

                                    // Return the updated contact
                                    return item;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Create a user
     */
    createUser(user: { phone: string; email_id: string }): Observable<any> {
        return this._authService.activate_account(user);
    }
}
