<div class="absolute relative w-full h-40 sm:h-48 px-8 sm:px-12 bg-accent-100 dark:bg-accent-700">
    <div class="flex items-center justify-end w-full mx-auto pt-6">
        <div class="flex items-center  dark:bg-transparent">
            <!-- <button mat-button [color]="'warn'">
                Delete
            </button> -->
            <button class="ml-2" mat-flat-button [color]="'primary'" (click)="saveUserInfo()">
                Save
            </button>
        </div>
        <!-- <a mat-icon-button>
            <mat-icon class="text-black" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </a> -->
    </div>
</div>

<div class="relative flex flex-col flex-auto items-center bg-white">
    <div class="flex flex-auto justify-between items-end -mt-16 px-4 w-full">
        <div
            class="relative flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
            <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
            <div class="absolute inset-0 flex items-center justify-center z-20">
                <div>
                    <input id="avatar-file-input" class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                        type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                        (change)="uploadAvatar(avatarFileInput.files)" #avatarFileInput>
                    <label class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                        for="avatar-file-input" matRipple>
                        <mat-icon class="text-white" [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                    </label>
                </div>
                <div>
                    <button mat-icon-button (click)="removeAvatar()">
                        <mat-icon class="text-white" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                    </button>
                </div>
            </div>

            <img class="object-cover w-full h-full" *ngIf="user?.avatar" [src]="user?.avatar">
            <div class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                *ngIf="!user.avatar">
                {{userInfoList?.name?.charAt(0)}}
            </div>
        </div>
    </div>
    <div class="inset-0 flex flex-col min-w-0 overflow-hidden">
        <div
            class="flex settings-responce sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:px-10 dark:bg-transparent relative">
            <div class="flex-1 min-w-0 setting-padding">
                <div class="flex items-center">
                    <div class="text-3xl  mb-1 font-bold tracking-tight leading-none">
                        Account
                    </div>
                </div>
            </div>
            <!-- <div class="ml-auto flex items-center">
                <button class="ml-4" mat-flat-button type="button" [color]="'primary'" (click)="saveUserInfo()">
                    Save
                </button>
            </div> -->
        </div>
        <div class="flex-auto settings-res p-6 sm:p-6 rounded-t-2xl shadow overflow-y-auto" cdkScrollable>
            <div class="h-400 min-h-400 max-h-400">
                <form [formGroup]="accountForm" class="px-6">

                    <div class="w-full">
                        <div class="text-xl">Profile</div>
                        <div class="text-secondary">Following information is publicly displayed, be careful!</div>
                    </div>

                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>First Name</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                                <input [formControlName]="'first_name'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Last Name</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                                <input [formControlName]="'last_name'" matInput>
                            </mat-form-field>
                        </div>

                        <!-- <div class="sm:col-span-4">
                            <mat-form-field class="fuse-mat-emphasized-affix w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>User Name</mat-label>
                                <div class="text-secondary" matPrefix>fusetheme.com/</div>
                                <input [formControlName]="'user_name'" matInput>
                            </mat-form-field>
                        </div> -->

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Job Title</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:briefcase'"
                                    matPrefix></mat-icon>
                                <input [formControlName]="'job_title'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Company</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:building-office-2'"
                                    matPrefix></mat-icon>
                                <input [formControlName]="'company'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-4">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Home</mat-label>
                                <mat-select placeholder="Select Label" rows="3" formControlName="homepage">
                                    <mat-option *ngFor="let menu of MenuAccessList;let i=index" [value]="menu._id">
                                        {{menu?.menu_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-4">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>About</mat-label>
                                <textarea matInput formControlName="about" rows="3"></textarea>
                            </mat-form-field>
                            <div class="mt-1 text-md text-hint">Brief description for your profile. Basic HTML and Emoji
                                are
                                allowed.</div>
                        </div>
                    </div>

                    <div class="my-10 border-t"></div>

                    <div class="w-full">
                        <div class="text-xl">Personal Information</div>
                        <div class="text-secondary">Communication details in case we want to connect with you. These
                            will be kept
                            private.</div>
                    </div>

                    <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">

                        <div class="sm:col-span-4">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Email</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:envelope'"
                                    matPrefix></mat-icon>
                                <input [formControlName]="'email'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Phone NO</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:phone'" matPrefix></mat-icon>
                                <input [formControlName]="'phone_no'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Date Of Birth</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:cake'" matPrefix></mat-icon>
                                <input matInput [matDatepicker]="birthdayDatepicker" [formControlName]="'dob'"
                                    [placeholder]="'Date Of Birth'">
                                <mat-datepicker-toggle matSuffix [for]="birthdayDatepicker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #birthdayDatepicker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Gender</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'" matPrefix></mat-icon>
                                <mat-select placeholder="Select" formControlName="gender">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let gender of genderList; let i=index"
                                        [value]="gender.vsd_code">
                                        {{gender?.vsd_value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Language</mat-label>
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:globe-alt'"
                                    matPrefix></mat-icon>
                                <mat-select placeholder="Select" formControlName="language">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let lang of languageList; let i=index" [value]="lang.vsd_code">
                                        {{lang?.vsd_value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-4">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Address</mat-label>
                                <mat-icon class="icon-size-5" matPrefix>my_location</mat-icon>
                                <input ngx-google-places-autocomplete [formControlName]="'address'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>City</mat-label>
                                <mat-icon class="icon-size-5" matPrefix>location_city</mat-icon>
                                <input [formControlName]="'city'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>State</mat-label>
                                <mat-icon class="icon-size-5" matPrefix>location_on</mat-icon>
                                <input [formControlName]="'state'" matInput>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Country</mat-label>
                                <mat-icon class="icon-size-5" matPrefix>flag</mat-icon>
                                <mat-select placeholder="Select" formControlName="country">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let country of countriesList; let i=index"
                                        [value]="country.vsd_code">
                                        {{country?.vsd_value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="sm:col-span-2">
                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                <mat-label>Pin Code</mat-label>
                                <mat-icon class="icon-size-5" matPrefix>pin_drop</mat-icon>
                                <input [formControlName]="'post_code'" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="mt-11 mb-10 border-t"></div>
                </form>
            </div>

        </div>
    </div>
</div>