import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SharedModule } from 'app/shared/shared.module';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-activity-tracker',
    templateUrl: './activity-tracker.component.html',
    styleUrls: ['./activity-tracker.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class ActivityTrackerComponent implements OnInit, OnChanges {
    @Input('form_data') form_data: any;
    formDetails: any[] = [];

    constructor(private _commonService: CommonService) {}

    ngOnInit() {
        this.loadActivity();
    }

    async loadActivity() {
        const response: any = await this._commonService
            .getDataByFields(MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER, {
                form_id: this.form_data?.form_id,
                ref_id: this.form_data?.row_data?._id,
            })
            .toPromise();

        this.formDetails = response?.data || [];

        const userNames = {};
        for (let element of this.formDetails) {
            if (userNames?.[element?.done_by]) {
                element.done_by_user = userNames[element.done_by];
            } else {
                let response = await lastValueFrom(
                    this._commonService.getDataById(
                        MODELS_CONSTANTS.USERS,
                        element.done_by
                    )
                )
                    .then((response) => response?.data)
                    .catch((err) => console.error(err));

                if (response) {
                    element.done_by_user = response?.name;
                }

                userNames[element.done_by] = response?.name;
            }
            if (element.action == 'create') {
                element.title = 'Created';
            } else if (element.action == 'update') {
                element.title = 'Updated';
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('form_data' in changes) {
            this.loadActivity();
        }
    }
}
