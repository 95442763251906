import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from 'app/core/auth/auth.service';
import { ContactsService } from 'app/modules/features/contacts/contacts.service';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'settings-account',
    templateUrl: './account.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [DatePipe],
    imports: [
        FormsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule,
        MatInputModule, CommonModule, TextFieldModule, MatSelectModule,
        MatOptionModule, MatButtonModule, MatDatepickerModule,
    ],
})
export class SettingsAccountComponent implements OnInit {
    @ViewChild('avatarFileInput') private _avatarFileInput: ElementRef;
    accountForm: FormGroup;
    userInfoList: any;
    submitted: boolean = false;
    _id: string;
    genderList: any;
    user: any;
    languageList: any;
    countriesList: any;
    menuList: any;
    menuPermission: any;
    MenuAccessList = [];
    editMode: boolean = false;
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        private _commonService: CommonService,
        private fb: FormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private snackbar: SnackbarService,
        private _contactsService: ContactsService,
        private _authService: AuthService
    ) { }

    ngOnInit(): void {
        this.user = this.getUser();
        this.initializeForm();
        this.loadData();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private initializeForm(): void {
        this.accountForm = this.fb.group({
            user_id: [''],
            profile_url: [''],
            image_url: [''],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            user_name: [''],
            job_title: [''],
            homepage: [''],
            company: [''],
            about: [''],
            email: ['', Validators.email],
            phone_no: [''],
            dob: [''],
            gender: [''],
            language: [''],
            address: [''],
            city: [''],
            state: [''],
            country: [''],
            post_code: [''],
            // avatar:['']
        });
    }

    private loadData(): void {
        this.getAllMenus();
        this.getGenderList();
        this.getLanguageList();
        this.getCountriesList();
        this.getUserInfo();
    }

    async getAllMenus(): Promise<void> {
        try {
            const menuRes = await this._commonService.getDataByField(MODELS_CONSTANTS.MENU_ROLES, 'role_id', this.user.role_id).toPromise();
            this.menuPermission = menuRes.data;
            this._commonService.getAllData(MODELS_CONSTANTS.MENUS).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.menuList = response?.data;
                        this.menuPermission.forEach(async ele => {
                            const res = await this.menuList.find(obj => obj._id === ele.menu_id);
                            if (res && res.menu_path) {
                                this.MenuAccessList.push(res);
                            }
                        });
                        this._changeDetectorRef.markForCheck();
                    }
                },
                error: (err) => console.error(err),
            });
        } catch (error) {
            console.error('Error fetching menu roles:', error);
        }
    }

    getGenderList(): void {
        this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'USER_GENDER').subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.genderList = response?.data;
                    this._changeDetectorRef.markForCheck();
                }
            },
            error: (err) => console.error(err),
        });
    }

    getLanguageList(): void {
        this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'PREFERRED_LANGUAGE').subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.languageList = response?.data;
                    this._changeDetectorRef.markForCheck();
                }
            },
            error: (err) => console.error(err),
        });
    }

    getCountriesList(): void {
        this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'COUNTRIES').subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.countriesList = response?.data;
                    this._changeDetectorRef.markForCheck();
                }
            },
            error: (err) => console.error(err),
        });
    }

    getUserInfo(): void {
        this._commonService.getDataByField(MODELS_CONSTANTS.USER_INFO, 'user_id', this.user._id).subscribe({
            next: (response: any) => {
                if (response?.status === 200 && response?.data?.length) {
                    this.userInfoList = response?.data[0];
                    this.modifyUserInfo(this.userInfoList);
                    this._changeDetectorRef.markForCheck();
                } else {
                    this.accountForm.get('first_name').setValue(this.user.name);
                    this._changeDetectorRef.markForCheck();
                }
            },
            error: (err) => console.error(err),
        });
    }

    modifyUserInfo(userdata: any): void {
        this.accountForm.reset(userdata);
        this._id = userdata?._id;
    }

    saveUserInfo(): void {
        this.submitted = true;
        if (this.accountForm.valid) {
            const formData = this.accountForm.value;
            formData.user_id = this.user._id;
            if (this._id) {
                formData['_id'] = this._id;
            }
            this._commonService.saveRecord(MODELS_CONSTANTS.USER_INFO, formData).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        const type = formData?._id ? 'modified' : 'added';
                        this.snackbar.success(`User Info ${type} successfully`);
                        this.getUserInfo();
                    } else {
                        this.snackbar.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
            if(this.user?.image_id) {
                let json = {
                    _id: this.user._id,
                    email_id: this.user.email_id,
                    phone: this.user.phone,
                    avatar: this.user.image_id != 'delete' ? this.user.image_id : ''
                }
                this._commonService.saveRecord(MODELS_CONSTANTS.USERS, json).subscribe({
                    next: (response: any) => {
                      if (response?.status === 200) {
                        this.snackbar.success(
                          `User modified successfully`
                        );
                      } else {
                        this.snackbar.error(
                          response?.message ||
                          MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                      }
                    },
                    error: (err) => {
                      console.error(err);
                      this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    },
                  });
            }
        } else {
            this.snackbar.warning('Please fill the form');
        }
    }

    private getUser(): { homepage?: string } | null {
        const user = this._authService.userInfo;
        return user && JSON.stringify(user) !== '{}' ? user : null;
    }

    removeAvatar(): void
    {
        this.user.avatar = null;
        this.user.image_id = 'delete';
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Upload avatar
     *
     * @param fileList
     */
    uploadAvatar(fileList: FileList): void
    {
        if ( !fileList?.length )
        {
            return;
        }

        const allowedTypes = ['image/jpeg', 'image/png'];
        const file = fileList[0];

        // if ( !allowedTypes.includes(file.type) )
        // {
        //     return;
        // }

        this._commonService.uploadImage(fileList, 'USERS').subscribe({
            next: (response: any) => {
                if (response?.url) {
                    this.user.avatar = response?.url;
                    this.user.image_id = response._id;
                    this.snackbar.success(`Image added successfully`);
                    this._changeDetectorRef.markForCheck();
                } else {
                    this.snackbar.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                }
            },
            error: (err) => {
                console.error(err);
                this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            },
        });
    }

    /**
     * Toggle edit mode
     *
     * @param editMode
     */
    toggleEditMode(editMode: boolean | null = null): void
    {
        if ( editMode === null )
        {
            this.editMode = !this.editMode;
        }
        else
        {
            this.editMode = editMode;
        }
        this._changeDetectorRef.markForCheck();
    }

}
