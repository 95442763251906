<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div
        class="flex flex-row flex-0 sm:items-center sm:justify-between p-6 z-10 ml-12 lg:ml-0 mt-4 sm:mt-0 sm:pb-6 sm:px-10 bg-default dark:bg-transparent relative">
        <div class="flex-1 min-w-0">
            <!-- Title -->
            <div class="flex items-center">
                <div class="text-3xl font-bold tracking-tight leading-none">
                    Account
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end ml-auto">
            <button
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'" (click)="save()">Save
            </button>
        </div>
    </div>

    <!-- Form -->
    <div class="flex-auto -mt-16 p-8 sm:p-10 rounded-t-2xl shadow overflow-y-auto" cdkScrollable>
        <form [formGroup]="companyForm" class="px-6">

            <!-- Section -->
            <div class="w-full">
                <div class="text-xl">Profile</div>
                <div class="text-secondary">Following information is publicly displayed, be careful!</div>
            </div>
            <div class="grid sm:grid-cols-4 gap-4 w-full mt-8">
                <!-- Name -->
                <div class="sm:col-span-4">
                    <mat-form-field
                        class="w-full"
                        [subscriptSizing]="'dynamic'">
                        <mat-label>Company Name</mat-label>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:building-office-2'"
                            matPrefix></mat-icon>
                        <input formControlName="company_name" matInput>
                    </mat-form-field>
                </div>
                <div class="sm:col-span-4">
                    <mat-form-field
                        class="w-full"
                        [subscriptSizing]="'dynamic'">
                        <mat-label>Contact Person</mat-label>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:user'"
                            matPrefix></mat-icon>
                        <input formControlName="contact_person" matInput>
                    </mat-form-field>
                </div>
                <div class="sm:col-span-2">
                    <mat-form-field
                        class="w-full">
                        <mat-label>Business Type</mat-label>
                        <mat-select formControlName="business_type">
                            <mat-option>--</mat-option>
                            <mat-option [value]="business.vsd_value" *ngFor="let business of businessType;">
                                <div class="font-medium">{{business.vsd_value}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2" style="line-height: 1;">
                    <mat-label>Vat Applicable
                    </mat-label>
                    <mat-radio-group class="w-full" style="display:flex !important;margin-top: 6px;" [color]="'primary'">
                    <mat-label style="margin-top: 15px;">Yes</mat-label> 
                    <mat-radio-button name="vat" [checked]="selected" (click)="vat()" class="mt-1"></mat-radio-button>
                    <mat-label style="margin-top: 15px;">No</mat-label>
                    <mat-radio-button name="vat" [checked]="selected" (click)="vat2()" class="mt-1"></mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="sm:col-span-2" *ngIf="vatNumFlag">
                    <mat-form-field class="w-full">
                    <mat-label>Vat Number</mat-label>
                    <input matInput [placeholder]="'Vat Number'" [spellcheck]="false">
                    </mat-form-field>
                </div>
                
                <div class="sm:col-span-2">
                    <mat-form-field
                        class="w-full">
                        <mat-label>Payment Terms</mat-label>
                        <mat-select formControlName="payment_terms">
                            <mat-option>--</mat-option>
                            <mat-option [value]="payment.vsd_value" *ngFor="let payment of PaymentsTerms">
                                <div class="font-medium">{{payment.vsd_value}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="sm:col-span-2">
                    <mat-form-field
                        class="w-full">
                        <mat-label>Preferred MOP</mat-label>
                        <mat-select  formControlName="preferred_mop">
                            <mat-option>--</mat-option>
                            <mat-option [value]="preferred.vsd_value" *ngFor="let preferred of preferredMOP;">
                                <div class="font-medium">{{preferred.vsd_value}}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- Divider -->
            <div class="my-5 border-t"></div>

            <!-- Section -->
            <div class="w-full">
                <div class="text-xl">Personal Information</div>
                <div class="text-secondary">Communication details in case we want to connect with you. These will be kept private.</div>
            </div>
            <div>
                    <div class="grid sm:grid-cols-12 gap-2 w-full mt-4">
                        <div class="sm:col-span-6">
                            <mat-form-field class="w-full">
                                <mat-label>Address</mat-label>
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix> my_location</mat-icon>
                                <input matInput [formControlName]="'address'" ngx-gp-autocomplete [spellcheck]="false" (onAddressChange)="handleAddressChange($event)"/>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-6">
                            <mat-form-field
                                class="w-full" >
                                <mat-label>City</mat-label>
                                <mat-icon 
                                    class="icon-size-5"
                                    matPrefix> location_city</mat-icon>
                                <input [spellcheck]="false" [formControlName]="'city'" matInput>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-6">
                            <mat-form-field
                                class="w-full" >
                                <mat-label>State</mat-label>
                                <mat-icon 
                                    class="icon-size-5"
                                    matPrefix>location_on</mat-icon>
                                <input [spellcheck]="false" [formControlName]="'state'" matInput>
                            </mat-form-field>
                        </div>
                        
                        <!-- Country -->
                        <div class="sm:col-span-6">
                            <mat-form-field
                                class="w-full"
                                [subscriptSizing]="'dynamic'">
                                <mat-label>Country</mat-label>
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix>flag</mat-icon>
                                <input [spellcheck]="false" [formControlName]="'country'" matInput>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-6">
                            <mat-form-field
                                class="w-full" >
                                <mat-label>Pincode</mat-label>
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix>pin_drop</mat-icon>
                                <input [spellcheck]="false" [formControlName]="'pincode'" matInput>
                            </mat-form-field>
                        </div>
                        <div class="sm:col-span-6">
                            <mat-form-field
                                class="w-full">
                                <mat-label>Tabs Position</mat-label>
                                <mat-select formControlName="tab_position">
                                    <mat-option>--</mat-option>
                                    <mat-option *ngFor="let tabs of tabPositionList" [value]="tabs.value">
                                        <div class="font-medium">{{tabs.value}}</div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
            </div>

            <div class="mt-11 mb-10 border-t"></div>
        </form>
    </div>

</div>
