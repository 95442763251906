import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector       : 'fuse-vertical-navigation-basic-item',
    templateUrl    : './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [NgClass, NgIf, RouterLink, RouterLinkActive, MatTooltipModule, NgTemplateOutlet, MatIconModule],
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy, OnChanges
{
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private _navigationService: NavigationService
    )
    {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe(() =>
        {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        setTimeout(() => {
            let activeMenu: any = document.getElementsByClassName(
                'fuse-vertical-navigation-item-active'
            );
            activeMenu = activeMenu?.[0];

            let link;
            if(activeMenu){
                link = activeMenu.getAttribute('href');
                if (!link) {
                    link = activeMenu.getAttribute('ng-reflect-router-link');
                }
                if (link && link?.startsWith('/')) {
                    link = link.substring(1);
                }
            }
           
            const itemLink = this.item?.link?.replace(/\/+/g, '/');
            if (activeMenu && itemLink?.includes(link)) {
                this.onMenuClick(this.item);
                this._changeDetectorRef.detectChanges();
            } else {
                let currentPath = window.location.pathname;
                if (currentPath?.startsWith('/')) {
                    currentPath = currentPath.substring(1);
                }
                if (itemLink?.includes(currentPath)) {
                    this.onMenuClick(this.item);
                    this._changeDetectorRef.detectChanges();
                }
            }
        }, 1000);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
    
    /**
     * On Changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['item'] && changes?.item?.currentValue){
            this.item.function = this.onMenuClick.bind(this);
        }
    }

    onMenuClick(item){
        this._navigationService.setCurrentMenu(item);
    }

    menuDetails(item) {
        localStorage.setItem('menuDetails', item?.title);
    }

    onFormClick(item:any){
        console.log( item, 'dcdcd')
    }
}
