import { ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewChild, WritableSignal, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import moment from 'moment';

import { AuthService } from 'app/core/auth/auth.service';
import { ListService } from 'app/modules/core/list/list.service';
import { COMPONENT_REFERENCE } from 'app/shared/constants/component-reference.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { TEMPLATES_CONSTANTS } from 'app/shared/constants/templates.constants';
import { VALUE_SET_CODE_CONSTANTS, VALUE_SET_DETAILS_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { SHARED_CONSTANTS } from 'app/shared/constants/shared.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { API_REGISTRY_CONSTANTS } from 'app/shared/constants/api-registry.constants';

@Component({
    selector: 'app-form',
    templateUrl: './forms-renderer.component.html',
    styleUrls: ['./forms-renderer.component.scss'],
})
@Injectable()
export class DynamicFormRendererComponent implements OnInit, OnChanges {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @Input() data: any;
    refreshForm: any;
    FormHeader: string = 'Add';
    formdata: any = {};
    form_details: any = {};
    form_module_code: any;
    form_module_code_url: any;
    form_module_code_category: any;
    form_module_doc_no_flag: boolean = false; 
    submissiondata: { data: any };
    form_id: any;
    formname: any;
    collection_name: any;
    form_data_id: any;
    id: any;
    form_code: any;
    active_version: any;
    rows: any = [];
    vortexModule: any;
    vortexUpdateModule: any;
    updateData: Boolean = false;
    workflowcode: any;
    stepData: any;
    drawerWidth: string = '100%';
    classType: string = 'Icon-Size-md'
    conversationData: any = {};
    actionComponent: { component: any, inputs?: any };
    formFooterData: { footerPermissions?: any, footerData?: any, isApproval?: boolean, formId?: string } = {};
    formHeaderData: { form_header_actions?: any[] } = {};
    menuPermissions: any = { write: true, read: true };
    form_read_only: WritableSignal<boolean> = signal(false);
    form_show: any = false;
    showLoader: WritableSignal<boolean> = signal(true);
    showPdfLoader: WritableSignal<boolean> = signal(false);
    progressValue: number = 0;

    // PDF VIEWER
    pdfSrc: WritableSignal<string> = signal('');
    showPdfViewer: WritableSignal<boolean> = signal(false);
    pageZoom: WritableSignal<number> = signal(1);
    minZoom: number = .1;
    maxZoom: number = 5;
    currentPdfPage: number = 1;
    totalPdfPages: number;

    save = {
        "label": "Save",
        "showValidations": false,
        "disableOnInvalid": true,
        "tableView": false,
        "key": "save",
        "type": "button",
        "customClass": "pull-right mb-2",
        "saveOnEnter": false,
        "input": true,
        "action": "event",
        "event": "onSave"
    };
    submit = {
        "label": "Submit",
        "showValidations": false,
        "disableOnInvalid": true,
        "tableView": false,
        "key": "submit",
        "type": "button",
        "customClass": "pull-right mr-2 mb-2",
        "saveOnEnter": false,
        "input": true,
        "action": "event",
        "event": "onSubmit"
    };
    resubmit = {
        "label": "Re-Submit",
        "showValidations": false,
        "disableOnInvalid": true,
        "tableView": false,
        "key": "resubmit",
        "type": "button",
        "customClass": "pull-right mr-2 mb-2",
        "saveOnEnter": false,
        "input": true,
        "action": "event",
        "event": "onResubmit"
    };

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    tenants: any;
    ai_module_code: string;

    constructor(
        private _router: Router,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
        private _commonService: CommonService,
        private snackbar: SnackbarService,
        private authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _listService: ListService
    ) {
    }

    ngOnInit(): void {
        this.showLoader.set(true);
        const userInfo = this.authService.userInfo;;
        this._listService.permissions$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((menu: any) => {
                if (menu?.permissions) {
                    this.menuPermissions = menu.permissions;
                    if(this.data?.handle_clock) {
                        this.menuPermissions.write = true;
                    }
                    this.form_read_only.set(!this.menuPermissions.write);
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }

                if(menu?.params && menu?.params?.length){
                    this.ai_module_code = menu?.params?.find((e) => e?.code === 'ai_module_code')?.value;
                }
            });

        this.submissiondata = { data: {} };
        if (this.dialogData && (this.dialogData?.form_code || this.dialogData?.form_id)) {
            this.data = this.dialogData;
        }
        (async () => {
            this.FormHeader = this.data.title;
            this.formFooterData.footerData = this.submissiondata.data;
            this.formFooterData = { ...this.formFooterData };
            await this.get_form_details(this.data);
            const tenant_response: any = await this._commonService.getDataByField(MODELS_CONSTANTS.TENANTS, 'tenant_id', userInfo.tenant_id).toPromise();
            if(tenant_response?.data && tenant_response?.data.length) {
                this.tenants = tenant_response?.data;
            }
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        })();
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if ('data' in changes) {
            this.showLoader.set(true);

            this.formFooterData.footerData = this.data?.row_data;
            this.formFooterData = { ...this.formFooterData };

            await this.get_form_details(this.data);
            this.FormHeader = this.data.title;
            if (this.matDrawer.opened) {
                this.matDrawer.close();
            }
            this.actionComponent = null;

            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        }
    }

    // Get form details to load forms
    async get_form_details(data: any) {
        try {
            this.form_read_only.set(!this.menuPermissions.write);
            this.form_show = false;
            this.submissiondata = { data: {} };
            this.formFooterData.footerData = this.submissiondata.data;
            this.formFooterData = { ...this.formFooterData };

            const result = await this._commonService.getDataByField(
                MODELS_CONSTANTS.FORM_SETP,
                data.form_code ? 'form_code' : '_id',
                data.form_code ? data.form_code : data.form_id
            ).toPromise();
            if (result?.status === 200) {
                this.form_details = result.data[0];
                this.formFooterData.isApproval = this.form_details?.is_approval;
                this.formFooterData.formId = this.form_details?._id;
                this.get_form_actions(this.form_details?._id);
                this.get_form_footers(this.form_details?._id);
                await this.get_form_module_details(result?.data[0]?.module_id);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_form_actions(form_id) {
        try {
            const result = await this._commonService
                .getDataByField(
                    MODELS_CONSTANTS.FORM_ACTIONS,
                    'form_id',
                    form_id
                )
                .toPromise();
            if (result?.status === 200) {
                // this.form_header_actions = result.data || [];
                this.formHeaderData.form_header_actions = result.data || [];
                this.formHeaderData = { ...this.formHeaderData };
            }
        } catch (error) {
            console.error(error);
        }
    }

    async get_form_footers(form_id) {
        try {
            const result = await this._commonService
                .getDataByField(MODELS_CONSTANTS.FORM_FTR, 'form_id', form_id)
                .toPromise();
            if (result?.status === 200) {
                this.formFooterData.footerPermissions = result.data?.[0];
                this.formFooterData = { ...this.formFooterData }
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Get form selected module details
    async get_form_module_details(module_id) {
        const module_response = await this._commonService.getDataByFields(
            MODELS_CONSTANTS.MODULES,
            { module_id }
        ).toPromise();
        if (module_response?.status === 200 && module_response?.data.length > 0) {
            this.form_module_code = module_response.data[0].module_code;
            this.form_module_code_category = module_response.data[0].module_category;
            this.form_module_doc_no_flag = module_response.data[0]?.isDocNo || false;
            // this.formFooterData.isApproval = module_response?.data[0]?.isApproval || false;
            this.formdata = await this.select_url_replace(this.form_details.form_data);
            if (this.authService.userInfo.client_id) {
                this.formdata.client = true;
            } else {
                this.formdata.client = false;
            }
            if (this.authService.userInfo._id == this.submissiondata?.data?.created_by) {
                this.formdata.requester = true;
            } else {
                this.formdata.requester = false;
            }

            this.refreshForm = new EventEmitter();
            await this.get_form_module_url_details(module_response.data[0].module_category);
        }
    }

    // Get form selected module URL details to call API
    async get_form_module_url_details(module_category) {
        const module_url_response = await this._commonService.getDataByFields(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            {
                vs_code: VALUE_SET_CODE_CONSTANTS.API_REGISTRY_BASE_URL,
                vsd_code: module_category
            }
        ).toPromise();
        if (module_url_response?.status === 200 && module_url_response?.data.length > 0) {
            this.form_module_code_url = module_url_response.data[0].vsd_property_1;
            if (this.data.is_from_board) {
                this.get_task_form_map();
            } else if (this.data.id && this.form_details.init_action) {
                // Call get id details API using init action
                this.get_id_details();
            } else if (this.form_details.init_action) {
                this.get_id_details();
            } else if (this.data.id) {
                // Call get id details API using module code url
                await this.get_id_details_module_url(this.data.id)
            } else if (this.data.form_data) { 
                this.submissiondata = { data: this.data.form_data };
                this.addSaveSubmit(0);
                this.form_show = true;
            } else if(this.data.module_schema){
                this.submissiondata = { data: { module_schema : this.data.module_schema } };
                this.addSaveSubmit(0);
                this.form_show = true;
            } else if(this.data.action && !this.data.id){
                await this.get_id_details_module_url(null)
            } else {
                this.submissiondata = { data: {} };
                this.addSaveSubmit(0);
                this.form_show = true;
            }

            if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data.vs_code) {
                this.submissiondata.data[this.form_details.parent_id] = this.data.parent_row_data.vs_code;
            } else if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data._id) {
                this.submissiondata.data[this.form_details.parent_id] = this.data.parent_row_data._id; 
            }
            this.submissiondata.data.tenant_id = this.authService.userInfo.tenant_id;
            this.submissiondata.data.user_id = this.authService.userInfo._id;

            this.formFooterData.footerData = this.submissiondata.data;
            this.formFooterData = { ...this.formFooterData };
        }
    }

    async get_task_form_map() {
        const task_from_map = await this._commonService.getDataByFields(
            MODELS_CONSTANTS.BOARD_TASK_FORM,
            {
                task_id: this.data.id,
                ref_type_id: this.data.form_id
            }
        ).toPromise();
        if (task_from_map?.status === 200 && task_from_map?.data.length > 0) {
            await this.get_id_details_module_url(task_from_map.data[0].ref_id);
        }
    }

    // Get id details when click edit using init action
    async get_id_details() {
        const apiRegistryResponse = await this._commonService.getDataByField(
            MODELS_CONSTANTS.API_REGISTRIES,
            '_id',
            this.form_details.init_action
        ).toPromise();
        if (
            apiRegistryResponse?.status === 200 &&
            apiRegistryResponse?.data.length > 0
        ) {
            let payload = { module_code: this.form_module_code };
            if (this.data.id) {
                payload['id'] = this.data.id;
            }
            if (this.data.row_data) {
                payload = { ...payload, ...this.data.row_data }
            }
            const response = await this._commonService.executeApiMethod(
                apiRegistryResponse.data[0],
                payload
            );
            if (response?.status === 200) {
                if(response?.data?.length){
                    response.data[0]['all_data'] = cloneDeep(response?.data);
                }
                await this.find_file_components_replace_value(response.data[0]);
            }
        }
    }

    // Get id details when click edit using module code url
    async get_id_details_module_url(id: any) {
        let params: any = {};
        if (this.form_module_code === 'form_footer') {
            params['form_id'] = this.data.id;
        } else {
            if (this.data.params && this.data.params.length && !this.data.is_from_board) {
                let replace_params = this.params_value_replace(this.data.params, this.data.row_data, this.data.parent_row_data);
                if (replace_params && replace_params.length) {
                    replace_params.forEach((p: any) => {
                        params[p.key] = p.value;
                    });
                }
            } else if (this.form_module_code_category === 'CORE') {
                params['_id'] = id;
            } else if (this.form_module_code_category === 'VRM') {
                params['id'] = id;
            }
        }

        const formParams = this.formdata.components.find((e) => e.key === 'form_params');
        if(formParams?.components?.length) {
            params = {};
            for(let param of formParams.components) {
                if(!param?.key?.includes('field_name') && param?.key && param?.defaultValue) {
                    params[param.key] = param.defaultValue;
                }
            }
        }
        const response: any = await this._commonService.callAPI(
            this.form_module_code_url,
            'get',
            this.form_module_code,
            {},
            params
        ).toPromise();
        if (response?.status === 200) {
            if(response?.data?.length){
                response.data[0]['all_data'] = cloneDeep(response?.data);
            }
            await this.find_file_components_replace_value(response?.data[0]);
        }
    }

    async find_file_components_replace_value(data: any) {
        if (!data) data = {};
        let file_upload_components = this.find_file_components(this.formdata.components);

        const update_file = async (data, file_upload_components) => {
            await Promise.all(file_upload_components.map(async (component) => {
                let file_data_id: any = data[component.key];
                if (file_data_id && file_data_id != null && !Array.isArray(file_data_id)) {
                    const response = await this._commonService
                        .getDataById(
                            MODELS_CONSTANTS.FILE_STORAGE,
                            file_data_id
                        )
                        .toPromise()
                        .catch((err) => console.error(err));
                    if (response && response.status === 200) {
                        let { name, size } = response.data;
                        let key = this.file_to_key(response.data);
                        let file_data = {
                            "_id": file_data_id,
                            "storage": "url",
                            "url": `https://vortexstorage.s3.ap-southeast-2.amazonaws.com/${key}`,
                            "originalName": name,
                            "name": key,
                            "size": size
                        };
                        data[component.key] = [file_data];
                    }
                } else {
                    data[component.key] = [];
                }
            }));
        }

        await update_file(data, file_upload_components);

        const data_grid_components = this.find_grid_components(this.formdata.components);
        for(const grid_component of data_grid_components) {
            let grid_file_upload_components = this.find_file_components(grid_component.components);
            if(grid_file_upload_components && grid_file_upload_components?.length) {
                const files = data[grid_component?.key];
                if(Array.isArray(files)) {
                    for(let file of files) {
                        await update_file(file, grid_file_upload_components);
                    }
                }
            }
        }

        if(data?.all_data && data?.all_data?.length){
            for(let item of data?.all_data) {
                await update_file(item, file_upload_components);
            }
        }

        this.addSaveSubmit(data.status);

        if (this.form_details && this.form_details.is_approval && this.form_details.board_id) {
            if (data && (data.status == 1 || data.status == 2 || data.status == 3)) {
                setTimeout(() => {
                    this.form_read_only.set(true);
                }, 500);
            } else {
                this.form_read_only.set(false);
            }
        } else {
            this.form_read_only.set(!this.menuPermissions.write);
        };
        this.submissiondata = { data };
        this.formFooterData.footerData = this.submissiondata.data;
        this.formFooterData = { ...this.formFooterData };

        this.form_show = true;
    };

    addSaveSubmit(status) { // null-New; 0-Saved; 1- Submitted; 2-Approved; 3-Rejected; 4-Pushedback

        // Set form as editable if the user has write permissions
        this.form_read_only.set(!this.menuPermissions.write);

        if(this.form_details.form_type != 'custom') {
    
            // Ensure that fields are added to the form if available
            if (this.data?.form_data?.fields?.length) {
                for (let field of this.data.form_data.fields) {
                    this.formdata.components[0]?.components.unshift({
                        label: field.title,
                        showValidations: false,
                        disableOnInvalid: true,
                        tableView: false,
                        key: field.title,
                        type: field.type,
                        saveOnEnter: false,
                        input: true
                    });
                }
            }
        
            // If there are custom buttons, do not proceed further
            if (this.data?.custom_buttons) {
                this.form_read_only.set(false);
                return;
            }

            const lastComponentKey = this.formdata?.components?.[this.formdata.components.length - 1]?.key;

            // Determine whether to show "Save" or "Submit" button based on status
            if (this.form_details.is_approval) {
                this.save.disableOnInvalid = false;

                if (status === 0 || status === null) {
                    if (
                        lastComponentKey !== 'submit' &&
                        lastComponentKey !== 'resubmit' &&
                        lastComponentKey !== 'save'
                    ) {
                        this.formdata.components.push(this.save);
                    }

                    if (lastComponentKey !== 'submit') {
                        this.formdata.components.push(this.submit);
                    }
                } else if (status === 4) {
                    if (
                        lastComponentKey !== 'submit' &&
                        lastComponentKey !== 'resubmit' &&
                        lastComponentKey !== 'save'
                    ) {
                        this.formdata.components.push(this.save);
                    }

                    if (lastComponentKey !== 'resubmit') {
                        this.formdata.components.push(this.resubmit);
                    }
                }
            } else {
                if (
                    lastComponentKey !== 'save' &&
                    this.formdata?.components?.[
                        this.formdata.components.length - 2
                    ]?.key !== 'save'
                ) {
                    this.formdata.components.push(this.save);
                }

                if (
                    this.form_details.form_code === 'USERS' &&
                    lastComponentKey !== 'submit'
                ) {
                    this.formdata.components.push(this.submit);
                }
            }
        }
    
        // Update the form view after a short delay
        setTimeout(() => {
            this.formdata = { ...this.formdata };
            this._changeDetectorRef.detectChanges();
        }, 1000);
        //this.changeForm();
    }



    async select_url_replace(form_data: any, is_after_submit = false) {
        const urlRes = await this._commonService.getDataByField(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            'vs_code',
            VALUE_SET_CODE_CONSTANTS.API_REGISTRY_BASE_URL
        ).toPromise();

        let string = JSON.stringify(form_data.components);
        urlRes.data.forEach(url => {
            let regex = new RegExp(`{{${url.vsd_code}}}`, 'g');
            string = string.replace(regex, url.vsd_property_1);
        });
        const token_regex = new RegExp('{{TOKEN}}', 'g');
        string = string.replace(token_regex, this.authService.access_token);
        const module_code_regex = new RegExp('{{module_code}}', 'g');
        string = string.replace(module_code_regex, this.form_module_code);
        let components = JSON.parse(string);
        return { components };
    }

    async onCustomEvent(event: any) {
        let is_taks_create = false;
        if (event.type === 'onSave') {
            if(!event?.data?.status) {
                if (this.form_details.is_approval && this.form_details.board_id) {
                    event.data.status = 0;
                } else {
                    if(this.form_details?.client){
                        event.data.status = 1;
                    }else{
                        event.data.status = 0;
                    }
                }
            }

            this.render_formSubmit(event, is_taks_create)
        }else if (event.type === 'onSubmit') {
            if(this.formdata.client){
                event.data.status = 1;
            }else{
                event.data.status = 1;
            }
            if (this.form_details.is_approval && this.form_details.board_id) {
                is_taks_create = true
            }
            this.form_read_only.set(true);
            this.changeForm();
            this.render_formSubmit(event, is_taks_create)
        } else if (event.type === 'onNotifyVendor') {
            if(event?.data?._id) {
                this.notifyVendor(event.data);
            } else {
                if(!event?.data?.status) {
                    if (this.form_details.is_approval && this.form_details.board_id) {
                        event.data.status = 0;
                    } else {
                        if(this.form_details?.client){
                            event.data.status = 1;
                        }else{
                            event.data.status = 0;
                        }
                    }
                }

                this.render_formSubmit(event, is_taks_create, this.notifyVendor)
            }
        } else if (event.type === 'displayPDF') {
            if(event.data.pdf_url) {
                this.showPdfViewer.set(true);
                setTimeout(() => {
                    const pdfViewer = document.getElementById('pdf-viewer');
                    const formPdfViewer = document.getElementById('form-pdf-viewer');
                    
                    if(pdfViewer && formPdfViewer) {
                        pdfViewer.classList.remove('hidden');
                        formPdfViewer.appendChild(pdfViewer);
                        this.pdfSrc.set(event.data.pdf_url);
                    }
                }, 500);
            }
        } else if (event.type === 'onSendAIMessage'){
            this.showLoader.set(true);
            const { instructions } = event.data;

            if(!instructions) {
                this.snackbar.error('AI Instructions is required');
                return;
            }
            
            const response = await this._commonService.sendMessageToAI(instructions);

            event.data.response = response;
            this.submissiondata = {...this.submissiondata};
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        } else if(event.type === 'onExcelJsonProcess') {
            if(event?.data?.file_urls && event?.data?.file_urls?.length) {
                this.showLoader.set(true);
                const json = await this.getExcelJSON(event.data.file_urls);
                event.data.json_data = JSON.stringify(json, null, 2);
                this.submissiondata = { ...this.submissiondata };
                setTimeout(() => {
                    this.showLoader.set(false);
                }, 1000);
            }
        } else if (event.type === 'onExcelJsonDownload') {
            this.showLoader.set(true);
            let json;
            if(event?.data?.json_data) {
                json = JSON.parse(event?.data?.json_data);
            } else if(event?.data?.file_urls && event?.data?.file_urls?.length) {
                json = await this.getExcelJSON(event.data.file_urls);
            }
            if(json && json?.length) {
                const title = event?.data?.title;
                this.downloadJson(json, title);
            } else {
                this.snackbar.error('No JSON found to download');
            }
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        } else if(event.type === 'onValidateGST') {
            if(!event.data.gstNumber){
                this.snackbar.error('Please enter GST number');
                return;
            }
            this.showLoader.set(true);
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_GST;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.gst_details = data;
            event.data.validGst = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        } else if(event.type === 'onValidatePAN') {
            if(!event.data.panNumber){
                this.snackbar.error('Please enter PAN number');
                return;
            }
            this.showLoader.set(true);
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_PAN;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.pan_details = data;
            event.data.validPan = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        } 
        // TODO: Remove this after cleartax api cross origin configuration is done
        else if (event.type === 'onValidateGSTNode') {
            if(!event.data.gstNumber){
                this.snackbar.error('Please enter GST number');
                return;
            }
            this.showLoader.set(true);
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_GST_NODE;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.gst_details = data;
            event.data.validGst = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        } 
        // TODO: Remove this after cleartax api cross origin configuration is done
        else if (event.type === 'onValidatePANNode') {
            if(!event.data.panNumber){
                this.snackbar.error('Please enter PAN number');
                return;
            }
            this.showLoader.set(true);
            const apiRegistryId = API_REGISTRY_CONSTANTS.VALIDATE_PAN_NODE;
            const data = await this.executeAPI(apiRegistryId, event.data);
            event.data.pan_details = data;
            event.data.validPan = data ? true : false;
            this.submissiondata = { ...this.submissiondata };
            setTimeout(() => {
                this.showLoader.set(false);
            }, 1000);
        }

        if(this.data.custom_buttons){
            if(event.type === 'onConfirm'){
                this.data?.close_dialog(event.data);
            }
            if(event.type === 'onCancel'){
                this.data?.close_dialog();
            }
        }
    }

    async executeAPI(apiRegistryId, apiData) {
        try {
            const apiRegistry = await this._commonService
                .getDataById(MODELS_CONSTANTS.API_REGISTRIES, apiRegistryId)
                .toPromise()
                .then((res) => res.data)
                .catch((err) => console.error(err));
    
            const response = await this._commonService
                .executeApiMethod(apiRegistry, apiData)
                .catch((err) => console.error(err));
    
            return response?.data || null;
        } catch (error) {
            return null;
        }
    }

    async getExcelJSON(file_url) {
        const requestBody = {
            file_url,
            module_code: 'AI_EXCEL_UPLOAD'
        }
        const response: any = await this._commonService.getJsonFromExcel(requestBody).toPromise().catch(err => console.error(err));

        let json;
        if(response?.data) {
            try {
                json = JSON.parse(response?.data);
            } catch (error) {
                json = response?.data;
            }
        }

        return json || [];
    }

    async changeForm() {
        this.formdata = await this.select_url_replace(this.form_details.form_data, true);
        this.refreshForm.emit({
            form: this.formdata
        });
    }

    async render_formSubmit(event: any, is_taks_create = false, notifyVendor = null) {
        let data = cloneDeep(event.data);
        const isMultipleFiles = data?.multiple_files && true;
        if(isMultipleFiles) {
            data = data?.multiple_files;
            const currentFileIds = data?.filter((e) => e?._id)?.map((e) => e?._id);
            const previousFileIds = this.submissiondata?.data?.all_data?.map((e) => e?._id);
            const fileToDeleteIds = previousFileIds?.filter((e) => !currentFileIds?.includes(e));
            if(fileToDeleteIds?.length){
                await this.delete(fileToDeleteIds);
            }
        } else {
            delete data?.all_data;
            data = [{...data, ...data?.form_params}];
        };
        let response: any;
        for(let [index, form_data] of data.entries()) {
            // let form_data = cloneDeep(event.data);
            let isAdd = !form_data?._id;
            delete form_data.save;
            delete form_data.submit;
            delete form_data.createdAt;
            delete form_data.updatedAt;
            delete form_data.created_on;
            delete form_data.updated_on;
            delete form_data.created_by;
            delete form_data.updated_by;
            
            Object.keys(form_data).map(key => {
                let { type, components } = this.find_key_to_type(this.formdata.components, key);
                let key_value: any = form_data[key];
                if (type == "file") {
                    form_data[key] =
                        key_value &&
                        key_value[0] &&
                        (key_value[0]['_id'] || key_value[0].data['_id'])
                            ? key_value[0]['_id'] || key_value[0].data['_id']
                            : null;
                }
                if (type == "datetime") {
                    if(key_value){
                        form_data[key] = moment(key_value).format("YYYY-MM-DD hh:mm")
                    }
                }
                if(type == 'datagrid') {
                    for(let doc of form_data[key]) {
                        Object.keys(doc).map((key) => {
                            if(components && components?.length) {
                                const { type } = this.find_key_to_type(components, key);
                                if(type == "file") {
                                    let doc_key_value: any = doc[key];
                                    
                                    doc[key] =
                                    doc_key_value &&
                                    doc_key_value[0] &&
                                    (doc_key_value[0]['_id'] || doc_key_value[0].data['_id'])
                                        ? doc_key_value[0]['_id'] || doc_key_value[0].data['_id']
                                        : null;
                                }
                            }
                        })
                    }
                }
            });
            if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data.vs_code) {
                form_data[this.form_details.parent_id] = this.data.parent_row_data.vs_code;
            } else if (this.form_details.parent_id && this.data.parent_row_data && this.data.parent_row_data._id) {
                form_data[this.form_details.parent_id] = this.data.parent_row_data._id;
            }
    
            if (this.form_module_code === 'form_footer') {
                if (form_data?._id) {
                    this.update(form_data)
                } else {
                    form_data['form_id'] = this.data.id;
                    this.add(form_data);
                }
            } else {
                if (this.data.params && this.data.params.length && !this.data.is_from_board) {
                    let params = this.params_value_replace(this.data.params, this.data.row_data, this.data.parent_row_data);
                    if (params && params.length) {
                        params.forEach((p: any) => {
                            form_data[p.key] = p.value;
                        });
                    }
                } else if (this.data.id && !this.data.is_from_board && !isMultipleFiles) {
                    if (this.form_module_code_category === "CORE") {
                        form_data['_id'] = this.data.id;
                    } else if (this.form_module_code_category === "VRM") {
                        form_data['id'] = this.data.id;
                    }
                }

                if (isAdd) {
                    response = await this.add(form_data, is_taks_create, notifyVendor);
                } else {
                    response = await this.update(form_data, is_taks_create);
                }
            }

            if(index === data?.length - 1 && response?.status === 200) {
                if(this.data?.close_drawer){
                    this.data?.close_drawer();
                } else if(this.data?.close_dialog) {
                    this.close()
                }
            }
        }
    }

    async add(form_data, is_taks_create = false, notifyVendor = null) {
        if (this.form_module_doc_no_flag) {
            // this.form_module_code_url = 'http://localhost:8006/v1/common/create_with_doc_no';
            if (this.form_module_code_url.endsWith('/')) {
                this.form_module_code_url +=
                    SHARED_CONSTANTS.CREATE_WITH_DOC_NO_ENDPOINT;
            } else {
                this.form_module_code_url +=
                    '/' + SHARED_CONSTANTS.CREATE_WITH_DOC_NO_ENDPOINT;
            }
        }
        const response = await this._commonService.callAPI(
            this.form_module_code_url,
            'post',
            this.form_module_code,
            form_data,
            {}
        ).toPromise()
            .catch((err) => {
                console.error(err);
                this.snackbar.error(err?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            });

        try {
            if (response?.status === 200) {
                this.submissiondata = { data: response.data };
                this.formFooterData.footerData = this.submissiondata.data;
                this.formFooterData = { ...this.formFooterData };
                this.snackbar.success(`Form added successfully`);

                if (this.data?.handle_clock) {
                    this.data?.handle_clock();
                }

                const body = {
                    form_id: this.data.form_id,
                    ref_id: response?.data?._id,
                    action: 'create',
                    remarks: 'Form Data Created',
                    where: "",
                    date_time: new Date(),
                    done_by: response?.data?.created_by || this.authService?.userInfo?._id
                };

                this._commonService.saveRecord(MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER, body)
                    .subscribe({
                        next: () => { },
                        error: (saveError) => {
                            console.error(saveError);
                            this.snackbar.error(saveError?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        }
                    });

                if (is_taks_create) {
                    this.call_create_task(form_data);
                }
                if (this.data.close_dialog) {
                    // this.data?.close_dialog(form_data);
                }
                
                if(notifyVendor) {
                    notifyVendor(response?.data);
                    this.submissiondata.data = response?.data;
                    this.submissiondata = { ...this.submissiondata };
                } else {
                    if (this.data.isReturnAddData) {
                        this.data?.close_dialog(response.data);
                    }
                }
            }
        } catch (error) {
            console.error(error);
            this.snackbar.error(error?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
        }
        return response;
    }

    async update(form_data, is_taks_create = false) {
        const response: IResponse<any> = await this._commonService.callAPI(
            this.form_module_code_url,
            'put',
            this.form_module_code,
            form_data,
            {}
        ).toPromise()
            .catch((err) => {
                console.error(err);
                this.snackbar.error(err?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            });

        try {
            if (response?.status === 200) {
                this.snackbar.success(`Form updated successfully`);

                const body = {
                    form_id: this.data.form_id,
                    ref_id: response?.data?._id,
                    action: 'update',
                    remarks: 'Form Data Updated',
                    where: "",
                    date_time: new Date(),
                    done_by: response?.data?.updated_by || this.authService?.userInfo?._id
                };

                this._commonService.saveRecord(MODELS_CONSTANTS.FORM_ACTIVITY_TRACKER, body)
                    .subscribe({
                        next: () => { },
                        error: (saveError) => {
                            console.error(saveError);
                            this.snackbar.error(saveError?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        }
                    });

                if (is_taks_create) {
                    this.call_create_task(form_data);
                }
                if (this.data.close_dialog) {
                    //this.data?.close_dialog(form_data);
                }
            }
        } catch (error) {
            console.error(error);
            this.snackbar.error(error?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
        }
        return response;
    }

    async delete(ids: string | string[]){
        let response: any;
        if(typeof ids === 'string'){
            response = await this._commonService.callAPI(
                this.form_module_code_url,
                'delete',
                this.form_module_code,
                {},
                {_id: ids}
            ).toPromise(); 
        } else {
            for(let id of ids){
                if(id) {
                    response = await this._commonService.callAPI(
                        this.form_module_code_url,
                        'delete',
                        this.form_module_code,
                        {},
                        {_id: id}
                    ).toPromise(); 
                }
            }
        }
    }

    approve() {
        this._router.navigate(['/admin/formdata-table', this.form_id]);
    }

    reject() {
        this._router.navigate(['/admin/formdata-table', this.id]);
    }

    close() {
        this.data.close_dialog();
        if (this.data?.handle_clock) {
        this.data.handle_clock = null;
        }
    }

    openComponent(action) {
        this.actionComponent = COMPONENT_REFERENCE?.[action?.action_type];
        const inputs = {};
        if (
            action?.action_type === 'api' ||
            action?.action_type === 'redirection'
        ) {
            return;
        } else if (action?.action_type === 'list_view') {
            inputs['listId'] = action?.list_view;
            inputs['row_data'] = this.data?.row_data || {};
            inputs['parent_row_data'] = this.data?.row_data || {};
        } else if (action?.action_type === 'form_render') {
            inputs['data'] = {
                id: this.data?.row_data?._id || this.data?.row_data?.id,
                form_id: action?.form_render,
                title: action?.name || action?.tooltip,
                row_data: this.data?.row_data || {},
                close_drawer: () => { this.matDrawer?.close() },
                action
            };
        } else {
            if (
                this.actionComponent?.inputs &&
                'data' in this.actionComponent?.inputs
            ) {
                inputs['data'] = {
                    id: this.data?.row_data?._id || this.data?.row_data?.id,
                    reference_type_id: action?.ref_id,
                    form_id: this.data?.form_id,
                    title: this.data?.title,
                    row_data: this.data?.row_data || {},
                    reference_id:
                        this.data?.row_data?._id || this.data?.row_data?.id,
                    reference_type: action?.ref_type
                };
                if (action?.configurations) {
                    inputs['data'] = {
                        ...inputs['data'],
                        ...action?.configurations,
                    };
                }
            } else if (
                this.actionComponent?.inputs &&
                'params' in this.actionComponent?.inputs
            ) {
                inputs['params'] = this.formFooterData.footerPermissions;
            } else if (
                this.actionComponent?.inputs &&
                'row_data' in this.actionComponent?.inputs
            ) {
                if(action?.row_data) {
                    inputs['row_data'] = action?.row_data;
                } else {
                    inputs['row_data'] = this.data?.row_data;
                }
            } else if (
                this.actionComponent?.inputs &&
                'form_data' in this.actionComponent?.inputs
            ) {
                inputs['form_data'] = {
                    row_data: this.submissiondata.data,
                    form_id: this.data?.form_id,
                };
            }
        }

        this.drawerWidth = action?.drawer_width || '100%';
        this.classType = action?.class_type || 'Icon-Size-md';
        this.actionComponent.inputs = inputs;

        if (this.actionComponent) {
            this.matDrawer.open();
        }
    }

    file_to_key(file) {
        let key = "vortex/";
        if (file.tenant_id) key += file.tenant_id;
        if (file.category) key = `${key}/${file.category}`;
        if (file.sub_category) key = `${key}/${file.sub_category}`;
        key = `${key}/${file.key}`;
        return key;
    }

    async call_create_task(form_data) {
        let task_name_fiels = this.find_key_to_tags(this.formdata.components, 'task_name');
        let task_description_fiels = this.find_key_to_tags(this.formdata.components, 'task_description');
        let payload = {
            board_id: this.form_details.board_id,
            ref_type: "form_render",
            "ref_type_id": this.data.form_id,
            "ref_id": form_data._id,
            "title": task_name_fiels && form_data[task_name_fiels] ? form_data[task_name_fiels] : this.form_details.form_name,
            "description": task_description_fiels && form_data[task_description_fiels] ? form_data[task_description_fiels] : ""
        };
        const form = await this._commonService
            .getDataByFields(MODELS_CONSTANTS.BOARD_TASK_FORM, {
                ref_type: 'form_render',
                ref_type_id: this.data.form_id,
                ref_id: form_data._id,
            })
            .toPromise()
            .then((res) => res.data[0])
            .catch((err) => console.error(err));
        if(form && form?.task_id) {
            const task = await this._commonService
                .getDataById(MODELS_CONSTANTS.BOARD_TASK, form?.task_id)
                .toPromise()
                .then((res) => res.data)
                .catch((err) => console.error(err));

            if(task) { 
                await this._commonService.saveRecord(MODELS_CONSTANTS.BOARD_TASK, { _id: task?._id, status: 1 }).toPromise();
                return;
            } 
        }
        await this._commonService.create_task(payload).toPromise();
    }

    params_value_replace(params: any = [], row_data: any = {}, parent_row_data: any = {}) {
        return params.map((item: any) => {
            let value = item.value;

            value = value.replace(/{{row_data\.(.*?)}}/g, (match, p1) => row_data[p1] || match);
            value = value.replace(/{{parent_row_data\.(.*?)}}/g, (match, p1) => parent_row_data[p1] || match);

            if (value.includes('{{')) {
                return null;
            }

            return {
                key: item.key,
                value: value
            };
        }).filter(item => item !== null);
    }

    find_file_components(components: any[]): any[] {
        let fileComponents = [];
        components.forEach(component => {
            if (component.type === 'file') {
                fileComponents.push(component);
            } else if (component.columns) {
                fileComponents = fileComponents.concat(this.find_file_components(component.columns));
            } else if (component.tabs) {
                component.tabs.forEach(tab => {
                    tab.components.forEach(tabComponent => {
                        if (tabComponent.columns) {
                            fileComponents = fileComponents.concat(this.find_file_components(tabComponent.columns));
                        } else if (tabComponent.type === 'file') {
                            fileComponents.push(tabComponent);
                        }
                    });
                });
            } else if (component.fieldset) {
                fileComponents = fileComponents.concat(this.find_file_components(component.fieldset));
            } else if (component.type === 'table') {
                component.rows.forEach(row => {
                    row.forEach(tableComponent => {
                        fileComponents = fileComponents.concat(this.find_file_components([tableComponent]));
                    });
                });
            } else if (component.type === 'panel') {
                fileComponents = fileComponents.concat(this.find_file_components(component.components));
            } else if (component.components) {
                fileComponents = fileComponents.concat(this.find_file_components(component.components));
            }
        });
        return fileComponents;
    }

    find_grid_components(components: any[]): any[] {
        const gridComponents = [];
        for(const component of components) {
            if(component.type === 'datagrid') {
                gridComponents.push(component);
            }
        }
        return gridComponents;
    }

    find_key_to_tags(components: any[], tag: any): string | null {
        for (const component of components) {
            // Check if component has tags and tags is an array
            if (component.tags && Array.isArray(component.tags)) {
                if (component.tags.includes(tag)) {
                    return component.key;
                }
            }

            // Check if component has columns
            if (component.columns) {
                const key = this.find_key_to_tags(component.columns, tag);
                if (key) {
                    return key;
                }
            }

            // Check if component has tabs
            else if (component.tabs) {
                for (const tab of component.tabs) {
                    const key = this.find_key_to_tags(tab.components, tag);
                    if (key) {
                        return key;
                    }
                }
            }

            // Check if component has a fieldset
            else if (component.fieldset) {
                const key = this.find_key_to_tags(component.fieldset, tag);
                if (key) {
                    return key;
                }
            }

            // Check if component is a table
            else if (component.type === 'table') {
                for (const row of component.rows) {
                    for (const tableComponent of row) {
                        const key = this.find_key_to_tags([tableComponent], tag);
                        if (key) {
                            return key;
                        }
                    }
                }
            }

            // Check if component is a panel
            else if (component.type === 'panel') {
                const key = this.find_key_to_tags(component.components, tag);
                if (key) {
                    return key;
                }
            }

            // Check if component has nested components
            else if (component.components) {
                const key = this.find_key_to_tags(component.components, tag);
                if (key) {
                    return key;
                }
            }
        }
        return null; // Key not found
    }

    find_key_to_type(components: any[], key: any): { type: string, components?: any } | null {
        if(components && components?.length) {
            for (const component of components) {
                if (component.key === key) {
                    const obj = { type: component.type };
                    if(component.type === 'datagrid') {
                        obj['components'] = component.components;
                    }
                    return obj;
                } else if (component.columns) {
                    const { type } = this.find_key_to_type(component.columns, key);
                    if (type) {
                        return { type };
                    }
                } else if (component.tabs) {
                    for (const tab of component.tabs) {
                        for (const tabComponent of tab.components) {
                            if (tabComponent.key === key) {
                                return { type: tabComponent.type };
                            }
                        }
                    }
                } else if (component.fieldset) {
                    const { type } = this.find_key_to_type(component.fieldset, key);
                    if (type) {
                        return { type };
                    }
                } else if (component.type === 'table') {
                    for (const row of component.rows) {
                        for (const tableComponent of row) {
                            if (tableComponent.key === key) {
                                return { type: tableComponent.type };
                            }
                        }
                    }
                } else if (component.type === 'panel') {
                    const { type } = this.find_key_to_type(component.components, key);
                    if (type) {
                        return { type };
                    }
                } else if (component.components) {
                    const { type } = this.find_key_to_type(component.components, key);
                    if (type) {
                        return { type };
                    }
                }
            }
        }
        return { type: null }; // Key not found
    }

    notifyVendor = async (vendorInfo: any) => {
        if (vendorInfo.email) {
            let userInfo: any = {};
            vendorInfo.redirect_url = '/home';
            let response: any = await this._commonService
                .vendor(vendorInfo)
                .toPromise()
                .catch((err) => {
                    console.error(err)
                });
            if (response?.status == 200 && response?.data) {
                userInfo = response?.data;
            }
            if (userInfo._id) {
                userInfo.url = environment.appURL + 'sign-in?params=' + userInfo?.params;
                const result: any = await this._commonService.mailUser(
                    userInfo._id,
                    TEMPLATES_CONSTANTS.MAIL_VENDOR,
                    { userInfo: userInfo, vendorInfo: vendorInfo }
                ).toPromise();
                if (result?.status === 200) {
                    this.snackbar.success('Notification mail sent successfully');
                }
            }
        }
    }

    zoomPdfPage(scale: number) {
        this.pageZoom.update((zoom) => zoom + scale);
    }

    resetPdfZoom(){
        this.pageZoom.set(1);
    }

    afterPdfLoaded(pdf: PDFDocumentProxy) {
        this.totalPdfPages = pdf.numPages;
    }

    onPdfProgress(progressData: PDFProgressData) {
        this.progressValue = (progressData.loaded / progressData.total) * 100;
        if (progressData.loaded === progressData.total) {
            setTimeout(() => {
                this.showPdfLoader.set(false);
                this.progressValue = 0;
            }, 1500);
        } else {
            this.showPdfLoader.set(true);
        }
    }

    changePdfPage(count: number) {
        this.currentPdfPage += count;
    }

    jumpToPage(pageNum: number){
        this.currentPdfPage = pageNum;
    }

    // Function to download JSON
    downloadJson(jsonData, title = 'jsondata') {
        const jsonString = JSON.stringify(jsonData, null, 2);

        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    }
}
