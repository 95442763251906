import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';

import { MaterialModule } from 'app/shared/material.module';
import { CommonService } from 'app/shared/services/common/common.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormRendererComponent } from 'app/modules/common/forms/forms-renderer/forms-renderer.component';
import { FORM_CONSTANTS } from 'app/shared/constants/forms.constants';
import { CommonModule } from '@angular/common';
import { IResponse } from 'app/shared/interfaces/response-i';
import { Board } from 'app/modules/features/scrumboard/scrumboard.models';
import { ScrumboardService } from 'app/modules/features/scrumboard/scrumboard.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss'],
  standalone: true,
  imports: [MaterialModule, CommonModule]
})
export class ApprovalComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Output() dataEvent: EventEmitter<any> = new EventEmitter<any>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild('pushback_dialog') pushback_dialog: TemplateRef<any>;
  
  dialogRef: MatDialogRef<DynamicFormRendererComponent>;
  show_next: boolean = false;
  enablePushbackFlag: boolean = false;
  stagePushbackFlag: boolean = false;
  isLastStage: boolean = false;
  isFirstStage: boolean = false;
  board: Board;
  boardStages: any;
  boardTasks: any;
  approvedStages: any;
  selectedStage: string;
  userInfo: any;
  taskStatus: number;

  constructor(
    private _commonService: CommonService, 
    private dialog: MatDialog, 
    private _scrumboardService: ScrumboardService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService
  ) { }

  async ngOnInit() {
    this.show_next = this.data?.show_next;
    this.userInfo = this._authService.userInfo;
    this.taskStatus = this.data?.row_data?.status;
    await this.checkStage();
    await this.getBoardProperties();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if('data' in changes){
      this.show_next = this.data?.show_next;
      this.taskStatus = this.data?.row_data?.status;
      await this.checkStage();
      await this.getBoardProperties();
    }
  }

  async getBoardProperties() {
    this._scrumboardService.board$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((board: Board) => {
        this.board = { ...board };
        this.enablePushbackFlag = !!(this.board && this.board.enable_pushback === true);
        this.stagePushbackFlag = !!(this.board && this.board.stage_pushback === true);
        this._changeDetectorRef.markForCheck();
      });

    const stageResponse: any = await this._scrumboardService.get_board_stages(this.board._id).toPromise();
    this.boardStages = stageResponse;

    const taskResponse: any = await this._scrumboardService.get_board_tasks(this.board._id).toPromise();
    this.boardTasks = taskResponse;

    this.fetchApprovedStages();
  }

  fetchApprovedStages() {
    const requestPayload: any = {
      task_id: this.data.row_data._id,
      board_id: this.data.row_data.board_id
    };

    this._commonService.getDataByMultipleField(MODELS_CONSTANTS.BOARD_TASK_APPROVAL, requestPayload).subscribe({
      next: (response: any) => {
        if (response?.status === 200) {
          const approvedStages = response?.data.filter(item => item.appr_status === 'approved');

          let approvedStageIds = approvedStages.map(stage => stage.stage_id)
          approvedStageIds = [...new Set(approvedStageIds)]
          this.approvedStages = this.boardStages.filter(stage => approvedStageIds.includes(stage._id));
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  async checkStage() {
    const stages = await this._commonService
        .getDataByField(
            MODELS_CONSTANTS.BOARD_STAGES,
            'board_id',
            this.data?.row_data?.board_id
        )
        .toPromise()
        .then((res) => res?.data);
    stages?.sort((a, b) => a.position - b.position);
    const currentStageIndex = stages?.findIndex(
        (e) => e?._id === this.data?.row_data?.stage_id
    );
    if (currentStageIndex !== -1) {
      if(currentStageIndex === 0) {
        this.isFirstStage = true
      } else if (currentStageIndex == stages?.length - 1) {
        this.isLastStage = true;
      }
    }

  }

  async approve_onclick() {
      this.dialogRef = this.dialog.open(DynamicFormRendererComponent, {
          autoFocus: false,
          maxWidth: '80vw',
          width: '50vw',
          minHeight: '30vh',
          maxHeight: '80vh',
          data: {
              close_dialog: this.close_dialog,
              form_id: FORM_CONSTANTS.CONFIRMATION,
              custom_buttons: true,
              form_data: {
                  title: 'Approve Task',
                  submitButtonLabel: 'Approve',
                  cancelButtonLabel: 'Cancel',
                  fields: [ { title: 'Remarks', type: 'textarea' } ],
              },
          },
      });

      this.dialogRef.afterClosed().subscribe(async (data) => {
        if (data) {
            let payload = {
                task_id: this.data?.row_data._id,
                status: 'approved',
                remarks: data['Remarks'],
                user_type : this.data?.user_type ? this.data?.user_type.user_type : null,
                approve_user_id : this.data?.user_type ? this.data?.user_type.user_id : null,
            };
            let res: any = await this._commonService.update_task_status(payload).toPromise();
            if (res && res.status === 200) {
              this.call_api(res.data,  "approved");
              this.emitData('success', { stageData: res?.data, status: "When Approved" });
              this.data?.close_dialog(true);
            } else {
              this.emitData('failure');
            }
        }
      })
  }

  async reject_onclick() {
    this.dialogRef = this.dialog.open(DynamicFormRendererComponent, {
      autoFocus: false,
      maxWidth: '80vw',
      width: '50vw',
      minHeight: '30vh',
      maxHeight: '80vh',
      data: {
          close_dialog: this.close_dialog,
          form_id: FORM_CONSTANTS.CONFIRMATION,
          custom_buttons: true,
          form_data: {
              title: 'Reject Task',
              submitButtonLabel: 'Reject',
              cancelButtonLabel: 'Cancel',
              fields: [ { title: 'Remarks', type: 'textarea' } ],
          },
      },
    });

    this.dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
          let payload = {
            task_id: this.data?.row_data?._id,
            status: "rejected",
            remarks: data['Remarks'],
            user_type : this.data?.user_type ? this.data?.user_type.user_type : null,
            approve_user_id : this.data?.user_type ? this.data?.user_type.user_id : null,
          };
          let res: any = await this._commonService.update_task_status(payload).toPromise();
          if (res && res.status === 200) {
            this.call_api(res.data,  "rejected");
            this.emitData('success', { stageData: res?.data, status: "When Rejected" });
            this.data?.close_dialog(true);
          } else {
            this.emitData('failure');
          }
      }
    })
  }

  async onTaskPushback() {
    if(this.isFirstStage) {
      this.pushbackToRequester();
    } else {
      if (this.stagePushbackFlag) {
        this.pushbackToSelectedStage();
      } else {
        this.dialogRef = this.dialog.open(DynamicFormRendererComponent, {
          autoFocus: false,
          maxWidth: '80vw',
          width: '50vw',
          minHeight: '30vh',
          maxHeight: '80vh',
          data: {
            close_dialog: this.close_dialog,
            form_id: FORM_CONSTANTS.CONFIRMATION,
            custom_buttons: true,
            form_data: {
              title: 'Pushback Task',
              submitButtonLabel: 'Pushback',
              cancelButtonLabel: 'Cancel',
              fields: [{ title: 'Remarks', type: 'textarea' }],
            },
          },
        });
    
        this.dialogRef.afterClosed().subscribe(async (dialogData) => {
          if (dialogData) {
            this.pushbackOnClick(dialogData); 
          }
        });
      }
    }
  }

  async pushbackToRequester() {
    try {
      const task_id: string = this.data.row_data._id;
      const taskForms = await this._commonService
          .getDataByFields(MODELS_CONSTANTS.BOARD_TASK_FORM, {
              task_id,
          })
          .toPromise()
          .then((res) => res.data || [])
          .catch((err) => console.error(err));
  
      for(let form of taskForms) {
        if(form?.ref_type === 'form_render') {
          const formDetails = await this._commonService
              .getDataById(MODELS_CONSTANTS.FORM_SETP, form?.ref_type_id)
              .toPromise()
              .then((res) => res.data)
              .catch((err) => console.error(err));
  
          const moduleDetails = await this._commonService
              .getDataByField(
                  MODELS_CONSTANTS.MODULES,
                  'module_id',
                  formDetails.module_id
              )
              .toPromise()
              .then((res) => res.data[0])
              .catch((err) => console.error(err));
          
          const module_code = moduleDetails?.module_code;
  
          const data = await this._commonService
              .getDataById(module_code, form?.ref_id)
              .toPromise()
              .then((res) => res.data)
              .catch((err) => console.error(err));
          
          await this._commonService.saveRecord(module_code, { _id: data?._id, status: 0 }).toPromise();
        }
      }
      const res = await this._commonService.saveRecord(MODELS_CONSTANTS.BOARD_TASK, { _id: task_id, status: 0 }).toPromise();
      if (res && res.status === 200) {
        this.call_api(res.data,  "requester_pushback");
        // this.emitData('success', { stageData: res?.data, status: "When Requester Pushback" });
        this.data?.close_dialog(true);
      } else {
        // this.emitData('failure');
      }
    } catch (error) {
      console.error(error)
    }
  }
  
  async pushbackOnClick(dialogData?: any) {
    let payload = {
      task_id: this.data?.row_data?._id,
      status: "pushback",
      remarks: this.stagePushbackFlag ? "" : dialogData?.Remarks, 
      user_type: this.data?.user_type?.user_type || null,
      approve_user_id: this.data?.user_type?.user_id || null,
    };

    if (this.stagePushbackFlag) {
      if(this.selectedStage == 'requester') {
        this.pushbackToRequester();
        return;
      } else {
        payload['stage_id'] = this.selectedStage;
      }
    }
  
    try {
      let res: any = await this._commonService.update_task_status(payload).toPromise();
  
      if (res && res.status === 200) {
        this.call_api(res.data, "pushback");
        this.emitData('success', { stageData: res?.data, status: "On Pushback" });
        this.data?.close_dialog(true);
      } else {
        this.emitData('failure');
      }
    } catch (error) {
      this.emitData('failure');
    }
  }
  
  async pushbackToSelectedStage() {
    this.dialogRef = this.dialog.open(this.pushback_dialog, {
      autoFocus: false,
      maxWidth: '80vw',
      width: '50vw',
      minHeight: '30vh',
      maxHeight: '80vh',
      data: {
        close_dialog: this.close_dialog
      },
    });
  
    this.dialogRef.afterClosed().subscribe(async (dialogData) => {
      if (dialogData) {
        this.pushbackOnClick(dialogData); 
      }
    });
  }

  closeDialog(){
    this.dialogRef.close()
  }
  

  async next_stage_onclick() {
    // const stages = await this._commonService
    //     .getDataByField(
    //         MODELS_CONSTANTS.BOARD_STAGES,
    //         'board_id',
    //         this.data?.row_data?.board_id
    //     )
    //     .toPromise()
    //     .then((res) => res?.data);
    // stages?.sort((a, b) => a.position - b.position);
    // const currentStageIndex = stages?.findIndex((e) => e?._id === this.data?.row_data?.stage_id);
    // if(currentStageIndex !== -1 && currentStageIndex !== stages?.length - 1) {
    //   const nextStageId = stages[currentStageIndex + 1]?._id;
    //   const requestBody = { _id: this.data?.row_data?._id, stage_id: nextStageId };
    //   const result: IResponse<any> = await this._commonService.saveRecord(MODELS_CONSTANTS.BOARD_TASK, requestBody).toPromise();
    //   if(result?.status === 200){
    //     this.data?.close_dialog(true);
    //   }
    // }

    let payload = {
      task_id: this.data?.row_data._id,
      status: 'approved',
      user_type : 'user',
      approve_user_id : this.userInfo._id,
    };

    let res: any = await this._commonService.update_task_status(payload).toPromise();

    if (res && res.status === 200) {
      this.call_api(res.data,  "approved");
      this.emitData('success', { stageData: res?.data, status: "When Approved" });
      this.data?.close_dialog(true);
    } else {
      this.emitData('failure');
    }
  }

  close_dialog = (data?: any) => {
    this.dialogRef.close(data);
  }

  emitData(message:string, data?: any) {
    this.dataEvent.emit({message, data});
  }

  async call_api(data: any, status: any ){
    let { from_stage, to_stage } = data;
    let call_apis_array: any[] = [];

    if (from_stage && from_stage._id) {
      let filtered: any[] = from_stage.actions.filter((a: any) =>
        a.action_type === "api" && (a.stage_trigger === "complete" || a.stage_trigger === status)
      );
      call_apis_array.push(...filtered);
    }
    
    if (to_stage && to_stage._id) {
      let filtered: any[] = to_stage.actions.filter((a: any) => a.stage_trigger === "init");
      call_apis_array.push(...filtered);
    }

    let response = await Promise.all(
        call_apis_array.map(async (c) => {
            let result = await this._commonService.getDataByField(MODELS_CONSTANTS.API_REGISTRIES, '_id', c.api).toPromise();
            if( result?.status === 200 && result?.data.length > 0){
              return result.data[0];
            }
        })
    );

    await Promise.all(
        response.map(async (a) => {
            let payload = this.data?.row_data;
            try {
                await this._commonService.executeApiMethod(a, payload);
            } catch (error) {
              console.error(error);
            }
        })
    );

    await Promise.all(
      response.map( async (a) =>{
        let payload = {
          task_id : this.data?.row_data?._id,
          action : a.api_name,
          remarks : a.api_desc,
          where : "",
          date_time : new Date(),
          done_by : "system"
        }
        await this._commonService.saveRecord(
            MODELS_CONSTANTS.TASK_ACTIVITY_TRACKER,
            payload
        ).toPromise();
      })
    );
  }
}
