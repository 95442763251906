import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';

@Component({
    selector       : 'tenants-security',
    templateUrl    : './security.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatButtonModule],
})
export class TenantsSecurityComponent implements OnInit
{
    @Input() row_data: any;
    securityForm: UntypedFormGroup;
    tenantId: any;
    datasource: any;

    /**
     * Constructor
     */
    constructor(
        private _commonService: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private _snackbar:SnackbarService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        if (this.row_data && this.row_data._id) {
            this.tenantId = this.row_data._id
        }
        this.securityForm = this.fb.group({
            // currentPassword  : [''],
            // newPassword      : [''],
            two_step_authentication: [false],
            password_change: [false],
        });
        if(this.tenantId){
            this._commonService.getDataById(MODELS_CONSTANTS.TENANTS,this.tenantId).subscribe((response:any)=>{
                this.datasource = response.data;
                this.securityForm.reset(this.datasource);
                this._changeDetectorRef.markForCheck();
            });
        }
        else {
            this.securityForm.reset();
            this._changeDetectorRef.markForCheck();
        }
    }

    save() {
        if (this.securityForm.valid) {
            const formData = this.securityForm.value;
            //formData['status'] = formData?.status ? 1 : 0;
            if (this.tenantId) {
                formData['_id'] = this.tenantId;
            }
            formData['tenant_id'] = this.tenantId;
            this._commonService.saveRecord(MODELS_CONSTANTS.TENANTS, formData).subscribe({
                    next: (response) => {
                        if (response?.status === 200) {
                            //this.dialogRef?.close();
                            const type = formData?._id ? 'modified' : 'added';
                            this._snackbar.success(
                                `Tenants ${type} successfully`
                            );
                        } else {
                            this._snackbar.error(
                                response?.message ||
                                    MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                            );
                        }
                    },
                    error: (err) => {
                        console.error(err);
                        this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    },
                });
        } else {
            this._snackbar.warning('Please fill the form');
        }
      }
}
