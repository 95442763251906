import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { FuseCardComponent } from '@fuse/components/card';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MaterialModule } from 'app/shared/material.module';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  standalone: true,
  animations : fuseAnimations,
  imports: [FormsModule, ReactiveFormsModule, MatDialogClose, MatSlideToggleModule, AngularEditorModule, MatButtonToggleModule, MatTableModule, MatMenuModule, MatFormFieldModule, MatIconModule, MatInputModule, CommonModule,
    TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatDatepickerModule, MatTooltip, MatDividerModule, MatIconModule, MaterialModule, FuseCardComponent, ],
})
export class AnnouncementsComponent  implements OnInit {
  
  searchView: 'Tile' | 'Grid' = 'Tile';
  dataSource: any[] = [];
  filteredDataSource: any[] = [];
  selectedValue: string = 'Show All';
  filterOptions: string[] = ['Show All', 'This Week', 'Previous Week', 'Last 15 Days', 'Previous Month'];


  @ViewChild('call_ProjectDialog', { static: false }) call_ProjectDialog: TemplateRef<any>;
  anounceTitle: any;
  announcementData: any;
  tags: any;
  constructor(
    public dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    private _commonService: CommonService,
  ) {}

  ngOnInit() : void {
    this.getAllMenus();
  }


  getAllMenus() {
    this._commonService
      .getAllData(MODELS_CONSTANTS.ANNOUNCEMENT)
      .subscribe({
        next: (response: IResponse<any[]>) => {
          if (response?.status === 200) {
            this.dataSource = response?.data;
            this.applyFilter(''); // Initialize filtered data
            console.log('Announcements',this.dataSource );
            this._changeDetectorRef.markForCheck();
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  applyFilter(filterValue: string): void {
    const filter = filterValue.trim().toLowerCase();
    this.filteredDataSource = this.dataSource.filter(element =>
      element.title.toLowerCase().includes(filter) ||
      element.description.toLowerCase().includes(filter) ||
      (element.start_date && new Date(element.start_date).toLocaleDateString().toLowerCase().includes(filter)) ||
      (element.end_date && new Date(element.end_date).toLocaleDateString().toLowerCase().includes(filter))
    );
  }


  applyDateFilter(filterOption: string): void {
    const now = new Date();
    let filteredData = this.dataSource;

    if (filterOption === 'This Week') {
      const startOfWeek = new Date(now);
      startOfWeek.setDate(now.getDate() - now.getDay()); // Go to the beginning of the current week (Sunday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Current week's Saturday

      filteredData = filteredData.filter(element => {
        const startDate = new Date(element.start_date);
        return startDate >= startOfWeek && startDate <= endOfWeek;
      });
    } else if (filterOption === 'Previous Week') {
      const startOfPreviousWeek = new Date(now);
      startOfPreviousWeek.setDate(now.getDate() - now.getDay() - 7); // Go back to the previous Sunday
      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Previous week's Saturday

      filteredData = filteredData.filter(element => {
        const startDate = new Date(element.start_date);
        return startDate >= startOfPreviousWeek && startDate <= endOfPreviousWeek;
      });
    } else if (filterOption === 'Last 15 Days') {
      const fifteenDaysAgo = new Date(now);
      fifteenDaysAgo.setDate(now.getDate() - 15);

      filteredData = filteredData.filter(element => {
        const startDate = new Date(element.start_date);
        return startDate >= fifteenDaysAgo && startDate <= now;
      });
    } else if (filterOption === 'Previous Month') {
      const startOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const endOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0);

      filteredData = filteredData.filter(element => {
        const startDate = new Date(element.start_date);
        return startDate >= startOfPreviousMonth && startDate <= endOfPreviousMonth;
      });
    } else if (filterOption === 'Show All') {
      filteredData = this.dataSource;
    }

    this.filteredDataSource = filteredData;
    this._changeDetectorRef.markForCheck();
  }



  callProjectDialog(data) {
    this.announcementData = data
    console.log('announcementData', this.announcementData);
    if (typeof this.announcementData.tags === 'string') {
      this.tags = this.announcementData.tags.split(',').map(tag => tag.trim());
    } else {
        this.tags = [];
    }
    let dialogRef = this.dialog.open(this.call_ProjectDialog, {
      width: '800px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'Custom-container1'
    });
  }
  
}
