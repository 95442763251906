import { TextFieldModule } from '@angular/cdk/text-field';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, Input, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatMenuModule } from '@angular/material/menu';
import moment from 'moment';
import { TaskViewComponent } from '../task-view/task-view.component';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
    selector: 'project-tasks',
    templateUrl: './tasks.component.html',
    styleUrl: './tasks.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    // providers: [DatePipe],
    imports: [FormsModule, ReactiveFormsModule, MatTableModule, MatMenuModule, MatFormFieldModule, MatIconModule, MatInputModule, CommonModule,
        TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatDatepickerModule, MatTooltip, MatSortModule],
})

export class TasksComponent implements OnInit {
    @Input() row_data: any;
    @ViewChild('labelTemplate', { static: false }) labelTemplate: TemplateRef<any>;
    @ViewChild('taskTemplate', { static: false }) taskTemplate: TemplateRef<any>;
    taskForm: FormGroup;
    configForm: FormGroup;
    addEditTaskHeader: string = 'Add Task';
    dialogRef: MatDialogRef<any>;
    sortDirection: boolean = false;
    sortKey: string = '';
    @ViewChild(MatSort) sort: MatSort;

    taskId;
    projectId;
    dataSource;
    projects;
    users;
    statusList;
    pointsList;
    milestone;
    taskDate;
    workingHours;
    displayedColumns: string[] = [
        'task_title',
        'project_name',
        'assing_to_name',
        'task_status',
        'actions'
    ];

    labelColor = '#83c340';
    labelId;
    editFlag = false;
    labelForm;
    labelList;
    labelColorPicker = [
        { id: 1, colorName: 'green', colorCode: '#83c340', colorFlag: true },
        { id: 2, colorName: 'lightblue-1', colorCode: '#29c2c2', colorFlag: false },
        { id: 3, colorName: 'darkblue-1', colorCode: '#2d9cdb', colorFlag: false },
        { id: 4, colorName: 'grey', colorCode: '#aab7b7', colorFlag: false },
        { id: 5, colorName: 'yellow', colorCode: '#f1c40f', colorFlag: false },
        { id: 6, colorName: 'orange', colorCode: '#e18a00', colorFlag: false },
        { id: 7, colorName: 'red', colorCode: '#e74c3c', colorFlag: false },
        { id: 8, colorName: 'pink', colorCode: '#d43480', colorFlag: false },
        { id: 9, colorName: 'purple', colorCode: '#ad159e', colorFlag: false },
        { id: 10, colorName: 'lightblue-2', colorCode: '#37b4e1', colorFlag: false },
        { id: 11, colorName: 'darkblue-2', colorCode: '#34495e', colorFlag: false },
        { id: 12, colorName: 'lightpurple', colorCode: '#dbadff', colorFlag: false },
    ];

    constructor(
        private _commonService: CommonService,
        private fb: FormBuilder,
        private _snackbar: SnackbarService,
        private dialog: MatDialog,
        private _fuseConfirmationService: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private snackbar: SnackbarService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        if (this.row_data && this.row_data._id) {
            this.projectId = this.row_data._id
        } else {
            this.route.params.subscribe((params: Params) => {
                this.projectId = params['id'];
            });
        }
        this.taskForm = this.fb.group({
            task_title: [''],
            task_description: [''],
            start_date: [''],
            end_date: [''],
            project_id: [''],
            points: [''],
            milestone_id: [''],
            assing_to: [''],
            collaborators: [''],
            task_status: [''],
            label_id: [''],
        });
        this.labelForm = this.fb.group({
            labelName: [''],
        })
        this.getAllData();
        this.getAllLabels();
    }

    sortData(column: string) {
        if (this.sortKey === column) {
          this.sortDirection = !this.sortDirection;
        } else {
          this.sortKey = column;
          this.sortDirection = false;
        }
        
        if (this.sort && column && this.dataSource.length > 1) {
          this.dataSource.sort((a, b) => {
              const valA = a[column];
              const valB = b[column];
    
              if (valA < valB) {
                  return this.sortDirection ? 1 : -1;
              } else if (valA > valB) {
                  return this.sortDirection ? -1 : 1;
              } else {
                  return 0;
              }
          });
        }
    
        this.dataSource = this.dataSource.slice();
    
        this._changeDetectorRef.detectChanges();
      }

    async getAllData() {
        const userResult = await this._commonService.getAllData(MODELS_CONSTANTS.USERS).toPromise();
        if (userResult?.status === 200) {
            this.users = userResult?.data || [];
        }
        const projectResult = await this._commonService.getAllData(MODELS_CONSTANTS.PROJECT).toPromise();
        if (projectResult?.status === 200) {
            this.projects = projectResult?.data || [];
        }
        const milestoneResult = await this._commonService.getDataByField(MODELS_CONSTANTS.MILESTONE, 'project_id', this.projectId).toPromise();
        if (milestoneResult?.status === 200) {
            this.milestone = milestoneResult?.data || [];
        }
        this.getAllTasks();
        const statusResult = await this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'TASK_STATUS').toPromise();
        if (statusResult?.status === 200) {
            this.statusList = statusResult?.data || [];
        }
        const pointsResult = await this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'POINTS').toPromise();
        if (pointsResult?.status === 200) {
            this.pointsList = pointsResult?.data || [];
        }
    }

    async getAllLabels() {
        let response: any = await this._commonService.getDataByField(MODELS_CONSTANTS.LABEL_MASTER, 'labelType', 'tasks').toPromise();
        if (response.status == 200) {
            this.labelList = response?.data;
        }
        this._changeDetectorRef.markForCheck();
    }

    getAllTasks() {
        let data: any = {
            project_id: this.projectId,
            task_type: 'Task'
        }
        this._commonService.getDataByMultipleField(MODELS_CONSTANTS.PROJECT_TASKS, data).subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.dataSource = response?.data;
                    this.dataSource.forEach(element => {
                        let project = this.projects.find((item) => item._id == element.project_id)
                        let user = this.users.find((item) => item._id == element.assing_to)
                        if (project) {
                            element.project_name = project.projectTitle;
                        }
                        if (user) {
                            element.assing_to_name = user.name;
                        }
                    });
                    console.log(response?.data);
                    this._changeDetectorRef.detectChanges();
                }
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    addTask() {
        this.addEditTaskHeader = 'Add Task';
        this.taskForm.reset();
        this.taskId = null;
        this.dialogRef = this.dialog.open(this.taskTemplate, {
            autoFocus: false,
            width: '70vw',
            maxHeight: '100vh',
        });
        // this.dialogRef.afterClosed().subscribe(result => {
        // });
    }

    modifyTask(rowData) {
        // rowData.status = rowData?.status == 1 ? true : false;
        this.addEditTaskHeader = 'Edit Task';
        this.taskForm.reset(rowData);
        this.taskId = rowData?._id;
        this.dialogRef = this.dialog.open(this.taskTemplate, {
            autoFocus: false,
            width: '70vw',
            maxHeight: '90vh',
        });
    }

    save() {
        console.log("taskForm", this.taskForm.value);
        if (this.taskForm.valid) {
            const formData = this.taskForm.value;
            formData['task_type'] = 'Task';
            formData['project_id'] = this.projectId;
            // formData['status'] = formData?.status ? 1 : 0;
            if (this.taskId) {
                formData['_id'] = this.taskId;
            }
            this._commonService.saveRecord(MODELS_CONSTANTS.PROJECT_TASKS, formData).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.dialogRef?.close();
                        const type = formData?._id ? 'modified' : 'added';
                        this._snackbar.success(
                            `Task ${type} successfully`
                        );
                        this.getAllData();
                        this.taskId = null;
                    } else {
                        this._snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        } else {
            this._snackbar.warning('Please fill the form');
        }
    }

    deleteTask(rowData) {
        this.configForm = this.fb.group({
            title: 'Remove Data',
            message: 'Are you sure you want to remove permanently? <span class="font-medium">This action cannot be undone!</span>',
            icon: this.fb.group({
                show: true,
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn'
            }),
            actions: this.fb.group({
                confirm: this.fb.group({
                    show: true,
                    label: 'Remove',
                    color: 'warn'
                }),
                cancel: this.fb.group({
                    show: true,
                    label: 'Cancel'
                })
            }),
            dismissible: true

        });

        // Open the dialog and save the reference of it
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);
        dialogRef.afterClosed().subscribe((result) => {
            if (result == "confirmed") {
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.PROJECT_TASKS, rowData?._id)
                    .subscribe({
                        next: (response: any) => {
                            if (response?.status === 200) {
                                this._snackbar.success(
                                    'Task deleted successfully'
                                );
                                this.getAllData();
                            } else {
                                this._snackbar.error(
                                    response?.message ||
                                    MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                                );
                            }
                        },
                        error: (err) => {
                            console.error(err);
                            this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        },
                    });
            }
        });
    }

    viewTask(data) {
        this.dialogRef = this.dialog.open(TaskViewComponent, {
            data: {
                projectId: data.project_id,
                taskId: data._id,
            },
            width: '100vw',
            maxWidth:'100vw',
            height:'100vh',
            panelClass: 'my-dialog',
        });
        this._changeDetectorRef.markForCheck();
    }

    addLabelData() {
        this.labelColorPicker.forEach(element => {
            element.colorFlag = false;
        });
        this.labelColorPicker[0].colorFlag = true;
        this.labelColor = this.labelColorPicker[0].colorCode;
        this.labelId = '';
        this.labelForm.reset();
        this.editFlag = false;
    }

    openLableDialog() {
        this.dialogRef = this.dialog.open(this.labelTemplate, {
            autoFocus: false,
            width: '70vw',
            maxHeight: '90vh',
        });
    }

    colorPicker(code) {
        this.labelColor = code.toString();
        let response: any = this.labelColorPicker.filter((element) => element.colorCode == code)
        this.labelColorPicker.forEach(element => {
            if (response[0].colorCode != element.colorCode) {
                element.colorFlag = false;
            }
            else {
                element.colorFlag = true;
            }
        });
    }

    editLabelData(data: any) {
        this.editFlag = true;
        this.labelId = data._id;
        this.labelForm.setValue({
            labelName: data?.labelName,
        })
        this.labelColor = data?.labelColor;
        let response: any = this.labelColorPicker.filter((element) => element?.colorCode == data?.labelColour)
        this.labelColorPicker.forEach(element => {
            if (response[0]?.colorCode != element?.colorCode) {
                element.colorFlag = false;
            }
            else {
                element.colorFlag = true;
            }
        });
    }

    async saveLabel() {
        let json = this.labelForm?.value;
        json.labelType = 'tasks';
        json.labelColour = this.labelColor;
        if (this.labelId)
            json._id = this.labelId;
        const eventResponse = await this._commonService.saveRecord(MODELS_CONSTANTS.LABEL_MASTER, json).toPromise();
        if (eventResponse?.status == 200) {
            this.getAllLabels();
            this.labelForm.reset();
            const type = json?._id ? 'modified' : 'added';
            this._snackbar.success(`Labels ${type} successfully`);
        }
    }

    async deleteLabelData() {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete Label',
            message: 'Are you sure you want to delete this Label? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
                cancel: {
                    label: 'Cancel'
                }
            }
        });
    
        confirmation.afterClosed().subscribe(async (result) => {
            if (result === 'confirmed') {
                    const eventResponse = await this._commonService.deleteRecordsById(MODELS_CONSTANTS.LABEL_MASTER, this.labelId).toPromise();
                    if (eventResponse.status === 200) {
                        this.labelForm.reset();
                        this.getAllLabels();
                        this._snackbar.success('Labels deleted successfully');
                    }
            }
        });
    }
    
    closeDialog() {
        this.dialogRef.close();
    }

}
