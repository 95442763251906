<div class="ng-container" style="width: inherit;overflow: hidden;">
  <div style="background-color: #ffffff;padding-left: 15px;">
    <div class="w-full flex flex-row header-section">
      <div class="header-section">
        <div class="header">
          {{FormHeader}}
        </div>
      </div>
      <div class="flex justify-end cencel-dialog-icon ml-auto header">
        <div [ngClass]="dialogData && dialogData?.title ? 'mr-8' : 'mr-0'">
          <button mat-flat-button color="primary" class="api-primary-btn ml-2"
            (click)="save_form();$event.preventDefault()">
            Save
          </button>
        </div>
        <mat-icon *ngIf="dialogData && dialogData?.title" class="api-primary-btn cursor-pointer mt-2"
          [matTooltip]="'Close'" (click)="close()">close</mat-icon>
      </div>
    </div>

    <div class="scrollable-content">
      <ng-container *ngTemplateOutlet="tabContent"></ng-container>
    </div>

  </div>
</div>

<ng-template #tabContent>
  <div>
    <form [formGroup]="formFrom">
      <div class="w-full">
        <div class="min-[700px]:flex w-full">
          <div class="min-[700px]:w-2/4 pr-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Code</mat-label>
              <input matInput type="text" formControlName="form_code">
            </mat-form-field>
          </div>
          <div class="min-[700px]:w-2/4 pr-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="form_name">
            </mat-form-field>
          </div>
          <div class="min-[700px]:w-2/4 pr-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Module</mat-label>
              <mat-select formControlName="module_id">
                <mat-option [value]="">--</mat-option>
                <mat-option *ngFor="let item of modules_array; let i=index" [value]="item.module_id">
                  {{item.module_code}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="min-[700px]:flex w-full">
          <div class="min-[700px]:w-2/4 pr-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Version</mat-label>
              <mat-select formControlName="current_version">
                <mat-option [value]="">--</mat-option>
                <mat-option *ngFor="let item of version_list; let i=index" [value]="item.current_version">
                  {{item.current_version}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="min-[700px]:w-2/4 pr-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Init Action</mat-label>
              <mat-select formControlName="init_action">
                <mat-option [value]="">--</mat-option>
                <mat-option *ngFor="let item of api_registries; let i=index" [value]="item._id">
                  {{item.api_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="min-[700px]:w-2/4 pr-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Parent ID</mat-label>
              <input matInput type="text" formControlName="parent_id">
            </mat-form-field>
          </div>
        </div>
        <div class="min-[700px]:flex w-full">
          <div class="min-[700px]:w-2/6 pr-1" *ngIf="formFrom.value.form_type != 'custom'">
            <div class="w-full">
              <mat-label>Approval</mat-label>
              <mat-radio-group formControlName="is_approval" color="primary">
                  <mat-radio-button [value]="true"><label class="mt-4">Yes</label></mat-radio-button>
                  <mat-radio-button [value]="false"><label class="mt-4">No</label></mat-radio-button>
              </mat-radio-group>
          </div>
          </div>
          <div class="min-[700px]:w-2/6 pr-1" *ngIf="formFrom.get('is_approval').value == true">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Board</mat-label>
              <mat-select formControlName="board_id">
                <mat-option [value]="">--</mat-option>
                <mat-option *ngFor="let item of boards; let i=index" [value]="item._id">
                  {{item.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="min-[700px]:w-4/6 pr-1">
            <div class="w-full">
              <mat-label>Form Type</mat-label>
              <mat-radio-group formControlName="form_type" color="primary">
                  <mat-radio-button [value]="'custom'"><label class="mt-4">Custom</label></mat-radio-button>
                  <mat-radio-button [value]="'standard'"><label class="mt-4">Standard</label></mat-radio-button>
              </mat-radio-group>
          </div>
          </div>
          <div class="ml-auto">
            <button mat-mini-fab [matTooltip]="'AI Prompt'" color="primary" class="shadow-md ml-2" (click)="openPrompt()">
              <mat-icon class="custom-icon">psychology</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
    <br>
    <!-- <div class="w-full flex justify-end">
      <button mat-flat-button color="primary" class="api-primary-btn ml-2"
        (click)="save_form();$event.preventDefault()">
        Save
      </button>
    </div> -->
  </div>
  <div>
    <form-builder [form]="form" (change)="onChange($event)" (customEvent)="onCustomEvent($event)"> </form-builder>
  </div>
</ng-template>

<ng-template #prompt_dialog>
  <div class="overflow-hidden">
    <form [formGroup]="promtForm">
      <div class="w-full flex flex-row add-modal-header">
        <div class="mt-2">Prompt</div>
        <div class="flex flex-row ml-auto justify-end">
          <mat-icon class="mt-2 cursor-pointer" [matTooltip]="'Close'"
            (click)="closeDialog(); $event.preventDefault()">close</mat-icon>
        </div>
      </div>

      <div class="row col-12">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Module</mat-label>
          <input type="text"
           placeholder="Select a module"
           aria-label="Module"
           matInput
           formControlName="module_code"
           [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            @for (module of filteredModules | async; track $index) {
              <mat-option [value]="module?.module_code">{{module?.module_code}}</mat-option>
            }
          </mat-autocomplete>

          <!-- <mat-select formControlName="module_code">
            @for (module of modules_array; track $index) {
              <mat-option [value]="module?.module_code">{{module?.module_code}}</mat-option>
            }
          </mat-select> -->
        </mat-form-field>
      </div>

      <div class="row col-12">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Instructions</mat-label>
          <textarea matInput formControlName="prompt_text" [placeholder]="'Enter Prompt'" rows="5">
          </textarea>
        </mat-form-field>
      </div>

      <div class="row col-12">
        <section class="example-section">
          <mat-checkbox color="primary" formControlName="is_override_settings">Override existing settings</mat-checkbox>
          <mat-checkbox color="primary" formControlName="is_remove_fields">Remove fields</mat-checkbox>
        </section>
      </div>
    </form>
    <div class="w-full flex justify-end">
      <button mat-flat-button class="primary-btn px-4 py-2" (click)="executePrompt()" [disabled]="isPromptSubmitDisabled">
        <mat-icon class="custom-icon">launch</mat-icon>
        <span class="ml-2 mr-1">Execute</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #confirm_dialog>
  <div>
    <ng-container>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <ng-container>
          <button mat-stroked-button [matDialogClose]="'update'" [color]="'primary'">
            Update Selected Version!
          </button>
        </ng-container>

        <ng-container>
          <button mat-flat-button [matDialogClose]="'new'" [color]="'primary'">
            Create New Version!
          </button>
        </ng-container>

        <ng-container>
          <button mat-stroked-button [matDialogClose]="'update&avtive'" [color]="'primary'">
            Update Selected Version & Set Active Version?
          </button>
        </ng-container>

        <ng-container>
          <button mat-flat-button [matDialogClose]="'new&active'" [color]="'primary'">
            Create New Version & Set Active Version?
          </button>
        </ng-container>

      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #confirm_module_dialog>
  <div>
    <ng-container>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <ng-container>
          <button mat-stroked-button [matDialogClose]="'new'" [color]="'primary'">
            Do you want to create a new Module?
          </button>
        </ng-container>

        <ng-container>
          <button mat-flat-button [matDialogClose]="'old'" [color]="'primary'">
            Do you want to select old Module?
          </button>
        </ng-container>

      </div>
    </ng-container>
  </div>
</ng-template>

<div class="z-10 absolute top-0 left-0 h-full w-full flex justify-center items-center bg-black bg-opacity-50" *ngIf="showLoader()">
  <mat-spinner class="z-10" [diameter]="50" color="primary"></mat-spinner>
</div>