import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Chat } from 'app/layout/common/quick-chat/quick-chat.types';
import { ChatService } from 'app/modules/features/chat/chat.service';
import {
    BehaviorSubject,
    map,
    Observable,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuickChatService {
    // private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    // private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>(null);

    /**
     * Constructor
     */
    constructor(private _chatService: ChatService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat> {
        return this._chatService.chat$;
    }

    /**
     * Getter for chat
     */
    get chats$(): Observable<Chat[]> {
        return this._chatService.chats$;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chats
     */
    getChats(): Observable<any> {
        return this._chatService.getChats();
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any> {
        return this._chatService.getChatById(id);
    }

    /**
     * Send a message
     * 
     * @param body 
     * @returns 
     */
    sendMessage(body: any) {
        return this._chatService.sendMessage(body);
    }
}
