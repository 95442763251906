<div class="fuse-vertical-navigation-wrapper">

    <!-- Header -->
    <div class="fuse-vertical-navigation-header">
        <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
    </div>

    <!-- Content -->
    <div class="fuse-vertical-navigation-content" fuseScrollbar
        [fuseScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}" #navigationContent>

        <!-- Content header -->
        <div class="fuse-vertical-navigation-content-header">
            <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
        </div>

        <div class="fuse-vertical-navigation-content-header-user">
            <ng-content select="[fuseVerticalNavigationContentHeaderUserInfo]"></ng-content>
        </div>

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside'">
                    <fuse-vertical-navigation-aside-item [item]="item" [name]="name" [activeItemId]="activeAsideItemId"
                        [autoCollapse]="autoCollapse" [skipChildren]="true"
                        (click)="toggleAside(item)"></fuse-vertical-navigation-aside-item>
                </ng-container>

                <!-- Basic -->
                <ng-container *ngIf="item.type === 'basic'">
                    <fuse-vertical-navigation-basic-item [item]="item"
                        [name]="name"></fuse-vertical-navigation-basic-item>
                </ng-container>

                <!-- Collapsable -->
                <ng-container *ngIf="item.type === 'collapsable'">
                    <fuse-vertical-navigation-collapsable-item [item]="item" [name]="name"
                        [autoCollapse]="autoCollapse"></fuse-vertical-navigation-collapsable-item>
                </ng-container>

                <!-- Divider -->
                <ng-container *ngIf="item.type === 'divider'">
                    <fuse-vertical-navigation-divider-item [item]="item"
                        [name]="name"></fuse-vertical-navigation-divider-item>
                </ng-container>

                <!-- Group -->
                <ng-container *ngIf="item.type === 'group'">
                    <fuse-vertical-navigation-group-item [item]="item" [name]="name"
                        [autoCollapse]="autoCollapse"></fuse-vertical-navigation-group-item>
                </ng-container>

                <!-- Spacer -->
                <ng-container *ngIf="item.type === 'spacer'">
                    <fuse-vertical-navigation-spacer-item [item]="item"
                        [name]="name"></fuse-vertical-navigation-spacer-item>
                </ng-container>

            </ng-container>

        </ng-container>

        <!-- Content footer -->
        <div class="fuse-vertical-navigation-content-footer">
            <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
        </div>

    </div>

    <!-- Footer -->
    <div class="fuse-vertical-navigation-footer">
        <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
    </div>

</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
    <div class="fuse-vertical-navigation-aside-wrapper" fuseScrollbar
        [fuseScrollbarOptions]="{wheelPropagation: false, suppressScrollX: true}" [@fadeInLeft]="position === 'left'"
        [@fadeInRight]="position === 'right'" [@fadeOutLeft]="position === 'left'"
        [@fadeOutRight]="position === 'right'">

        <!-- Items -->
        <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

            <!-- Skip the hidden items -->
            <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
                <!-- Aside -->
                <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                    <div class="flex items-center w-full px-6 py-6 border-b aside-nav">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="125.08 433.42 829.97 213.36"
                            style="width: 63% !important; margin-left:2rem; margin-top:0.5rem;">
                            <defs>
                                <style>
                                    .cls-1 {
                                        fill: var(--fuse-primary-400);
                                    }

                                    .cls-2 {
                                        fill: none;
                                    }

                                    .cls-3 {
                                        fill: black;
                                    }

                                    .cls-4 {
                                        fill: #1f2857;
                                    }

                                    .cls-5 {
                                        fill: var(--fuse-accent-800);
                                    }
                                </style>
                            </defs>
                            <ellipse class="cls-1" cx="907.76" cy="554.41" rx="31.75" ry="26.38"
                                transform="translate(113.18 1261.54) rotate(-73.08)" />
                            <path class="cls-1" d="M127.21,477.54h27.61v92.33q0,15.61,6.34,26.71H134.23q-7-8.37-7-26.71Z" />
                            <path class="cls-1"
                                d="M183.33,569.87V477.54h27.61v12.9q13.13-15.17,30.33-15.16,15.84,0,25.57,10.41,8.15,8.82,8.15,21.95v62.23q0,15.61,6.33,26.71h-26.7q-7.25-8.6-7.24-26V511.71q0-18.56-16.3-18.56a23.47,23.47,0,0,0-20.14,12v64.72q0,15.61,6.34,26.71H190.57Q183.33,588,183.33,569.87Z" />
                            <path class="cls-1"
                                d="M347.63,492.7H337.45V571q0,14.94,5.88,25.58H317.08q-7.24-8.6-7.24-26V492.7H293.77V477.54q19.23,0,31.23-11.32a71,71,0,0,0,12.45-14.93v26.25H347q17.88,0,21.73,19Q361.66,492.7,347.63,492.7Z" />
                            <path class="cls-1"
                                d="M452.41,567.84l9.28,19.91q-14,11.55-36,11.54-25.12,0-40.29-19.69-12.21-15.61-14-39.6-.22-29.19,16.75-48,15.39-16.74,38.7-16.74,19.46,0,29.87,9.27,8.37,7.69,8.37,20.37,0,11.32-6.33,18.33Q452.63,530,435.66,538A151.94,151.94,0,0,0,413,550.41q-8.82,6.11-8.82,9.51,0,9,6.56,18.1,6.33,8.37,14.94,8.37Q441.33,586.39,452.41,567.84Zm-27.84-79.67q-11.31,0-17.65,19.69-4.76,14.49-4.75,32.82v2.94q3.84-4.53,17.65-14.26,10.86-7.47,14-11.31a23.25,23.25,0,0,0,4.76-14.71c0-4.53-1.29-8.18-3.85-11A13.19,13.19,0,0,0,424.57,488.17Z" />
                            <path class="cls-1"
                                d="M491.34,634.6l9.5-20.6q15.17,19.92,30.33,19.92,22.4,0,23.53-38.7-6.56,4.08-17.2,4.07-29,0-44.81-19.91-13.12-15.84-13.12-38.7,0-28.29,13.8-46.4,14.49-19,40.51-19,13.13,0,20.37,8.59v-6.33h27.61V584.36q0,23.53-6.34,36.89a38.87,38.87,0,0,1-21,21q-10.18,4.53-25.58,4.53a66.23,66.23,0,0,1-20.93-3.4Q497.67,640,491.34,634.6Zm62.91-61.78V501.07a19.62,19.62,0,0,0-6.9-9.27,17.58,17.58,0,0,0-10.75-3.63q-25.35,0-25.35,53.64,0,23.54,8.38,34.85,7,9.74,19.68,9.73a15.27,15.27,0,0,0,10.75-3.84A12.66,12.66,0,0,0,554.25,572.82Z" />
                            <path class="cls-1"
                                d="M606.3,570.55v-93h27.61v12.67q12-14.94,24.21-14.93t19.69,8.37l-7.92,22q-10-12.23-19.91-12.22-6.79,0-12,6.11a19.88,19.88,0,0,0-4.07,12.22V571q0,14.49,6.34,25.58H613.31Q606.31,588.89,606.3,570.55Z" />
                            <path class="cls-1"
                                d="M833.06,492.7H822.88V571q0,14.94,5.88,25.58H802.51q-7.25-8.6-7.24-26V492.7H779.2V477.54q19.23,0,31.23-11.32a71,71,0,0,0,12.45-14.93v26.25h9.5q17.88,0,21.73,19Q847.09,492.7,833.06,492.7Z" />
                            <path class="cls-1"
                                d="M770.15,596.57H743.22a39.86,39.86,0,0,1-5.21-10q-3.62,5.43-12.22,9a41.41,41.41,0,0,1-17,3.62q-16.07,0-25.35-9.51-7.92-8.35-7.92-21.49,0-15.16,13.35-25.8,5.45-4.53,23.77-14,12.9-6.56,17.88-11.31,6.56-6.57,6.56-14.26A14.47,14.47,0,0,0,733,492.36a13.35,13.35,0,0,0-9.95-4.19,20.33,20.33,0,0,0-12.34,4.3q-5.76,4.3-15,16.29l-9.05-20.59q20.15-12.9,38.47-12.9,39.39,0,39.38,36v60.65Q764.49,586.61,770.15,596.57ZM736.88,573V528.91a104.57,104.57,0,0,1-14,9.05q-10.64,6.33-14.94,12.79t-4.3,16.18q0,9,4.76,14.48a11.37,11.37,0,0,0,9.5,4.75Q730.55,586.16,736.88,573Z" />
                            <polyline class="cls-2" points="736.89 537.28 737 504.88 737.01 501.17" />
                            <path class="cls-1"
                                d="M949.4,569.2V508.55q0-36-39.38-36-18.33,0-38.48,12.9l9.06,20.6q9.27-12,15-16.3a20.28,20.28,0,0,1,12.33-4.3,13.38,13.38,0,0,1,10,4.19A14.5,14.5,0,0,1,922,500.17a19,19,0,0,1-.11,2,18.77,18.77,0,0,1-3.58,8.89l0,0a24.84,24.84,0,0,1-2.68,3.16l-.16.17q-5,4.76-17.88,11.32-18.33,9.5-23.76,14-13.37,10.63-13.35,25.8,0,13.13,7.92,21.5c.39.4.78.78,1.19,1.15s.58.53.89.79l.12.1.61.49.72.55.51.37c.17.13.35.25.53.37s.66.45,1,.64c.64.4,1.3.78,2,1.13l.65.33.84.39,1,.44.26.1a10.12,10.12,0,0,0,1,.4c.82.3,1.67.57,2.54.81l.75.2c.36.1.72.18,1.09.25l.33.08c.42.09.84.17,1.27.24l.31.06,1.35.2,1.2.14c.29,0,.58.07.88.09l.54,0c.46,0,.93.07,1.4.08.76,0,1.54.06,2.33.06l1.26,0,.94,0c.3,0,.61,0,.91-.06.62,0,1.25-.1,1.87-.18s1-.13,1.54-.21.74-.12,1.11-.2.58-.1.87-.17c.91-.19,1.82-.41,2.72-.66l1.2-.36A41.29,41.29,0,0,0,910.7,593a42,42,0,0,0,4.14-2c.21-.12.42-.23.62-.36.55-.32,1.07-.64,1.56-1,.26-.17.52-.35.76-.53s.56-.41.83-.62l.19-.14a20,20,0,0,0,4.12-4.41c.11.36.24.72.38,1.09a36.14,36.14,0,0,0,3.19,6.34c.49.82,1,1.66,1.63,2.53h26.93Q949.4,583.91,949.4,569.2Zm-27.61,1.13q-6.35,13.13-19,13.13a11.38,11.38,0,0,1-9.51-4.76q-4.75-5.43-4.75-14.48,0-9.74,4.3-16.18c2.86-4.3,13.68-13.49,20.77-17.72,5.74-3.17,8.2-.47,8.2,2.08Z" />
                            <path class="cls-3"
                                d="M896.3,559.64a16.3,16.3,0,0,1,15.34-10.87,16.1,16.1,0,0,1,5.4.93l1.54.51-6.47,18.36-.67.24-.56.19c-1.63.53-3.32,1-5,1.46-2,.51-3.58.88-5.08,1.18-2.41.47-4.65.81-6.86,1l-2.34.22Z" />
                            <path class="cls-4"
                                d="M911.64,550.27a14.72,14.72,0,0,1,4.9.84l.11,0-5.71,16.25-.48.16c-1.63.53-3.27,1-4.92,1.45s-3.31.83-5,1.15a66.81,66.81,0,0,1-6.72,1l3.88-11a14.76,14.76,0,0,1,13.92-9.87m0-3h0a17.79,17.79,0,0,0-16.75,11.87l-3.88,11-1.56,4.43,4.67-.45c2.26-.22,4.55-.56,7-1,1.51-.3,3.15-.68,5.16-1.2,1.67-.44,3.37-.94,5.06-1.49l.55-.18,1.36-.48.5-1.37,5.71-16.24,1-2.89-2.91-1-.08,0a17.83,17.83,0,0,0-5.87-1Z" />
                            <path class="cls-3"
                                d="M892.85,590.57a16.33,16.33,0,0,1-5.39-.92l-1.53-.51,5-14.09,3.29.05a15.05,15.05,0,0,0,3.36-1,20.34,20.34,0,0,1,3.29-1.06,18,18,0,0,0,2.44-.71c.65-.23,1.33-.46,2.09-.66s1.2-.28,1.63-.36a11.88,11.88,0,0,0,1.59-.39l.44-.36a3.81,3.81,0,0,1,1.15-.79l4.4-1.54-4.65,6.63-1.72,4.87A16.29,16.29,0,0,1,892.85,590.57Z" />
                            <path class="cls-4"
                                d="M910.65,571.16l-2.09,3-1.78,5.07a14.75,14.75,0,0,1-18.82,9l-.11,0,4.09-11.64,2.29,0c2.25-.22,4.66-1.67,6.88-2.11,1.67-.33,3-1,4.62-1.38s1.87-.31,3.49-.85c.16,0,1.27-1,1.43-1.1m7.82-5.92-8.81,3.09a5.26,5.26,0,0,0-1.6,1l-.21.17a11.56,11.56,0,0,1-1.15.27c-.42.08-.94.18-1.73.38s-1.55.47-2.2.69a15.84,15.84,0,0,1-2.23.65,21,21,0,0,0-3.55,1.14,14.71,14.71,0,0,1-2.9.91l-2.11,0-2.15,0-.72,2L885,587.2l-1,2.87,2.89,1,.11,0a17.76,17.76,0,0,0,22.6-10.87l1.64-4.68,1.86-2.64,5.36-7.65Z" />
                            <path class="cls-3"
                                d="M912.25,574.7q-2.62.84-5.29,1.52t-5.4,1.12c-1.81.31-3.64.53-5.48.68a54.17,54.17,0,0,1-5.56.19,45.75,45.75,0,0,1-5.59-.44c-.94-.14-1.87-.31-2.8-.53a25.67,25.67,0,0,1-2.78-.79,18.41,18.41,0,0,1-5.3-2.76l-.6-.5c-.19-.18-.39-.35-.58-.54a11.14,11.14,0,0,1-1-1.22,9,9,0,0,1-1.78-6.15,11.13,11.13,0,0,1,.24-1.55c.06-.25.13-.5.2-.74s.15-.49.24-.72a17.8,17.8,0,0,1,2.95-4.94,31.33,31.33,0,0,1,3.89-3.92,50.27,50.27,0,0,1,4.34-3.27,70.78,70.78,0,0,1,9.39-5.23c1.62-.76,3.27-1.44,4.93-2.1s3.34-1.25,5-1.8,3.41-1.06,5.13-1.51,3.47-.87,5.22-1.21a69.27,69.27,0,0,1,10.67-1.3,46.9,46.9,0,0,1,5.43.09,30.27,30.27,0,0,1,5.46.87,17.84,17.84,0,0,1,5.29,2.25l.62.44c.2.16.4.31.59.48a10.57,10.57,0,0,1,1.11,1.11,9.17,9.17,0,0,1,1.64,2.79,9.37,9.37,0,0,1,.55,3.23,10.57,10.57,0,0,1-.13,1.6c0,.26-.09.52-.14.78s-.12.51-.2.76a18.08,18.08,0,0,1-2.65,5.35,28.74,28.74,0,0,1-1.78,2.27c-.62.73-1.28,1.42-2,2.07a44.52,44.52,0,0,1-4.26,3.66,53.89,53.89,0,0,1-4.6,3.12c-1.58,1-3.19,1.87-4.84,2.68s-3.32,1.58-5,2.27S914,574.13,912.25,574.7Zm-1.79-5.49c1.62-.52,3.24-1.1,4.83-1.72s3.16-1.28,4.7-2a67.33,67.33,0,0,0,8.89-4.92,48.19,48.19,0,0,0,4-3,28.36,28.36,0,0,0,3.47-3.46,14.65,14.65,0,0,0,2.42-3.93,6.62,6.62,0,0,0,.36-4.07,5.76,5.76,0,0,0-.88-1.86,6.78,6.78,0,0,0-.7-.84,4.93,4.93,0,0,0-.4-.39,3.91,3.91,0,0,0-.44-.37,14.32,14.32,0,0,0-4.28-2.26,27.08,27.08,0,0,0-5-1.19,40.65,40.65,0,0,0-5.18-.42,51.05,51.05,0,0,0-5.24.17c-1.74.14-3.48.35-5.21.65s-3.46.64-5.16,1.07-3.4.91-5.08,1.45-3.32,1.15-5,1.81-3.23,1.4-4.8,2.18-3.1,1.64-4.6,2.55a49.26,49.26,0,0,0-4.33,3A38.54,38.54,0,0,0,879,555a26.35,26.35,0,0,0-3.31,3.88,14.59,14.59,0,0,0-2.13,4.34c-.05.19-.09.37-.13.56a5.3,5.3,0,0,0-.1.55,8.19,8.19,0,0,0-.07,1.09,5.85,5.85,0,0,0,.39,2,6.54,6.54,0,0,0,2.69,3.08,14.75,14.75,0,0,0,4.27,1.75,28.55,28.55,0,0,0,4.84.75,44,44,0,0,0,5,.07,67.68,67.68,0,0,0,10.09-1.25c1.67-.33,3.33-.73,5-1.16S908.83,569.75,910.46,569.21Z" />
                            <path class="cls-5" d="M914,544.94a8.71,8.71,0,1,1,8.21-5.82A8.7,8.7,0,0,1,914,544.94Z" />
                            <path class="cls-3"
                                d="M914,528a8.37,8.37,0,0,1,2.72.47,8.21,8.21,0,0,1-2.72,16,8.37,8.37,0,0,1-2.73-.46A8.22,8.22,0,0,1,914,528m0-1h0a9.22,9.22,0,0,0-8.69,6.16A9.21,9.21,0,0,0,914,545.44a9.21,9.21,0,0,0,3.05-17.9A9.11,9.11,0,0,0,914,527Z" />
                            <path class="cls-5"
                                d="M129.47,459.43A15.29,15.29,0,1,1,140.34,464,14.77,14.77,0,0,1,129.47,459.43Z" />
                        </svg>
                    </div>
                    <fuse-vertical-navigation-aside-item [item]="item" [name]="name"
                        [autoCollapse]="autoCollapse"></fuse-vertical-navigation-aside-item>
                </ng-container>

            </ng-container>

        </ng-container>

    </div>
</ng-container>