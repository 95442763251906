import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthService } from 'app/core/auth/auth.service';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';

@Component({
    selector: 'settings-security',
    templateUrl: './security.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MatInputModule,
        MatSlideToggleModule, MatButtonModule, CommonModule, NgIf],
})
export class SettingsSecurityComponent implements OnInit {
    securityForm: FormGroup;
    loggedInUserId: any;
    userInfoList: any;
    submitted: boolean = false;
    passwordMatch: boolean = false;
    authChanged: boolean = false;
    loggedInUser_id: any;
    loggedInUserDetails: any;
    _id: any;
    userId: any;

    constructor(
        private _commonService: CommonService,
        private _authService: AuthService,
        private fb: FormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private snackbar: SnackbarService,
    ) { }

    ngOnInit(): void {

        this.securityForm = this.fb.group({
            current_password: [''],
            new_password: ['', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
            confirm_password: ['', [Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
            two_step_auth: [''],
            askPasswordChange: [''],
        });

        this.getUserInfo();
    }

    getUserInfo() {
        const userInfo = this._authService.userInfo;
        if (userInfo && JSON.stringify(userInfo) !== '{}') {
            this.userId = userInfo._id;
        }
        this._commonService
            .getDataByField(MODELS_CONSTANTS.USER_INFO, 'user_id', this.userId)
            .subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.userInfoList = response.data[0];
                        console.log('userInfo', this.userInfoList);

                        this.modifySecurityPreference(this.userInfoList);
                        this._changeDetectorRef.markForCheck();
                    }
                },
                error: (err) => {
                    console.error(err);
                },
            });
    }

    modifySecurityPreference(securityPreferencedata) {
        this.securityForm.setValue({
            current_password: '',
            new_password: '',
            confirm_password: '',
            two_step_auth: this.userInfoList?.two_step_auth ? this.userInfoList?.two_step_auth : '',
            askPasswordChange: this.userInfoList?.askPasswordChange ? this.userInfoList?.askPasswordChange : '',
        })
        this._id = securityPreferencedata?._id;
    }

    saveSecurity() {
        this.submitted = true;

        let current_password = this.securityForm.controls.current_password.value;
        let new_password = this.securityForm.controls.new_password.value;
        let confirm_password = this.securityForm.controls.confirm_password.value;

        if (current_password && new_password && confirm_password) {

            let passwordJson = {
                current_password: current_password,
                new_password: new_password,
                confirm_password: confirm_password
            }
            this._authService.change_password(passwordJson).subscribe((response) => {
                console.log(response);
                if (response?.status === 200) {
                    this.snackbar.success(
                        `Password changed successfully`
                    );
                    this.authChanged = false;
                    this.getUserInfo();
                } else {
                    this.snackbar.error(
                        response?.message ||
                        MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                    );
                }
            })
        }

        if (this.authChanged) {
            if (this.userInfoList) {
                let json: any = {
                    two_step_auth: this.securityForm.controls.two_step_auth.value ? true : false,
                    askPasswordChange: this.securityForm.controls.askPasswordChange.value ? true : false,
                    first_name: this.userInfoList?.first_name,
                    last_name: this.userInfoList?.last_name,
                    _id: this._id,
                    user_id: this.userId
                }
                console.log('json', json);
                this._commonService.saveRecord(MODELS_CONSTANTS.USER_INFO, json).subscribe({
                    next: (response: any) => {
                        if (response?.status === 200) {
                            const type = json?._id ? 'modified' : 'added';
                            this.snackbar.success(
                                `Authentication ${type} successfully`
                            );
                            this.authChanged = false;
                            this.getUserInfo();
                        } else {
                            this.snackbar.error(
                                response?.message ||
                                MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                            );
                        }
                    },
                    error: (err) => {
                        console.error(err);
                        this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    },
                });
            } else {
                this.snackbar.warning('Please create the account setting');
            }
        }
    }

    passwordMatchValidator(pass_key, confirm_pass_key) {
        if (pass_key === confirm_pass_key) {
            this.passwordMatch = false;
        }
        else {
            this.passwordMatch = true;
        }

    }
}
