import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { CommonService } from 'app/shared/services/common/common.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrl: './content-management.component.scss',
})

export class ContentManagementComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @Input() params: any;
  @Input() contentProperty: string;
  contentPropertyName: string;
  @Input() row_data: any;
  @Input() parent_row_data: any;
  clickedIndex: number | null = null;
  categoryList: any;
  subCategoryList: any[];
  announcemnetTile: any[];
  panelOpenState = false;
  dataSource: any;
  subCategoryFlag: boolean = false;
  categoryFlag: boolean = false;
  ContentName: any;
  subscription: Subscription;
  annoncement: any;
  showScrollButton = false;
  titleClass: boolean = false;
  particularCategoryList: any;
  searchView: 'Vertical' | 'Horizontal' = "Vertical";

  isValidDate(dateString: string): boolean {
    if (!dateString) {
      return false;
    }

    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  onScroll() {
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;
    this.showScrollButton = scrollTop > 100;
  }

  scrollToTop() {
    this.scrollContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _commonService: CommonService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!this.contentProperty && !this.params) {
      this.subscription = this.route.params.subscribe((params: Params) => {
        this.contentProperty = params['id'];
        this.getDatabyProperty();
        const state = this.router.getCurrentNavigation()?.extras.state;
        if (state) {
          this.row_data = state;
          this.parent_row_data = state;
          this._changeDetectorRef.detectChanges();
        }
      });
      this.titleClass = false;
    } else if (this.params) {
      this.getDatabyFooter(this.params);
      this.titleClass = true;

    } else {
      this.getDatabyProperty();
      this.titleClass = false
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.scrollContainer) {
        this.scrollContainer.nativeElement.addEventListener(
          'scroll',
          this.onScroll.bind(this)
        );
        this.onScroll(); // Check the initial scroll position
      }
    });
  }

  async getDatabyProperty() {
    this.contentPropertyName = this.contentProperty;
    try {
      const result = await this._commonService.getDataByField(
        MODELS_CONSTANTS.CONTENT_MANAGEMENT, 'property_type', this.contentProperty
      ).toPromise();

      console.log(result);

      if (result?.status === 200) {
        this.dataSource = result?.data || [];
        this.categoryList = result?.data || [];

        // let currentDate = new Date();
        // this.categoryList = this.categoryList.filter(element => 
        //   new Date(element.start_date) <= currentDate && 
        //   new Date(element.end_date) > currentDate
        // );

        this._changeDetectorRef.markForCheck();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getDatabyFooter(params) {
    this.contentPropertyName = params.property_type;
    try {
      const result = await this._commonService.getAllData(
        MODELS_CONSTANTS.CONTENT_MANAGEMENT).toPromise();
      if (result?.status === 200) {
        this.dataSource = result?.data || [];
        this.categoryList = result?.data.filter(item =>
          item.property_type == params.property_type &&
          item.category == params.category &&
          item.sub_category == params.sub_category
        ) || [];
        this._changeDetectorRef.markForCheck();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getParticularData(data, index) {

    this.clickedIndex = this.clickedIndex === index ? null : index;
    this.particularCategoryList = [];
    
    try {
      const category_response = await this._commonService.getDataByField(
        MODELS_CONSTANTS.CONTENT_MANAGEMENT, '_id', data
      ).toPromise();

      if (category_response?.status === 200) {
        this.particularCategoryList = category_response?.data;
        this.categoryFlag = true;

        // Check if at least one item in the list has a non-empty description
        this.subCategoryFlag = this.particularCategoryList.some(item =>
          item.description && item.description.trim() !== ""
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  isCardClicked(index: number): boolean {
    return this.clickedIndex === index;
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

}
