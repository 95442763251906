<div class="p-10">
    <div>
        <h4>Config Form</h4>
    </div>
    <div class="p-4">
        <form [formGroup]="configForm">
            <div class="grid grid-cols-1">
                <div>
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Code</mat-label>
                        <input matInput type="text" formControlName="code">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Category</mat-label>
                        <input matInput type="text" formControlName="category">
                    </mat-form-field>
                </div>
                <div>
                    <mat-label>Config Value</mat-label>
                    <angular-editor formControlName="config_value" [config]="config"
                        [placeholder]="'Enter text here...'"></angular-editor>
                </div>
                <div class="mt-4">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Status</mat-label>
                        <input matInput type="text" formControlName="status">
                    </mat-form-field>
                </div>
                <div>
                    <button mat-flat-button color="primary" class="api-primary-btn mr-8" (click)="save()">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
