import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { API_REGISTRY_CONSTANTS } from 'app/shared/constants/api-registry.constants';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { SharedModule } from 'app/shared/shared.module';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { Board } from '../../scrumboard.models';
import { ScrumboardService } from '../../scrumboard.service';

@Component({
    selector: 'scrumboard-card-details',
    templateUrl: './details.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TextFieldModule, NgClass, NgIf, NgFor, DatePipe, SharedModule],
})
export class ScrumboardCardDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;

    @Input() row_data: any;
    subtaskdiv = false;
    checksubtask = false;
    board: Board;
    card: any = {};
    cardForm: UntypedFormGroup;
    labels: string[] = [];
    filteredLabels: string[] = [];
    contactList;
    board_stages: any;
    // Private
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        @Optional() public matDialogRef: MatDialogRef<ScrumboardCardDetailsComponent>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private _scrumboardService: ScrumboardService,
        private _snackbar: SnackbarService,
        private _commonService: CommonService
    ) {}

    get dueDate() {
        return this.cardForm.get('dueDate').value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {
        // Prepare the card form
        this.cardForm = this._formBuilder.group({
            task_id: [''],
            title: ['', [Validators.required]],
            asignTo: [''],
            stage_id: [{value: '', disabled: true}],
            description: [''],
            labels: [[]],
            dueDate: [null],
            subtasks: [[]]
        });

        // Get the board
        const contactsResult = await this._commonService
            .getAllData(MODELS_CONSTANTS.CONTACTS)
            .toPromise();
        if (contactsResult?.status === 200) {
            this.contactList = contactsResult?.data;
        }
        
        this._scrumboardService.board$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((board) => {
                // Board data
                this.board = board;
            });
            
        // stages
        this._scrumboardService.board_stages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((stages) => {
                this.board_stages = stages;

                let labels = [];
                this.board_stages?.forEach((stage) => {
                    stage?.tasks?.forEach((task) => {
                        if(task?.labels?.length){
                            labels = [...labels, ...task?.labels];
                        }
                    })
                })

                labels = Array.from(new Set(labels));

                // Get the labels
                this.labels = this.filteredLabels = labels;
            });

        // Get the card details
        if (this.row_data) {
            this.card = this.row_data;
        }


        // this._scrumboardService.card$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((card) => {
        //         this.card = card || {};
        //     });

        // Fill the form
        this.cardForm.reset(this.card);

        // // Update card when there is a value change on the card form
        // this.cardForm.valueChanges
        //     .pipe(
        //         tap((value) =>
        //         {
        //             // Update the card object
        //             this.card = assign(this.card, value);
        //         }),
        //         debounceTime(300),
        //         takeUntil(this._unsubscribeAll),
        //     )
        //     .subscribe((value) =>
        //     {
        //         // Update the card on the server
        //         this._scrumboardService.updateCard(value.id, value).subscribe();

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });
    }

    /**
     * On changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if ('row_data' in changes) {
            if (this.row_data) {
                setTimeout(() => {
                    this.card = this.row_data;
                    this.cardForm?.reset(this.card);
                });
            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Save card
     */
    saveCard() {
        if (this.cardForm.valid) {
            const requestBody = this.cardForm.getRawValue();
            const oldTaskInfo = cloneDeep(this.card);
            const oldAsignedTaskUser = oldTaskInfo?.asignTo;
            this.card = { ...this.card, ...requestBody };
            this.card['board_id'] = this.board?._id;
            // this.card['stage_id'] = this.board_stages[0]?._id;

            this._commonService
                .saveRecord(MODELS_CONSTANTS.BOARD_TASK, this.card)
                .subscribe(async (response: IResponse<any>) => {
                    if (response?.status === 200) {
                        const message = this.card?._id ? 'updated' : 'created';
                        this._snackbar.success(`Card ${message} successfully`);
                        this._scrumboardService
                            .get_board_stages(this.card?.board_id)
                            .subscribe();

                        const taskInfo = response?.data;
                        const asignTo = taskInfo?.asignTo;
                        const stageInfo = this.board_stages?.find((e) => e?._id == taskInfo?.stage_id);

                        // Send mail notification only if new taks is created, task is assigned to new member or task stage is changed 
                        if(!this.card?._id || asignTo !== oldAsignedTaskUser || taskInfo?.stage_id !== oldTaskInfo?.stage_id) {
                            const toStageActions = await this._commonService
                                .getDataByField(
                                    MODELS_CONSTANTS.STAGE_ACTIONS,
                                    'stage_id',
                                    stageInfo?._id
                                )
                                .toPromise()
                                .then((res) =>
                                    res?.data?.filter(
                                        (e) => e?.stage_trigger === 'On Entry'
                                    )
                                )
                                .catch((err) => console.error(err));

                            const actionsToStage = await this._commonService
                                .getDataById(
                                    MODELS_CONSTANTS.BOARD_STAGES,
                                    stageInfo?._id
                                )
                                .toPromise()
                                ?.then((res) => {
                                    let actions = res?.data?.actions;
                                    return actions
                                        ?.filter(
                                            (e) =>
                                                e?.stage_trigger === 'On Entry'
                                        )
                                        ?.map((e) => {
                                            e['stage_id'] = res?.data?._id;
                                            return e;
                                        });
                                });

                            let fromStageActions = [];
                            let actionsFromStage = [];
                            if (taskInfo?.stage_id !== oldTaskInfo?.stage_id) {
                                fromStageActions = await this._commonService
                                    .getDataByField(
                                        MODELS_CONSTANTS.STAGE_ACTIONS,
                                        'stage_id',
                                        oldTaskInfo?.stage_id
                                    )
                                    .toPromise()
                                    .then((res) =>
                                        res?.data?.filter(
                                            (e) =>
                                                e?.stage_trigger === 'On Exit'
                                        )
                                    )
                                    .catch((err) => console.error(err));

                                actionsFromStage = await this._commonService
                                    .getDataById(
                                        MODELS_CONSTANTS.BOARD_STAGES,
                                        oldTaskInfo?.stage_id
                                    )
                                    .toPromise()
                                    ?.then((res) => {
                                        let actions = res?.data?.actions;
                                        return actions
                                            ?.filter(
                                                (e) =>
                                                    e?.stage_trigger ===
                                                    'On Exit'
                                            )
                                            ?.map((e) => {
                                                e['stage_id'] = res?.data?._id;
                                                return e;
                                            });
                                    });
                            }

                            const stageActions = [...fromStageActions, ...toStageActions, ...actionsFromStage, ...actionsToStage];

                            const executeApi = async (apiRegistry, apiData, isMail = false) => {
                                try {
                                    await this._commonService.executeApiMethod(apiRegistry, apiData);
                                    if(isMail) {
                                        this._snackbar.success('Mail sent successfully');
                                    }
                                } catch (error) {
                                    console.error(error)
                                }
                            };

                            for(const action of stageActions) {
                                const apiRegistry = await this._commonService
                                    .getDataById(MODELS_CONSTANTS.API_REGISTRIES, action?.api)
                                    .toPromise()
                                    .then((res) => res?.data)
                                    .catch((err) => console.error(err));

                                const apiData = {}
                                if(apiRegistry?._id === API_REGISTRY_CONSTANTS.SEND_NOTIFY_MAIL_TO_USER) {
                                    const content_management_id = action?.params?.find((e) => e?.key?.trim() === "template_id")?.value;
                                    if(!content_management_id){
                                        console.error('Failed to send mail. Template Id not provided.');
                                        return;
                                    }

                                    if(!taskInfo?.asignTo) {
                                        console.error('Failed to send mail to user. Task is not assigned to any user.')
                                        return;
                                    }
                                    const user_id = this.contactList?.find((e) => e?._id === taskInfo?.asignTo)?.user_id;
                                    const user_info = await this._commonService.getDataById(MODELS_CONSTANTS.USERS, user_id).toPromise();
                
                                    apiData['content_management_id'] = content_management_id;
                                    apiData['user_id'] = user_id;
                                    apiData['template_data'] = { userInfo: user_info?.data, taskInfo, stageInfo };
                                    await executeApi(apiRegistry, apiData, true);
                                } else if (apiRegistry?._id === API_REGISTRY_CONSTANTS.SEND_NOTIFY_MAIL_TO_ASSIGNEE) {
                                    const content_management_id = action?.params?.find((e) => e?.key?.trim() === "template_id")?.value;
                                    if(!content_management_id){
                                        console.error('Failed to send mail. Template Id not provided.');
                                        return;
                                    }

                                    const user_ids = stageInfo?.members?.map((e) => e?.member_id);
                                    if(user_ids?.length){
                                        for(const [index, user_id] of user_ids.entries()) {
                                            if(user_id){
                                                const user_info = await this._commonService.getDataById(MODELS_CONSTANTS.USERS, user_id).toPromise();
                                                apiData['content_management_id'] = content_management_id;
                                                apiData['user_id'] = user_id;
                                                apiData['template_data'] = { userInfo: user_info?.data, taskInfo, stageInfo };
                                                let isMail: boolean = false;
                                                if(index === user_ids?.length - 1) {
                                                    isMail = true;
                                                } 
                                                await executeApi(apiRegistry, apiData, isMail);
                                            }
                                        }
                                    }
                                } else {
                                    await executeApi(apiRegistry, apiData);
                                }
                            }
                        }
                    }
                });
        } else {
            this._snackbar.warning(MESSAGE_CONSTANTS.FILL_FORM);
        }
    }

    /**
     * Return whether the card has the given label
     *
     * @param label
     */
    hasLabel(label: string): boolean {
        return this.card.labels?.includes(label);
    }

    /**
     * Filter labels
     *
     * @param event
     */
    filterLabels(event): void {
        // Get the value
        const value = event.target.value.toLowerCase();

        // Filter the labels
        if (!this.labels) {
            this.labels = [];
        }
        this.filteredLabels = this.labels.filter((label) =>
            label.toLowerCase().includes(value)
        );
    }

    /**
     * Filter labels input key down event
     *
     * @param event
     */
    filterLabelsInputKeyDown(event, labelTitle: string): void {
        // Return if the pressed key is not 'Enter'
        if (event.key !== 'Enter') {
            return;
        }

        // If there is no label available...
        if (!this.filteredLabels || this.filteredLabels?.length === 0) {
            const label = labelTitle
            this.filteredLabels.push(label);
            this.labels.push(label);

            // Return
            // return;
        }

        // If there is a label...
        const label = this.filteredLabels[0];
        const isLabelApplied = this.card?.labels?.find(
            (cardLabel) => cardLabel === label
        );

        // If the found label is already applied to the card...
        if (isLabelApplied) {
            // Remove the label from the card
            this.removeLabelFromCard(label);
        } else {
            // Otherwise add the label to the card
            this.addLabelToCard(label);
        }
    }

    /**
     * Toggle card label
     *
     * @param label
     * @param change
     */
    toggleProductTag(label: string, change: MatCheckboxChange): void {
        if (change.checked) {
            this.addLabelToCard(label);
        } else {
            this.removeLabelFromCard(label);
        }
    }

    /**
     * Add label to the card
     *
     * @param label
     */
    addLabelToCard(label: string): void {
        if(!Array.isArray(this.card?.labels)){
            this.card.labels = [];
        }
        // Add the label
        this.card?.labels?.unshift(label);

        // Update the card form data
        this.cardForm.get('labels').patchValue(this.card.labels);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }
    
    /**
     * Add label to the card
     *
     * @param subtasks
     */
    addsubtaskToCard(subtask: string): void {
        if(!Array.isArray(this.card?.subtasks)){
            this.card.subtasks = [];
        }
        // Add the label
        this.card?.subtasks?.unshift(subtask);

        // Update the card form data
        this.cardForm.get('subtasks').patchValue(this.card.subtasks);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Remove label from the card
     *
     * @param label
     */
    removeLabelFromCard(label: string): void {
        // Remove the label
        this.card.labels.splice(
            this.card.labels.findIndex((cardLabel) => cardLabel === label),
            1
        );

        // Update the card form data
        this.cardForm.get('labels').patchValue(this.card.labels);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Remove label from the card
     *
     * @param subtask
     */
    removesubtaskFromCard(subtask: string): void {
        // Remove the label
        this.card.subtasks.splice(
            this.card.subtasks.findIndex((cardsubtask) => cardsubtask === subtask),
            1
        );

        // Update the card form data
        this.cardForm.get('subtasks').patchValue(this.card.subtasks);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }
    /**
     * Check if the given date is overdue
     */
    isOverdue(date: string): boolean {
        return (
            DateTime.fromISO(date).startOf('day') <
            DateTime.now().startOf('day')
        );
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    private _readAsDataURL(file: File): Promise<any> {
        // Return a new promise
        return new Promise((resolve, reject) => {
            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void => {
                resolve(reader.result);
            };

            // Reject the promise on error
            reader.onerror = (e): void => {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }
}
