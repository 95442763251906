<!-- ----------------------------------------------------------------------------------------------------- -->
@switch (layout) {
    @case ('empty') {
        <!-- Empty layout -->
        <!-- ----------------------------------------------------------------------------------------------------- -->
        <empty-layout />
    }

    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- Layouts with horizontal navigation -->
    <!-- ----------------------------------------------------------------------------------------------------- -->
    @case ('centered') {
        <!-- Centered -->
        <centered-layout />
    }
    @case ('enterprise') {
        <!-- Enterprise -->
        <enterprise-layout />
    }
    @case ('material') {
        <!-- Material -->
        <material-layout />
    }
    @case ('modern') {
        <!-- Modern -->
        <modern-layout />
    }

    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- Layouts with vertical navigation -->
    <!-- ----------------------------------------------------------------------------------------------------- -->
    @case ('classic') {
        <!-- Classic -->
        <classic-layout />
    }
    @case ('classy') {
        <!-- Classy -->
        <classy-layout />
    }
    @case ('compact') {
        <!-- Compact -->
        <compact-layout />
    }
    @case ('dense') {
        <!-- Dense -->
        <dense-layout />
    }
    @case ('futuristic') {
        <!-- Futuristic -->
        <futuristic-layout />
    }
    @case ('thin') {
        <!-- Thin -->
        <thin-layout />
    }
    
    @default {
        <!-- Empty layout -->
        <!-- ----------------------------------------------------------------------------------------------------- -->
        <empty-layout />
    }
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<settings></settings>
