import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

@Component({
    selector: 'app-form-header',
    templateUrl: './form-header.component.html',
    styleUrls: ['./form-header.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class FormHeaderComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Output() onHeaderActionClick = new EventEmitter();

    form_header_actions: any[] = [];

    constructor() {}

    ngOnInit() {
        this.form_header_actions = this.data?.form_header_actions || [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('data' in changes) {
            this.form_header_actions = this.data?.form_header_actions || [];
        }
    }

    onFormHeaderActionClick(action) {
        this.onHeaderActionClick.emit(action);
    }
}
