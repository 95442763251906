import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: '/newsite' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },
    // { path: 'home', pathMatch: 'full', redirectTo: '' },

    // New site Routes
    {
        path: '',
        children: [
            { path: '', loadChildren: () => import('app/newsite/newsite.module').then(m => m.NewsiteModule) },
        ]
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'set-password', loadChildren: () => import('app/modules/auth/set-password/set-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
            { path: 'otp', loadChildren: () => import('app/modules/auth/otp/otp.routes') },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,

        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            isAccessRestrictionRoute: true
        },
        children: [
            { path: 'core', loadChildren: () => import('app/modules/core/core.module').then(m => m.CoreModule) },
            { path: 'config', loadChildren: () => import('app/modules/config/config.module').then(m => m.ConfigModule) },
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
            { path: 'chat', loadChildren: () => import('app/modules/features/chat/chat.routes') },
            { path: 'contacts', loadChildren: () => import('app/modules/features/contacts/contacts.routes') },
            { path: 'notes', loadChildren: () => import('app/modules/features/notes/notes.routes') },
            { path: 'scrumboard', loadChildren: () => import('app/modules/features/scrumboard/scrumboard.routes') },
            { path: 'tasks', loadChildren: () => import('app/modules/features/tasks/tasks.routes') },
            { path: 'forms', loadChildren: () => import('app/modules/common/forms/dynamic-forms.module').then(m => m.DynamicFormsModule) },
            { path: 'tabs', loadChildren: () => import('app/modules/common/tabs/dynamic-tabs.module').then(m => m.DynamicTabsModule) },
            { path: 'settings', loadChildren: () => import('app/modules/core/settings/settings.routes') },
            { path: 'project-view/:id', loadChildren: () => import('app/modules/core/project-view/project-view.routes') },
            { path: 'tenants', loadChildren: () => import('app/modules/admin/tenants/tenants.routes') },
            { path: 'vendor', loadChildren: () => import('app/modules/vendor-information/vendor-information.module').then(m => m.VendorModule) },
            { path: 'vendor/:decrypted', loadChildren: () => import('app/modules/vendor-information/vendor-information.module').then(m => m.VendorModule) },
            { path: 'tiles', loadChildren: () => import('app/modules/vendor-information/tiles/tiles.routes') },
            { path: 'tiles/:encrypted/:id', loadChildren: () => import('app/modules/vendor-information/tiles/tiles.routes') },
            { path: 'projects-dashboard', loadChildren: () => import('app/modules/projects-dashboards/projects-dashboards.module').then(m => m.ProjectsDashboardsModule) },

        ]
    },
    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [

        ]
    },
];
