import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { SharedModule } from 'app/shared/shared.module';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-forms-conversation',
    templateUrl: './forms-conversation.component.html',
    styleUrls: ['./forms-conversation.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class FormsConversationComponent implements OnInit, OnChanges {
    @Input() data: any;

    conversations = [];
    enableMessageSelection: boolean;

    private userInfo = this._authService.userInfo;;

    constructor(
        private _commonService: CommonService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackbar: SnackbarService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _authService: AuthService
    ) {}

    get selectedMessages() {
        return this.conversations.filter((e) => e?.isSelected);
    }

    ngOnInit() {
        if (this.data?.title) {
            this.data.title = this.data?.title.replace(/^Edit /, '').trim();
            this._changeDetectorRef.detectChanges();
        }
        this.getConversation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('data' in changes) {
            if (this.data?.title) {
                this.data.title = this.data?.title
                    .replace(/^Edit /, '')
                    .trim();
                this._changeDetectorRef.detectChanges();
            }
            this.getConversation();
        }
    }

    getConversation() {
        if (
            (this.data?.form_id && this.data?.reference_id) ||
            (this.data.reference_type === 'module' &&
                this.data?.reference_type &&
                this.data?.reference_type_id)
        ) {
            const filter = {};
            if (this.data.reference_type === 'form') {
                filter['form_id'] = this.data?.form_id;
                filter['reference_id'] = this.data?.reference_id;
            } else if (this.data.reference_type === 'module') {
                filter['reference_type'] = this.data?.reference_type;
                filter['reference_type_id'] = this.data?.reference_type_id;
            } else {
                filter['form_id'] = this.data?.form_id;
                filter['reference_id'] = this.data?.reference_id;
            }
            this._commonService
                .getDataByFields(MODELS_CONSTANTS.FORM_CONVERSATIONS, filter)
                .subscribe((response: IResponse<any>) => {
                    if (response?.status === 200) {
                        this.conversations = response?.data?.map((e) => {
                            if (e?.sender_user_id === this.userInfo?._id) {
                                e['isMine'] = true;
                            } else {
                                e['isMine'] = false;
                            }

                            return e;
                        });
                    }
                });
        }
    }

    sendMessage(inputEl) {
        const message = inputEl?.value;
        const requestBody = {
            form_id: this.data?.form_id || null,
            reference_id: this.data?.reference_id || null,
            reference_type: this.data?.reference_type || null,
            reference_type_id: this.data?.reference_type_id || null,
            sender_user_id: this.userInfo?._id || null,
            sender_user_name: this.userInfo?.name || null,
            message,
        };

        this._commonService
            .saveRecord(MODELS_CONSTANTS.FORM_CONVERSATIONS, requestBody)
            .subscribe((response: IResponse<any>) => {
                if (response?.status === 200) {
                    response.data['isMine'] = true;
                    this.conversations.push(response?.data);
                    inputEl.value = '';
                }
            });
    }

    /**
     * select a message
     */
    selectMessage(message) {
        if (this.enableMessageSelection) {
            message['isSelected'] = !message?.['isSelected'];
        }
    }

    /**
     * change message selection flag
     */
    onMessageSelectionFlagChange() {
        this.enableMessageSelection = !this.enableMessageSelection;
        if (!this.enableMessageSelection) {
            this.conversations?.forEach((e) => {
                e['isSelected'] = false;
            });
        }
    }

    /**
     * Clear messages
     */
    clearMessages() {
        const selectedMessages = this.selectedMessages;
        const confirmation = this._fuseConfirmationService.open({
            title: 'Clear messages',
            message:
                'Are you sure you want to clear all the selected messages from this chat? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Clear',
                },
            },
        });

        confirmation.afterClosed().subscribe(async (result) => {
            if (result === 'confirmed') {
                const selectedMessageIds = selectedMessages?.map((e) => e?._id);
                const success = await this.deleteSelectedMessages(
                    selectedMessageIds
                );
                if (success) {
                    this._snackbar.success(
                        'All the selected messages are cleared successfully'
                    );
                    this.getConversation();
                    this.enableMessageSelection = false;
                } else {
                    this._snackbar.error(
                        'Something went wrong while deleting selected messages, please try again later'
                    );
                }
            }
        });
    }

    /**
     * Delete selected messages
     */
    async deleteSelectedMessages(ids): Promise<boolean> {
        for (let [index, id] of ids.entries()) {
            let success = true;
            const response$ = this._commonService.deleteRecordsById(
                MODELS_CONSTANTS?.FORM_CONVERSATIONS,
                id
            );
            const result = await lastValueFrom(response$);
            if (result?.status !== 200) {
                success = false;
            }

            if (index === ids?.length - 1) {
                return success;
            }
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
