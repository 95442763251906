<div class="w-full flex flex-wrap gap-4">
  <div
    class="reborder border relative flex w-full sm:w-56 flex-col rounded-xl bg-clip-border p-6 shadow-md cursor-pointer"
    style="min-height: 88px;" (click)="addActionDialog()" matTooltip="Add">
    <div class="addtile flex relative m-0 overflow-hidden rounded-none text-center shadow-none">
      <div class="h-full w-full flex justify-center gap-1">
        <mat-icon class="custom_tileicon_add">note_add</mat-icon>
      </div>
    </div>
  </div>
  <div *ngFor="let element of formHeaderActions"
    class="tile divide-x divide-gray-200 flex reborder border relative w-full sm:w-56  rounded-xl  bg-clip-border px-3 py-0 h-22 shadow-md ">
    <div class=" w-2/5 justify-center gap-1  p-2 m-auto">
      <mat-icon [svgIcon]="element?.icon" class="custom_tileicon"></mat-icon>
    </div>

    <div class="flex p-0 w-full m-auto">
      <ul class="flex w-full my-2 ml-3 flex-col">
        <div class="relative mr-auto py-1 overflow-hidden rounded-nonetext-center shadow-none">
          <div class="capitalize font-bold">{{element?.tooltip}}</div>
        </div>
        <!-- <div class="m-auto items-center w-full  ">
          <div class=""><span class="font-medium text-gray-400 text-sm">{{element?.action_type}}</span></div>
        </div> -->
      </ul>
    </div>

    <div class="m-auto pl-2 hover-icons flex flex-col p-3">
      <mat-icon [matTooltip]="'Edit'" class="cursor-pointer edit-icon h-5 w-5 mb-2"
        [svgIcon]="'heroicons_outline:pencil-square'" (click)="editActionDialog(element)"></mat-icon>
      <mat-icon [matTooltip]="'Delete'" class="cursor-pointer del-icon  h-5 w-5 mt-2"
        [svgIcon]="'heroicons_outline:trash'" (click)="deleteAction(element)"></mat-icon>
    </div>
  </div>
</div>

<ng-template #add_action>
  <form [formGroup]="actionForm" (ngSubmit)="addAction()">
    <div class="flex justify-content-between align-items-center">
      <div class="add-action-modal-header">
        {{actionHeader}}
      </div>
      <div class="flex">
        <button mat-flat-button color="primary" class="mr-2" type="submit">Save</button>
        <button mat-icon-button mat-dialog-close type="button">
          <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
        </button>
      </div>
    </div>
    <div class="w-full pt-4">
      <div class="w-full flex flex-wrap">

        <div class="w-1/2 px-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Action Type</mat-label>
            <mat-select placeholder="Select" formControlName="action_type"
              (selectionChange)="onActionTypeChange($event.value)">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of action_types; let i=index" [value]="item.vsd_value">
                {{item?.vsd_code}}
              </mat-option>
            </mat-select>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="w-1/2 px-1" *ngIf="actionType === 'list_view'">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>List View</mat-label>
            <mat-select placeholder="Select" formControlName="list_view">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of setupListView" [value]="item?._id">
                {{item?.list_view_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-1/2 px-1" *ngIf="actionType === 'form_render'">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Form Render</mat-label>
            <mat-select placeholder="Select" formControlName="form_render">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of formSetups" [value]="item?._id">
                {{item?.form_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-1/2 px-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Icon</mat-label>
            <mat-select placeholder="Select" formControlName="icon" aria-required="true">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of apiIcons; let i=index" [value]="item.vsd_value">
                <mat-icon [svgIcon]="item.vsd_value"></mat-icon>
                {{item.vsd_property_1}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-1/2 px-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Tooltip</mat-label>
            <input matInput type="text" formControlName="tooltip">
          </mat-form-field>
        </div>

        <ng-container *ngIf="actionType !== 'form_render'">
          <div class="w-1/2 px-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Reference Type</mat-label>
              <mat-select placeholder="Select" formControlName="ref_type" (selectionChange)="getRefData($event.value)">
                <mat-option [value]="null">--</mat-option>
                <mat-option [value]="'form'">Form</mat-option>
                <mat-option [value]="'module'">Module</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="w-1/2 px-1" *ngIf="actionForm?.get('ref_type')?.value == 'module'">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Reference Id</mat-label>
              <input matInput type="text" formControlName="ref_id" [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                @for (ref of filteredRefTypesData | async; track ref) {
                  <mat-option [value]="ref?.[refKey]">{{ref?.[refDisplayKey]}}</mat-option>
                }
              </mat-autocomplete>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="w-1/2 px-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Drawer Width</mat-label>
            <mat-select placeholder="Select" formControlName="drawer_width">
              <mat-option [value]="null">--</mat-option>
              <mat-option [value]="'100%'">100%</mat-option>
              <mat-option [value]="'75%'">75%</mat-option>
              <mat-option [value]="'50%'">50%</mat-option>
              <mat-option [value]="'25%'">25%</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div c class="w-1/2 px-1" *ngIf="selectedActionType === 'form-conversations'" formGroupName="configurations">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Class</mat-label>
            <mat-select placeholder="Select" formControlName="class_type">
              <mat-option [value]="null">--</mat-option>
              <mat-option [value]="'100%'">Icon-Size-sm</mat-option>
              <mat-option [value]="'75%'">Icon-Size-md</mat-option>
              <mat-option [value]="'50%'">Icon-Size-lg</mat-option>
              <mat-option [value]="'25%'">Icon-Size-xl</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-1/2 px-1" *ngIf="selectedActionType === 'form-conversations'" formGroupName="configurations">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Delete Permission</mat-label>
            <mat-select placeholder="Select" formControlName="delete_permission">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngIf="selectedActionType === 'schedule'">
          <div class="w-1/2 px-1" formGroupName="configurations">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Show Events</mat-label>
              <mat-select placeholder="Select" formControlName="show_events" multiple>
                <mat-option [value]="null">--</mat-option>
                <ng-container *ngFor="let event of eventTypes">
                  <mat-option *ngIf="event?.vsd_value" [value]="event?.vsd_value">{{event?.vsd_value}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</ng-template>