import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SharedModule } from 'app/shared/shared.module';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactsService } from 'app/modules/features/contacts/contacts.service';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { VALUE_SET_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';

@Component({
    selector: 'tenants-team',
    templateUrl: './team.component.html',
    styleUrl: './team.component.scss',
    standalone: true,
    imports: [SharedModule],
})
export class TenantsTeamComponent implements OnInit {
    @ViewChild('avatarFileInput') private _avatarFileInput: ElementRef;
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    @Input() row_data: any;
    contacts: any;
    configForm: FormGroup;
    apiUrl: string = environment.socketApiRoot;

    contactsCount: number = 0;
    countries: any;
    drawerMode: 'side' | 'over';
    selectedContact: any;
    tenants: any;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    contactForm: any;
    contactId: any;
    selectedTenant: any;
    roles: any;
    selectedLabel: string = 'Primary';

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetectorRef: ChangeDetectorRef,
        private _contactsService: ContactsService,
        private fb: FormBuilder,
        // @Inject(DOCUMENT) private _document: any,
        private _router: Router,
        private _snackbar: SnackbarService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _commonService: CommonService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.row_data && this.row_data.tenant_id) {
            console.log('tenant', this.row_data.tenant_id)
            this.selectedTenant = this.row_data.tenant_id
        }

        this.getAllContacts();
        this.getAllCountries();
        this.getAllRoles();

        this.contactForm = this.fb.group({
            _id: [''],
            user_id: [''],
            avatar: [null],
            name: ['', [Validators.required]],
            emails: this.fb.array([]),
            phoneNumbers: this.fb.array([]),
            title: [''],
            role_id: [''],
            company: [''],
            birthday: [null],
            address: [null],
            notes: [null],
            enableLogin: [false]
            // tags        : [[]],
        });

    }

    async getAllContacts() {
        const contacts: any = await this._commonService.getDataByField(MODELS_CONSTANTS.CONTACTS, 'tenant_id', this.selectedTenant).toPromise();
        if (contacts?.status === 200) {
            this.contacts = contacts?.data || [];
            this.contactsCount = this.contacts?.length || 0;
            this._changeDetectorRef.markForCheck();
        }
    }

    async getAllCountries() {
        const countries: any = await this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', VALUE_SET_CODE_CONSTANTS.COUNTRIES).toPromise();
        console.log('countries', countries)
        if (countries?.status === 200) {
            this.countries = countries?.data || [];
            this._changeDetectorRef.markForCheck();
        }
    }

    async getAllRoles() {
        const roles: any = await this._commonService.getDataByField(MODELS_CONSTANTS.ROLES,'tenant_id',this.selectedTenant).toPromise();
        console.log('roles', roles)
        if (roles?.status === 200) {
            this.roles = roles?.data || [];
            this._changeDetectorRef.markForCheck();
        }
    }

    addContact() {
        this.contactForm.reset();
        this.contactId = null;
        this.fillData({});

        this.matDrawer.toggle();
    }

    modifyTask(rowData) {
        this.contactForm.reset(rowData);
        this.contactId = rowData?._id;
        this.fillData(rowData);
        this.matDrawer.toggle();
    }

    fillData(contact) {
        (this.contactForm.get('emails') as UntypedFormArray).clear();
        (this.contactForm.get('phoneNumbers') as UntypedFormArray).clear();

        // Setup the emails form array
        const emailFormGroups = [];

        if (contact.emails?.length > 0) {
            // Iterate through them
            contact.emails.forEach((email) => {
                // Create an email form group
                emailFormGroups.push(
                    this.fb.group({
                        email: [email.email],
                        label: [email.label],
                    })
                );
            });
        } else {
            // Create an email form group
            emailFormGroups.push(
                this.fb.group({
                    email: [''],
                    label: ['Primary'],
                })
            );
        }

        // Add the email form groups to the emails form array
        emailFormGroups.forEach((emailFormGroup) => {
            (this.contactForm.get('emails') as UntypedFormArray).push(
                emailFormGroup
            );
        });

        // Setup the phone numbers form array
        const phoneNumbersFormGroups = [];

        if (contact.phoneNumbers?.length > 0) {
            // Iterate through them
            contact.phoneNumbers.forEach((phoneNumber) => {
                // Create an email form group
                phoneNumbersFormGroups.push(
                    this.fb.group({
                        country: [phoneNumber.country],
                        phoneNumber: [phoneNumber.phoneNumber],
                        label: [phoneNumber.label],
                    })
                );
            });
        } else {
            // Create a phone number form group
            phoneNumbersFormGroups.push(
                this.fb.group({
                    country: ['us'],
                    phoneNumber: [''],
                    label: ['Primary'],
                })
            );
        }

        // Add the phone numbers form groups to the phone numbers form array
        phoneNumbersFormGroups.forEach((phoneNumbersFormGroup) => {
            (
                this.contactForm.get('phoneNumbers') as UntypedFormArray
            ).push(phoneNumbersFormGroup);
        });
    }

    save() {
        console.log("contactForm", this.contactForm.value);
        if (this.contactForm.valid) {
            const formData = this.contactForm.value;
            formData.emails = formData.emails.filter((email) => email.email);
            formData.phoneNumbers = formData.phoneNumbers.filter((phoneNumber) => phoneNumber.phoneNumber);

            if (this.contactId) {
                formData['_id'] = this.contactId;
            }
            this._commonService.saveRecord(MODELS_CONSTANTS.CONTACTS, formData).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        const type = formData?._id ? 'modified' : 'added';
                        this.matDrawer.toggle();
                        this._snackbar.success(
                            `Contact ${type} successfully`
                        );
                        this.getAllContacts();
                        // this.contactId = null;
                    } else {
                        this._snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        } else {
            this._snackbar.warning('Please fill the form');
        }
    }

    uploadAvatar(fileList: FileList): void {
        // Return if canceled
        if (!fileList?.length) {
            return;
        }

        const allowedTypes = ['image/jpeg', 'image/png'];
        const file = fileList[0];

        // Return if the file is not allowed
        if (!allowedTypes.includes(file.type)) {
            return;
        }

        // Upload the avatar
        this._contactsService.uploadAvatar(this.contactId, file).subscribe((data) => {
            this.contactForm.get('avatar').setValue(data.avatar);
        });
    }

    addEmailField(): void {
        // Create an empty email form group
        const emailFormGroup = this.fb.group({
            email: [''],
            label: ['Secondary'],
        });

        // Add the email form group to the emails form array
        (this.contactForm.get('emails') as UntypedFormArray).push(emailFormGroup);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    addPhoneNumberField(): void {
        // Create an empty phone number form group
        const phoneNumberFormGroup = this.fb.group({
            country: ['us'],
            phoneNumber: [''],
            label: ['Secondary'],
        });

        // Add the phone number form group to the phoneNumbers form array
        (this.contactForm.get('phoneNumbers') as UntypedFormArray).push(phoneNumberFormGroup);

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    
    /**
     * Remove the email field
     *
     * @param index
     */
    removeEmailField(index: number): void
    {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Remove Email',
            message: 'Are you sure you want to delete this Email? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
                cancel: {
                    label: 'Cancel'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
            // Get form array for emails
        const emailsFormArray = this.contactForm.get('emails') as UntypedFormArray;
             // Remove the email field       
            emailsFormArray.removeAt(index);
         this.ensurePrimaryEmailExists();
         // Mark for check
         this._changeDetectorRef.markForCheck();
    }
});
}

    /**
     * Remove the phone number field
     *
     * @param index
     */
    removePhoneNumberField(index: number): void
    {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Remove Number',
            message: 'Are you sure you want to delete this PhoneNumber? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
                cancel: {
                    label: 'Cancel'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
        const phoneNumbersFormArray = this.contactForm.get('phoneNumbers') as UntypedFormArray;
        phoneNumbersFormArray.removeAt(index);
        this.ensurePrimaryPhoneExists();
        this._changeDetectorRef.markForCheck();
    }
});
}
    private ensurePrimaryPhoneExists(): void {
        const phoneNumbersFormArray = this.contactForm.get('phoneNumbers') as UntypedFormArray;
        const hasPrimary = phoneNumbersFormArray.controls.some(control => control.get('label').value === 'Primary');

        if (!hasPrimary && phoneNumbersFormArray.length > 0) {
            phoneNumbersFormArray.at(0).get('label').setValue('Primary');
        }
    }
    

    phoneLabel(index: number): string {
        const phoneNumbersFormArray = this.contactForm.get('phoneNumbers') as UntypedFormArray;
        const phoneControl = phoneNumbersFormArray.at(index);
        return phoneControl.get('label').value;
    }

    setPrimaryPhone(index: number): void {
        const phoneNumbersFormArray = this.contactForm.get('phoneNumbers') as UntypedFormArray;
        phoneNumbersFormArray.controls.forEach((control, idx) => {
            control.get('label').setValue(idx === index ? 'Primary' : 'Secondary');
        });
    }
    setSecondaryPhone(index: number): void {
        const phoneNumbersFormArray = this.contactForm.get('phoneNumbers') as UntypedFormArray;
        phoneNumbersFormArray.at(index).get('label').setValue('Secondary');
    }



    removeAvatar(): void {
        // Get the form control for 'avatar'
        const avatarFormControl = this.contactForm.get('avatar');

        // Set the avatar as null
        avatarFormControl.setValue(null);

        // Set the file input value as null
        this._avatarFileInput.nativeElement.value = null;

        // Update the contact
        this.contactForm.value.avatar = null;
    }

    getCountryByIso(iso: string) {
        return this.countries?.find(country => country.vsd_property_2 === iso);
    }


    onBackdropClicked(): void {
        // Go back to the list
        this._router.navigate(['./'], { relativeTo: this._activatedRoute });

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    deleteContact() {
        this.configForm = this.fb.group({
            title: 'Remove Data',
            message: 'Are you sure you want to remove permanently? <span class="font-medium">This action cannot be undone!</span>',
            icon: this.fb.group({
                show: true,
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn'
            }),
            actions: this.fb.group({
                confirm: this.fb.group({
                    show: true,
                    label: 'Remove',
                    color: 'warn'
                }),
                cancel: this.fb.group({
                    show: true,
                    label: 'Cancel'
                })
            }),
            dismissible: true

        });

        // Open the dialog and save the reference of it
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);
        dialogRef.afterClosed().subscribe((result) => {
            if (result == "confirmed") {
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.CONTACTS, this.contactId)
                    .subscribe({
                        next: (response: any) => {
                            if (response?.status === 200) {
                                this._snackbar.success(
                                    'Staff deleted successfully'
                                );
                                this.getAllContacts();
                                this.matDrawer.toggle();
                            } else {
                                this._snackbar.error(
                                    response?.message ||
                                    MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                                );
                            }
                        },
                        error: (err) => {
                            console.error(err);
                            this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        },
                    });
            }
        });
    }

    /**
     * On Tenant change
     */
    onTenantChange(tenant_id) {
        this._contactsService.getContacts(tenant_id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private ensurePrimaryEmailExists(): void {
        const emailFormArray = this.contactForm.get('emails') as UntypedFormArray;
        const hasPrimary = emailFormArray.controls.some(control => control.get('label').value === 'Primary');

        if (!hasPrimary && emailFormArray.length > 0) {
            emailFormArray.at(0).get('label').setValue('Primary');
        }
    }
    
    emailLabel(index: number): string {
        const emailFormArray = this.contactForm.get('emails') as UntypedFormArray;
        const emailControl = emailFormArray.at(index);
        return emailControl.get('label').value;
    }


    
    setPrimary(index: number): void {
        const emailFormArray = this.contactForm.get('emails') as UntypedFormArray;
        emailFormArray.controls.forEach((control, idx) => {
            control.get('label').setValue(idx === index ? 'Primary' : 'Secondary');
        });
    }
    setSecondary(index: number): void {
        const emailFormArray = this.contactForm.get('emails') as UntypedFormArray;
        emailFormArray.at(index).get('label').setValue('Secondary');
    }

    
}
