<div class="inset-0 flex flex-col min-w-0 overflow-hidden" [ngClass]="{'absolute' : !dialogData}">
  <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="matDrawer.close()">
    <mat-drawer class="dark:bg-gray-900" [style.width]="drawerWidth" [mode]="'over'" [opened]="false" [position]="'end'"
      [disableClose]="true" [autoFocus]="false" #matDrawer>
      <div class="absolute top-0 right-0 p-2">
        <button class="btn z-10 bg-warn" matTooltip="Back" (click)="matDrawer.close()">
          <mat-icon class="back-icon icon-size-5 mt-1" matPrefix [svgIcon]="'heroicons_solid:arrow-uturn-left'">
          </mat-icon>
        </button>
      </div>
      <div class="absolute top-14 w-full component-container">
        <ng-container *ngComponentOutlet="actionComponent?.component; inputs:actionComponent?.inputs">
        </ng-container>
      </div>
    </mat-drawer>
    <mat-drawer-content class="flex flex-col">
      <div class="h-full overflow-auto flex flex-col" [ngClass]="{ 'px-4': !dialogData }">
        <div class="w-full flex flex-row">
          <div class="header-section w-full px-0 shadow"
            *ngIf="FormHeader || formHeaderData?.form_header_actions?.length">
            <div class="header">
              {{FormHeader}}
            </div>
            <div class="ml-auto flex justify-end mr-2">
              <ng-container *ngIf="formHeaderData?.form_header_actions?.length">
                <app-form-header [data]="formHeaderData" (onHeaderActionClick)="openComponent($event)"></app-form-header>
              </ng-container>
              <div *ngIf="(dialogData && (dialogData?.form_code || dialogData?.form_id)) || data?.show_close" class="cencel-dialog-icon">
                <button mat-icon-button [matTooltip]="'Close'" (click)="close()" class="mr-1 close-btn">
                  <mat-icon class="close-icon">close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="form_show" class="overflow-y-auto overflow-x-hidden px-1 h-full">
          <formio [form]="formdata" [submission]="submissiondata" (customEvent)="onCustomEvent($event)" 
           [refresh]="refreshForm" (submit)="render_formSubmit($event)" [readOnly]="form_read_only()" (formLoad)="afterFormLoad($event)">
          </formio>
        </div>

        <div *ngIf="formFooterData?.footerPermissions" class="pt-3 pb-1 mt-auto">
          <app-form-footer [data]="formFooterData" (onFooterActionClick)="openComponent($event)" (dataEvent)="receiveData($event)"></app-form-footer>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<div id="pdf-viewer" class="relative hidden h-full w-full flex flex-col" *ngIf="showPdfViewer()">
  <div class="flex justify-between w-full p-2 h-14">
    <button mat-icon-button matTooltip="Zoom In" (click)="zoomPdfPage(.2)" [disabled]="pageZoom() >= (maxZoom - .2)">
      <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-plus'"></mat-icon>
    </button>

    <button mat-icon-button matTooltip="Reset" (click)="resetPdfZoom()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-path'"></mat-icon>
    </button>

    <button mat-icon-button matTooltip="Zoom Out" (click)="zoomPdfPage(-.2)" [disabled]="pageZoom() <= (minZoom + .2)">
      <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-minus'"></mat-icon>
    </button>
  </div>

  <div class="w-full">
    <pdf-viewer 
      [src]="pdfSrc()" 
      [rotation]="0"
      [original-size]="false" 
      [show-all]="true" 
      [fit-to-page]="false"
      [zoom]="pageZoom()"
      [zoom-scale]="'page-width'" 
      [stick-to-page]="true"
      [(page)]="currentPdfPage" 
      [render-text]="true" 
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      (after-load-complete)="afterPdfLoaded($event)"
      (on-progress)="onPdfProgress($event)"
      style="height: 588px;">
    </pdf-viewer>
  </div>

  <div class="w-full h-14 flex justify-between items-center p-2">
    <button mat-icon-button matTooltip="First Page" (click)="jumpToPage(1)" [disabled]="currentPdfPage <= 1">
      <mat-icon [svgIcon]="'heroicons_outline:chevron-double-left'"></mat-icon>
    </button>

    <button mat-icon-button matTooltip="Previous Page" (click)="changePdfPage(-1)" [disabled]="currentPdfPage <= 1">
      <mat-icon [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
    </button>

    <div matTooltip="Current Page">
      {{currentPdfPage}}
    </div>

    <button mat-icon-button matTooltip="Next Page" (click)="changePdfPage(1)" [disabled]="currentPdfPage >= totalPdfPages">
      <mat-icon [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
    </button>

    <button mat-icon-button matTooltip="Last Page" (click)="jumpToPage(totalPdfPages)" [disabled]="currentPdfPage >= totalPdfPages">
      <mat-icon [svgIcon]="'heroicons_outline:chevron-double-right'"></mat-icon>
    </button>
  </div>

  <div class="z-10 absolute top-0 left-0 h-full w-full flex justify-center items-center bg-black bg-opacity-50" *ngIf="showPdfLoader()">
    <mat-progress-spinner class="z-10" [diameter]="50" color="primary" mode="determinate" [value]="progressValue"></mat-progress-spinner>
  </div>
</div>

<ng-template #form_required_fields>
  <div class="w-full h-full">

    <div class="flex justify-content-between align-items-center bg-fuse-primary text-white px-2 -ml-6 -mt-6 h-14" style="width: calc(100% + 48px);">
      <div class="font-medium text-lg ml-2">
        Required Fields
      </div>
      <div>
        <button mat-icon-button mat-dialog-close type="button">
          <mat-icon [svgIcon]="'heroicons_solid:x-mark'" class="text-white"></mat-icon>
        </button>
      </div>
    </div>

    <div class="w-full overflow-auto" style="height: calc(100% - 32px)">
      <ng-container *ngFor="let field of allRequiredFields">
        <div class="w-full flex justify-content-between p-2 border-b">
          <div class="pr-3">
            {{field}}
          </div>
          @if (requiredFields?.includes(field)) {
            <mat-icon [svgIcon]="'heroicons_solid:x-circle'" class="text-red-600"></mat-icon>
          } @else { 
            <mat-icon [svgIcon]="'heroicons_solid:check-circle'" class="text-green-600"></mat-icon>
          }
        </div>
      </ng-container>
    </div>

  </div>
</ng-template>

<div class="z-10 absolute top-0 left-0 h-full w-full flex justify-center items-center bg-black bg-opacity-50" *ngIf="showLoader()">
  <mat-spinner class="z-10" [diameter]="50" color="primary"></mat-spinner>
</div>