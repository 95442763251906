import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, filter, lastValueFrom, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Tag, Task } from './tasks.types';
@Injectable({ providedIn: 'root' })
export class TasksService {
    // Private
    private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);
    private _task: BehaviorSubject<Task | null> = new BehaviorSubject(null);
    private _tasks: BehaviorSubject<Task[] | null> = new BehaviorSubject(null);
    private _drawer: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * Constructor
     */
    constructor(private _commonService: CommonService, private _authService: AuthService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for tags
     */
    get tags$(): Observable<Tag[]> {
        return this._tags.asObservable();
    }

    /**
     * Getter for task
     */
    get task$(): Observable<Task> {
        return this._task.asObservable();
    }

    /**
     * Getter for tasks
     */
    get tasks$(): Observable<Task[]> {
        return this._tasks.asObservable();
    }

    /**
     * Getter for drawer
     */
    get drawer$(): Observable<boolean> {
        return this._drawer.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get tags
     */
    getTags(): Observable<Tag[]> {
        let userInfo = this._authService.userInfo;
        return this._commonService
            .getDataByField(
                MODELS_CONSTANTS.LABELS,
                'user_id',
                userInfo?._id
            )
            .pipe(
                map((response: IResponse<Tag[]>) => {
                    const tags =
                        response?.data && response?.data?.length
                            ? response?.data?.filter((e) => e?.type === 'task')
                            : [];
                    this._tags.next(tags);
                    return tags;
                })
            );
    }

    /**
     * Crate tag
     *
     * @param tag
     */
    createTag(tag: Tag): Observable<Tag> {
        let userInfo = this._authService.userInfo;
        tag['type'] = 'task';
        tag['user_id'] = userInfo?._id;
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._commonService.saveRecord(MODELS_CONSTANTS.LABELS, tag).pipe(
                map((response: IResponse<any>) => {
                    if (response?.status === 200) {
                        // Update the tags with the new tag
                        this._tags.next([...tags, response?.data]);

                        // Return new tag from observable
                        return response?.data;
                    }
                }),
            )),
        );
    }

    /**
     * Update the tag
     *
     * @param id
     * @param tag
     */
    updateTag(id: string, tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._commonService.saveRecord(MODELS_CONSTANTS.LABELS, tag).pipe(
                map((response: IResponse<any>) => {
                    if (response?.status === 200) {
                        // Find the index of the updated tag
                        const index = tags.findIndex((item) => item._id === id);

                        // Update the tag
                        tags[index].title = tag.title;

                        // Update the tags
                        this._tags.next(tags);

                        // Return the updated tag
                        return tags[index];
                    }
                }),
            )),
        );
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.LABELS, id)
                    .pipe(
                        map((response: IResponse<any>) => {
                            if (response?.status === 200) {
                                // Find the index of the deleted tag
                                const index = tags.findIndex(
                                    (item) => item._id === id
                                );

                                // Delete the tag
                                tags.splice(index, 1);

                                // Update the tags
                                this._tags.next(tags);

                                // Return the deleted status
                                return true;
                            } else {
                                return false;
                            }
                        }),
                        filter((isDeleted) => isDeleted),
                        switchMap((isDeleted) =>
                            this.tasks$.pipe(
                                take(1),
                                map((tasks) => {
                                    // Iterate through the tasks
                                    tasks.forEach((task) => {
                                        const tagIndex = task.tags.findIndex(
                                            (tag) => tag === id
                                        );

                                        // If the task has a tag, remove it
                                        if (tagIndex > -1) {
                                            task.tags.splice(tagIndex, 1);
                                        }
                                    });

                                    // Return the deleted status
                                    return isDeleted;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Get tasks
     */
    getTasks(data?:any): Observable<Task[]> {
        let userInfo = this._authService.userInfo;
        const params = {};
        // if(data) {
        //     params['reference_type_id'] = data?.reference_type_id || null;
        //     params['reference_type'] = data?.reference_type;

        //     if(data?.reference_type !== 'module' && data?.reference_id) {
        //         params['reference_id'] = data?.reference_id;
        //     }
        // } else {
        //     params['user_id'] = userInfo?._id;
        //     params['reference_type'] = 'menu';
        // }

        params['user_id'] = userInfo?._id;
        params['reference_type'] = 'menu';
        
        return this._commonService
            .getDataByFields(
                MODELS_CONSTANTS.TASKS,
                params
            )
            .pipe(
                map((response: IResponse<Task[]>) => {
                    const data = response?.data || [];
                    data.sort((a, b) => b.order - a.order);
                    this._tasks.next(data);
                    return data;
                })
            );
    }

    /**
     * Update tasks orders
     *
     * @param tasks
     */
    async updateTasksOrders(tasks: Task[], prevTasks: Task[]): Promise<void> {
        const clonedTasks = cloneDeep(tasks).reverse();
        const clonedPrevTasks = cloneDeep(prevTasks).reverse();
        for (let [index, task] of clonedTasks.entries()) {
            const oldTaskOrder = clonedPrevTasks.find(
                (e) => e?._id === task?._id
            )?.order;
            if (oldTaskOrder !== index + 1) {
                const result$ = this.saveTask({
                    _id: task?._id,
                    order: index + 1,
                });
                await lastValueFrom(result$);
            }
        }
    }

    /**
     * Get task by id
     */
    getTaskById(id: string): Observable<Task> {
        return this._tasks.pipe(
            take(1),
            map((tasks) => {
                // Find the task
                let task;
                
                if(id === 'create'){
                    task = JSON.parse(localStorage.getItem('task_data'));
                    console.log('taskcreatedata',task)
                    // task = {};
                } else {
                    // Find the task
                    task = tasks.find(item => item._id === id) || null;
                }

                // Update the task
                this._task.next(task);

                // Return the task
                return task;
            }),
            switchMap((task) => {
                if (!task) {
                    return throwError('Could not found task with id of ' + id + '!');
                }

                return of(task);
            }),
        );
    }

    /**
     * Update task
     *
     * @param id
     * @param task
     */
    updateTask(id: string, task: Task): Observable<Task> {
        return this.tasks$.pipe(
            take(1),
            switchMap((tasks) =>
                this._commonService
                    .saveRecord(MODELS_CONSTANTS.TASKS, task)
                    .pipe(
                        map((response) => {
                            // Find the index of the updated task
                            const index = tasks.findIndex(
                                (item) => item._id === id
                            );

                            // Update the task
                            tasks[index] = task;

                            // Update the tasks
                            this._tasks.next(tasks);

                            // Return the updated task
                            return task;
                        }),
                        switchMap((updatedTask) =>
                            this.task$.pipe(
                                take(1),
                                filter((item) => item && item._id === id),
                                tap(() => {
                                    // Update the task if it's selected
                                    this._task.next(updatedTask);

                                    // Return the updated task
                                    return updatedTask;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Save task
     */
    saveTask(task): Observable<any> {
        let userInfo = this._authService.userInfo;
        task.user_id = userInfo._id;
        return this.tasks$.pipe(
            take(1),
            switchMap((tasks) =>
                this._commonService
                    .saveRecord(MODELS_CONSTANTS.TASKS, task)
                    .pipe(
                        map((response: IResponse<any>) => {
                            if (response?.status === 200 && !task?._id) {
                                // Update the tasks with the new task
                                this._tasks.next([response?.data, ...tasks]);

                                // Update the Task
                                this._task.next(response?.data);

                                localStorage.removeItem('task_data');
                            }
                            // Return the new task
                            return response;
                        })
                    )
            )
        );

        // return this.tasks$.pipe(
        //     take(1),
        //     switchMap((tasks) => {
        //         return this._commonService.saveRecord(MODELS_CONSTANTS.TASKS, task).pipe(
        //             tap((response: IResponse<any>) => {
        //                 if(!task?._id){

        //                 }
        //             })
        //         );
        //     })
        // )
    }

    /**
     * Delete the task
     *
     * @param id
     */
    deleteTask(id: string): Observable<boolean> {
        return this.tasks$.pipe(
            take(1),
            switchMap((tasks) =>
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.TASKS, id)
                    .pipe(
                        map((response: IResponse<any>) => {
                            if (response?.status === 200) {
                                // Find the index of the deleted task
                                const index = tasks.findIndex(
                                    (item) => item._id === id
                                );

                                // Delete the task
                                tasks.splice(index, 1);

                                // Update the tasks
                                this._tasks.next(tasks);

                                // Return the deleted status
                                return true;
                            } else {
                                return false;
                            }
                        })
                    )
            )
        );
    }

    openDrawer(){
        this._drawer.next(true)
    }

    closeDrawer(){
        this._drawer.next(false)
    }
}
