import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthService } from 'app/core/auth/auth.service';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';

@Component({
    selector: 'settings-notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatSlideToggleModule, MatButtonModule],
})
export class SettingsNotificationsComponent implements OnInit {

    notificationsForm: FormGroup;
    loggedInUserId: any;
    notificationPermissions: any;
    _id: any;
    submitted: boolean;

    constructor(
        private _commonService: CommonService,
        private fb: FormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private snackbar: SnackbarService,
        private _authService: AuthService
    ) { }

    ngOnInit(): void {
        this.notificationsForm = this.fb.group({
            communication_notify: [''],
            security_notify: [''],
            meetups_notify: [''],
            sms_notification: [''],
            whatsapp_notification: [''],
            email_notification: [''],
            push_notification: [''],
            notify_someone_comments: [''],
            notify_someone_mention: [''],
            notify_someone_follows: [''],
            notify_someone_replies: [''],
        });
        this.getNotificationPermissions();
    }

    getNotificationPermissions() {
        const userInfo = this._authService.userInfo;
        if (userInfo && JSON.stringify(userInfo) !== '{}') {
            this.loggedInUserId = userInfo._id;
            console.log('log-in userNotify-id', this.loggedInUserId);
        }
        this._commonService
            .getDataByField(MODELS_CONSTANTS.USER_INFO, 'user_id', this.loggedInUserId)
            .subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.notificationPermissions = response?.data[0];
                        console.log('log-in userNotify-list', this.notificationPermissions);

                        this.modifyNotificationPermissions(this.notificationPermissions);

                        this._changeDetectorRef.markForCheck();
                    }
                },
                error: (err) => {
                    console.error(err);
                },
            });
    }

    modifyNotificationPermissions(notifydata) {
        this.notificationsForm.reset(notifydata);
        this._id = notifydata?._id;
    }

    saveNotificationPermissions() {
        this.submitted = true;
        if (this.notificationsForm.touched && this.notificationPermissions) {
            const formData = this.notificationsForm.value;
            formData.user_id = this.loggedInUserId;
            formData.first_name = this.notificationPermissions.first_name;
            formData.last_name = this.notificationPermissions.last_name;

            if (this._id) {
                formData['_id'] = this._id;
            }
            this._commonService
                .saveRecord(MODELS_CONSTANTS.USER_INFO, formData)
                .subscribe({
                    next: (response: any) => {
                        if (response?.status === 200) {
                            const type = formData?._id ? 'modified' : 'added';
                            this.snackbar.success(
                                `Notification Permissions ${type} successfully`
                            );
                            this.getNotificationPermissions();
                        } else {
                            this.snackbar.error(
                                response?.message ||
                                MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                            );
                        }
                    },
                    error: (err) => {
                        console.error(err);
                        this.snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    },
                });
        } else {
            this.notificationPermissions ? this.snackbar.warning('Please change the value') : this.snackbar.warning('Please create the account setting')
        }
    }
}
