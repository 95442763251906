import { DIALOG_DATA } from '@angular/cdk/dialog';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';

@Component({
    selector: 'task-view',
    templateUrl: './task-view.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCalendar, MatDialogClose, MatTableModule, MatCard, MatMenuModule, MatFormFieldModule, MatIconModule, MatInputModule, CommonModule,
        TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatDatepickerModule, MatTooltip],
})
export class TaskViewComponent implements OnInit {
    @ViewChild('milestoneTemplate')
    configForm: FormGroup;
    dialogRef: MatDialogRef<any>;

    displayedColumns: string[] = [
        'title',
        'due_date',
        'description',
        'actions'
    ];
    taskDetails;
    taskDate: any = [];
    users;
    labelList;
    milestone;
    workingHours;
    projectId;
    taskId;
    statusList;
    projects;
    comment;
    userData;
    commentList: any = [];
    subTaskDetails;
    createBtn: Boolean = false;
    subTask;
    changeTaskRecords: Boolean = false;
    changeSubTaskRecords: Boolean = false;
    /**
     * Constructor
     */
    constructor(
        @Inject(DIALOG_DATA) private config: { name: string },
        private _commonService: CommonService,
        private _snackbar: SnackbarService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _authService: AuthService
    ) {
    }
    /**
     * On init
     */
    ngOnInit(): void {
        if (this.config) {
            this.projectId = this.config['projectId'];
            this.taskId = this.config['taskId'];
        }
        this.userData = this._authService.userInfo;;
        this.getAllData();
    }

    async getAllData() {
        const userResult = await this._commonService.getAllData(MODELS_CONSTANTS.USERS).toPromise();
        if (userResult?.status === 200) {
            this.users = userResult?.data || [];
        }
        const projectResult = await this._commonService.getAllData(MODELS_CONSTANTS.PROJECT).toPromise();
        if (projectResult?.status === 200) {
            this.projects = projectResult?.data || [];
        }
        const labelResult = await this._commonService.getDataByField(MODELS_CONSTANTS.LABEL_MASTER, 'labelType', 'tasks').toPromise();
        if (labelResult?.status === 200) {
            this.labelList = labelResult?.data || [];
        }
        const milestoneResult = await this._commonService.getDataByField(MODELS_CONSTANTS.MILESTONE, 'project_id', this.projectId).toPromise();
        if (milestoneResult?.status === 200) {
            this.milestone = milestoneResult?.data || [];
        }
        const statusResult = await this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'TASK_STATUS').toPromise();
        if (statusResult?.status === 200) {
            this.statusList = statusResult?.data || [];
        }
        this.getAllTasks();
        this.getAllsubTasks();
    }

    async getAllTasks() {
        const response = await this._commonService.getDataByField(MODELS_CONSTANTS.PROJECT_TASKS, '_id', this.taskId).toPromise();
        if (response.status == 200 && response?.data?.length) {
            this.taskDetails = response?.data[0];
            this.loadTask(this.taskDetails);
            this.loadTaskDate(this.taskDetails);
        } else {
            this.taskDetails = [];
        }
        this._changeDetectorRef.markForCheck();
    }

    async getAllsubTasks() {
        let data: any = {
            parent_id: this.taskId,
            task_type: 'SubTask'
        }
        const response = await this._commonService.getDataByMultipleField(MODELS_CONSTANTS.PROJECT_TASKS, data).toPromise();
        if (response.status == 200 && response?.data?.length) {
            this.subTaskDetails = response?.data
        } else {
            this.subTaskDetails = [];
        }
        this._changeDetectorRef.markForCheck();
    }

    changeValue(value, field_name, data) {
        data[field_name] = value;
        this.loadTask(data);
    }

    loadTask(data) {
        if (data?.assing_to) {
            let assingto = this.users.find((item) => item._id == data.assing_to)
            if (assingto)
                data.assing_to_name = assingto.name;
        }
        if (data?.collaborators) {
            let collaborator = this.users.find((item) => item._id == data.collaborators)
            if (collaborator)
                data.collaborator_name = collaborator.name;
        }
        if (data?.milestone_id) {
            let milestone = this.milestone.find((item) => item._id == data.milestone_id)
            if (milestone)
                data.milestone_title = milestone.title;
        }
        if (data?.label_id) {
            let label = this.labelList.find((item) => item._id == data.label_id)
            if (label)
                data.labelName = label.labelName;
        }
        if (data?.project_id) {
            let project = this.projects.find((item) => item._id == data.project_id)
            if (project)
                data.project_name = project.projectTitle;
        }
        this.taskDetails = data;
        this._changeDetectorRef.markForCheck();
    }

    loadTaskDate(data) {
        if (data?.task_date) {
            if (data?.task_date?.length != 0) {
                this.taskDate = data.task_date;
                let sum = 0;
                this.taskDate.forEach(element => {
                    sum = sum + Number(element.time)
                });
                this.workingHours = sum;
            } else {
                this.taskDate = [];
                let json: any = {
                    date: '',
                    time: ''
                }
                this.taskDate.push(json);
                this.workingHours = '';
            }
        } else {
            this.taskDate = [];
            let json: any = {
                date: '',
                time: ''
            }
            this.taskDate.push(json);
            this.workingHours = '';
        }
        if (data?.comment) {
            if (data?.comment?.length)
                this.commentList = data.comment;
        }
        this._changeDetectorRef.markForCheck();
    }

    addMore() {
        let json: any = {
            date: '',
            time: ''
        }
        console.log('json', json);
        this.taskDate.push(json);
    }

    // removeHours(index: number) {
    //     this.taskDate.splice(index, 1);
    // }
    removeHours(index: number) {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Remove Hours',
            message: 'Are you sure you want to remove these hours? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Remove',
                },
            },
        });
    
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.taskDate.splice(index, 1);
                this._snackbar.success('Hours Removed successfully');
            }
        });
    }
    

    removeFields() {
        if (this.taskDetails.assing_to_name)
            delete this.taskDetails.assing_to_name;
        if (this.taskDetails.collaborator_name)
            delete this.taskDetails.collaborator_name;
        if (this.taskDetails.milestone_title)
            delete this.taskDetails.milestone_title;
        if (this.taskDetails.labelName)
            delete this.taskDetails.labelName;
    }

    save() {
        this.removeFields();
        if (this.taskDetails) {
            const formData = this.taskDetails;
            if (this.taskDate?.length) {
                if (this.taskDate[0].date && this.taskDate[0].time)
                    formData.task_date = this.taskDate;
            }

            this._commonService.saveRecord(MODELS_CONSTANTS.PROJECT_TASKS, formData).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.dialogRef?.close();
                        const type = formData?._id ? 'modified' : 'added';
                        this._snackbar.success(
                            `Task ${type} successfully`
                        );
                        this.getAllTasks();
                    } else {
                        this._snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        } else {
            this._snackbar.warning('Please fill the form');
        }
    }

    addComment() {
        if (this.comment) {
            let comments: any = {
                id: this.taskDetails._id,
                user_id: this.userData._id,
                user_name: this.userData.name,
                message: this.comment
            }
            this.commentList.push(comments);
            this.saveComment();
        } else {
            this._snackbar.warning('Please Enter the comment');
        }
    }

    deleteComment(index) {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete Comment',
            message: 'Are you sure you want to delete this comment? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });
    
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.commentList.splice(index, 1);
                this.saveComment();
                this._snackbar.success('Comment deleted successfully');
            }
        });
    }

    saveComment() {
        let formData: any = {
            _id: '',
            comment: '',
            task_title: '',
            assing_to: '',
            project_id: '',
        }
        formData.comment = this.commentList;
        formData._id = this.taskDetails._id;
        formData.task_type = 'Task';
        formData.task_title = this.taskDetails.task_title;
        formData.assing_to = this.taskDetails.assing_to;
        formData.project_id = this.taskDetails.project_id;

        this._commonService.saveRecord(MODELS_CONSTANTS.PROJECT_TASKS, formData).subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.dialogRef?.close();
                    const type = formData?._id ? 'modified' : 'added';
                    this._snackbar.success(
                        this.comment ? `Comment added successfully` : `Comment deleted successfully`
                    );
                    this.getAllTasks();
                    this.comment = null;
                } else {
                    this._snackbar.error(
                        response?.message ||
                        MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                    );
                }
            },
            error: (err) => {
                console.error(err);
                this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            },
        });

    }

    subTaskField() {
        this.createBtn = true;
    }

    closeSubTaskField() {
        this.createBtn = false;
    }

    addSubTask() {
        if (this.subTask) {
            let formData: any = {
                task_type: 'SubTask',
                task_title: this.subTask,
                assing_to: this.taskDetails.assing_to,
                project_id: this.taskDetails.project_id,
                parent_id: this.taskDetails._id,
                task_status: 'Todo'
            }
            this.saveSubTask(formData)
        } else {
            this._snackbar.warning('Please Enter the task');
        }
    }

    updateSubTask(status, data) {
        data['task_status'] = status;
        this.saveSubTask(data);
    }

    saveSubTask(formData) {

        this._commonService.saveRecord(MODELS_CONSTANTS.PROJECT_TASKS, formData).subscribe({
            next: (response: any) => {
                if (response?.status === 200) {
                    this.dialogRef?.close();
                    const type = formData?._id ? 'modified' : 'added';
                    this._snackbar.success(
                        `Task ${type} successfully`
                    );
                    this.subTask = null;
                    this.getAllsubTasks();
                } else {
                    this._snackbar.error(
                        response?.message ||
                        MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                    );
                }
            },
            error: (err) => {
                console.error(err);
                this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            },
        });
    }

    deleteSubTask(id) {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete Subtask',
            message: 'Are you sure you want to delete this subtask? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });
    
        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.PROJECT_TASKS, id)
                    .subscribe({
                        next: (response: any) => {
                            if (response?.status === 200) {
                                this._snackbar.success('Subtask deleted successfully');
                                this.getAllsubTasks();
                            } else {
                                this._snackbar.error(
                                    response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                                );
                            }
                        },
                        error: (err) => {
                            console.error(err);
                            this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        },
                    });
            }
        });
    }
    
    closeDialog() {
        this.dialogRef.close();
    }
}
    

