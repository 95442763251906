import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, lastValueFrom, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private base_url: string = `${environment.apiRoot}/navigation`;
    private _compactNavigation: FuseNavigationItem[] = [];
    private _defaultNavigation: FuseNavigationItem[] = [];
    private _futuristicNavigation: FuseNavigationItem[] = [];
    private _horizontalNavigation: FuseNavigationItem[] = [];
    private _navigation: ReplaySubject<any> = new ReplaySubject<any>(1);
    private _currentMenu: BehaviorSubject<any> = new BehaviorSubject(null);
    private _navigationFetched: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _roleMenus: BehaviorSubject<any> = new BehaviorSubject([]);

    menuRolePermissions: any[] = [];

    constructor(private httpClient: HttpClient, private authService: AuthService, private _commonService: CommonService) { }

    private _getHeaders(): HttpHeaders {
        const token = this.authService.access_token;
        if (!token) {
            // Returning an empty headers object if token is not found
            return new HttpHeaders();
        }
        return new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
    }

    get navigation$(): Observable<any> {
        // Triggering the navigation data fetching when accessing the property
        this.get();
        this.get_api_category_db_params();
        return this._navigation.asObservable();
    }

    get currentMenu$(): Observable<any> {
        return this._currentMenu.asObservable();
    }

    get navigationFetched$(): Observable<boolean> {
        return this._navigationFetched.asObservable();
    }

    get roleMenus$(): Observable<any> {
        return this._roleMenus.asObservable();
    }

    get() {
        // Making the HTTP request to fetch navigation data
        this.httpClient.get(this.base_url, {
            headers: this._getHeaders(),
        }).pipe(
            switchMap(async (navigationData: any) => {
                navigationData.data.sort((a, b) => a.sequence - b.sequence);

                let menus = [];
                navigationData.data.forEach((item, index) => {
                    const { children, ...rest } = cloneDeep(item);
                    menus?.push(rest);

                    if(children && children?.length) {
                        menus = menus.concat(children);
                    }

                    if(index === navigationData.data.length - 1){
                        this._roleMenus.next(menus);
                    }
                });

                this.processNavigationData(navigationData.data);
                await this.getMenuRolePermissions();
                
                return navigationData;
            }),
            catchError(error => {
                // Handling errors gracefully
                console.error('Error fetching navigation data:', error);
                return throwError(error);
            })
        ).subscribe();
    }

    async get_api_category_db_params(){
        const response$ = this._commonService.getDataByField(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            'vs_code',
            'API_CATE_DB_PARAMS'
        );
        const response = await lastValueFrom(response$);
        if (response?.status === 200 && response?.data?.length) {
            const output = {};
            response.data.forEach(item => {
                const { vsd_code, vsd_value, vsd_property_1 } = item;
                if (!output[vsd_code]) {
                    output[vsd_code] = {};
                }
                output[vsd_code][vsd_value] = vsd_property_1;
            });
            await localStorage.setItem('api_cate_db_params', JSON.stringify(output))
        }
    }

    private processNavigationData(navigationData: any): void {
        const parentMenus: any[] = navigationData.filter(val => (val.parent_id == null || val.parent_id == '' || val.parent_id == '*'));
        this._compactNavigation = cloneDeep(parentMenus);
        this._defaultNavigation = cloneDeep(parentMenus);
        this._futuristicNavigation = cloneDeep(parentMenus);
        this._horizontalNavigation = cloneDeep(parentMenus);

        parentMenus.forEach((defaultNavItem) => {
            this._compactNavigation.forEach((compactNavItem) => {
                if (compactNavItem.children?.length > 0) {
                    compactNavItem.type = 'aside';
                    compactNavItem.children.forEach((childItem) => {
                        if (childItem.children?.length > 0) {
                            childItem.type = 'collapsable';
                        }else{
                            childItem.type = 'basic';
                        }
                    })
                }else{
                    compactNavItem.type = 'basic';
                }
            });
        });

        parentMenus.forEach((defaultNavItem) => {
            this._defaultNavigation.forEach((dfltNavItem) => {
                if (dfltNavItem.children?.length > 0) {
                    dfltNavItem.type = 'collapsable';
                    dfltNavItem.children.forEach((childItem) => {
                        if (childItem.children?.length > 0) {
                            childItem.type = 'collapsable';
                        }else{
                            childItem.type = 'basic';
                        }
                    })
                }
            });
        });

        parentMenus.forEach((defaultNavItem) => {
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                if (futuristicNavItem.children?.length > 0) {
                    futuristicNavItem.type = 'aside';
                    futuristicNavItem.children.forEach((childItem) => {
                        if (childItem.children?.length > 0) {
                            childItem.type = 'collapsable';
                        }else{
                            childItem.type = 'basic';
                        }
                    })
                }
            });
        });

        parentMenus.forEach((defaultNavItem) => {
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                if (horizontalNavItem.children?.length > 0) {
                    horizontalNavItem.type = 'aside';
                    horizontalNavItem.children.forEach((childItem) => {
                        if (childItem.children?.length > 0) {
                            childItem.type = 'collapsable';
                        }else{
                            childItem.type = 'basic';
                        }
                    })
                }
            });
        });

        // Notify subscribers with processed navigation data
        this._navigation.next({
            compact: cloneDeep(this._compactNavigation),
            default: cloneDeep(this._defaultNavigation),
            futuristic: cloneDeep(this._futuristicNavigation),
            horizontal: cloneDeep(this._horizontalNavigation)
        });
    }

    private async getMenuRolePermissions(){
        return new Promise<void>(async (resolve) => {
            const userInfo = this.authService.userInfo;
            const response$ = this._commonService.getDataByField(
                MODELS_CONSTANTS.MENU_ROLES,
                'role_id',
                userInfo?.role_id
            );
            const response = await lastValueFrom(response$);
            if (response?.status === 200 && response?.data?.length) {
                this.menuRolePermissions = response?.data;
                setTimeout(() => {
                    this._navigationFetched.next(true);
                }, 1000);
                resolve();
            } else {
                resolve();
            }
        })
    }

    async setCurrentMenu(menu){
        const currentMenu = cloneDeep(menu);

        if (!this.menuRolePermissions || !this.menuRolePermissions?.length) {
            await this.getMenuRolePermissions();
        }

        // Get menu permissions
        let menuRole = this.menuRolePermissions?.find(
            (e) => e?.menu_id === menu?.id
        );

        const { read, write } = menuRole || {};
        const permissions = { read: read ?? true, write: write ?? false };
        currentMenu['permissions'] = permissions;

        // Set current menu
        this._currentMenu.next(currentMenu);
        this._navigationFetched.next(true);
    }
}
