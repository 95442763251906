import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { Message } from 'app/layout/common/messages/messages.types';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { Subject, lastValueFrom } from 'rxjs';

@Component({
    selector       : 'messages',
    templateUrl    : './messages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'messages',
    standalone     : true,
    imports        : [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class MessagesComponent implements OnInit, OnDestroy
{
    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

    messages: Message[] = [];
    unreadCount: number = 0;
    user: any = {};
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _commonService: CommonService,
        private _snackbar: SnackbarService,
        private _authService: AuthService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // // Subscribe to message changes
        this.getAllMessages();
        // this._messagesService.messages$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((messages: Message[]) =>
        //     {
        //         // Load the messages
        //         this.messages = messages;

        //         // Calculate the unread count
        //         this._calculateUnreadCount();

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all the messages
     */
    
    getAllMessages(){
        this.user = this._authService.userInfo;
        this._commonService.getDataByField(MODELS_CONSTANTS.MESSAGES, 'user_id', this.user._id).subscribe({
            next: (response: IResponse<Message[]>) => {
                if(response?.status === 200){
                    this.messages = response?.data || [];
                    this._calculateUnreadCount();
                    this._changeDetectorRef.detectChanges();
                }
            },
            error: (err) => {
                console.error(err)
            }
        })
    }

    /**
     * Open the messages panel
     */
    openPanel(): void
    {
        // Return if the messages panel or its origin is not defined
        if ( !this._messagesPanel || !this._messagesOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }
        
        // Get all the messages
        this.getAllMessages();

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
    }

    /**
     * Close the messages panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all messages as read
     */
    async markAllAsRead()
    {
        // Mark all as read
        const unreadMessages = this.messages.filter((e) => !e.read);
        for (let [index, message] of unreadMessages.entries()) {
            message.read = true;
            const response$ = this._commonService.saveRecord(
                MODELS_CONSTANTS.MESSAGES,
                message
            );
            await lastValueFrom(response$);
            if (index === this?.messages?.length - 1) {
                this._snackbar.success('Marked all messages as read');
                this._calculateUnreadCount();
                this._changeDetectorRef.detectChanges();
            }
        }
        // this._messagesService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given message
     */
    toggleRead(message: Message): void
    {
        // Toggle the read status
        message.read = !message.read;

        // Update the message
        this._commonService
            .saveRecord(MODELS_CONSTANTS.MESSAGES, message)
            .subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this._snackbar.success('Message updated successfully');
                        this._calculateUnreadCount();
                        this._changeDetectorRef.detectChanges();
                    } else {
                        this._snackbar.error(response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        // this._messagesService.update(message.id, message).subscribe();
    }

    /**
     * Delete the given message
     */
    delete(message: Message): void
    {
        // Delete the message
        this._commonService
            .deleteRecordsById(MODELS_CONSTANTS.MESSAGES, message?._id)
            .subscribe({
                next: (response) => {
                    if (response?.status === 200) {
                        const index = this.messages.findIndex(
                            (e) => e?._id === message?._id
                        );
                        if (index !== -1) {
                            this.messages.splice(index, 1);
                            this._calculateUnreadCount();
                            this._changeDetectorRef.detectChanges();
                        }
                        this._snackbar.success('Message deleted successfully');
                    } else {
                        this._snackbar.error(
                            response?.message ||
                                MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        // this._messagesService.delete(message.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX : 'start',
                        originY : 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX : 'end',
                        originY : 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX : 'end',
                        originY : 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() =>
        {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.messages && this.messages.length )
        {
            count = this.messages.filter(message => !message.read).length;
        }

        this.unreadCount = count;
    }
}
