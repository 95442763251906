import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    Router,
    RouterOutlet,
} from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NavigationService } from './core/navigation/navigation.service';
import { SnackbarService } from './shared/services/snackbar/snackbar.service';
import { AuthService } from './core/auth/auth.service';
import { IResponse } from './shared/interfaces/response-i';
import { CommonService } from './shared/services/common/common.service';
import { MODELS_CONSTANTS } from './shared/constants/models.constants';
import { cloneDeep } from 'lodash';
// import { FirebaseService } from './shared/services/firebase/firebase.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    currentUrl: string;
    roleMenus: any[] = [];
    homepagePath: string = '';

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        // private firebaseService: FirebaseService
        private _navigationService: NavigationService,
        private _router: Router,
        private _snackbar: SnackbarService,
        private _activatedRoute: ActivatedRoute,
        private _authService: AuthService,
        private _commonService: CommonService
    ) {}

    ngOnInit() {
        //this.checkMenuAccess();

        // this.firebaseService.currentMessage.subscribe((message) => {
        //     if (message) {
        //         // Need to do notification
        //         console.log(message, "message")
        //     }
        // });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    checkMenuAccess() {
        const validateAccess = async () => {
            const allowedRoutes = [
                'scrumboard',
                'tasks',
                'chat',
                'contacts',
                'project-view',
                'tenants',
                'vendor',
                'tiles',
            ];

            let route = this._activatedRoute.snapshot;
            const isAccessRestrictionRoute =
                route?.firstChild?.data?.['isAccessRestrictionRoute'] || false;

            if (isAccessRestrictionRoute) {
                let url = decodeURIComponent(this.currentUrl);
                url = this.getTrimmedRoute(url);
                let parentMenu;

                if (url?.includes('/')) {
                    const currentRoute = url.slice(0, url.indexOf('/'));

                    if (allowedRoutes.includes(currentRoute)) {
                        parentMenu = this.roleMenus.find((e) => {
                            if (
                                e?.link === currentRoute ||
                                e?.link === `/${currentRoute}`
                            ) {
                                return e;
                            }
                        });
                        if (parentMenu) {
                            url = this.getTrimmedRoute(parentMenu?.link);
                        }
                    }
                }

                const userInfo = this._authService.userInfo;

                if (userInfo?.homepage && !this.homepagePath) {
                    const response: IResponse<any> = await this._commonService
                        .getDataByField(
                            MODELS_CONSTANTS.MENUS,
                            '_id',
                            userInfo?.homepage
                        )
                        .toPromise();

                    const menuPath = response.data[0].ref_id
                        ? `${response.data[0].menu_path}/${response.data[0].ref_id}`
                        : response.data[0].menu_path;
                    this.homepagePath = this.getTrimmedRoute(menuPath);
                }

                if (url !== '' && url !== 'home' && this.homepagePath !== url) {
                    const menu = this.roleMenus.find((e) => {
                        let link = this.getTrimmedRoute(e?.link);
                        url = this.getTrimmedRoute(url);
                        return link === url;
                    });

                    if (!menu) {
                        this._router.navigate(['home']);
                        this._snackbar.error(
                            "You don't have permission to access this menu"
                        );
                    }
                }
            }
        };

        this._navigationService.roleMenus$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.roleMenus = data;
                if (this.currentUrl) {
                    validateAccess();
                }
            });

        this._router.events
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.currentUrl = event.url;
                    if (this.roleMenus?.length) {
                        validateAccess();
                    }
                }
            });
    }

    getTrimmedRoute(route: string): string {
        route = cloneDeep(route?.trim());
        route = route?.startsWith('/') ? route.slice(1) : route;
        route = route?.endsWith('/') ? route.slice(0, -1) : route;

        return route;
    }
}
