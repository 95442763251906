<div class="w-full">
    <div class="w-full flex mb-4">
        <div class="lg:ml-0 text-3xl font-bold tracking-tight">
            Timesheet
        </div>
    </div>
    <div class="max-w-full overflow-auto table-section">
        <table mat-table [dataSource]="dataSource" matSort class="h-fit">
            <ng-container matColumnDef="task_title">
                <th mat-header-cell *matHeaderCellDef class="min-w-50" mat-sort-header (click)="sortData('task_title')"> Task </th>
                <td mat-cell class="viewName" *matCellDef="let element" (click)="viewTask(element)">
                    {{element?.task_title}} </td>
            </ng-container>

            <ng-container matColumnDef="project_name">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('project_name')"> Project </th>
                <td mat-cell *matCellDef="let element"> {{element?.project_name}} </td>
            </ng-container>

            <ng-container matColumnDef="assing_to_name">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('assing_to_name')"> Assigned To </th>
                <td mat-cell *matCellDef="let element"> {{element?.assing_to_name}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="task_status">
                <th mat-header-cell *matHeaderCellDef class="min-w-40"> Status </th>
                <td mat-cell *matCellDef="let element"> {{element?.task_status}} </td>
            </ng-container> -->

            <ng-container matColumnDef="start_time">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('start_time')"> Start Date </th>
                <td mat-cell *matCellDef="let element"> {{element?.start_time | date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="end_time">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('end_time')"> End Date </th>
                <td mat-cell *matCellDef="let element"> {{element?.end_time | date:'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="total_hours">
                <th mat-header-cell *matHeaderCellDef class="min-w-30" mat-sort-header (click)="sortData('total_hours')"> Total </th>
                <td mat-cell *matCellDef="let element"> {{element?.total_hours}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
</div>
