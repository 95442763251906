<div class="flex flex-auto flex-col px-6 sm:px-16 sm:pb-20">
  <div class="w-full max-w-3xl">
    <div class="text-4xl font-extrabold leading-none tracking-tight"> All Activities </div>
    <div class="text-secondary mt-1.5 text-lg">
      Application wide activities are listed here as individual items, starting with the most recent.
    </div>
    <div class="mt-18">
      <div class="container">
        <div id="timeline">
          <div class="timeline-item " *ngFor="let item of formDetails;let i=index">
            <button mat-icon-button class="timeline-icon border-gray-300 ring ring-white">
              <mat-icon class="text-white">stars</mat-icon>
            </button>
            <div class="timeline-content  rounded-lg shadow-lg"
              [ngClass]="i%2!=0 ? '  text-right right right-7_7  mr-1rem ' : ' text-left left-32 ml-1rem'">
              <div class=" h-full">
                <div class="h-1/2 px-2 text-white place-content-center  rounded-t-lg bg-primary">
                  <div class="text-2xl text-center ">{{item.title}}</div>
                </div>
                <div class="h-1/2 m-auto  place-content-center">
                  <div class="text-md px-2 remarks">{{item.remarks}} by {{item.done_by_user}}</div>
                  <div class="text-md px-2">{{item.date_time | date: 'dd-MMM-yyyy, h:mm a'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>