<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <ng-container *ngIf="(note$ | async) as note">
        <!-- Image -->
        <ng-container *ngIf="note.image">
            <div class="relative w-full px-4 pt-4">
                <img class="w-1/5 object-cover mx-2 mt-2" [src]="note.image">
                <div class="absolute" style="top: 8px; left: 19%;">
                    <button mat-icon-button (click)="removeImage(note)" matTooltip="Remove Image">
                        <mat-icon style="color: red;" [svgIcon]="'heroicons_mini:x-circle'" class="rounded-full bg-white"></mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>
        <div class="p-4">
            <div class="flex flex-row py-4">
                <div calss="mt-2">{{title}}</div>
                <div class="flex ml-auto">
                    <div class="flex items-center justify-end mr-8">
                    <ng-container *ngIf="!note?._id">
                            <button mat-flat-button [color]="'primary'" [disabled]="!note.title || !note.content" (click)="saveNote(note)">
                                Save
                            </button>
                    </ng-container>
                    <button *ngIf="note?._id" mat-flat-button [color]="'primary'" [disabled]="!note.title || !note.content" (click)="saveNote(note)">
                        Save
                    </button>
                    </div>
                <mat-icon class="cursor-pointer mt-2" mat-flat-button matDialogClose [matTooltip]="'Close'">close</mat-icon>
                </div>    
            </div>
            <!-- Title -->
            <div>
                <label>Title</label>
                <mat-form-field appearance="outline" class="w-full">
                <input matInput type="text"
                    class="w-full"
                    [placeholder]="'Title'"
                    [(ngModel)]="note.title"
                    >
                    </mat-form-field>
            </div>
            <!-- Note -->
            <div class="flex flex-col w-full py-2">
                <label>Note</label>
                <mat-form-field appearance="outline" class="w-full">
                <textarea matInput type="text"
                    class="w-full"
                    [placeholder]="'Note'"
                    [(ngModel)]="note.content"
                    ></textarea>
                    </mat-form-field>
            </div>
            <div  *ngIf="!note?._id">
            <ng-container *ngIf="note.labels && note.labels.length">
                <div class="flex flex-wrap items-center mx-1 mt-6">
                    <ng-container *ngFor="let label of note.labels; trackBy: trackByFn">
                        <div class="flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium dark:text-gray-300 dark:bg-gray-700" [ngStyle]="{'background-color': label.color}">
                            <div>
                                {{label.title}}
                            </div>
                            <mat-icon
                                class="ml-1 icon-size-4 cursor-pointer"
                                [svgIcon]="'heroicons_solid:x-circle'"
                                (click)="toggleLabelOnNote(note, label)"></mat-icon>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div  *ngIf="!note?._id">
            <button mat-icon-button [matMenuTriggerFor]="labelsMenu" matTooltip="Label">
                <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
            </button>
            <mat-menu #labelsMenu="matMenu">
                <ng-container *ngIf="(labels$ | async) as labels">
                    <ng-container *ngFor="let label of labels">
                        <button mat-menu-item (click)="toggleLabelOnNote(note, label)">
                            <span class="flex items-center">
                                <mat-checkbox class="flex items-center pointer-events-none" [color]="'primary'"
                                    [checked]="isNoteHasLabel(note, label)" disableRipple></mat-checkbox>
                                <span class="ml-1 leading-5">{{label.title}}</span>
                            </span>
                        </button>
                    </ng-container>
                </ng-container>
            </mat-menu>
        </div>
            <!-- Tasks -->
            <!-- <ng-container *ngIf="note?.tasks">
                <div class="mx-2 mt-4 space-y-1.5">
                    <ng-container *ngFor="let task of note.tasks; trackBy: trackByFn; let index = index">
                        <div class="group flex items-center">
                            <mat-checkbox
                                class="flex items-center"
                                [color]="'primary'"
                                [(ngModel)]="task.completed"
                                >
                            </mat-checkbox>
                            <input
                                class="w-full px-1 py-0.5"
                                [ngClass]="{'text-secondary line-through': task.completed}"
                                [placeholder]="'Task'"
                                [(ngModel)]="task.content">
                            <mat-icon
                                class="hidden group-hover:flex ml-auto icon-size-5 cursor-pointer"
                                [svgIcon]="'heroicons_solid:x-mark'"
                                matTooltip="Remove Task"
                                (click)="removeTaskFromNote(note, index)">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <div class="flex items-center">
                        <mat-icon
                            class="-ml-0.5 icon-size-5 text-hint"
                            [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                        <input
                            class="w-full ml-1.5 px-1 py-0.5"
                            [placeholder]="'Add task'"
                            (keydown.enter)="addTaskToNote(note, newTaskInput.value); newTaskInput.value = ''"
                            #newTaskInput>
                    </div>
                </div>
            </ng-container> -->
            <!-- Labels -->
             <div *ngIf="note?._id">
            <ng-container *ngIf="note.labels && note.labels.length">
                <div class="flex flex-wrap items-center mx-1 mt-6">
                    <ng-container *ngFor="let label of note.labels; trackBy: trackByFn">
                        <div class="flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium dark:text-gray-300 dark:bg-gray-700" [ngStyle]="{'background-color': label.color}">
                            <div>
                                {{label.title}}
                            </div>
                            <mat-icon
                                class="ml-1 icon-size-4 cursor-pointer"
                                [svgIcon]="'heroicons_solid:x-circle'"
                                (click)="toggleLabelOnNote(note, label)"></mat-icon>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
            <!-- Add Actions -->
            <!-- <ng-container *ngIf="!note?._id">
                <div class="flex items-center justify-end mt-4">
                    <button mat-flat-button matDialogClose class="mr-2">
                        Close
                    </button>

                    <button mat-flat-button [color]="'primary'" [disabled]="!note.title && !note.content" (click)="saveNote(note)">
                        Save
                    </button>
                </div>
            </ng-container> -->
            <!-- Edit Actions -->
            <ng-container *ngIf="note?._id">
                <div class="flex items-center justify-between mt-4">
                    <div class="flex items-center space-x-2">
                        <!-- Image -->
                        <div>
                            <input id="image-file-input" class="absolute h-0 w-0 opacity-0 invisible pointer-events-none" type="file"
                                [multiple]="false" [accept]="'image/jpeg, image/png'" (change)="uploadImage(note, imageFileInput.files)"
                                #imageFileInput>
                            <label
                                class="flex items-center  mt-3 justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-gray-400 hover:bg-opacity-20 dark:hover:bg-black dark:hover:bg-opacity-5"
                                for="image-file-input" matRipple matTooltip="Image">
                                <mat-icon [svgIcon]="'heroicons_outline:photo'"></mat-icon>
                            </label>
                        </div>
                        <!-- Checklist -->
                        <!-- <button mat-icon-button (click)="addTasksToNote(note)" matRipple matTooltip="Task">
                            <mat-icon [svgIcon]="'heroicons_outline:clipboard-document-list'"></mat-icon>
                        </button> -->
                        <!-- Labels -->
                        <button mat-icon-button [matMenuTriggerFor]="labelsMenu" matTooltip="Label">
                            <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
                        </button>
                        <mat-menu #labelsMenu="matMenu">
                            <ng-container *ngIf="(labels$ | async) as labels">
                                <ng-container *ngFor="let label of labels">
                                    <button mat-menu-item (click)="toggleLabelOnNote(note, label)">
                                        <span class="flex items-center">
                                            <mat-checkbox class="flex items-center pointer-events-none" [color]="'primary'"
                                                [checked]="isNoteHasLabel(note, label)" disableRipple></mat-checkbox>
                                            <span class="ml-1 leading-5">{{label.title}}</span>
                                        </span>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </mat-menu>
                        <!-- Archive -->
                        <button mat-icon-button (click)="toggleArchiveOnNote(note)" [matTooltip]="note?.archived ? 'Unarchive' : 'Archive'">
                            <mat-icon [svgIcon]="note?.archived ? 'heroicons_outline:archive-box-x-mark' : 'heroicons_outline:archive-box'"></mat-icon>
                        </button>
                        <!-- Delete -->
                        <button mat-icon-button (click)="deleteNote(note)" matTooltip="Delete">
                            <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        </button>
                    </div>
                    <div>
                       
                        <!-- <button mat-flat-button matDialogClose class="mr-2">
                            Close
                        </button>
                        
                        <button mat-flat-button [color]="'primary'" [disabled]="!note.title && !note.content" (click)="saveNote(note)">
                            Save
                        </button> -->
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

</div>
