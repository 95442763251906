<div class="flex flex-col justify-between overflow-hidden bg-white h-full">
  <div class="flex flex-0 items-center h-18 px-4 md:px-6 border-b bg-gray-50 dark:bg-transparent">

    <!-- Back button -->
    <!-- <a class="md:-ml-2" mat-icon-button (click)="closeDrawer()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-long-left'"></mat-icon>
    </a> -->

    <!-- Contact info -->
    <div class="flex items-center ml-2 lg:ml-0 mr-2 cursor-pointer">
      <div class="relative flex flex-0 items-center justify-center w-10 h-10">
        <div
          class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
          {{data?.title?.charAt(0)}}
        </div>
      </div>
      <div class="ml-4 text-lg font-medium leading-5 truncate">{{data?.title}}</div>
    </div>

    <button class="ml-auto" mat-icon-button [matMenuTriggerFor]="conversationHeaderMenu" *ngIf="data?.delete_permission">
      <mat-icon [svgIcon]="'heroicons_outline:ellipsis-vertical'"></mat-icon>
      <mat-menu #conversationHeaderMenu>
        <button mat-menu-item (click)="onMessageSelectionFlagChange()">
          <ng-container *ngIf="!enableMessageSelection">
            <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
            Select messages
          </ng-container>
          <ng-container *ngIf="enableMessageSelection">
            <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
            Unselect messages
          </ng-container>
        </button>
        <button mat-menu-item (click)="clearMessages()" *ngIf="selectedMessages?.length">
          <mat-icon [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
          Clear messages
        </button>
      </mat-menu>
    </button>
  </div>


  <div class="conversation-container">
    <!-- Conversation -->
    <div class="flex overflow-y-auto flex-col-reverse conversation">
      <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent">
        <ng-container
          *ngFor="let conversation of conversations; let i = index; let first = first; let last = last; trackBy: trackByFn">
          <!-- Start of the day -->
          <ng-container
            *ngIf="first || (conversations[i - 1]?.createdAt | date:'d') !== (conversation?.createdAt | date:'d')">
            <div class="flex items-center justify-center my-3 -mx-6">
              <div class="flex-auto border-b"></div>
              <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                {{conversation?.createdAt | date: 'longDate'}}
              </div>
              <div class="flex-auto border-b"></div>
            </div>
          </ng-container>

          <div class="flex flex-col pt-2 mt-1" [ngClass]="{'items-end': conversation?.isMine,
                                'items-start': !conversation?.isMine,
                                'bg-blue-200': conversation?.isSelected
                                }" (click)="conversation?.isMine ? selectMessage(conversation) : null">
            <!-- Bubble -->
            <div class="relative max-w-3/4 px-3 py-2 rounded-lg" [ngClass]="{'bg-blue-500 text-blue-50': conversation?.isMine,
                                        'bg-gray-500 text-gray-50': !conversation?.isMine}">
              <!-- Speech bubble tail -->
              <ng-container *ngIf="last || conversations[i + 1]?.isMine !== conversation?.isMine">
                <div class="absolute bottom-0 w-3"
                  [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': conversation?.isMine,
                                                'text-gray-500 -left-1 -ml-px mb-px -scale-x-1': !conversation?.isMine}">
                  <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                </div>
              </ng-container>
              <div *ngIf="!conversation?.isMine && conversation?.sender_user_name" class="pb-2 font-bold">
                {{conversation?.sender_user_name}}
              </div>
              <!-- Message -->
              <div class="min-w-4 leading-5 message" [innerHTML]="conversation?.message">
              </div>
            </div>

            <!-- Time -->
            <ng-container *ngIf="first
                               || last
                               || conversations[i + 1]?.isMine !== conversation?.isMine
                               || conversations[i + 1]?.createdAt !== conversation?.createdAt">
              <div class="my-0.5 text-sm font-medium text-secondary" [ngClass]="{'mr-3': conversation?.isMine,
                                            'ml-3': !conversation?.isMine}">
                {{conversation?.createdAt | date:'HH:mm'}}
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Message field -->
    <div class="flex items-end p-4 border-t bg-gray-50 dark:bg-transparent">
      <mat-form-field class="fuse-mat-dense fuse-mat-rounded fuse-mat-bold w-full ml-4" subscriptSizing="dynamic">
        <textarea matInput cdkTextareaAutosize (keydown.enter)="sendMessage(messageInput)" class="message"
          #messageInput></textarea>
      </mat-form-field>
      <div class="flex items-center h-11 my-px ml-4">
        <button (click)="sendMessage(messageInput)" mat-icon-button>
          <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Speech bubble tail SVG -->
<ng-template #speechBubbleExtension>
  <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
        fill="currentColor" fill-rule="nonzero"></path>
    </g>
  </svg>
</ng-template>