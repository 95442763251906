<div *ngIf="timerAccess">
    <div class="flex flex-row items-center justify-center bg-primary p-2 h-10 w-26 rounded-md cursor-pointer"
        (click)="activity_tracker()" [matTooltip]="clockInData?.type === 'clockIn' ? disTooltip : tooltip"
        [ngClass]="{'bg-red-500': clockInData?.type == 'clockIn', 'bg-primary': clockInData?.type != 'clockIn'}">
        <mat-icon [svgIcon]="'heroicons_outline:clock'" class="text-white font-semibold mr-1"
            style="font-size: 16px;"></mat-icon>
        <div class="block">
            <div *ngIf="clockInData?.type !== 'clockIn'" class="text-sm text-white font-semibold">Check-in</div>
            <div *ngIf="clockInData?.type == 'clockIn'" class="text-sm text-white font-semibold">Check-out</div>
            <div class="text-sm text-white font-normal">{{elapsedTime}}</div>
        </div>
    </div>
</div>

<mat-drawer class="dark:bg-gray-900 dynamic-tabs-container" [style.width]="drawerWidth" [mode]="'over'" [opened]="false"
    [position]="'end'" [disableClose]="true" [autoFocus]="false" #matDrawer>
    <div class="dynamic-tabs-container" *ngIf="dynamicTabsData">
        <app-dynamic-tabs [data]="dynamicTabsData"></app-dynamic-tabs>
    </div>
</mat-drawer>