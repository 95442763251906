<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div
        class="flex settings-responce sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:px-10 bg-default dark:bg-transparent relative">
        <div class="flex-1 min-w-0 setting-padding">
            <!-- Title -->
            <div class="flex items-center">
                <div class="text-3xl  mb-1 font-bold tracking-tight leading-none">
                    Notification
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end ml-auto">
            <button class="ml-4" mat-flat-button type="button" 
                [color]="'primary'" (click)="saveNotificationPermissions()">
                Save
            </button>
        </div>
    </div>

    <div class="flex-auto settings-res p-6 sm:p-10 rounded-t-2xl shadow overflow-y-auto" cdkScrollable>
    <!-- Form -->
    <form [formGroup]="notificationsForm" class="px-6">

        <!-- Alerts Section -->
        <div class="w-full text-xl">Alerts</div>
        <div class="grid grid-cols-1 gap-6 w-full mt-8">

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">Communication</div>
                    <div class="text-md text-secondary">Get news, announcements, and product updates.</div>
                </div>
                <div class="cursor-pointer" (click)="communication.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'communication_notify'" 
                    #communication>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">Security</div>
                    <div class="text-md text-secondary">
                        Get important notifications about your account security.
                    </div>
                </div>
                <div class="cursor-pointer" (click)="securityToggle.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'security_notify'"
                    #securityToggle>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">Meetups</div>
                    <div class="text-md text-secondary">
                        Get an email when a Meetup is posted close to my location.
                    </div>
                </div>
                <div class="cursor-pointer" (click)="meetupsToggle.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'meetups_notify'" #meetupsToggle>
                </mat-slide-toggle>
            </div>
        </div>
        <!-- Alerts Divider -->
        <div class="my-10 border-t"></div>


        <!-- Notification Mode Section -->
        <div class="w-full text-xl">Notification Mode</div>
        <div class="grid grid-cols-1 gap-6 w-full mt-8">

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">SMS</div>
                </div>
                <div class="cursor-pointer" (click)="mode_sms.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'sms_notification'" #mode_sms>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">WhatsApp</div>
                </div>
                <div class="cursor-pointer" (click)="mode_whatsapp.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'whatsapp_notification'"
                    #mode_whatsapp>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">Email</div>
                </div>
                <div class="cursor-pointer" (click)="mode_email.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'email_notification'" #mode_email>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto">
                    <div class="leading-6 font-medium">Push Notification</div>
                </div>
                <div class="cursor-pointer" (click)="mode_push.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'push_notification'" #mode_push>
                </mat-slide-toggle>
            </div>
        </div>
        <!-- Notification Mode Divider -->
        <div class="my-10 border-t"></div>


        <!-- Account Activity Section -->
        <div class="w-full text-xl">Account Activity</div>
        <div class="w-full mt-8 font-medium">Email me when:</div>
        <div class="grid grid-cols-1 gap-4 w-full mt-4">

            <div class="flex items-center justify-between">
                <div class="flex-auto leading-6">
                    Someone comments on one of my items
                </div>
                <div class="cursor-pointer" (click)="comments.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'notify_someone_comments'"
                    #comments>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto leading-6">
                    Someone mentions me
                </div>
                <div class="cursor-pointer" (click)="mention.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'notify_someone_mention'"
                    #mention>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto leading-6">
                    Someone follows me
                </div>
                <div class="cursor-pointer" (click)="follow.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'notify_someone_follows'" #follow>
                </mat-slide-toggle>
            </div>

            <div class="flex items-center justify-between">
                <div class="flex-auto leading-6">
                    Someone replies to my job posting
                </div>
                <div class="cursor-pointer" (click)="inquiry.toggle()"></div>
                <mat-slide-toggle class="ml-2" [color]="'primary'" [formControlName]="'notify_someone_replies'"
                    #inquiry>
                </mat-slide-toggle>
            </div>
        </div>
        <!-- Account Activity Divider -->
        <div class="my-10 border-t"></div>


        <!-- Actions -->
        <!-- <div class="flex items-center justify-end">
            <button mat-stroked-button type="button">
                Cancel
            </button>
            <button class="ml-4" mat-flat-button type="button" 
                [color]="'primary'" (click)="saveNotificationPermissions()">
                Save
            </button>
        </div> -->
    </form>
    </div>

</div>