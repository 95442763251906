<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div
        class="flex flex-row flex-0 sm:items-center sm:justify-between p-6 lg:ml-0 mt-4 sm:mt-0  sm:pb-6 sm:px-10 bg-default dark:bg-transparent relative">
        <div class="flex-1 min-w-0">
            <!-- Title -->
            <div class="flex items-center">
                <div class="text-3xl font-bold tracking-tight leading-none">
                    Security
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end ml-auto">
            <button (click)="save()"
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'">Save
            </button>
        </div>
    </div>
    <div class="flex-auto -mt-16 p-6 sm:p-10 rounded-t-2xl shadow overflow-y-auto" cdkScrollable>
    <!-- Form -->
    <form [formGroup]="securityForm" class="px-6">

        <!-- Section -->
        <!-- <div class="w-full">
            <div class="text-xl">Change your password</div>
            <div class="text-secondary">You can only change your password twice within 24 hours!</div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <div class="sm:col-span-4">
                <mat-form-field
                    class="w-full"
                    [subscriptSizing]="'dynamic'">
                    <mat-label>Current password</mat-label>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:key'"
                        matPrefix></mat-icon>
                    <input
                        [formControlName]="'currentPassword'"
                        type="password"
                        matInput>
                </mat-form-field>
            </div>
            <div class="sm:col-span-4">
                <mat-form-field
                    class="w-full"
                    [subscriptSizing]="'dynamic'">
                    <mat-label>New password</mat-label>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:key'"
                        matPrefix></mat-icon>
                    <input
                        [formControlName]="'newPassword'"
                        type="password"
                        matInput>
                </mat-form-field>
                <div class="mt-1 text-md text-hint">Minimum 8 characters. Must include numbers, letters and special characters.</div>
            </div>
        </div> -->

        <!-- Divider -->
        <div class="my-10 border-t"></div>

        <!-- Section -->
        <div class="w-full">
            <div class="text-xl">Security preferences</div>
            <div class="text-secondary">Keep your account more secure with following preferences.</div>
        </div>
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <!-- 2-step auth -->
            <div class="sm:col-span-4 flex items-center justify-between">
                <div
                    class="flex-auto cursor-pointer"
                    (click)="twoStepToggle.toggle()">
                    <div class="leading-6 font-medium">Enable 2-step authentication</div>
                    <div class="text-md text-secondary">
                        Protects you against password theft by requesting an authentication code via SMS on every login.
                    </div>
                </div>
                <mat-slide-toggle
                    class="ml-4"
                    [color]="'primary'"
                    [formControlName]="'two_step_authentication'"
                    #twoStepToggle>
                </mat-slide-toggle>
            </div>
            <!-- Ask to change password -->
            <div class="sm:col-span-4 flex items-center justify-between">
                <div
                    class="flex-auto cursor-pointer"
                    (click)="askPasswordChangeToggle.toggle()">
                    <div class="leading-6 font-medium">Ask to change password on every 6 months</div>
                    <div class="text-md text-secondary">
                        A simple but an effective way to be protected against data leaks and password theft.
                    </div>
                </div>
                <mat-slide-toggle
                    class="ml-4"
                    [color]="'primary'"
                    [formControlName]="'password_change'"
                    #askPasswordChangeToggle>
                </mat-slide-toggle>
            </div>
        </div>

        <!-- Divider -->
        <div class="my-10 border-t"></div>

        <!-- Actions -->
        <!-- <div class="flex items-center justify-end">
            <button
                mat-stroked-button
                type="button">
                Cancel
            </button>
            <button
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'">Save
            </button>
        </div> -->
    </form>
    </div>

</div>
