import { TextFieldModule } from '@angular/cdk/text-field';
// import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, Input, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SharedModule } from 'app/shared/shared.module';
import { ActivatedRoute, Params } from '@angular/router';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';


@Component({
    selector: 'tenants-account',
    templateUrl: './account.component.html',
    standalone: true,
    imports: [TextFieldModule, SharedModule],
})
export class TenantsAccountComponent implements OnInit {
    @Input() row_data: any;
    @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
    //accountForm: UntypedFormGroup;
    businessType: any;
    selected: boolean = true;
    vatNumFlag: boolean = false;
    preferredMOP: any;
    PaymentsTerms: any;
    companyForm: FormGroup;
    countryList: any;
    tenantId: string;
    selectPaymentTerms: any;
    selectPreferredMOP: any;
    selectBusinessType:any;
    selectCountry: any;
    rowData: any;

    tabPositionList: any = [
        { id: '1', value: 'Top' },
        { id: '2', value: 'Left' },
        { id: '3', value: 'Right' },
    ];

    /**
     * Constructor
     */
    constructor(
        private _commonService: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private _snackbar:SnackbarService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getData();
        if (this.row_data && this.row_data._id) {
            this.tenantId = this.row_data._id
        }
        this.companyForm = this.fb.group({
            company_name : [null],
            contact_person: [null],
            business_type: [null],
            payment_terms: [null],
            preferred_mop: [null],
            address: [null],
            city: [null],
            state: [null],
            pincode: [null],
            country: [null],
            tab_position: [null],
        });

        if(this.tenantId){
            this._commonService.getDataById(MODELS_CONSTANTS.TENANTS,this.tenantId).subscribe((response:any)=>{
                this.rowData = response.data;
                this.companyForm.reset(this.rowData);
                this._changeDetectorRef.markForCheck();
            });
        }
        else {
            this.companyForm.reset();
            this._changeDetectorRef.markForCheck();
        }
    }

    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'long_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    getData() {
        this._commonService
            .getDataByField(
                MODELS_CONSTANTS.VALUE_SET_DETAILS,
                'vs_code',
                'BUSINESS_TYPE'
            )
            .subscribe((response: any) => {
                this.businessType = response.data;
        });
        this._commonService
            .getDataByField(
                MODELS_CONSTANTS.VALUE_SET_DETAILS,
                'vs_code',
                'PREFERRED_MOP'
            )
            .subscribe((response: any) => {
                this.preferredMOP = response.data;
                this.selectPreferredMOP = this.preferredMOP.vsd_value;
        });
        this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS,'vs_code','PAYMENT_TERMS').subscribe((response:any)=>{
            this.PaymentsTerms = response.data;
            this.selectPaymentTerms = this.PaymentsTerms.vsd_value;
        });
        this._commonService
            .getDataByField(
                MODELS_CONSTANTS.VALUE_SET_DETAILS,
                'vs_code',
                'COUNTRIES'
            )
            .subscribe((response: any) => {
                this.countryList = response.data;
                this.selectCountry = this.countryList.vsd_value;
        });
    }

    vat() {
        this.vatNumFlag = true;
        this.selected = true;
    }

    vat2() {
        this.vatNumFlag = false;
        this.selected = false;
    }

    save() {
        if (this.companyForm.valid) {
            const formData = this.companyForm.value;
            //formData['status'] = formData?.status ? 1 : 0;
            if (this.tenantId) {
                formData['_id'] = this.tenantId;
            }
            formData['tenant_id'] = this.tenantId;
            this._commonService.saveRecord(MODELS_CONSTANTS.TENANTS, formData).subscribe({
                    next: (response) => {
                        if (response?.status === 200) {
                            //this.dialogRef?.close();
                            const type = formData?._id ? 'modified' : 'added';
                            this._snackbar.success(
                                `Tenants ${type} successfully`
                            );
                        } else {
                            this._snackbar.error(
                                response?.message ||
                                    MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                            );
                        }
                    },
                    error: (err) => {
                        console.error(err);
                        this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                    },
                });
        } else {
            this._snackbar.warning('Please fill the form');
        }
      }

      public handleAddressChange(place: google.maps.places.PlaceResult) {
        this.companyForm.reset();
        const fullAddress = [];
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (this.componentForm[addressType]) {
    
          const val = place.address_components[i][this.componentForm[addressType]];
          if (addressType === 'address') {
            fullAddress[0] = val;
          } else if (addressType == 'route') {
            fullAddress[1] = val;
          }
    
          if (addressType === 'locality') {
            this.companyForm.controls.city.setValue(val);
          }
          else if (addressType === 'administrative_area_level_1') {
            this.companyForm.controls.state_province.setValue(val)
          } else if (addressType === 'country') {
            this.companyForm.controls.country.setValue(val)
          } else if (addressType === 'city') {
            this.companyForm.controls.country.setValue(val)
          }
           else if (addressType === 'pincode') {
            this.companyForm.controls.postal_code.setValue(val)
          }
        }
      }
      const fullAdr = fullAddress.join(' ');
      this.companyForm.controls.street_address.setValue(fullAdr)
    }
}
