import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { debounceTime, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { NotesService } from '../notes.service';
import { Label, Note } from '../notes.types';


@Component({
    selector       : 'notes-details',
    templateUrl    : './details.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    imports        : [NgIf, MatButtonModule, MatIconModule, FormsModule, TextFieldModule, NgFor, MatCheckboxModule, NgClass, MatRippleModule, MatMenuModule, MatDialogModule, AsyncPipe, MatTooltipModule, MatFormFieldModule, MatInputModule, NgStyle],
})
export class NotesDetailsComponent implements OnInit, OnDestroy
{
    note$: Observable<Note>;
    labels$: Observable<Label[]>;
    initialNote: Note;
    title= 'Add Note';

    noteChanged: Subject<Note> = new Subject<Note>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    labelList: Label[];
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private _data: { note: Note, params?: any },
        private _notesService: NotesService,
        private _matDialogRef: MatDialogRef<NotesDetailsComponent>,
        private _commonService: CommonService,
        private _snackbar: SnackbarService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _authService: AuthService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Edit
        if (this._data.note._id) {
            this.title = 'Edit Note';
            this._notesService.getNoteById(this._data.note._id).subscribe();
            this.note$ = this._notesService.note$;
        } else {
            this.title = 'Add Note';
            const user = this._authService.userInfo;
            // Create an empty note
            const note = {
                user_id  : user?._id,
                title    : '',
                content  : '',
                tasks    : null,
                image    : null,
                labels   : [],
                archived : false,
                createdAt: null,
                updatedAt: null,
            };

            if (this._data?.params) {
                note['reference_type'] = this._data?.params?.reference_type;
                note['reference_type_id'] = this._data?.params?.reference_type_id;
                note['reference_id'] = this._data?.params?.reference_id;
            } else {
                note['reference_type'] = 'menu';
            }

            this.note$ = of(note);
        }

        // Get the labels
        this.labels$ = this._notesService.labels$;

        // Subscribe to note updates
        this.noteChanged
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                switchMap((note) => this.saveNote(note, false))
            )
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.detectChanges();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create a new note
     *
     * @param note
     */
    saveNote(note: Note, isCloseDialog: boolean = true): Observable<any>
    {
        note.labels = this.labelList;
        this._commonService.saveRecord(MODELS_CONSTANTS.NOTES, note).subscribe({
            next: (response: IResponse<any>) => {
                if (response.status === 200) {
                    const message = note?._id ? 'updated' : 'created';
                    this._snackbar.success(`Note ${message} successfully`);
                    if (isCloseDialog) {
                        this._matDialogRef?.close();
                    } else {
                        this._notesService.getNotes(this._data?.params).subscribe();
                    }
                    this._changeDetectorRef.detectChanges();
                } else {
                    this._snackbar.error(
        
                        response?.message || MESSAGE_CONSTANTS.TRY_AGAIN_LATER
    
                    );
                }
            },
            error: (err) => {
                console.error(err);
                this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
            },
        });
        return of();
    }
  

    /**
     * Upload image to given note
     *
     * @param note
     * @param fileList
     */
    uploadImage(note: Note, fileList: FileList): void
    {
        // Return if canceled
        if ( !fileList.length )
        {
            return;
        }

        const allowedTypes = ['image/jpeg', 'image/png'];
        const file = fileList[0];

        // Return if the file is not allowed
        if ( !allowedTypes.includes(file.type) )
        {
            return;
        }

        this._readAsDataURL(file).then((data) =>
        {
            // Update the image
            note.image = data;

            // Update the note
            this.noteChanged.next(note);
        });
    }

    /**
     * Remove the image on the given note
     *
     * @param note
     */
    removeImage(note: Note): void
    {
        note.image = null;

        // Update the note
        this.noteChanged.next(note);
    }

    /**
     * Add an empty tasks array to note
     *
     * @param note
     */
    addTasksToNote(note): void
    {
        if ( !note.tasks )
        {
            note.tasks = [];
        }
    }

    /**
     * Add task to the given note
     *
     * @param note
     * @param task
     */
    addTaskToNote(note: Note, task: string): void
    {
        if ( task.trim() === '' )
        {
            return;
        }

        const obj = {
            content: task,
            completed: false,
        }
        note.tasks.push(obj);
        // Add the task
        // this._notesService.addTask(note, task).subscribe();
    }

    /**
     * Remove the given task from given note
     *
     * @param note
     * @param task
     */
    removeTaskFromNote(note: Note, index: number): void
    {
        // Remove the task
        note.tasks.splice(index, 1);

        // Update the note
        // this.noteChanged.next(note);
    }

    /**
     * Is the given note has the given label
     *
     * @param note
     * @param label
     */
    isNoteHasLabel(note: Note, label: Label): boolean
    {
        return !!note?.labels?.find(item => item._id === label._id);
    }

    /**
     * Toggle the given label on the given note
     *
     * @param note
     * @param label
     */
    toggleLabelOnNote(note: Note, label: Label): void {
        const { _id, title } = label;
        if (!note.labels) {
            note.labels = [];
        }
        if (this.isNoteHasLabel(note, label)) {
            note.labels = note.labels.filter(item => item._id !== label._id)
            .map((e) => {
                return { _id: e._id, title: e.title , color: e.color};
            });
        } else {
            note.labels.push({ _id, title, color: label.color });
            console.log('Label color:', label.color);
        }
        this.labelList = note.labels;
    }


    /**
     * Toggle archived status on the given note
     *
     * @param note
     */
    toggleArchiveOnNote(note: Note): void
    {
        note.archived = !note.archived;

        // Update the note
        this.noteChanged.next(note);

        // Close the dialog
        this._matDialogRef.close();
    }

    /**
     * Update the note details
     *
     * @param note
     */
    updateNoteDetails(note: Note): void
    {
        this.noteChanged.next(note);
    }

    /**
     * Delete the given note
     *
     * @param note
     */
    deleteNote(note: Note): void
    {    
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete note',
            message: `Are you sure you want to delete this note? <br> 
                This action cannot be undone!`,
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });

        confirmation.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.NOTES, note?._id)
                    .subscribe({
                        next: (response: IResponse<any>) => {
                            if (response?.status === 200) {
                                this._snackbar.success(
                                    'Note deleted succefully'
                                );
                                this._matDialogRef.close();
                            } else {
                                this._snackbar.error(
                                    response?.message ||
                                        MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                                );
                            }
                        },
                        error: (err) => {
                            console.error(err);
                            this._snackbar.error(
                                MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                            );
                        },
                    });
            }
        });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    private _readAsDataURL(file: File): Promise<any>
    {
        // Return a new promise
        return new Promise((resolve, reject) =>
        {
            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void =>
            {
                resolve(reader.result);
            };

            // Reject the promise on error
            reader.onerror = (e): void =>
            {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }
}
