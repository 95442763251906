import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef, Input } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
    selector: 'milestone',
    templateUrl: './milestone.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, DatePipe, ReactiveFormsModule, MatTableModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatButtonModule, MatTooltip, MatSortModule],
})
export class MilestoneComponent implements OnInit {
    @Input() row_data: any;
    @ViewChild('milestoneTemplate')
    milestoneTemplate: TemplateRef<any>;
    milestoneForm: FormGroup;
    configForm: FormGroup;
    addEditMileHeader: string = 'Add Milestone';
    dialogRef: MatDialogRef<any>;
    sortDirection: boolean = false;
    sortKey: string = '';
    @ViewChild(MatSort) sort: MatSort;

    milestoneId;
    projectId;
    dataSource;
    displayedColumns: string[] = [
        'title',
        'due_date',
        'description',
        'actions'
    ];
    /**
     * Constructor
     */
    constructor(
        private _commonService: CommonService,
        private fb: FormBuilder,
        private _snackbar: SnackbarService,
        private dialog: MatDialog,
        private _fuseConfirmationService: FuseConfirmationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private snackbar: SnackbarService,
        private route: ActivatedRoute
    ) {
    }
    /**
     * On init
     */
    ngOnInit(): void {
        if (this.row_data && this.row_data._id) {
            this.projectId = this.row_data._id
        } else {
            this.route.params.subscribe((params: Params) => {
                this.projectId = params['id'];
            });
        }
        this.milestoneForm = this.fb.group({
            title: [''],
            description: [''],
            due_date: [''],
            project_id: ['']
        });

        this.getAllData();
    }

    sortData(column: string) {
        if (this.sortKey === column) {
          this.sortDirection = !this.sortDirection;
        } else {
          this.sortKey = column;
          this.sortDirection = false;
        }
        
        if (this.sort && column && this.dataSource.length > 1) {
          this.dataSource.sort((a, b) => {
              const valA = a[column];
              const valB = b[column];
    
              if (valA < valB) {
                  return this.sortDirection ? 1 : -1;
              } else if (valA > valB) {
                  return this.sortDirection ? -1 : 1;
              } else {
                  return 0;
              }
          });
        }
    
        this.dataSource = this.dataSource.slice();
    
        this._changeDetectorRef.detectChanges();
      }

    async getAllData() {
        const result = await this._commonService.getDataByField(MODELS_CONSTANTS.MILESTONE, 'project_id', this.projectId).toPromise();
        if (result?.status === 200) {
            this.dataSource = result?.data || [];
        }
        this._changeDetectorRef.markForCheck();
    }

    addMilestone() {
        this.addEditMileHeader = 'Add Milestone';
        this.milestoneForm.reset();
        this.milestoneId = null;
        this.dialogRef = this.dialog.open(this.milestoneTemplate, {
            autoFocus: false,
            width: '70vw',
            maxHeight: '90vh',
        });
    }

    modifyMilestone(rowData) {
        // rowData.status = rowData?.status == 1 ? true : false;
        this.addEditMileHeader = 'Edit Milestone';
        this.milestoneForm.reset(rowData);
        this.milestoneId = rowData?._id;
        this.dialogRef = this.dialog.open(this.milestoneTemplate, {
            autoFocus: false,
            width: '70vw',
            maxHeight: '90vh',
        });
    }

    save() {
        console.log("milestoneForm", this.milestoneForm.value);
        if (this.milestoneForm.valid) {
            const formData = this.milestoneForm.value;
            formData['project_id'] = this.projectId;
            // formData['status'] = formData?.status ? 1 : 0;
            if (this.milestoneId) {
                formData['_id'] = this.milestoneId;
            }
            this._commonService.saveRecord(MODELS_CONSTANTS.MILESTONE, formData).subscribe({
                next: (response: any) => {
                    if (response?.status === 200) {
                        this.dialogRef?.close();
                        const type = formData?._id ? 'modified' : 'added';
                        this._snackbar.success(
                            `Milestone ${type} successfully`
                        );
                        this.getAllData();
                        this.milestoneId = null;
                    } else {
                        this._snackbar.error(
                            response?.message ||
                            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                        );
                    }
                },
                error: (err) => {
                    console.error(err);
                    this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                },
            });
        } else {
            this._snackbar.warning('Please fill the form');
        }
    }

    deleteMilestone(rowData) {
        this.configForm = this.fb.group({
            title: 'Remove Data',
            message: 'Are you sure you want to remove permanently? <span class="font-medium">This action cannot be undone!</span>',
            icon: this.fb.group({
                show: true,
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn'
            }),
            actions: this.fb.group({
                confirm: this.fb.group({
                    show: true,
                    label: 'Remove',
                    color: 'warn'
                }),
                cancel: this.fb.group({
                    show: true,
                    label: 'Cancel'
                })
            }),
            dismissible: true

        });

        // Open the dialog and save the reference of it
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);
        dialogRef.afterClosed().subscribe((result) => {
            if (result == "confirmed") {
                this._commonService
                    .deleteRecordsById(MODELS_CONSTANTS.MILESTONE, rowData?._id)
                    .subscribe({
                        next: (response: any) => {
                            if (response?.status === 200) {
                                this._snackbar.success(
                                    'Milestone deleted successfully'
                                );
                                this.getAllData();
                            } else {
                                this._snackbar.error(
                                    response?.message ||
                                    MESSAGE_CONSTANTS.TRY_AGAIN_LATER
                                );
                            }
                        },
                        error: (err) => {
                            console.error(err);
                            this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
                        },
                    });
            }
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
