import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { VALUE_SET_CODE_CONSTANTS } from 'app/shared/constants/value-set.contants';
import { IResponse } from 'app/shared/interfaces/response-i';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { SharedModule } from 'app/shared/shared.module';
import { cloneDeep } from 'lodash';
import { lastValueFrom, map, Observable, startWith } from 'rxjs';

@Component({
    selector: 'app-form-header-actions',
    templateUrl: './form-header-actions.component.html',
    styleUrls: ['./form-header-actions.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class FormHeaderActionsComponent implements OnInit {
    @ViewChild('add_action') add_action: TemplateRef<any>;

    @Input('row_data') row_data: any;

    formHeaderActions: any[] = [];
    dialogRef: MatDialogRef<any>;
    apiIcons: any = [];
    action_types: any = [];
    actionHeader: string = 'Add Actions';
    actionId: string;
    setupListView: any;
    formSetups: any;
    eventTypes: any = [];
    refTypesData: any[] = [];
    refKey: string;
    refDisplayKey: string;
    filteredRefTypesData: Observable<any[]>;

    actionForm: FormGroup = this.fb.group({
        icon: [''],
        action_type: ['', [Validators.required]],
        list_view: [''],
        form_render: [''],
        tooltip: [''],
        ref_type: [''],
        ref_id: [''],
        drawer_width: [''],
        class_type: [''],
        configurations: this.fb.group({}),
    });

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _snackbar: SnackbarService,
        private _commonService: CommonService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService
    ) {}

    get selectedActionType() {
        return this.actionForm.get('action_type').value;
    }

    get configurations() {
        return this.actionForm.get('configurations') as FormGroup;
    }

    get actionType(): string {
        return this.actionForm.get('action_type')?.value;
    }

    ngOnInit() {
        this.loadHeaderActions();
        this.getAllIcons();
        this.getActionTypes();
        this.getAllListView();
        this.getFormSetup();

        this.filteredRefTypesData = this.actionForm.get('ref_id').valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }

    private _filter(value: string): any[] {
        const filterValue = value.toLowerCase();

        return this.refTypesData.filter((option) =>
            option?.[this.refDisplayKey]?.toLowerCase()?.includes(filterValue)
        );
    }

    loadHeaderActions() {
        this._commonService
            .getDataByField(
                MODELS_CONSTANTS.FORM_ACTIONS,
                'form_id',
                this.row_data?._id
            )
            .subscribe((response: IResponse<any>) => {
                if (response?.status === 200) {
                    this.formHeaderActions = response?.data || [];
                    this._changeDetectorRef.detectChanges();
                }
            });
    }

    async getRefData(event){
        this.refKey = '';
        this.refDisplayKey = '';
        this.refTypesData = [];

        const refIdformControl = this.actionForm.get('ref_id');
        if (event === 'module') {
            this.refKey = 'module_code';
            this.refDisplayKey = 'module_code';
            await this.getModulesList();
            refIdformControl.addValidators([Validators.required]);
        } else {
            refIdformControl.clearValidators();
        }
        
        refIdformControl.setValue('');
        refIdformControl.updateValueAndValidity();
    }

    async getModulesList() {
        const response = await lastValueFrom(
            this._commonService.getAllData(MODELS_CONSTANTS.MODULES)
        ).catch((err) => console.error(err));
        this.refTypesData = response?.data || [];
    }

    addActionDialog() {
        this.actionHeader = 'Add Actions';
        this.actionForm.reset();
        this.actionId = null;
        this.dialogRef = this._matDialog.open(this.add_action, {
            autoFocus: false,
            maxHeight: '90vh',
            width: '60vw',
        });
    }

    async editActionDialog(action) {
        this.actionHeader = 'Edit Actions';
        this.actionId = action?._id;
        if (action?.configurations) {
            this.resetConfigurations();
            const configurations = this.configurations;
            Object.keys(action?.configurations).forEach((e) => {
                configurations.addControl(e, new FormControl(''));
            });
        }
        if(action?.action_type === 'schedule'){
            await this.getCalendarEventTypes();
        }
        if(action?.ref_type){
            await this.getRefData(action?.ref_type);
        }
        this.actionForm.reset(action);
        this.dialogRef = this._matDialog.open(this.add_action, {
            autoFocus: false,
            maxHeight: '90vh',
            width: '60vw',
        });
    }

    async getAllIcons() {
        try {
            const result = await this._commonService
                .getDataByField(
                    MODELS_CONSTANTS.VALUE_SET_DETAILS,
                    'vs_code',
                    'WIDGET_ICON'
                )
                .toPromise();
            if (result?.status === 200) {
                this.apiIcons = result?.data || [];
                this._changeDetectorRef.markForCheck();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async getActionTypes() {
        try {
            const result = await this._commonService
                .getDataByField(
                    MODELS_CONSTANTS.VALUE_SET_DETAILS,
                    'vs_code',
                    VALUE_SET_CODE_CONSTANTS.ACTION_TYPES
                )
                .toPromise();
            if (result?.status === 200) {
                this.action_types = result?.data || [];
                this._changeDetectorRef.markForCheck();
            }
        } catch (error) {
            console.error(error);
        }
    }

    async getAllListView() {
        const result = await this._commonService
            .getAllData(MODELS_CONSTANTS.LIST_VIEW)
            .toPromise();
        if (result?.status === 200) {
            this.setupListView = result?.data || [];
        }
    }

    async getFormSetup() {
        const result = await this._commonService
            .getAllData(MODELS_CONSTANTS.FORM_SETP)
            .toPromise();
        if (result?.status === 200) {
            this.formSetups = result?.data || [];
            this._changeDetectorRef.markForCheck();
        }
    }

    addAction() {
        if (this.actionForm.valid) {
            const formData = this.actionForm.getRawValue();
            const requestBody = cloneDeep(formData);
            requestBody['form_id'] = this.row_data?._id;
            if(requestBody?.ref_type === 'form'){
                requestBody['ref_id'] = this.row_data?._id;
            }
            if (this.actionId) {
                requestBody['_id'] = this.actionId;
            }

            this.saveAction(requestBody);
        } else {
            console.log(this.actionForm)
        }
    }

    async onActionTypeChange(actionType) {
        this.resetConfigurations();
        const configurationGroup = this.configurations;
        if (actionType === 'form-conversations') {
            configurationGroup.addControl(
                'delete_permission',
                new FormControl('')
            );
            configurationGroup.addControl('class_type', new FormControl(''));
            configurationGroup.updateValueAndValidity();
        } else if (actionType === 'schedule') {
            configurationGroup.addControl('show_events', new FormControl(''));
            await this.getCalendarEventTypes();
            
            this._changeDetectorRef.detectChanges();
            configurationGroup.updateValueAndValidity();
        } else if (actionType === 'form_render'){
            this.actionForm.get('ref_id').clearValidators();
            this.actionForm.get('ref_type').setValue('');
            this.actionForm.get('ref_id').setValue('');
        }
    }

    async getCalendarEventTypes(){
        const result = await this._commonService
        .getDataByField(
            MODELS_CONSTANTS.VALUE_SET_DETAILS,
            'vs_code',
            'EVENT_TYPES'
        )
        .toPromise();
        this.eventTypes = result?.data || [];
    }

    saveAction(body) {
        this._commonService
            .saveRecord(MODELS_CONSTANTS.FORM_ACTIONS, body)
            .subscribe((response: IResponse<any>) => {
                if (response?.status === 200) {
                    const message = body?._id ? 'modified' : 'added';
                    this._snackbar.success(`Action ${message} successfully`);
                    this.loadHeaderActions();
                    this.actionId = null;
                    this.actionForm.reset();
                    this.dialogRef.close();
                }
            });
    }

    deleteAction(action) {
        const confirmation = this._fuseConfirmationService.open({
            title: 'Delete Action',
            message:
                'Are you sure you want to delete this header action? <br> This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });
        confirmation.afterClosed().subscribe(async (result) => {
            if (result === 'confirmed') {
                this._commonService
                    .deleteRecordsById(
                        MODELS_CONSTANTS.FORM_ACTIONS,
                        action?._id
                    )
                    .subscribe((response: IResponse<any>) => {
                        if (response?.status === 200) {
                            this._snackbar.success(
                                'Action deleted successfully'
                            );
                            this.loadHeaderActions();
                        }
                    });
            }
        });
    }

    resetConfigurations() {
        const configurationGroup = this.configurations;
        if (configurationGroup) {
            Object.keys(configurationGroup.controls).forEach((key) => {
                configurationGroup.removeControl(key);
            });
        }
    }
}
