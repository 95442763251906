<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container class="flex-auto h-full bg-card dark:bg-transparent" (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer class="w-full md:w-160 dark:bg-gray-900" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
            <!-- Edit Staff---- -->
        <div class="flex flex-col w-full">

                <!-- Edit mode -->
                <ng-container>
                                <div
                                    class="absolute del-savebtn hover:border-b flex items-center justify-end w-full mx-auto p-3 z-[12]  setBGcolor bg-white">
                                    <div class="flex items-center   dark:bg-transparent">
                                        <!-- Delete -->
                                        <button class="border contact-btn " mat-button [color]="'warn'" [matTooltip]="'Delete'"
                                        (click)="deleteContact()">
                                        Delete
                                        </button>
                                        <button class="ml-2 border cursor-pointer contact-btn" mat-button [color]="'primary'"
                                            [disabled]="contactForm.invalid" [matTooltip]="'Save'" (click)="save()">
                                            Save
                                        </button>
                                    </div>
                                    <a mat-icon-button [matTooltip]="'Close'" (click)="matDrawer.close()" >
                                        <mat-icon class="text-black" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                                    </a>
                                </div>
                                <!-- Avatar -->
                                <div class="relative flex flex-col flex-auto items-center">
                                    <div class="w-full max-w-3xl">
                                        <form [formGroup]="contactForm" class="contact-details">
                                <div class="flex flex-auto justify-between items-end mt-16 px-4">
                                    <div
                                        class="relative flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
                                        <!-- Upload / Remove avatar -->
                                        <div class="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
                                        <div class="absolute inset-0 flex items-center justify-center z-20">
                                            <div>
                                                <input id="avatar-file-input"
                                                    class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                                                    type="file" [multiple]="false" [accept]="'image/jpeg, image/png'"
                                                    (change)="uploadAvatar(avatarFileInput.files)" #avatarFileInput>
                                                <label
                                                    class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer hover:bg-hover"
                                                    for="avatar-file-input" matRipple>
                                                    <mat-icon class="text-white"
                                                        [svgIcon]="'heroicons_outline:camera'"></mat-icon>
                                                </label>
                                            </div>
                                            <div>
                                                <button mat-icon-button (click)="removeAvatar()">
                                                    <mat-icon class="text-white"
                                                        [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- Image/Letter -->
                                        <img class="object-cover w-full h-full" *ngIf="contactForm.value.avatar"
                                            [src]="apiUrl + contactForm.value.avatar">
                                        <div class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                                            *ngIf="!contactForm.value.avatar">
                                            {{contactForm.value?.name?.charAt(0)}}
                                        </div>
                                    </div>
                                    <!-- <div>
                                        <mat-slide-toggle formControlName="enableLogin" color="primary">Enable
                                            Login</mat-slide-toggle>
                                    </div> -->
                                    <div class=" flex m-auto xs:ml-2 mr-1 w-65">
                                        <div class="w-full">
                                <div class="w-full">
                                    <!-- Name -->
                                    <div class="mt-8 flex">
                                        <mat-form-field class="w-full fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                            <mat-label>Name</mat-label>
                                            <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                                            <input matInput [formControlName]="'name'" [placeholder]="'Name'"
                                                [spellcheck]="false">
                                        </mat-form-field>
                                        <!-- Company -->
                                        <!-- <div class="">
                                            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                                <mat-label>Company</mat-label>
                                                <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                    [svgIcon]="'heroicons_solid:building-office-2'"></mat-icon>
                                                <input matInput [formControlName]="'company'" [placeholder]="'Company'">
                                            </mat-form-field>
                                        </div> -->
                                    </div>

                                </div>
                                    </div>
                                    </div>
                                    </div>  

                                    <div class="px-6 columns-2">
                                    <!-- Title -->
                                    <div class="mt-8">
                                        <mat-form-field class="w-full fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                            <mat-label>Job title</mat-label>
                                            <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                [svgIcon]="'heroicons_solid:briefcase'"></mat-icon>
                                            <input matInput [formControlName]="'title'" [placeholder]="'Job title'">
                                        </mat-form-field>
                                    </div>

                                    

                                    <div class="mt-8">
                                        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                                            <mat-label>Role</mat-label>
                                            <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                                            <mat-select [formControlName]="'role_id'">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of roles;let i=index" [value]="item._id">
                                                    {{item.role_name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Emails -->
                                    <div class="mt-8">
                                        <div class="space-y-4">
                                            <ng-container
                                                *ngFor="let email of contactForm.get('emails')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                                <div class="flex">
                                                    <mat-form-field class="flex flex-auto fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                                        <mat-label *ngIf="first">Email</mat-label>
                                                        <mat-label class=" flex ml-2 align-end text-primary w-full">{{ emailLabel(i)
                                                        }} <mat-icon class="ml-auto icon-size-5 cursor-pointer"
                                                        [svgIcon]="'heroicons_solid:plus-circle'" *ngIf="first" matTooltip="Add an email address" (click)="addEmailField()"></mat-icon>
                                                    </mat-label>
                                                        <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                            [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
                                                        <input matInput [formControl]="email.get('email')"
                                                            [placeholder]="'Email address'" [spellcheck]="false">
                                                            <button mat-icon-button [matMenuTriggerFor]="afterMenu"> <mat-icon
                                                                class="hidden sm:flex icon-size-5">more_vert</mat-icon></button>
                                                        <mat-menu class="w-full" #afterMenu="matMenu" xPosition="after">
                                                            <button mat-menu-item value="option1"
                                                                (click)="setPrimary(i)">Primary</button>
                                                            <button mat-menu-item value="option2"
                                                                (click)="setSecondary(i)">Secondary</button>
                                                        </mat-menu>
                                                    </mat-form-field>
                                                    <!-- Remove email -->
                                                    <ng-container *ngIf="!(first && last)">
                                                        <div class="flex items-center w-10 pl-2"
                                                            [ngClass]="{'mt-6': first}">
                                                            <button class="w-8 h-8 min-h-8" mat-icon-button
                                                                (click)="removeEmailField(i)" matTooltip="Remove">
                                                                <mat-icon class="icon-size-5"
                                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- Phone numbers -->
                                    <div class="mt-8">
                                        <div class="space-y-4">
                                            <ng-container
                                                *ngFor="let phoneNumber of contactForm.get('phoneNumbers')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                                <div class="relative flex">
                                                    <mat-form-field class="flex-auto fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                                        <mat-label class="mb-2" *ngIf="first">Phone</mat-label>
                                                        <mat-label class="flex ml-2 mb-2 align-end text-primary w-full">{{ phoneLabel(i)
                                                        }} <mat-icon *ngIf="first"
                                                        class="icon-size-5 ml-auto mb-2 cursor-pointer"
                                                        [svgIcon]="'heroicons_solid:plus-circle'"  matTooltip="Add a phone number"
                                                        (click)="addPhoneNumberField()" ></mat-icon>
                                                    </mat-label>

                                                        <input matInput [formControl]="phoneNumber.get('phoneNumber')"
                                                            [placeholder]="'Phone'">
                                                            <button mat-icon-button [matMenuTriggerFor]="afterMenu"> <mat-icon
                                                                class="hidden sm:flex icon-size-5">more_vert</mat-icon></button>
                                                        <mat-menu class="w-full" #afterMenu="matMenu" xPosition="after">
                                                            <button mat-menu-item value="option1"
                                                                (click)="setPrimaryPhone(i)">Primary</button>
                                                            <button mat-menu-item value="option2"
                                                                (click)="setSecondaryPhone(i)">Secondary</button>
                                                        </mat-menu>
                                                        <mat-select class="mr-1.5"
                                                            [formControl]="phoneNumber.get('country')" matPrefix>
                                                            <mat-select-trigger>
                                                                <span class="flex items-center">
                                                                    <!-- <span
                                                                        class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden"
                                                                        [style.background]="'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'"
                                                                        [style.backgroundSize]="'24px 3876px'"
                                                                        [style.backgroundPosition]="getCountryByIso(phoneNumber.get('country').value)?.flagImagePos"></span> -->
                                                                    <img [src]="'assets/images/flags/' + getCountryByIso(phoneNumber.get('country').value)?.vsd_property_2 + '.svg'"
                                                                        [alt]="getCountryByIso(phoneNumber.get('country').value)?.vsd_value"
                                                                        class="w-6 h-4 mr-1 overflow-hidden">
                                                                    <span
                                                                        class="sm:mx-0.5 font-medium text-default">{{getCountryByIso(phoneNumber.get('country').value)?.vsd_property_1}}</span>
                                                                </span>
                                                            </mat-select-trigger>
                                                            <ng-container
                                                                *ngFor="let country of countries; trackBy: trackByFn">
                                                                <mat-option [value]="country.vsd_property_2">
                                                                    <span class="flex items-center">
                                                                        <!-- <span
                                                                            class="w-6 h-4 overflow-hidden"
                                                                            [style.background]="'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'"
                                                                            [style.backgroundSize]="'24px 3876px'"
                                                                            [style.backgroundPosition]="country?.flagImagePos"></span> -->
                                                                        <img [src]="'assets/images/flags/' + country?.vsd_property_2 + '.svg'"
                                                                            [alt]="country?.vsd_value"
                                                                            class="w-6 h-4 overflow-hidden">
                                                                        <span class="ml-2">{{country?.vsd_value}}</span>
                                                                        <span
                                                                            class="ml-2 font-medium">{{country?.vsd_property_1}}</span>
                                                                    </span>
                                                                </mat-option>
                                                            </ng-container>
                                                        </mat-select>
                                                    </mat-form-field>   
                                                    <!-- Remove phone number -->
                                                    <ng-container *ngIf="!(first && last)">
                                                        <div class="flex items-center w-10 pl-2"
                                                            [ngClass]="{'mt-6': first}">
                                                            <button class="w-8 h-8 min-h-8" mat-icon-button
                                                                (click)="removePhoneNumberField(i)" matTooltip="Remove">
                                                                <mat-icon class="icon-size-5"
                                                                    [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                                            </button>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                        </div>

                                    <!-- Address -->
                                    <div class="mt-8 pt-8">
                                        <mat-form-field class="w-full fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                            <mat-label>Address</mat-label>
                                            <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
                                            <input matInput [formControlName]="'address'" [placeholder]="'Address'">
                                        </mat-form-field>
                                    </div>

                                    <!-- Birthday -->
                                    <div class="mt-8">
                                        <mat-form-field class="w-full fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                            <mat-label>Birthday</mat-label>
                                            <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                [svgIcon]="'heroicons_solid:cake'"></mat-icon>
                                            <input matInput [matDatepicker]="birthdayDatepicker"
                                                [formControlName]="'birthday'" [placeholder]="'Birthday'">
                                            <mat-datepicker-toggle matSuffix [for]="birthdayDatepicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #birthdayDatepicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <!-- Notes -->
                                    <div class="mt-8">
                                        <mat-form-field class="w-full fuse-mat-dense" [subscriptSizing]="'dynamic'">
                                            <mat-label>Notes</mat-label>
                                            <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                                [svgIcon]="'heroicons_solid:bars-3-bottom-left'"></mat-icon>
                                            <textarea matInput [formControlName]="'notes'" [placeholder]="'Notes'"
                                                [rows]="5" [spellcheck]="false" cdkTextareaAutosize></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Edit Staff -->
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->

                <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
                    <div
                        class="flex flex-row flex-0 sm:items-center sm:justify-between p-6 z-10 lg:ml-0 mt-4 sm:mt-0 sm:pb-6 sm:px-10 bg-default dark:bg-transparent relative">
                        <div class="flex-1 min-w-0">
                            <div class="headcontact">
                                <div class="text-3xl font-bold tracking-tight leading-none">
                                    Staffs
                                </div>
                                <div class="ml-0.5 font-medium text-secondary headcontact">
                                    <ng-container *ngIf="contactsCount > 0">
                                        {{contactsCount}}
                                    </ng-container>
                                    {{contactsCount | i18nPlural: {
                                    '=0' : 'No staffs',
                                    '=1' : 'staff',
                                    'other': 'staffs'
                                    } }}
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center justify-end ml-auto">
                            <button class="ml-4" mat-flat-button type="button" [color]="'primary'" (click)="addContact()">Add
                            </button>
                        </div>
                    </div>

                <!-- Contacts list -->
                <div class="relative overflow-auto">
                    <ng-container *ngIf="contacts ">
                        <ng-container *ngIf="contacts.length; else noContacts">
                            <ng-container *ngFor="let contact of contacts; let i = index; trackBy: trackByFn">
                                <!-- Group -->
                                <ng-container
                                    *ngIf="(i === 0 || contact?.name?.charAt(0) !== contacts[i - 1].name?.charAt(0)) && contact?._id !== myContactId">
                                    <div
                                        class="z-10 sticky top-0 -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-secondary bg-gray-50 dark:bg-gray-900">
                                        {{contact?.name?.charAt(0)}}
                                    </div>
                                </ng-container>
                                <!-- Contact -->
                                <a class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b"
                                    [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedContact || selectedContact.id !== contact?._id,
                                                'bg-primary-50 dark:bg-hover': selectedContact && selectedContact.id === contact?._id}" (click)="modifyTask(contact)">
                                    <div
                                        class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                        <ng-container *ngIf="contact?.avatar">
                                            <img class="object-cover w-full h-full" [src]="apiUrl + contact?.avatar"
                                                alt="Contact avatar" />
                                        </ng-container>
                                        <ng-container *ngIf="!contact?.avatar">
                                            <div
                                                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                {{contact?.name?.charAt(0)}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="min-w-0 ml-4">
                                        <div class="font-medium leading-5 truncate">{{contact?._id === myContactId ?
                                            contact?.name + ' (Me)' : contact?.name}}</div>
                                        <div class="leading-5 truncate text-secondary">{{contact?.title}}</div>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <!-- No contacts -->
                    <ng-template #noContacts>
                        <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">There are no
                            contacts!</div>
                    </ng-template>

                </div>

            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>