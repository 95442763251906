import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { catchError, map, Observable, ReplaySubject, switchMap, take, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _commonService: CommonService, private _authService: AuthService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        this.getAll();
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll() {
        let user = this._authService.userInfo;
        this._commonService.getDataByField(MODELS_CONSTANTS.NOTIFICATIONS, 'user_id', user._id).subscribe({
            next: (notifications: any) => {
                if (notifications?.status === 200) {
                    this._notifications.next(notifications?.data);
                }
            },
            error: (err) => {
                return err;
            },
        });
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
                map((newNotification) => {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {
        return this._commonService.saveRecord(MODELS_CONSTANTS.NOTIFICATIONS, notification).pipe(
            tap((notifications: any) => {
                if (notifications?.status === 200) {
                    const index = notifications.findIndex(item => item?._id === id);

                    // Update the notification
                    this._notifications[index] = notification;

                    // Update the notifications
                    this._notifications.next(notifications);
                }
            }),
            map(() => notification),
            catchError(err => {
                // Handle error
                return throwError(err);
            })
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', { params: { id } }).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item?._id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
