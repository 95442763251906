import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AuthService } from 'app/core/auth/auth.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import moment from 'moment';



export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
  providers: [DatePipe]
})
export class EventsComponent implements OnInit {
  @Input() data: any;
  cssRule='';
  calendarOptions: CalendarOptions = {
    firstDay: 1 ,
    contentHeight: 'auto',
    height: '100%',
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin, timeGrigPlugin, listPlugin],
    locale: 'en-gb',
    views: { 
     week: {
       titleFormat:{ year: 'numeric', month: 'long', day: 'numeric'} 
      },
      day: {
       titleFormat:{ year: 'numeric', month: 'long', day: 'numeric'} 
      },
      month: {
       titleFormat:{year: 'numeric', month: 'long'}
      }
    },
    headerToolbar: {
      left: 'today,prev,next',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      center: 'title',
    },
    buttonText: { today: 'Today', month: 'Month', week: 'Week', day: 'Day', list: 'List', listMonth: 'List Month', listYear: 'List Year', listWeek: 'List Week', listDay: 'List Day' },
    eventTimeFormat: { 
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false
    },
    events: [],
    dateClick: this.toggleDateFun.bind(this),
    eventClick: this.callEventDetailsDialog.bind(this)

  };
  eventForm;
  monthList: any = [
    { id: 1, name: "Day(s)" },
    { id: 2, name: "Week(s)" },
    { id: 3, name: "Month(s)" },
    { id: 4, name: "Year(s)" },
  ];
  exceptionList: any = [
    { id: 1, name: "Holiday" },
    { id: 2, name: "Weekend" },
  ];
  repeatFlag: boolean = false;
  eventArr = [];
  eventList: any = [];
  eventId;
  eventTitle;
  eventDescription;
  startDate;
  endDate;
  startTime;
  endTime;
  location;
  editEvent;
  eventColor = '#000';
  eventType;
  labelColor = '#000';
  labelId;
  editFlag=false;
  labelForm;
  labelList;
  projectList;
  shareWithList = [{ name: "Only me", checkedValue: true }, { name: "All team members", checkedValue: false }, { name: "Specific members and teams", checkedValue: false }];
  skipList = [{ name: "Skip", checkedValue: true }, { name: "Skip to next day", checkedValue: false }];
  share_selectedValue = "0";
  skipSelectedValue = "0";
  specificMembers: Boolean = false;
  specificMemberList;
  allMembers = [];
  userDetails;
  holidayList = [];
  leaveList;
  TaskList;
  tenantData;
  AnnouncementList;
  listviewData;
  @ViewChild('eventDialog', { static: false }) eventDialog: TemplateRef<any>;
  @ViewChild('eventDetailsDialog', { static: false }) eventDetailsDialog: TemplateRef<any>;

  constructor(
    private _commonService: CommonService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private detailsDialog:MatDialog,
    private _snackbar: SnackbarService,
    private _fuseConfirmationService: FuseConfirmationService,private datePipe: DatePipe,private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService

  ) { }
  async ngOnInit() {
    await this.loadEvents();
  }

  async loadEvents(){
    this.eventArr = [];
    this.userDetails = this._authService.userInfo;;
    const tntResponse = await this._commonService.getDataById(MODELS_CONSTANTS.TENANTS,this.userDetails?.tenant_id).toPromise();
    if(tntResponse?.status==200){
      this.tenantData = tntResponse?.data;
      this.calendarOptions.firstDay = this.tenantData?.firstday_of_week;
      this.updateCssRules(this.tenantData?.weekend).then(() => {
        document.querySelectorAll(this.cssRule).forEach((dayElement: any) => {
          dayElement.style.color = 'var(--fuse-secondary)';
        });
      });
      this._changeDetectorRef.markForCheck();
    }
    if(!this.data || (this.data && this.data?.show_events?.includes('Holidays'))) {
      const response = await this._commonService.getAllData(MODELS_CONSTANTS.HOLIDAYS).toPromise();
      this.holidayList = response?.data;
      this.holidayList.forEach(ele=>{
        ele.date = this.datePipe.transform(ele.date, 'EEE MMM d yyyy');
      })
    } else {
      this.holidayList = [];
    }
    this.labelForm = this.fb.group({
      labelColour: [''],
      labelName: [''],
    })
    this.eventForm = this.fb.group({
      eventTitle: [''],
      eventDescription: [''],
      startDate: new FormControl(new Date().toISOString()),
      endDate: new FormControl(new Date().toISOString()),
      startTime: [],
      endTime: [],
      project:[],
      label:[],
      location: [],
      duration: [''],
      frequency: [''],
      cycles: [''],
      selectedMember: [],
      exception:[],
      eventColour:[]
    });
    
    await this.getEventType();
    await this.getAllLabels();
    await this.getAllLeave();
    await this.getAllTask();
    await this.getAllAnnouncement();
    const params = {};
    if(this.data) {
      params['reference_type_id'] = this.data?.reference_type_id || null;
      params['reference_type'] = this.data?.reference_type;

      if(this.data?.reference_type !== 'module' && this.data?.reference_id) {
          params['reference_id'] = this.data?.reference_id;
      }
    } else {
      params['reference_type'] = 'menu';
    }
    let eventResponse = { data: [] };
    if(!this.data || (this.data && this.data?.show_events?.includes('Events'))){
      eventResponse = await this._commonService.getDataByFields(MODELS_CONSTANTS.EVENTS, params).toPromise();
    } else {
      eventResponse = { data: [] }
    }
    eventResponse?.data.forEach(async element => {
      let repeatList=[];
      element?.repeatData.forEach(innerArray => {
        innerArray.forEach(element => {
          repeatList.push(element);
        });
      });

      let json:any = {
        title: element?.eventTitle,
        color: element?.eventColour,
        start: new Date(element.startDate),
        end: new Date(element.endDate),
        _id:element?._id,
        eventTitle: element?.eventTitle,
        eventDescription: element?.eventDescription,
        startDate: element?.startDate,
        endDate: element?.endDate,
        extendedProps: {
          department: 'test',
          startTime: moment.utc(element.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
          endTime: moment.utc(element.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
        },
        location: element?.location,
        eventColour: element?.eventColour,
        repeat: element?.repeat,
        repeatData: repeatList[0],
        shareWith: element?.shareWith,
        specific_members: [],
        project: element?.project,
        label: element?.label,
        eventType:'Events'
      }
      let response = await element?.specific_members.find(x => x == this.userDetails?._id);
      if (response?.length){
        element?.specific_members?.forEach((innerArray: any[]) => {
          innerArray?.forEach((item: any) => {
            json.specific_members.push(item);
          });
        });
    
        this.eventArr.push(json);
        if (element?.repeat) {
          let startDate;
          let endDate;
          let repeatData = repeatList[0];
          let i = 1;
          while (i < repeatData?.cycles) {
            if (repeatData?.frequency == 'Day(s)') {
              let sDate = moment(element?.startDate).toDate();
              let eDate = moment(element?.endDate).toDate();
              startDate = moment(sDate.setDate(sDate.getDate() + repeatData?.duration * i)).toDate().toISOString();
              endDate = moment(eDate.setDate(eDate.getDate() + repeatData?.duration * i)).toDate().toISOString();
            }
            else if (repeatData?.frequency == 'Week(s)') {
              let sDate = moment(element?.startDate);
              let eDate = moment(element?.endDate);
              startDate = moment(sDate.week(sDate.week() + repeatData?.duration * i)).toDate().toISOString();
              endDate = moment(eDate.week(eDate.week() + repeatData?.duration * i)).toDate().toISOString();
            }
            else if (repeatData?.frequency == 'Month(s)') {
              let sDate = moment(element?.startDate).toDate();
              let eDate = moment(element?.endDate).toDate();
              startDate = moment(sDate.setMonth(sDate.getMonth() + repeatData?.duration * i)).toDate().toISOString();
              endDate = moment(eDate.setMonth(eDate.getMonth() + repeatData?.duration * i)).toDate().toISOString();
            }
            else if (repeatData?.frequency == 'Year(s)') {
              let sDate = moment(element?.startDate).toDate();
              let eDate = moment(element?.endDate).toDate();
              startDate = moment(sDate.setFullYear(sDate.getFullYear() + repeatData?.duration * i)).toDate().toISOString();
              endDate = moment(eDate.setFullYear(eDate.getFullYear() + repeatData?.duration * i)).toDate().toISOString();
            }
            i = i + 1;
            let json:any = {
              title: element?.eventTitle,
              color: element?.eventColour,
              start: new Date(startDate),
              end: new Date(endDate),
              _id:element?._id,
              eventTitle: element?.eventTitle,
              eventDescription: element?.eventDescription,
              startDate: element?.startDate,
              endDate: element?.endDate,
              location: element?.location,
              eventColour: element?.eventColour,
              repeat: element?.repeat,
              repeatData: repeatList[0],
              shareWith: element?.shareWith,
              specific_members: [],
              project: element?.project,
              label: element?.label,
              exception: [],
              skip: element.skip,
              eventType:'Events',
              extendedProps: {
                department: 'test',
                startTime: moment.utc(element.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                endTime: moment.utc(element.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
              },
            }
            element?.specific_members?.forEach((innerArray: any[]) => {
              innerArray?.forEach((item: any) => {
                json.specific_members.push(item);
              });
            });

            if(repeatData?.exception?.length){
              let jsonStartDate = this.datePipe.transform(json?.start, 'EEE MMM d yyyy');
              repeatData?.exception.some(async element => {
                if (element == 'Holiday') {
                  let res = await this.holidayList.filter(ele => ele?.date == jsonStartDate);
                  if (res?.length) {
                    if (repeatData?.skip == 1) {
                      json.start.setDate(json.start.getDate() + 1);
                      if (repeatData?.exception?.length == 2) {
                        if (repeatData?.exception[1] == 'Weekend') {
                          const day = json?.start.getDay();
                          let filterDay = await this.tenantData?.weekend.filter(ele=>ele==day);
                          if (filterDay?.length == 0) {
                            this.eventArr.push(json);
                            return true;
                          }
                          else{
                            return true;
                          }
                        } else {
                          this.eventArr.push(json);
                          return true;
                        }
                      } else {
                        this.eventArr.push(json);
                        return true;
                      }
                    }
                    else{
                      return true;
                    }
                  } else {
                      if (repeatData?.exception?.length == 2) {
                        if (repeatData?.exception[1] == 'Weekend') {
                          const day = json?.start.getDay();
                          let filterDay = await this.tenantData?.weekend.filter(ele=>ele==day);
                          if (filterDay?.length == 0) {
                            this.eventArr.push(json);
                            return true;
                          }
                          else{
                            return true;
                          }
                        } else {
                          this.eventArr.push(json);
                          return true;
                        }
                      } else {
                        this.eventArr.push(json);
                        return true;
                      }
                  }
                } else if (element == 'Weekend') {
                  const day = json?.start.getDay();
                  let filterDay = await this.tenantData?.weekend.filter(ele=>ele==day);
                  if (filterDay?.length == 0) {
                    this.eventArr.push(json);
                    return true;
                  }
                  else{
                    return true;
                  }
                }
                return false;
              });              
            }
            else{
              this.eventArr.push(json);
            }
  
          }
        }
      }
    });

    if(this.leaveList?.length){
      this.leaveList.forEach(element => {
        let json:any = {
          title: element?.assign_leave_to + '-' + element?.leave_type,
          start: new Date(element.start_date),
          end: new Date(element.end_date),
          color:'var(--fuse-primary-500)',
          eventType:'Leave'
        }
        this.eventArr.push(json);
      });
    }
    if(this.projectList?.length){
      this.projectList.forEach(element => {
        if(!this.data || (this.data && this.data?.show_events?.includes('Project Start Date'))){ 
          let startJson:any = {
            title: element?.projectTitle + '- Start',
            start: new Date(element.startDate),
            end: new Date(element.startDate),
            color:'var(--fuse-primary-500)',
            eventType:'Projects'
          }
          this.eventArr.push(startJson);
        }

        if(!this.data || (this.data && this.data?.show_events?.includes('Project Deadline'))){
          let endJson:any = {
            title: element?.projectTitle + '- Deadline',
            start: new Date(element.endDate),
            end: new Date(element.endDate),
            color:'var(--fuse-primary-500)',
            eventType:'Projects'
          }
          this.eventArr.push(endJson);
        }
      });
    }
    if(this.TaskList?.length){
      this.TaskList.forEach(element => {
        if(!this.data || (this.data && this.data?.show_events?.includes('Task Start Date'))){ 
          let startJson:any = {
            title: element?.task_title + '- Start',
            start: moment(element.start_date).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(element.start_date).format("YYYY-MM-DDTHH:mm:ss"),
            color:'var(--fuse-primary-500)',
            eventType:'Projects'
          }
          this.eventArr.push(startJson);
        }
        
        if(!this.data || (this.data && this.data?.show_events?.includes('Task Deadline'))){ 
          let endJson:any = {
            title: element?.task_title + '- Deadline',
            start: moment(element.end_date).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(element.end_date).format("YYYY-MM-DDTHH:mm:ss"),
            color:'var(--fuse-primary-500)',
            eventType:'Projects'
          }
          this.eventArr.push(endJson);
        }
      });
    }
    
    if(this.AnnouncementList?.length){
      this.AnnouncementList.forEach(element => {
        let json:any = {
          title: element?.title,
          start: new Date(element.start_date),
          end: new Date(element.end_date),
          color:'var(--fuse-primary-500)',
          eventType:'Announcement'
        }
        this.eventArr.push(json);
      });
    }
    this.eventList = this.eventArr;
    this.calendarOptions.events = this.eventArr;
  }

  async getEventType() {
      let lstRes:any = await this._commonService.getAllData(MODELS_CONSTANTS.LIST_VIEW).toPromise();
      this.listviewData = lstRes?.data;
      const result = await this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS,'vs_code','EVENT_TYPES').toPromise();
      if (result?.status === 200) {
        this.eventType = result?.data;
      }

      if (!this.data || (this.data && (this.data?.show_events?.includes('Project Start Date') || this.data?.show_events?.includes('Project Deadline')))) {
          const projectResult = await this._commonService
              .getAllData(MODELS_CONSTANTS.PROJECT)
              .toPromise();
          if (result?.status === 200) {
              this.projectList = projectResult?.data;
          }
      }

      const contactsResult = await this._commonService.getAllData(MODELS_CONSTANTS.CONTACTS).toPromise();
      if (contactsResult?.status === 200) {
        this.specificMemberList = contactsResult?.data;
        this.specificMemberList.map(ele=>{
          if(ele?.user_id)
            this.allMembers.push(ele?.user_id);
        })
      }
  }
  openNew() {
    this.eventId=null;
    this.eventColor = "#000";
    this.eventForm.reset();
    this.refreshFormData();
    this.callEventDialog();
  }
  callEventDialog() {
    let dialogRef = this.dialog.open(this.eventDialog, {
      autoFocus: false,
      maxWidth: '100vw',
      width: '100%',
      height: '100%',
      maxHeight: '100vh',
      panelClass: 'full-screen-dialog'
    })
  }


  async callEventDetailsDialog(arg) {
    let response = arg.event._def.extendedProps;
    let route = 'core/list/'
    let res = await this.listviewData.filter(ele=>ele?.list_view_name == response?.eventType);
    if(res?.length){
      route+=res[0]?._id
      this.router.navigate([route]);
    }
    else{
      this.editEvent = response;
      this.eventId = response?._id;
      this.eventTitle = response?.eventTitle;
      this.eventDescription = response?.eventDescription
      this.startDate = response?.startDate
      this.endDate = response?.endDate
      this.location = response?.location
      let dialogRef = this.detailsDialog.open(this.eventDetailsDialog, {
        width: '800px'
      })
    }
  
  }

  toggleDateFun(arg) {
    this.eventForm.reset();
    this.eventForm.get('startDate').setValue(arg.date);
    this.eventForm.get('endDate').setValue(arg.date);
    this.eventColor = "#000";
    this.refreshFormData();
    this.callEventDialog();
  }
  async toggleEventFun() {
    this.refreshFormData();
    this.detailsDialog.closeAll();
    let response = this.editEvent;

    let idate = new Date(response.startTime)
    let ihours = ("0" + idate.getHours()).slice(-2);
    let iminutes = ("0" + idate.getMinutes()).slice(-2);
    let istr = ihours + ':' + iminutes;
    let startTime = istr

    let odate = new Date(response.endTime)
    let ohours = ("0" + odate.getHours()).slice(-2);
    let ominutes = ("0" + odate.getMinutes()).slice(-2);
    let ostr = ohours + ':' + ominutes;
    let endTime = ostr

    this.eventId = response._id;
    this.eventForm.reset(response);
    this.eventForm.get('startTime').setValue(startTime);
    this.eventForm.get('endTime').setValue(endTime);
    if (response?.repeat) {
      this.repeatFlag = response?.repeat;
      this.eventForm.patchValue(response?.repeatData);
      if (response?.repeatData?.skip) {
        this.skipSelectedValue = response?.repeatData?.skip;
        this.skipList.forEach((obj, index) => {
          if (index.toString() == this.skipSelectedValue)
            obj.checkedValue = true;
          else
            obj.checkedValue = false;
        });
      }
    }
    else
      this.repeatFlag = false;
    this.eventColor = response?.eventColour;

    if (response?.shareWith) {
      this.share_selectedValue = response.shareWith;
      if (this.share_selectedValue == "2") {
        this.eventForm.get('selectedMember').setValue(response.specific_members);
        this.specificMembers = true;
      }
      else
        this.specificMembers = false;

      this.shareWithList.forEach((obj, index) => {
        if (index.toString() == this.share_selectedValue)
          obj.checkedValue = true;
        else
          obj.checkedValue = false;
      });
    }
    
    this.callEventDialog()
  }

  refreshFormData() {
    this.repeatFlag = false;
    this.specificMembers = false;
    this.shareWithList.forEach((obj, index) => {
      if (index == 0)
        obj.checkedValue = true;
      else
        obj.checkedValue = false;
    });
  }

  getTime() {
    let splitInTime = this.eventForm.value.startTime.split(':');
    let IntimeFormat = (splitInTime[0] % 12 ? splitInTime[0] % 12 : 12) + ":" + splitInTime[1] + ' ' + (splitInTime[0] >= 12 ? 'PM' : 'AM')
    let InTimedateFormat = moment(this.eventForm.value.startDate).format("YYYY-MM-DD")
    let Intime = new Date(InTimedateFormat + ' ' + IntimeFormat);
    this.eventForm.value.startTime = moment(Intime).format("YYYY-MM-DDTHH:mm:ss.SSSSZ")

    let splitOutTime = this.eventForm.value.endTime.split(':');
    let OuttimeFormat = (splitOutTime[0] % 12 ? splitOutTime[0] % 12 : 12) + ":" + splitOutTime[1] + ' ' + (splitOutTime[0] >= 12 ? 'PM' : 'AM')
    let OutTimedateFormat = moment(this.eventForm.value.endDate).format("YYYY-MM-DD")
    let Outtime = new Date(OutTimedateFormat + ' ' + OuttimeFormat);
    this.eventForm.value.endTime = moment(Outtime).format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
  }
  
  async save() {
    if (this.eventForm.value.startTime == '' || this.eventForm.value.startTime == null ||
          this.eventForm.value.endTime == '' || this.eventForm.value.endTime == null) {
          let currentDateTime = moment(new Date()).format("YYYY-MM-DD");
          let str = "T00:00:00.4050+05:30"
          this.eventForm.value.startTime = currentDateTime + str;
          this.eventForm.value.endTime = currentDateTime + str;
        }
        else {
          this.getTime();
        }
    let json = this.eventForm?.value
    if(this.eventId)
      json._id = this.eventId;
    json.repeat = this.repeatFlag;
    if (this.repeatFlag) {
      let repeatJson = {
        duration: this.eventForm.value.duration,
        frequency: this.eventForm.value.frequency,
        cycles: this.eventForm.value.cycles,
        exception: this.eventForm.value.exception,
        skip : this.skipSelectedValue.toString(),
      }
      json.repeatData = repeatJson;
    }
    json.shareWith = this.share_selectedValue.toString();
    if (this.share_selectedValue == "2") {
      json.specific_members = this.eventForm.value.selectedMember;
      let res: any = json.specific_members.filter(obj => obj.user_id == this.userDetails._id);
      if (!res.length)
        json.specific_members.push(this.userDetails._id);
    }
    else if (this.share_selectedValue == "1") {
      let res: any = this.allMembers.filter(obj => obj.user_id == this.userDetails._id);
      if (!res.length)
        this.allMembers.push(this.userDetails._id);
      json.specific_members = this.allMembers;
    }
    else {
      this.allMembers = [];
      this.allMembers.push(this.userDetails._id);
      json.specific_members = this.allMembers;
    }
    const sTime = json.startTime.substring(11, 19);
    const eTime = json.endTime.substring(11, 19);
    json.startDate = await this.replaceTime(json.startDate, sTime);
    json.endDate = await this.replaceTime(json.endDate, eTime);
    if(this.data){
      json['reference_type_id'] = this.data?.reference_type_id || null;
      json['reference_type'] = this.data?.reference_type;

      if(this.data?.reference_type !== 'module' && this.data?.reference_id) {
        json['reference_id'] = this.data?.reference_id;
      }
    }
    const eventResponse = await this._commonService.saveRecord(MODELS_CONSTANTS.EVENTS, json).toPromise();
    if (eventResponse.status == 200) {
      const type = json?._id ? 'modified' : 'added';
      this._snackbar.success(`Events ${type} successfully`);
      this.refreshFormData();
      this.dialog.closeAll();
      this.eventForm.reset();
      if(!this.data){
        window.location.reload();    
      } else {
        await this.loadEvents();
        this._changeDetectorRef.detectChanges()
      }
    }
    else {
      console.log("event", "error");
    }
  }

  changeEvent(event) {
    this.repeatFlag = event.checked;
  }

  async deleteEvent() {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Delete Event',
      message: 'Are you sure you want to delete this Event? <br> This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
        cancel: {
          label: 'Cancel'
        }
      }
    });
    confirmation.afterClosed().subscribe(async (result) => {
      if (result === 'confirmed') {
        const eventResponse = await this._commonService
          .deleteRecordsById(MODELS_CONSTANTS.EVENTS, this.eventId)
          .toPromise();
        if (eventResponse.status == 200) {
          this._snackbar.success('Event deleted successfully');
          this.refreshFormData();
          this.dialog.closeAll();
          this.eventForm.reset();
          if (!this.data) {
            window.location.reload();
          } else {
            await this.loadEvents();
            this._changeDetectorRef.detectChanges();
          }
        } else {
          console.log('event', 'error');
        }
      }
    });
  }


  labelFilter(eventdata) {
    if (eventdata.value !== undefined) {
      this.calendarOptions.events = this.eventList.filter((element) => element?.label === eventdata?.value?._id);
    } else {
      this.calendarOptions.events = this.eventList;
    }
  }
  async eventTypeFilter(eventdata) {
    if (eventdata.value != undefined && eventdata?.value?.vsd_value){
      let res = await this.eventList.filter(ele=>ele.eventType==eventdata?.value?.vsd_value)
      this.calendarOptions.events = res;
    }
    else{
      this.calendarOptions.events = this.eventList;
    }
    
  }



  // label function


  addLabelData() {
    this.labelId = '';
    this.labelForm.reset();
    this.editFlag = false;
  }
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, { width: '800px', disableClose: true, autoFocus: false, panelClass: 'Custom-container1' });
  }
  editLabelData(data: any) {
    this.editFlag = true;
    this.labelId = data._id;
    this.labelForm.patchValue(data)
  }
  async saveLabel() {
    let json = this.labelForm?.value;
    json.labelType = 'events';
    if(this.labelId)
      json._id = this.labelId;
    const eventResponse = await this._commonService.saveRecord(MODELS_CONSTANTS.LABEL_MASTER, json).toPromise();
    if(eventResponse?.status == 200){
      this.labelId = '';
      this.editFlag = false;
      this.getAllLabels();
      this.labelForm.reset();
      const type = json?._id ? 'modified' : 'added';
      this._snackbar.success(`Labels ${type} successfully`);
    }
  }
  async getAllLabels() {
    let response: any = await this._commonService.getAllData(MODELS_CONSTANTS.LABEL_MASTER).toPromise();
    if (response.status == 200) {
      this.labelList = response?.data;
    }
  }
  async deleteLabelData() {
    const confirmation = this._fuseConfirmationService.open({
        title: 'Delete Label',
        message: 'Are you sure you want to delete this Label? <br> This action cannot be undone!',
        actions: {
            confirm: {
                label: 'Delete',
            },
            cancel: {
                label: 'Cancel'
            }
        }
    });

    confirmation.afterClosed().subscribe(async (result) => {
        if (result === 'confirmed') {
                const eventResponse = await this._commonService.deleteRecordsById(MODELS_CONSTANTS.LABEL_MASTER, this.labelId).toPromise();
                if (eventResponse.status === 200) {
                    this.labelForm.reset();
                    this.getAllLabels();
                    this._snackbar.success('Labels deleted successfully');
                }
        }
    });
}


  onSelectChange(event: MatRadioChange) {
    this.share_selectedValue = event.value;
    if (event.value == "2")
      this.specificMembers = true;
    else
      this.specificMembers = false;

    this.shareWithList.forEach((obj, index) => {
      if (index == event.value)
        obj.checkedValue = true;
      else
        obj.checkedValue = false;
    })
  }

  skipChange(event: MatRadioChange) {
    this.skipSelectedValue = event.value;

    this.skipList.forEach((obj, index) => {
      if (index == event.value)
        obj.checkedValue = true;
      else
        obj.checkedValue = false;
    })
  }

  replaceTime(datetimeString, newTime: string): Date {
    const originalDate = new Date(datetimeString);
    const year = originalDate.getFullYear();
    const month = originalDate.getMonth();
    const day = originalDate.getDate();
    const [hours, minutes, seconds] = newTime.split(':').map(Number);
    return new Date(year, month, day, hours, minutes, seconds);
  }

  async getAllLeave(){
    if(!this.data || (this.data && this.data?.show_events?.includes('Leave'))){ 
      const response = await this._commonService.getDataByField(MODELS_CONSTANTS.LEAVE,'assign_leave_to',this.userDetails?._id).toPromise();
      this.leaveList = response?.data;
    } else {
      this.leaveList = [];
    }
  }
  async getAllTask(){
    if(!this.data || (this.data && ( this.data?.show_events?.includes('Tast Start Date') || this.data?.show_events?.includes('Tast Deadline') ))){
      const response = await this._commonService.getDataByField(MODELS_CONSTANTS.PROJECT_TASKS,'assing_to',this.userDetails?._id).toPromise();
      this.TaskList = response?.data;
      this.TaskList = await this.TaskList.filter(ele=>ele?.assing_to==this.userDetails?._id);
    } else {
      this.TaskList = [];
    }
  }
  async getAllAnnouncement(){
    if(!this.data){
      const response = await this._commonService.getAllData(MODELS_CONSTANTS.ANNOUNCEMENT).toPromise();
      this.AnnouncementList = response?.data;
    } else {
      this.AnnouncementList = [];
    }
  }

  async updateCssRules(weekendData: string[]): Promise<void> {
  
    const promises = weekendData.map((element,index) => {
      element=element?.toString();
      switch (element) {
        case '0':
          this.cssRule = this.cssRule +' .fc-day-sun a,';
          break;
        case '1':
          this.cssRule = this.cssRule + ' .fc-day-mon a,';
          break;
        case '2':
          this.cssRule = this.cssRule +' .fc-day-tue a,';
          break;
        case '3':
          this.cssRule = this.cssRule + ' .fc-day-wed a,';
          break;
        case '4':
          this.cssRule = this.cssRule + ' .fc-day-thu a,';
          break;
        case '5':
          this.cssRule = this.cssRule + ' .fc-day-fri a,';
          break;
        case '6':
          this.cssRule = this.cssRule + ' .fc-day-sat a,';
          break;
      }
      if(this.tenantData.weekend.length-1 == index)
        this.cssRule = this.cssRule.slice(0, -1)
    })
    await Promise.all(promises);
  }
}
