<div class=" flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
  <div class="relative flex flex-col colmobileres sm:flex-row flex-0 sm:items-center sm:justify-between scrollmobileres px-4 border-b"
    style="align-items:center!important">
    <div class="text-4xl font-extrabold tracking-tight">
      Events

    </div>
    <div class="eventmobileres lable-response ">
      <div class="relative flex  flex-row  rowmobileres eventresponse  flex-0 sm:items-center sm:justify-between">
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript sm-Matfield">
          <mat-select placeholder="Event Label" (selectionChange)="labelFilter($event)">
            <mat-option>-Event label-</mat-option>
            <mat-option *ngFor="let label of labelList;let i=index" [value]="label">
              {{label.labelName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript sm-Matfield events-label">
          <mat-select placeholder="Event Type" (selectionChange)="eventTypeFilter($event)">
            <mat-option>-Event type-</mat-option>
            <mat-option *ngFor="let event of eventType;let i=index" [value]="event">
              {{event.vsd_value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-mini-fab [matTooltip]="'Add'" color="primary" class=" shadow-md ml-2 rotate-on-hover buttonresponse "
        (click)="openNew()" >
        <mat-icon>add</mat-icon>
    </button>
    <button mat-mini-fab [matTooltip]="'Manage Labels'" color="primary" class="  shadow-md ml-2 rotate-on-hover buttonresponse "
    (click)="addLabelData()"
    (click)="openDialogWithTemplateRef(myDialog)">
        <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
    </button>
      </div>
      <div class="relative flex flex-col   paddingmobileres  lg:flex-row  menu-response">
        <div class="hide-text">
        <button class="ml-0  border manageButton" mat-flat-button (click)="addLabelData()"
          (click)="openDialogWithTemplateRef(myDialog)">
          <mat-icon [svgIcon]="'heroicons_outline:tag'"></mat-icon>
          <span class="ml-2 mr-1">Manage Labels</span>
        </button>
      </div>
      <div class="hide-text">
        <button mat-mini-fab [matTooltip]="'Add Event'" color="primary" class="ml-0 menu-response addwidthres shadow-md rotate-on-hover "
        (click)="openNew()" >
        <mat-icon>add</mat-icon>
    </button>
      </div>
      </div>
    </div>

  </div>

  <div class="paddingscrollres">
    <full-calendar #calendar defaultView="dayGridMonth" [options]="calendarOptions" deepChangeDetection="true"
      (dateClick)="openDialogWithTemplateRef(myDialog)" class="cursor-pointer"></full-calendar>
  </div>
</div>


<ng-template #eventDetailsDialog>
  <div style="width: 100%;">
    <div class="flex border-b p-2 py-0">
      <div matDialogTitle class="text-3xl font-normal tracking-tight">Event details</div>
      <mat-icon [matTooltip]="'Close'" mat-dialog-close class="ml-auto">close</mat-icon>
    </div>

    <div class="card-body border-b">
      <div class="row mb15">
        <div class="col-md-12 p-2 capital text-3xl font-normal tracking-tight">{{eventTitle}}</div>
        <div class="col-md-12 p-2 flex">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>&nbsp;
          <div class="capital">{{startDate | date: 'EE, MMMM d'}}, {{startTime | date:'h:mm:ss a'}} - {{endDate |
            date: 'EE, MMMM d'}}, {{endTime | date:'h:mm:ss a'}}</div>
        </div>
        <div class="col-md-12 m-2 w-full h-10 py-2 px-4 desc-color" [ngStyle]="{'border-color': eventColor}">
          {{eventDescription}}
        </div>
        <div class="col-md-12 p-2 flex">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>&nbsp;
          <div class="capital">{{location}}</div>
        </div>
      </div>
    </div>

    <div class="example-button-row mt-3 mb-2 px-2" align="end">
      <button class="ml-0 " mat-flat-button [color]="'primary'" (click)="deleteEvent()">
        <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
        <span class="ml-2 mr-1">Delete</span>
      </button>
      <button class="ml-2 " mat-flat-button [color]="'primary'" (click)="toggleEventFun()">
        <mat-icon [svgIcon]="'heroicons_outline:pencil-square'"></mat-icon>
        <span class="ml-2 mr-1">Edit</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #eventDialog>
<ng-dialog-actions class="overflow-hidden">

  <div class="relative flex flex-row sm:flex-row flex-0 sm:items-center sm:justify-between border-b p-2">

    <div class="text-3xl font-extrabold tracking-tight px-4">Events</div>
    
    <div class="flex flex-row ml-auto">
      <button class="ml-0 menu-response" mat-flat-button [color]="'primary'" (click)="save()">
        <!-- <mat-icon [svgIcon]="'heroicons_outline:check'"></mat-icon> -->
        <span class="ml-2 mr-1">Save</span>
      </button>
    <mat-icon [matTooltip]="'Close'" mat-dialog-close class="mt-1	ml-4">close</mat-icon>
    </div>
  </div>

  <div class="flex-auto -mt-12 rounded-t-2xl shadow overflow-y-auto pt-10 pl-4">
    <form [formGroup]="eventForm" class="px-6" class="overflow-y-auto">
      <div class="form-scroll overflow-x-hidden">
        <div class="grid sm:grid-cols-4 gap-4 w-full mt-8">
          <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Event Title</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:calendar'" matPrefix></mat-icon>
              <input formControlName="eventTitle" matInput>
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Event Description</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:calendar'" matPrefix></mat-icon>
              <input formControlName="eventDescription" matInput>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="startpicker" [placeholder]="'Start Date'"
                      [max]="eventForm.controls['endDate'].value" formControlName="startDate" required autofocus
                      (click)="startpicker.open()" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="startpicker"></mat-datepicker-toggle>
                    <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
          </div>
    
          <div class="sm:col-span-1">
            <mat-label>Start Time</mat-label>
            <div class="border h-12 text-center	flex justify-center	border-slate-300 rounded-md">
              <input matInput type="time" formControlName="startTime" class="cursor-pointer">
            </div>
          </div>
    
          <div class="sm:col-span-1">
            <mat-form-field class="w-full">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="endpicker" [placeholder]="'End Date'"
                      [min]="eventForm.controls['startDate'].value" formControlName="endDate" required autofocus
                      (click)="endpicker.open()" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="endpicker"></mat-datepicker-toggle>
                    <mat-datepicker #endpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 ">
            <mat-label>End Time</mat-label>
            <div class="border h-12 text-center	flex justify-center	border-slate-300 rounded-md">
              <input matInput type="time" formControlName="endTime" class="cursor-pointer">
            </div>
          </div>
        </div>
        <div class="grid sm:grid-cols-4 gap-4 w-full">
          <div class="sm:col-span-1">
            <mat-form-field class="w-full">
              <mat-label>Project</mat-label>
              <mat-select placeholder="Select Project" formControlName="project">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let project of projectList;let i=index" [value]="project._id">
                  {{project.projectTitle}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full">
              <mat-label>Label</mat-label>
              <mat-select placeholder="Select Label" formControlName="label">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let label of labelList;let i=index" [value]="label?._id">
                  {{label?.labelName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-label>Share Width</mat-label>
            <mat-radio-group class="grid fuse-mat-no-subscript w-full mt-1" [color]="'primary'" (change)="onSelectChange($event)">
              <div class="flex flex-row">
                <mat-radio-button class="mr-2" *ngFor="let share of shareWithList; let i=index" [value]="i" [checked]="share.checkedValue">
                  {{share.name}}
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
        <div class="grid sm:grid-cols-4 gap-4 w-full">
          <div class="sm:col-span-1" *ngIf="specificMembers">
            <mat-form-field class="w-full">
              <mat-label>Specific Members</mat-label>
              <mat-select placeholder="Select Member" multiple formControlName="selectedMember">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let member of specificMemberList;let i=index" [value]="member?.user_id">
                  {{member?.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full">
              <mat-label>Location</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:map-pin'" matPrefix></mat-icon>
              <input matInput formControlName="location" [placeholder]="'Location'" [spellcheck]="false">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full">
              <mat-label>Color</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:pencil'" [ngStyle]="{'color': eventColor}" matPrefix></mat-icon>
              <input matInput type="color" formControlName="eventColour" [(ngModel)]="eventColor">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1 row">
            <div class="color-palet2">
              <div class="flex flex-row -mt-1 ml-4">
                <mat-label>Repeat</mat-label>
                <section>
                  <mat-checkbox class="-mt-1.5" color="primary" [checked]="repeatFlag"
                    (change)="changeEvent($event)"></mat-checkbox>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="grid sm:grid-cols-7 gap-4 w-full" *ngIf="repeatFlag">
          <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Duration</mat-label>
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:clock'" matPrefix></mat-icon>
              <input matInput type="number" placeholder="Duration" [spellcheck]="false"
              formControlName="duration">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Frequency</mat-label>
              <mat-select placeholder="Frequency" formControlName="frequency">
                <mat-option *ngFor="let months of monthList;let i=index" [value]="months.name">
                  {{months.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Cycle</mat-label>
              <input matInput type="number" placeholder="Cycles" [spellcheck]="false" formControlName="cycles">
            </mat-form-field>
          </div>
          <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
              <mat-label>Exception</mat-label>
              <mat-select placeholder="Exception" formControlName="exception" multiple>
                <mat-option *ngFor="let excep of exceptionList;let i=index" [value]="excep.name">
                  {{excep.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="sm:col-span-2" *ngIf="eventForm.controls['exception']?.value && eventForm.controls['exception']?.value[0]=='Holiday'">
            <mat-label>Holiday</mat-label>
            <mat-radio-group class="grid fuse-mat-no-subscript w-full mt-1" [color]="'primary'" (change)="skipChange($event)">
              <div class="flex flex-row">
                <mat-radio-button class="mr-2" *ngFor="let skip of skipList; let i=index" [value]="i" [checked]="skip.checkedValue">
                  {{skip.name}}
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div class="sm:col-span-1">
            <div class="flex items-center mt-8">
              <mat-icon class="icon icon-size-5 text-secondary m-auto ml-2 mt-2"
              matTooltip="Recurring will be stopped after the number of cycles. Keep it blank for infinity."
              [svgIcon]="'heroicons_outline:question-mark-circle'">
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-dialog-actions>

</ng-template>

<!-- Label drawer-->
<ng-template #myDialog>
   
<div class="relative pb-4 flex flex-row flex-0 sm:items-center sm:justify-between px-8 border-b">
 <div class="text-3xl font-extrabold tracking-tight">Manage Labels</div>
 <div class="flex flex-row">
  <button class="ml-0 menu-response" mat-flat-button [color]="'primary'" (click)="saveLabel()">
    <!-- <mat-icon [svgIcon]="'heroicons_outline:check'"></mat-icon> -->
    <span class="ml-2 mr-1">Save</span>
  </button>
  <mat-icon [matTooltip]="'Close'" mat-dialog-close class="mt-1	ml-4">close</mat-icon>
</div>
</div>
<mat-dialog-content>
 <div class="modal-body" style="padding-bottom:10px;">
   <form [formGroup]="labelForm" style="width: 100%;" (keydown.enter)="saveLabel()">
     <div class="row label-border">
       <div class="col-12" style="display:contents;">
         <div class="col-12">
           <div class="form-group position-relative">
             <div class="flex w-full manage">
              <div class="flex justify-center align-items-center h-12">
                <input matInput type="color" formControlName="labelColour" [(ngModel)]="labelColor">
              </div>
               <mat-form-field class="col-md-10 fuse-mat-dense fuse-mat-no-subscript w-full">
                 <input matInput formControlName="labelName" [placeholder]="'Label'" [spellcheck]="false">
               </mat-form-field>
               <div *ngIf="editFlag" class="flex delete-cancel-btn" >
                               
                              
                 <div class="bg-card delete-btn" [matTooltip]="'Delete'"  (click)="deleteLabelData()"  [disabled]="(accessRestriction?.delete_self || accessRestriction?.delete_all)? false:true"> 
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5" style="
                     height: 22px !important;
                     margin-top: 2px;
                     width: 26px!important;">
                     <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd" />
                     </svg>                                                   
                 </div>
                 &nbsp;&nbsp;
                 <div class="bg-card edit-btn" [matTooltip]="'Cancel selection'" 
                 (click)="addLabelData()"> 
                     
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="
                     height: 28px !important;
                     width: auto !important;">
                         <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                         </svg>
                 </div>
             </div>
               
             </div>
             <div style="display: flex-rap;" class="label-box ml-10">
               <div class="event-badge large mr5 clickable text-capt" [matTooltip]="'Pick a label'" style="margin-top: 7px;margin-bottom: 7px;" *ngFor="let label of labelList;let i=index"
                 [ngStyle]="{'background-color': label?.labelColour}" (click)="editLabelData(label)">
                 {{label?.labelName}}</div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </form>
 </div>
</mat-dialog-content>

</ng-template>