import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NotesService } from '../notes.service';
import { Label } from '../notes.types';
import { debounceTime, filter, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { IValueSetDetails } from 'app/shared/interfaces/response-i';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Inject, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, Input, OnDestroy, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatMenuModule } from '@angular/material/menu';
import moment from 'moment';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSort, MatSortModule } from '@angular/material/sort';


@Component({
  selector: 'notes-labels',
  templateUrl: './labels.component.html',
  styleUrl: './labels.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, MatDialogModule, ReactiveFormsModule, MatTableModule, MatMenuModule, MatFormFieldModule, MatIconModule, NgIf, NgFor, MatInputModule, CommonModule,
    TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatDatepickerModule, MatTooltip, MatSortModule, AsyncPipe],

})
export class NotesLabelsComponent implements OnInit, OnDestroy {
  labels$: Observable<Label[]>;

  labelChanged: Subject<Label> = new Subject<Label>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  labelInput = '';
  selectedLabel: {color: string; _id: string, title: string} | null = null;

  labelColor = '#83c340';

  labelColorPicker = [
    { id: 1, colorName: 'green', colorCode: '#83c340', colorFlag: true },
    { id: 2, colorName: 'lightblue-1', colorCode: '#29c2c2', colorFlag: false },
    { id: 3, colorName: 'darkblue-1', colorCode: '#2d9cdb', colorFlag: false },
    { id: 4, colorName: 'grey', colorCode: '#aab7b7', colorFlag: false },
    { id: 5, colorName: 'yellow', colorCode: '#f1c40f', colorFlag: false },
    { id: 6, colorName: 'orange', colorCode: '#e18a00', colorFlag: false },
    { id: 7, colorName: 'red', colorCode: '#e74c3c', colorFlag: false },
    { id: 8, colorName: 'pink', colorCode: '#d43480', colorFlag: false },
    { id: 9, colorName: 'purple', colorCode: '#ad159e', colorFlag: false },
    { id: 10, colorName: 'lightblue-2', colorCode: '#37b4e1', colorFlag: false },
    { id: 11, colorName: 'darkblue-2', colorCode: '#34495e', colorFlag: false },
    { id: 12, colorName: 'lightpurple', colorCode: '#dbadff', colorFlag: false },
  ];
  labelColorData: string;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notesService: NotesService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _fuseConfirmationService: FuseConfirmationService,
    private _snackbar: SnackbarService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the labels
    this.labels$ = this._notesService.labels$;

    // Subscribe to label updates
    this.labelChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        filter(label => label.title.trim() !== ''),
        switchMap(label => this._notesService.updateLabel(label, this.data)))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.detectChanges();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  selectLabel(label: { _id: string, title: string, color: string }) {
    this.selectedLabel = label;
    this.labelInput = label.title;
    this.labelColor = label.color;
    this.updateColorFlags(label.color);
  }

  clearSelection() {
    this.selectedLabel = null;
    this.labelInput = '';
    this.labelColor = this.labelColorPicker[0].colorCode; // Reset to default color
    this.updateColorFlags(this.labelColor);
  }

  saveLabel() {
    if (this.selectedLabel) {
      this.updateLabel(this.selectedLabel._id, this.labelInput, this.labelColor);
    } else {
      this.addLabel();
    }
  }
  /**
      * Add label
      *
      * @param title
      */
  addLabel(): void {
    let json = {
      title : this.labelInput,
      color : this.labelColor
    }
    this._notesService.addLabel(json, this.data).subscribe(() => {
      this.clearSelection();
      this._changeDetectorRef.detectChanges();
    });
  }

  updateLabel(id: string, title: string, color: string): void {
    const updatedLabel: Label = { _id: id, title: title, color: color };
    this.labelChanged.next(updatedLabel);
    this.clearSelection();
  }

  deleteLabel(id: string): void {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Remove Label',
      message: 'Are you sure you want to delete this Label? <br> This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
        cancel: {
          label: 'Cancel'
        }
      }
    });
    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this._notesService.deleteLabel(id, this.data).subscribe(() => {
          this._changeDetectorRef.detectChanges();
        });
        this._snackbar.success('Label Removed successfully');
        this.clearSelection();
      }
    });
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // colorPicker(code) {
  //   this.labelColor = code.toString();
  //   let response: any = this.labelColorPicker.filter((element) => element.colorCode == code)
  //   this.labelColorPicker.forEach(element => {
  //     if (response[0].colorCode != element.colorCode) {
  //       element.colorFlag = false;
  //     }
  //     else {
  //       element.colorFlag = true;
  //     }
  //   });
  // }
  colorPicker(color: string) {
    this.labelColor = color;
    if (this.selectedLabel) {
      this.selectedLabel.color = color;
    }
    this.updateColorFlags(color);
  }
  updateColorFlags(selectedColor: string) {
    this.labelColorPicker.forEach(element => {
      element.colorFlag = element.colorCode === selectedColor;
    });
  }
}

