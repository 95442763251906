<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div
        class="flex flex-row flex-0 sm:items-center sm:justify-between p-6 z-10 lg:ml-0 mt-4 sm:mt-0 sm:pb-6 sm:px-10 bg-default dark:bg-transparent relative">
        <div class="flex-1 min-w-0">
            <!-- Title -->
            <div class="flex items-center">
                <div class="text-3xl font-bold tracking-tight leading-none">
                    Location
                </div>
            </div>
        </div>
        <div class="flex items-center justify-end ml-auto">
            <button class="ml-4" mat-flat-button type="button" [color]="'primary'" (click)="addLocation()">Add
            </button>
        </div>
    </div>

    <!-- Form -->
    <div class="flex-auto p-8 sm:p-10 rounded-t-2xl shadow overflow-y-auto" cdkScrollable>
        <!-- -------table------ -->
        <div class="max-w-full overflow-auto table-section">
            <table mat-table [dataSource]="datasource" matSort class="h-fit">
                <ng-container matColumnDef="branch">
                    <th mat-header-cell *matHeaderCellDef class="min-w-30" mat-sort-header (click)="sortData('branch')">
                        Branch </th>
                    <td mat-cell class="viewName" *matCellDef="let element">
                        {{element?.branch}} </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef class="min-w-30" mat-sort-header
                        (click)="sortData('address')"> Address </th>
                    <td mat-cell *matCellDef="let element"> {{element?.address}} </td>
                </ng-container>

                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef class="min-w-30" mat-sort-header (click)="sortData('city')">
                        City </th>
                    <td mat-cell *matCellDef="let element"> {{element?.city}} </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef class="min-w-30" mat-sort-header (click)="sortData('state')">
                        State </th>
                    <td mat-cell *matCellDef="let element"> {{element?.state}} </td>
                </ng-container>

                <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef class="min-w-30" mat-sort-header
                        (click)="sortData('country')"> Country </th>
                    <td mat-cell *matCellDef="let element"> {{element?.country}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="min-w-10"> Actions </th>
                    <td mat-cell *matCellDef="let element;let i = index">
                        <mat-icon class="cursor-pointer edit-icon" [matTooltip]="'Edit'"
                            [svgIcon]="'heroicons_outline:pencil-square'"
                            (click)="modifyLocation(element,i)"></mat-icon>
                        <mat-icon class="cursor-pointer pl-1 del-icon" [matTooltip]="'Delete'"
                            [svgIcon]="'heroicons_outline:trash'" (click)="deleteLocation(i)"></mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            </table>
        </div>
        <!-- -----table----- -->
    </div>

</div>


<ng-template #locationTemplate>
    <div class="flex flex-row w-full add-edit-modal-header py-2">
        {{addEditHeader}}
        <div class="flex justify-end cencel-dialog-icon ml-auto">
            <button mat-flat-button color="primary" class="api-primary-btn mr-8"
                (click)="saveLocation(); $event.preventDefault()">
                Save
            </button>
            <mat-icon class="api-primary-btn cursor-pointer mt-2" [matTooltip]="'Close'"
                (click)="closeDialog()">close</mat-icon>
        </div>
    </div>

    <form [formGroup]="locationForm" class="px-6">
        <!-- <div class="w-full">
            <div class="text-xl">Location Details</div>
            <div class="text-secondary">Location Details</div>
        </div> -->
        <!-- <div class="my-10 border-t"></div> -->

        <div class="grid sm:grid-cols-4 gap-6 w-full mt-2">
            <div class="sm:col-span-2 location-form">
                <mat-form-field class="w-full">
                    <mat-label>Branch</mat-label>
                    <mat-icon class="icon-size-5" matPrefix> location_city</mat-icon>
                    <mat-select [formControlName]="'branch'">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of branchList;let i=index" [value]="item.vsd_code">
                            {{item.vsd_value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2 location-form">
                <mat-form-field class="w-full">
                    <mat-label>Address</mat-label>
                    <mat-icon class="icon-size-5" matPrefix> my_location</mat-icon>
                    <input ngx-google-places-autocomplete [formControlName]="'address'" matInput />
                </mat-form-field>
            </div>
            <div class="sm:col-span-2 location-form">
                <mat-form-field class="w-full">
                    <mat-label>City</mat-label>
                    <mat-icon class="icon-size-5" matPrefix> location_city</mat-icon>
                    <input [formControlName]="'city'" matInput>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2 location-form">
                <mat-form-field class="w-full">
                    <mat-label>State</mat-label>
                    <mat-icon class="icon-size-5" matPrefix>location_on</mat-icon>
                    <input [formControlName]="'state'" matInput>
                </mat-form-field>
            </div>

            <!-- Country -->
            <div class="sm:col-span-2 location-form">
                <mat-form-field class="w-full">
                    <mat-label>Country</mat-label>
                    <mat-icon class="icon-size-5" matPrefix> flag</mat-icon>
                    <input [formControlName]="'country'" matInput>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2 location-form">
                <mat-form-field class="w-full">
                    <mat-label>Pincode</mat-label>
                    <mat-icon class="icon-size-5" matPrefix>pin_drop</mat-icon>
                    <input [formControlName]="'pincode'" matInput>
                </mat-form-field>
            </div>
            <!-- Language -->
            <!-- <div class="sm:col-span-2">
                <mat-form-field
                    class="w-full"
                    [subscriptSizing]="'dynamic'">
                    <mat-label>Language</mat-label>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:globe-alt'"
                        matPrefix></mat-icon>
                    <mat-select>
                        <mat-option [value]="'english'">English</mat-option>
                        <mat-option [value]="'french'">French</mat-option>
                        <mat-option [value]="'spanish'">Spanish</mat-option>
                        <mat-option [value]="'german'">German</mat-option>
                        <mat-option [value]="'italian'">Italian</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
        </div>

        <!-- Divider -->
        <!-- <div class="mt-11 mb-10 border-t"></div> -->

        <!-- Actions -->
        <!-- <div class="flex items-center justify-end">
            <button
                mat-stroked-button
                type="button">
                Cancel
            </button>
            <button
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'">Save
            </button>
        </div> -->
    </form>
</ng-template>