<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container
        class="flex-auto h-full bg-card dark:bg-transparent"
        (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer
            class="w-full sm:w-128 dark:bg-gray-900"
            [mode]="drawerMode"
            [opened]="false"
            [position]="'end'"
            [disableClose]="true"
            #matDrawer>
            @if(data && matDrawer?.opened) {
                <tasks-details [data]="data"></tasks-details>
            } @else {
                <router-outlet></router-outlet>
            }
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex flex-col flex-auto">

                <!-- Header -->
                <div class="sticky top-0 z-20 bg-white border-b dark:bg-transparent flex flex-col sm:flex-row items-start sm:items-center sm:justify-between py-8 px-6 md:px-8">
                    <!-- Title -->
                    <div  class="max-[600px]:m-auto pb-4 md:pb-0">
                        <div class="text-4xl font-extrabold tracking-tight leading-none text-center">TO DOs</div>
                        <div class="ml-0.5 font-medium text-secondary">
                            <span *ngIf="tasksCount.incomplete === 0">All tasks completed!</span>
                            <span *ngIf="tasksCount.incomplete !== 0">{{tasksCount.incomplete}} remaining tasks</span>
                        </div>
                    </div>
                    <!-- Actions -->
                    <div class="mt-4 wfull max-[600px]:m-auto sm:mt-0">
                        <!-- Add section button -->
                        <button class="wfull"
                             mat-flat-button
                            [color]="'accent'"
                            (click)="createTask('section')"
                            [matTooltip]="'Click here to add a Section'">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">Add Section</span>
                        </button>
                        <!-- Add task button -->
                        <button
                        class="wfull ml0 ml2 "
                            mat-flat-button
                            [color]="'primary'"
                            (click)="createTask('task')"
                            [matTooltip]="'Click here to add a Task'">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">Add Task</span>
                        </button>
                    </div>
                </div>

                <!-- Tasks list -->
                <ng-container *ngIf="sections && sections.length > 0; else noSections">
                    <div
                        class="divide-y"
                        cdkDropList
                        [cdkDropListData]="sections"
                        (cdkDropListDropped)="dropped($event)">

                        <!-- Task -->
                        <ng-container *ngFor="let section of sections; let first = first; trackBy: trackByFn">
                            <div
                                class="group w-full select-none hover:bg-gray-100 dark:hover:bg-hover 
                                h-12 text-lg font-semibold bg-gray-50 dark:bg-transparent"
                                [ngClass]="{'text-hint': section.completed}"
                                [class.border-t]="first"
                                cdkDrag
                                [cdkDragLockAxis]="'y'">

                                <!-- Drag preview -->
                                <div
                                    class="flex flex-0 w-0 h-0"
                                    *cdkDragPreview></div>

                                <!-- Task content -->
                                <div class="relative flex items-center h-full pl-10">

                                    <!-- Selected indicator -->
                                    <!-- <ng-container *ngIf="selectedTask && selectedTask._id === task._id">
                                        <div class="z-10 absolute -top-px right-0 -bottom-px flex flex-0 w-1 bg-primary"></div>
                                    </ng-container> -->

                                    <!-- Drag handle -->
                                    <div
                                        class="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                        cdkDragHandle>
                                        <mat-icon
                                            class="icon-size-5 text-hint"
                                            [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                    </div>

                                    <!-- Task link -->
                                    <div
                                        class="flex flex-auto items-center min-w-0 h-full pr-7 cursor-pointer"
                                        (click)="modifyTask(section?._id)">
                                        <!-- Title & Placeholder -->
                                        <div class="flex-auto mr-2 truncate">
                                            <ng-container *ngIf="section.title">
                                                <span>{{section.title}}</span>
                                            </ng-container>
                                            <ng-container *ngIf="!section.title">
                                                <span class="select-none text-hint">{{section.type | titlecase}} title</span>
                                            </ng-container>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <!-- container -->
                            <ng-container *ngIf="section.tasks && section.tasks.length > 0; else noTasks">
                                <div
                                class="divide-y"
                                cdkDropList
                                [cdkDropListData]="section.tasks"
                                (cdkDropListDropped)="dropped($event)">
                                    <ng-container *ngFor="let task of section.tasks; let first = first; trackBy: trackByFn">
                                        <div
                                            [id]="'task-' + task._id"
                                            class="group w-full select-none hover:bg-gray-100 dark:hover:bg-hover h-16"
                                            [ngClass]="{'text-hint': task.completed}"
                                            [class.border-t]="first"
                                            cdkDrag
                                            [cdkDragLockAxis]="'y'">
            
                                            <!-- Drag preview -->
                                            <div
                                                class="flex flex-0 w-0 h-0"
                                                *cdkDragPreview></div>
            
                                            <!-- Task content -->
                                            <div class="relative flex items-center h-full pl-10">
            
                                                <!-- Selected indicator -->
                                                <ng-container *ngIf="selectedTask && selectedTask._id === task._id">
                                                    <div class="z-10 absolute -top-px right-0 -bottom-px flex flex-0 w-1 bg-primary"></div>
                                                </ng-container>
            
                                                <!-- Drag handle -->
                                                <div
                                                    class="md:hidden absolute flex items-center justify-center inset-y-0 left-0 w-8 cursor-move md:group-hover:flex"
                                                    cdkDragHandle>
                                                    <mat-icon
                                                        class="icon-size-5 text-hint"
                                                        [svgIcon]="'heroicons_solid:bars-3'"></mat-icon>
                                                </div>
            
                                                <!-- Complete task button -->
                                                <button
                                                    class="mr-2 -ml-2.5 leading-none"
                                                    (click)="toggleCompleted(task)"
                                                    mat-icon-button>
                                                    <ng-container *ngIf="task.completed">
                                                        <mat-icon
                                                            class="text-primary"
                                                            [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                                                    </ng-container>
                                                    <ng-container *ngIf="!task.completed">
                                                        <mat-icon
                                                            class="text-hint"
                                                            [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
                                                    </ng-container>
                                                </button>
            
                                                <!-- Task link -->
                                                <div
                                                    class="flex flex-auto items-center min-w-0 h-full pr-7 cursor-pointer"
                                                    (click)="modifyTask(task?._id)">
                                                    <!-- Title & Placeholder -->
                                                    <div class="flex flex-row truncate mr-auto">
                                                        <div class="flex-auto mr-2 truncate">
                                                            <ng-container *ngIf="task.title">
                                                                <span>{{task.title}}</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="!task.title">
                                                                <span class="select-none text-hint">{{task.type | titlecase}} title</span>
                                                            </ng-container>
                                                            <div
                                                            class="text-sm whitespace-nowrap text-secondary">
                                                            {{task.notes}}
                                                            </div>
                                                        </div>
                                                    <!-- Priority -->
                                                    <ng-container>
                                                        <div class="w-4 h-4 mr-3">
                                                            <!-- Low -->
                                                            <mat-icon
                                                                class="icon-size-4 text-green-600 dark:text-green-400"
                                                                *ngIf="task.priority === 0"
                                                                [svgIcon]="'heroicons_mini:arrow-long-down'"
                                                                [title]="'Low'"></mat-icon>
                                                            <!-- High -->
                                                            <mat-icon
                                                                class="icon-size-4 text-red-600 dark:text-red-400"
                                                                *ngIf="task.priority === 2"
                                                                [svgIcon]="'heroicons_mini:arrow-long-up'"
                                                                [title]="'High'"></mat-icon>
                                                        </div>
                                                    </ng-container>
                                                    </div>
                                                    <!-- <ng-container *ngIf="task?.tags?.length">
                                                        <ng-container *ngFor="let tag of (task?.tags | fuseFindByKey:'_id':tags); trackBy: trackByFn">
                                                            <div class="flex items-center justify-center px-4 m-1.5 rounded-full leading-9 text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700">
                                                                <span class="text-md font-medium whitespace-nowrap">{{tag?.title}}</span>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container> -->
                                                    <!-- Due date -->
                                                    <div
                                                        class="text-lg whitespace-nowrap text-secondary ml-2">
                                                        {{task.dueDate | date:'LLL dd'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>    
                            </ng-container>
                            <ng-template #noTasks>
                                <div class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent">
                                    <mat-icon
                                        class="icon-size-24"
                                        [svgIcon]="'heroicons_outline:clipboard-document-list'"></mat-icon>
                                    <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">Add a task to start planning!</div>
                                </div>
                            </ng-template>

                        </ng-container>
                    </div>

                </ng-container>

                <ng-template #noSections>
                    <div class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent">
                        <mat-icon
                            class="icon-size-24"
                            [svgIcon]="'heroicons_outline:clipboard-document-list'"></mat-icon>
                        <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">Add a section to start planning!</div>
                    </div>
                </ng-template>

            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>
