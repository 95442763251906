<ng-container *ngIf="taskStatus !== 0">
  <div style="width: -webkit-fill-available;" class="h-full relative P-2">
    <div class="flex items-center justify-end m-auto px-6" *ngIf="!show_next">
      <!-- <button mat-flat-button color="btnb border btn-primary" class="text-sm min-h-8 h-8 pl-2.5 pr-3.5" (click)="pushback_onclick()">
        <mat-icon [svgIcon]="'heroicons_solid:arrow-uturn-left'" class="p-1"></mat-icon>
        Push Back
      </button> -->
      <button *ngIf="enablePushbackFlag" mat-flat-button (click)="onTaskPushback()" class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5 color-accent">
        <mat-icon [svgIcon]="'heroicons_solid:arrow-uturn-left'" class="p-1"></mat-icon>
        Push Back
      </button>
      <button mat-flat-button color="primary" (click)="approve_onclick()" class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5">
        <mat-icon [svgIcon]="'heroicons_solid:check'" class="p-1"></mat-icon>
        Approve
      </button>
      <button mat-flat-button (click)="reject_onclick()" color="warn" class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5">
        <mat-icon [svgIcon]="'heroicons_solid:x-mark'" class="p-1"></mat-icon>
        Reject
      </button>
    </div>
    <div class="flex items-center justify-end m-auto px-6" *ngIf="show_next && !isLastStage">
      <button mat-flat-button color="primary" (click)="next_stage_onclick()" class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5">
        <mat-icon [svgIcon]="'heroicons_solid:check'" class="p-1"></mat-icon>
        Move to next stage
      </button>
      <button *ngIf="enablePushbackFlag" mat-flat-button (click)="onTaskPushback()" class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5 color-accent">
        <mat-icon [svgIcon]="'heroicons_solid:arrow-uturn-left'" class="p-1"></mat-icon>
        Push Back
      </button>
    </div>
  </div>
</ng-container>

<ng-template #pushback_dialog>
  <div class="overflow-hidden border">
    <div class="w-full flex flex-row add-modal-header bg-primary text-white p-3">
      <div class="text-center">Pushback to any completed stage</div>
      <div class="flex flex-row ml-auto justify-end">
        <mat-icon class="text-white text-center cursor-pointer" [matTooltip]="'Close'"
          (click)="close_dialog(); $event.preventDefault()">close</mat-icon>
      </div>
    </div>

    <div class="card-body">
      <div class="row p-4 items-center text-center justify-center">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Stage</mat-label>
          <mat-select placeholder="Select Stage" [(value)]="selectedStage">
            <mat-option value="requester">Pushback to Requester</mat-option>
            <mat-option *ngFor="let stage of approvedStages; let i=index" [value]="stage?._id">
              {{stage?.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex items-center justify-end m-auto px-6">
        <button mat-flat-button color="primary" (click)="close_dialog()" class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5">
          <mat-icon [svgIcon]="'heroicons_solid:x-mark'" class="p-1"></mat-icon>
          Cancel
        </button>
        <button mat-flat-button color="primary" (click)="close_dialog(true)"
          class="ml-3 text-sm min-h-8 h-8 pl-2 pr-3.5">
          <mat-icon [svgIcon]="'heroicons_solid:check'" class="p-1"></mat-icon>
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>