import { TextFieldModule } from '@angular/cdk/text-field';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { SharedModule } from 'app/shared/shared.module';
// import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
// import { Address } from 'ngx-google-places-autocomplete/objects/address';


@Component({
  selector: 'tenants-location',
  standalone: true,
  // imports: [FormsModule, ReactiveFormsModule, MatTableModule, MatFormFieldModule, MatIconModule, MatInputModule, TextFieldModule, MatSelectModule, MatOptionModule, MatButtonModule, MatRadioModule],
  imports: [SharedModule],
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss'
})
export class TenantsLocationComponent {
  //@ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild('locationTemplate', { static: false }) locationTemplate: TemplateRef<any>;
  @Input() row_data: any;
  tenantId: any;
  locationForm: FormGroup;
  configForm: FormGroup;
  addEditHeader: string = 'Add Location';
  dialogRef: MatDialogRef<any>;
  datasource: any;
  locationId: any;
  displayedColumns: string[] = [
    'branch',
    'address',
    'city',
    'state',
    'country',
    'actions'
  ];
  sortDirection: boolean = false;
  sortKey: string = '';
  @ViewChild(MatSort) sort: MatSort;
  index: any;
  branchList: any;

  constructor(private _commonService: CommonService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _snackbar: SnackbarService) {

  }

  async ngOnInit() {
    if (this.row_data && this.row_data._id) {
      this.tenantId = this.row_data._id
    }
    this.locationForm = this.fb.group({
      branch: [null, Validators.required],
      address: [null, Validators.required],
      city: [null],
      state: [null],
      pincode: [null],
      country: [null],
    });

    this.getAllLocation();
    this.getAllBranches();
  }

  getAllLocation() {
    if (this.tenantId) {
      this._commonService.getDataById(MODELS_CONSTANTS.TENANTS, this.tenantId).subscribe((response: any) => {
        this.datasource = response?.data?.location || [];
        this.locationForm.reset();
        this._changeDetectorRef.markForCheck();
      });
    }
    else {
      this.locationForm.reset();
      this._changeDetectorRef.markForCheck();
    }
  }

  getAllBranches() {
    this._commonService.getDataByField(MODELS_CONSTANTS.VALUE_SET_DETAILS, 'vs_code', 'TENANT_LOCATION_BRANCH').subscribe((branchList: any) => {
      console.log('branchlist', branchList)
      if (branchList?.status === 200) {
        this.branchList = branchList?.data || [];
        this._changeDetectorRef.markForCheck();
      }
    })
  }

  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  // public AddressChange(address: Address) {
  //   this.locationForm.controls.address.setValue('');
  //   this.locationForm.controls.city.setValue('');
  //   this.locationForm.controls.state.setValue('');
  //   this.locationForm.controls.country.setValue('');
  //   this.locationForm.controls.postcode.setValue('');
  //   console.log('address',address);
  // }

  addLocation() {
    this.addEditHeader = 'Add Location';
    this.locationForm.reset();
    this.index = null;
    this.dialogRef = this.dialog.open(this.locationTemplate, {
      autoFocus: false,
      width: '70vw',
      maxHeight: '100vh',
    });
  }

  modifyLocation(rowData, index) {
    console.log('index', index)
    this.addEditHeader = 'Edit Location';
    this.locationForm.reset(rowData);
    this.index = index;
    this.dialogRef = this.dialog.open(this.locationTemplate, {
      autoFocus: false,
      width: '70vw',
      maxHeight: '90vh',
    });
  }

  saveLocation() {
    if (this.locationForm.valid) {
      let formData;
      const location = this.locationForm.value;

      if (this.index != null) {
        this.datasource[this.index] = location;
      } else {
        this.datasource.push(location);
      }
      formData = {
        _id: this.tenantId,
        location: this.datasource
      }
      this.save(formData, 'update')
    } else {
      this._snackbar.warning('Please fill the form');
    }
  }

  deleteLocation(index) {
    const confirmation = this._fuseConfirmationService.open({
      title: 'Delete Location',
      message: 'Are you sure you want to delete this Location? <br> This action cannot be undone!',
      actions: {
        confirm: {
          label: 'Delete',
        },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.datasource.splice(index, 1);
        let formData;
        formData = {
          _id: this.tenantId,
          location: this.datasource
        }
        this.save(formData, 'delete');
      }
    });
  }


  save(formData, value) {

    formData['tenant_id'] = this.tenantId;
    this._commonService.saveRecord(MODELS_CONSTANTS.TENANTS, formData).subscribe({
      next: (response) => {
        if (response?.status === 200) {
          this.dialogRef?.close();
          const type = formData?._id ? 'modified' : 'added';
          this._snackbar.success(
            value == 'update' ? `Location ${type} successfully` : `Location deleted successfully`
          );
          this.getAllLocation();
          this.index = null;
        } else {
          this._snackbar.error(
            response?.message ||
            MESSAGE_CONSTANTS.TRY_AGAIN_LATER
          );
        }
      },
      error: (err) => {
        console.error(err);
        this._snackbar.error(MESSAGE_CONSTANTS.TRY_AGAIN_LATER);
      },
    });

  }

  sortData(column: string) {
    if (this.sortKey === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortKey = column;
      this.sortDirection = false;
    }

    if (this.sort && column && this.datasource.length > 1) {
      this.datasource.sort((a, b) => {
        const valA = a[column];
        const valB = b[column];

        if (valA < valB) {
          return this.sortDirection ? 1 : -1;
        } else if (valA > valB) {
          return this.sortDirection ? -1 : 1;
        } else {
          return 0;
        }
      });
    }

    this.datasource = this.datasource.slice();

    this._changeDetectorRef.detectChanges();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
