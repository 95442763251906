import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Signal,
    SimpleChanges,
    WritableSignal,
    computed,
    signal,
} from '@angular/core';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SharedModule } from 'app/shared/shared.module';
import { cloneDeep, isEqual } from 'lodash';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-form-footer',
    templateUrl: './form-footer.component.html',
    styleUrls: ['./form-footer.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class FormFooterComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Output() onFooterActionClick = new EventEmitter();

    footerPermissions: WritableSignal<any> = signal(null);
    footerData: WritableSignal<any> = signal(null);
    isApproval: WritableSignal<boolean> = signal(false);
    clonedData: any;
    workflowTask: any;

    formStatus: Signal<string> = computed(() =>
        this._commonService.getStatusDescription(
            this.isApproval() ? 'y' : 'n',
            this.footerData()?.status || 0
        )
    );

    constructor(private _commonService: CommonService) {}

    ngOnInit() {
        if (this.data) {
            this.data = cloneDeep(this.data);
            this.footerPermissions.set(this.data?.footerPermissions || null);
            
            this.footerData.set(this.data?.footerData || null);
            this.isApproval.set(this.data?.isApproval || false);
            this.getData();
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if ('data' in changes) {
            if(!isEqual(this.data, this.clonedData)) {
                this.data = cloneDeep(this.data);
                this.clonedData = cloneDeep(this.data);
                this.footerPermissions.set(this.data?.footerPermissions || null);
                this.footerData.set(this.data?.footerData || null);
                this.isApproval.set(this.data?.isApproval || false);
                this.workflowTask = null;
                this.getData();
                if(this.isApproval()) {
                    this.getWorkflowTask(this.data?.footerData?._id, this.data?.formId)
                }
            }
        }
    }

    async getData() {
        const isSame =
            this.footerData()?.updated_by === this.footerData()?.created_by;

        if (
            this.footerPermissions()?.show_created_by &&
            this.footerData()?.created_by
        ) {
            const userDetails = await lastValueFrom(
                this._commonService.getDataById(
                    MODELS_CONSTANTS.USERS,
                    this.footerData()?.created_by
                )
            )
                .then((response) => response?.data || null)
                .catch((err) => console.error(err));

            this.footerData.update((data) => {
                data.created_by = userDetails?.name || null;
                return data;
            });
        }

        if (
            this.footerPermissions()?.show_updated_by &&
            this.footerData()?.updated_by
        ) {
            if (
                this.footerPermissions()?.show_created_by &&
                this.footerData()?.created_by &&
                isSame
            ) {
                this.footerData.update((data) => {
                    data.updated_by = this.footerData()?.created_by;
                    return data;
                });
                return;
            }

            const userDetails = await lastValueFrom(
                this._commonService.getDataById(
                    MODELS_CONSTANTS.USERS,
                    this.footerData()?.updated_by
                )
            )
                .then((response) => response?.data || null)
                .catch((err) => console.error(err));

            this.footerData.update((data) => {
                data.updated_by = userDetails?.name || null;
                return data;
            });
        }
    }

    async getWorkflowTask(taskFormId, formId) {
        if(taskFormId && formId) {
            const taskFormData = await this._commonService
                .getDataByFields(MODELS_CONSTANTS.BOARD_TASK_FORM, {
                    ref_type: 'form_render',
                    ref_type_id: formId,
                    ref_id: taskFormId,
                })
                .toPromise()
                .then((res) => res?.data[0])
                .catch((err) => console.error(err));

            if(taskFormData && taskFormData?.task_id) {
                const task = await this._commonService
                    .getDataById(
                        MODELS_CONSTANTS.BOARD_TASK,
                        taskFormData?.task_id
                    )
                    .toPromise()
                    .then((res) => res.data)
                    .catch((err) => console.error(err));
                this.workflowTask = task;
            }
        }
    }

    openComponent(componentRef) {
        const obj = { action_type: componentRef };
        if(this.workflowTask){
            obj['row_data'] = this.workflowTask;
        }
        this.onFooterActionClick.emit(obj);
    }
}
