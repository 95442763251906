<!-- <div class="flex flex-col flex-auto w-63 min-w-86 p-2 md:p-4">
    <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold">Manage labels</div>
        <button
            matDialogClose
            mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <mat-form-field class="fuse-mat-dense w-48 mt-8">
        <input
            name="new-label"
            [autocomplete]="'off'"
            [placeholder]="'Create new label'"
            matInput
            #newLabelInput>
        <button
            [class.invisible]="newLabelInput.value.trim() === ''"
            mat-icon-button
            (click)="addLabel(newLabelInput.value); newLabelInput.value = ''"
            matSuffix>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
        </button>
    </mat-form-field>
    <div class="flex flex-col mt-4">
        <ng-container *ngIf="(labels$ | async) as labels">
            <ng-container *ngFor="let label of labels; trackBy: trackByFn">
                <mat-form-field class="fuse-mat-dense w-full">
                    <input [autocomplete]="'off'" [(ngModel)]="label.title" required matInput #oldLabelInput>
                    <button [class.invisible]="oldLabelInput.value.trim() === ''"
                        mat-icon-button (click)="updateLabel(label);" matSuffix>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                    </button>
                    <button
                    mat-icon-button
                    matSuffix
                    (click)="deleteLabel(label._id)">
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                </button>
                </mat-form-field>
            </ng-container>
        </ng-container>
    </div>
</div> -->
<div class="flex flex-col flex-auto w-63 min-w-86 p-2 md:p-4">
    <!-- Header -->
    <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold">Manage labels</div>
        <button matDialogClose mat-icon-button matTooltip="Close">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'" ></mat-icon>
        </button>
    </div>
      <!-- <div class="color-palet pb-2 overflow-x: scroll;">
        <div *ngFor="let colors of labelColorPicker;let i=index">
            <span class="color-tag clickable mr15" [matTooltip]="'Pick a color'" 
                [ngStyle]="colors.colorFlag ? {'background-color': colors.colorCode,'border-radius': '7px'} : {'background-color': colors.colorCode,'border-radius': '0px'}"
                (click)="colorPicker(colors.colorCode)"></span>
        </div>
    </div> -->
    <!-- New label -->
     <div class="flex flex-row p-4">
        <div class="w-20">
                <mat-form-field appearance="outline" class="w-full">
                    <input
                        matInput
                        type="color"
                        [(ngModel)]="labelColor"
                        (change)="colorPicker($event.target.value)"
                    />
                </mat-form-field>
        </div>
    <mat-form-field class="fuse-mat-dense w-40 ml-4 selected-color-box">
        <input
            name="new-label"
            [autocomplete]="'off'"
            [placeholder]="selectedLabel ? 'Edit label' : 'Create new label'"
            matInput
            [(ngModel)]="labelInput"
            #newLabelInput
            (keydown.enter)="saveLabel()">
            
        <button
            [class.invisible]="newLabelInput.value.trim() === ''"
            mat-icon-button
            (click)="saveLabel()"
            [matTooltip]="'Save'" 
            matSuffix>
            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
        </button>
        
        <button *ngIf="selectedLabel"
        mat-icon-button
        matSuffix
        (click)="deleteLabel(selectedLabel._id)"  [matTooltip]="'Delete'">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
    </button>
    </mat-form-field>
    <div class="ml-2 w-10">
        <button *ngIf="selectedLabel"
        mat-icon-button
        matSuffix
        (click)="clearSelection()"
        [matTooltip]="'Cancel Selection'">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
    </button>
    </div>
</div>
    
    <!-- Labels -->
    <div class="flex flex-row mt-4">
        <ng-container *ngIf="(labels$ | async) as labels">
            <div *ngFor="let label of labels; trackBy: trackByFn" class="flex flex-row">
                <div [ngStyle]="{'background-color': label?.color}"  [matTooltip]="'Pick a label'"  (click)="selectLabel(label)" class="label flex items-center m-1 py-0.5 px-3 rounded-full text-sm font-medium  cursor-pointer">
                    <span class="label-text">{{ label.title }}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>

