<div class="w-full">
    <div class="w-full flex mb-4">
        <div class="lg:ml-0 text-3xl font-bold tracking-tight">
            Milestone
        </div>
        <div class="ml-auto">
            <button mat-raised-button color="primary" class="api-primary-btn" (click)="addMilestone()">
                Add
            </button>
        </div>
    </div>
    <div class="max-w-full overflow-auto table-section">
        <table mat-table [dataSource]="dataSource" matSort class="h-fit">
            <ng-container matColumnDef="due_date">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('due_date')"> Due Date </th>
                <td mat-cell *matCellDef="let element">{{element?.due_date | date:'dd-MM-yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('title')"> Title </th>
                <td mat-cell *matCellDef="let element"> {{element?.title}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="min-w-40" mat-sort-header (click)="sortData('description')"> Description </th>
                <td mat-cell *matCellDef="let element"> {{element?.description}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="min-w-10"> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="cursor-pointer edit-icon" [matTooltip]="'Edit'" [svgIcon]="'heroicons_outline:pencil-square'"
                        (click)="modifyMilestone(element)"></mat-icon>
                    <mat-icon class="cursor-pointer pl-1 del-icon" [matTooltip]="'Delete'" [svgIcon]="'heroicons_outline:trash'"
                        (click)="deleteMilestone(element)"></mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
</div>

<ng-template #milestoneTemplate>
    <div class="flex flex-row w-full add-edit-modal-header py-2">
        {{addEditMileHeader}}
        <div class="flex justify-end cencel-dialog-icon ml-auto">
            <button mat-flat-button color="primary" class="api-primary-btn mr-8"
            (click)="save(); $event.preventDefault()">
            Save
        </button>
            <mat-icon class="api-primary-btn cursor-pointer" [matTooltip]="'Close'" (click)="closeDialog()">close</mat-icon>
        </div>
    </div>
    <form [formGroup]="milestoneForm">
        <div class="w-full">

            <div class="min-[700px]:flex w-full">
                <div class="min-[700px]:w-2/4 pr-1">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Title</mat-label>
                        <input matInput type="text" formControlName="title">
                    </mat-form-field>
                </div>
                <div class="min-[700px]:w-2/4 pr-1">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Description</mat-label>
                        <input matInput type="text" formControlName="description">
                    </mat-form-field>
                </div>
            </div>

            <div class="min-[700px]:flex w-full">
                <div class="min-[700px]:w-2/4 pr-1">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Due Date</mat-label>
                        <input matInput [matDatepicker]="endpicker" formControlName="due_date" required autofocus
                            (click)="endpicker.open()" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="endpicker"></mat-datepicker-toggle>
                        <mat-datepicker #endpicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

        </div>
        <!-- <div class="w-full flex justify-end">
            <button mat-button class="api-primary-btn cancel-btn" (click)="closeDialog();">
                Cancel
            </button>
          
        </div> -->
    </form>
</ng-template>

<ng-template #myDialog>

    <div class="relative pb-4 flex flex-row flex-0 sm:items-center sm:justify-between px-8 border-b">
        <div class="text-3xl font-extrabold tracking-tight">Manage Labels</div>
        <div class="flex flex-row">
            <button class="ml-0 menu-response" mat-flat-button [color]="'primary'" (click)="saveLabel()">
                <mat-icon [svgIcon]="'heroicons_outline:check'"></mat-icon>
                <span class="ml-2 mr-1">Save</span>
            </button>
            <mat-icon [matTooltip]="'Close'" mat-dialog-close class="mt-1	ml-4">close</mat-icon>
        </div>
    </div>
    <mat-dialog-content>
        <div class="modal-body" style="padding-bottom:10px;">
            <form [formGroup]="labelForm" style="width: 100%;">
                <div class="row label-border">
                    <div class="col-12" style="display:contents;">
                        <div class="color-palet pb-2 overflow-x: scroll;">
                            <div *ngFor="let colors of labelColorPicker;let i=index">
                                <span class="color-tag clickable mr15" [matTooltip]="'Pick a color'"
                                    [ngStyle]="colors.colorFlag ? {'background-color': colors.colorCode,'border-radius': '7px'} : {'background-color': colors.colorCode,'border-radius': '0px'}"
                                    (click)="colorPicker(colors.colorCode)"></span>
                            </div>
                        </div><br><br>
                        <div class="col-12">
                            <div class="form-group position-relative">
                                <div class="flex w-full manage">
                                    <mat-form-field class="col-md-10 fuse-mat-dense fuse-mat-no-subscript w-full">
                                        <input matInput formControlName="labelName" [placeholder]="'Label'"
                                            [spellcheck]="false">
                                    </mat-form-field>
                                    <div *ngIf="editFlag" class="flex delete-cancel-btn">


                                        <div class="bg-card delete-btn" [matTooltip]="'Delete'"
                                            (click)="deleteLabelData()"
                                            [disabled]="(accessRestriction?.delete_self || accessRestriction?.delete_all)? false:true">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                fill="currentColor" class="w-5 h-5" style="
                     height: 22px !important;
                     margin-top: 2px;
                     width: 26px!important;">
                                                <path fill-rule="evenodd"
                                                    d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div class="bg-card edit-btn" [matTooltip]="'Cancel selection'"
                                            (click)="addLabelData()">

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="
                     height: 28px !important;
                     width: auto !important;">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                                <div style="display: flex-rap;" class="label-box ml-10">
                                    <div class="event-badge large mr5 clickable text-capt" [matTooltip]="'Pick a label'"
                                        style="margin-top: 7px;margin-bottom: 7px;"
                                        *ngFor="let label of labelList;let i=index"
                                        [ngStyle]="{'background-color': label?.labelColour}"
                                        (click)="editLabelData(label)">
                                        {{label?.labelName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>

</ng-template>