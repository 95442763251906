import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

export function convertToExcel(
    jsonData: any[],
    columnMapping: { key: string; header: string }[],
    fileName: string
) {
    try {
        const mappedData = jsonData.map((row) => {
            const newRow: any = {};
            columnMapping.forEach((mapping) => {
                newRow[mapping.header] = row[mapping.key];
            });
            return newRow;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        saveFile(excelBuffer, fileName + '.xlsx', 'application/octet-stream');
    } catch (error) {
        console.error(error);
    }
}

export async function convertToPdf(
    jsonData: any[],
    columnMapping: { key: string; header: string }[],
    fileName: string
) {
    try {
        const mappedData = jsonData.map((row) => {
            const newRow: any = {};
            columnMapping.forEach((mapping) => {
                newRow[mapping?.header] = row[mapping.key] ?? '';
            });
            return newRow;
        });

        const tableWidth = columnMapping.reduce((acc, column) => {
            return acc + 50;
        }, 0);

        const pageWidthMM = 210;

        const scaleFactor = pageWidthMM / tableWidth;

        const element = document.getElementsByTagName('body');
        let primaryColor;
        if (element && element?.length) {
            const computedStyles = window.getComputedStyle(element[0]);
            if (computedStyles) {
                primaryColor =
                    computedStyles.getPropertyValue('--fuse-primary');
            }
        }

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            content: [
                { text: fileName, style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: columnMapping.map(() => 'auto'),
                        body: [
                            columnMapping.map((mapping) => {
                                return {
                                    text: mapping.header,
                                    style: 'tableHeader',
                                };
                            }),
                            ...mappedData.map((row) =>
                                columnMapping.map(
                                    (mapping) => row[mapping.header]
                                )
                            ),
                        ],
                    },
                    fontSize: 12 * scaleFactor,
                },
            ],
            styles: {
                header: {
                    fontSize: 18 * scaleFactor,
                    bold: true,
                    margin: [0, 0, 0, 10],
                    color: primaryColor || '#4f46e5',
                },
                tableHeader: { bold: true, color: primaryColor || '#4f46e5' },
            },
        };

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        await pdfMake.createPdf(docDefinition).getBuffer((pdfBuffer) => {
            saveFile(pdfBuffer, fileName + '.pdf', 'application/pdf');
        });
    } catch (error) {
        console.error(error);
    }
}

function saveFile(buffer: any, fileName: string, fileType: string) {
    try {
        const data: Blob = new Blob([buffer], { type: fileType });
        const downloadLink: HTMLAnchorElement = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(data);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    } catch (error) {
        console.error(error);
    }
}

export function generateUniqueId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

export function getDataByFieldsString(fieldString: string, data: any) {
    const fields = fieldString?.split('.');
    let value = data;
    for (let field of fields) {
        value = value?.[field];
    }
    return value;
}

export function file_to_key(file) {
    let key = "vortex/";
    if (file.tenant_id) key += file.tenant_id;
    if (file.category) key = `${key}/${file.category}`;
    if (file.sub_category) key = `${key}/${file.sub_category}`;
    key = `${key}/${file.key}`;
    return key;
}