import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MESSAGE_CONSTANTS } from 'app/shared/constants/message.constants';
import { MODELS_CONSTANTS } from 'app/shared/constants/models.constants';
import { CommonService } from 'app/shared/services/common/common.service';
import { SnackbarService } from 'app/shared/services/snackbar/snackbar.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-app-config',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSlideToggleModule, MatButtonModule,AngularEditorModule],
  templateUrl: './app-config.component.html',
  styleUrl: './app-config.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppConfigComponent {
  configForm: FormGroup;
  @Input() row_data: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  constructor(private fb: FormBuilder, private _commonService:CommonService, private _snackbar:SnackbarService){

  }
  ngOnInit(){
    if(typeof this.row_data?.config_value =='object')
      this.row_data.config_value=JSON.stringify(this.row_data?.config_value);

    this.configForm= this.fb.group({
      code: [''],
      category: [''],
      config_value: [''],
      status: [''],
    });
    this.configForm.reset(this.row_data);
  }
  async save(){
    const formData = this.configForm?.value;
    if (this.row_data?._id) {
      formData['_id'] = this.row_data?._id;
    }
    let response = await this._commonService.saveRecord(MODELS_CONSTANTS.APP_CONFIGS, formData).toPromise();
    if (response?.status === 200) {
      const type = this.row_data?._id ? 'modified' : 'added';
      this._snackbar.success(
        `App Config ${type} successfully`
      );
    } else {
      this._snackbar.error(
        response?.message ||
        MESSAGE_CONSTANTS.TRY_AGAIN_LATER
      );
    }
  }
}
