<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">

    <mat-drawer-container
        class="flex-auto h-full bg-card dark:bg-transparent"
        (backdropClick)="onBackdropClicked()">

        <!-- Drawer -->
        <mat-drawer
            class="w-full md:w-160 dark:bg-gray-900"
            [mode]="drawerMode"
            [opened]="false"
            [position]="'end'"
            [disableClose]="true"
            #matDrawer>
            <router-outlet></router-outlet>
        </mat-drawer>

        <mat-drawer-content class="flex flex-col">

            <!-- Main -->
            <div class="flex-auto">

                <!-- Header -->
                <div class="flex headcontact1  justify-between py-8 px-6 md:px-8 border-b dark:bg-transparent contacts-container">

                    <!-- Title -->
                    <div class="headcontact">
                        <div class="text-4xl font-extrabold tracking-tight leading-none">Users</div>
                        <div class="ml-0.5 font-medium text-secondary headcontact">
                            <ng-container *ngIf="contactsCount > 0">
                                {{contactsCount}}
                            </ng-container>
                            {{contactsCount | i18nPlural: {
                            '=0'   : 'No Users',
                            '=1'   : 'Users',
                            'other': 'Users'
                        } }}
                        </div>
                    </div>

                    <!-- Main actions -->
                    <div class="flex topbtns items-start mt-4 sm:mt-0 md:mt-4">
                        <!-- Search -->
                        <div class="flex-auto w-full mb-2">
                            <mat-form-field
                                class="fuse-mat-dense fuse-mat-rounded w-full min-w-50"
                                subscriptSizing="dynamic">
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix
                                    [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
                                <input
                                    matInput
                                    [formControl]="searchInputControl"
                                    [autocomplete]="'off'"
                                    [placeholder]="'Search contacts'">
                            </mat-form-field>
                        </div>

                        <!-- Filter contacts by tenant -->
                        <div class="flex addtext">
                            <mat-form-field appearance="outline" class="ml-4 dropdown" subscriptSizing="dynamic">
                                  <mat-select [(ngModel)]="selectedTenant" (selectionChange)="onTenantChange($event?.value)">
                                @for(tenant of tenants; track tenant._id){
                                    <mat-option [value]="tenant?.tenant_id">{{tenant?.name}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                      
                        <!-- Add contact button -->
                        <!-- <button
                            class="ml-4"
                            mat-flat-button
                            [color]="'primary'"
                            (click)="createContact()">
                            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                            <span class="ml-2 mr-1">Add</span>
                        </button> -->
                        <button mat-mini-fab [matTooltip]="'Add'" color="primary" class=" shadow-md ml-4 rotate-on-hover "  [color]="'primary'"
                        (click)="createContact()">
                        <mat-icon>add</mat-icon>
                    </button>
                        </div>
                    </div>
                </div>

                <!-- Contacts list -->
                <div class="relative">
                    <ng-container *ngIf="contacts$ | async as contacts">
                        <ng-container *ngIf="contacts.length; else noContacts">
                            <ng-container *ngFor="let contact of contacts; let i = index; trackBy: trackByFn">
                                <!-- Group -->
                                <ng-container *ngIf="(i === 0 || contact?.name?.charAt(0) !== contacts[i - 1].name?.charAt(0)) && contact?._id !== myContactId">
                                    <div class="z-10 sticky -mt-px px-6 py-1 md:px-8 border-t border-b font-medium uppercase text-secondary bg-gray-50 dark:bg-gray-900 top-position">
                                        {{contact?.name?.charAt(0)}}
                                    </div>
                                </ng-container>
                                <!-- Contact -->
                                <a
                                    class="z-20 flex items-center px-6 py-4 md:px-8 cursor-pointer border-b"
                                    [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedContact || selectedContact.id !== contact?._id,
                                                'bg-primary-50 dark:bg-hover': selectedContact && selectedContact.id === contact?._id}"
                                    [routerLink]="['./', contact?._id]">
                                    <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                                        <ng-container *ngIf="contact?.avatar">
                                            <img
                                                class="object-cover w-full h-full"
                                                [src]="baseUrl + contact?.avatar"
                                                alt="Contact avatar"/>
                                        </ng-container>
                                        <ng-container *ngIf="!contact?.avatar">
                                            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                                {{contact?.name?.charAt(0)}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="min-w-0 ml-4">
                                        <div class="font-medium leading-5 truncate">{{contact?._id === myContactId ? contact?.name + ' (Me)'  : contact?.name}}</div>
                                        <div class="leading-5 truncate text-secondary">{{contact?.title}}</div>
                                    </div>
                                </a>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <!-- No contacts -->
                    <ng-template #noContacts>
                        <div class="p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center">There are no contacts!</div>
                    </ng-template>

                </div>

            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>
